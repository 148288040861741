import React, {useState, useEffect, Component } from "react";
import Axios from '../api/axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";



const names = [
  {
    "title" : "mr",
    "firstname" : "Lawson",
    "lastname" : "Luke",
    "age" : 28,
    "occupation" : "Software Developer",
    "hobby" : "coding"
  },
  {
    "title" : "mr",
    "firstname" : "Michael",
    "lastname" : "Jackson",
    "age" : 35,
    "occupation" : "Singer",
    "hobby" : "dancing"
  },
  {
    "title" : "mr",
    "firstname" : "Janet",
    "lastname" : "Jackson",
    "age" : 35,
    "occupation" : "Singer",
    "hobby" : "dancing"
  }
]



{/*class DataTables extends Component {*/}

const DataTables = () => {
    const [DBtableViewBusinesses, setDBtableViewBusinesses] = useState([]);

    useEffect( () => {
        Axios.get("http://localhost:3001/api/getViewBusinesses").then((response) => {
            setDBtableViewBusinesses(response.data);
            console.log(response); 
            });
        },
        [])
{/* componentDidMount = ()=>{ */}
 if (!$.fn.DataTable.isDataTable("#myTable")) {
            $(document).ready(function () {
              setTimeout(function () {
                $("#table").dataTable().fnDestroy();
                $("#table").DataTable({
                  pagingType: "full_numbers",
                  pageLength: 5,
                  processing: true,
                  dom: "Bfrtip",
                  select: {
                    style: "single",
                    //bDestroy: true,
                  },
                  
                  buttons: [
                    {
                      extend: "pageLength",
                      className: "btn btn-secondary bg-secondary",
                    },
                    {
                      extend: "copy",
                      className: "btn btn-secondary bg-secondary",
                    },
                    {
                      extend: "csv",
                      className: "btn btn-secondary bg-secondary",
                    },
                    {
                      extend: "print",
                      customize: function (win) {
                        $(win.document.body).css("font-size", "10pt");
                        $(win.document.body)
                          .find("table")
                          .addClass("compact")
                          .css("font-size", "inherit");
                      },
                      className: "btn btn-secondary bg-secondary",
                    },
                  ],
      
                  fnRowCallback: function (
                    nRow,
                    aData,
                    iDisplayIndex,
                    iDisplayIndexFull
                  ) {
                    var index = iDisplayIndexFull + 1;
                    $("td:first", nRow).html(index);
                    return nRow;
                  },
      
                  lengthMenu: [
                    [10, 50, 100, 500, 1000, 5000, 10000, -1],
                    [10, 50, 100, 500, 1000, 5000, 10000, "All"],
                  ],
                  columnDefs: [
                    {
                      targets: 0,
                      render: function (data, type, row, meta) {
                        return type === "export" ? meta.row + 1 : data;
                      },
                    },
                  ],
                });
              }, 1000);
            });
          }
{/*}*/}  

{/* showTable = () => { */}
{/*const showTable = () => {
        try {
          return names.map((item, index) => {
            return (
                <tr>
                <td className="text-xs font-weight-bold">{index +1}</td>
               <td className="text-xs font-weight-bold">{item.title}</td>
               <td className="text-xs font-weight-bold">{item.firstname+ ' ' + item.lastname}</td>
               <td className="text-xs font-weight-bold">{item.age}</td>
               <td className="text-xs font-weight-bold">{item.hobby}</td>
               <td className="text-xs font-weight-bold">{item.occupation}</td>

</tr>
                );
          });
        } catch (e) {
          alert(e.message);
        }
      };*/}

   {/* render(){ */}
    
   
  return(
  <div class="container-fluid py-4">
         <div class="table-responsive p-0 pb-2">
            {/*<table id="table" className="table align-items-center justify-content-center mb-0">*/}
       <table id="table" className="table align-items-center table-striped table-bordered table-hover table-sm">
           <thead class="card-header text-uppercase white-text text-sm font-weight-bolder opacity-7 ps-2 ">
               <tr>
               <th className="">S/N</th>
               <th>ORGANISATION</th>
                <th>ORGANISATION ADDRESS</th>
                <th>ORGANISATION PHONE</th>
                <th>ORGANISATION EMAIL</th>
               

</tr>
           </thead>

           <tbody>
                  {/* {this.showTable()}*/}

      {
         DBtableViewBusinesses.map((item, index) => {
          return (
              <tr>
              <td className="text-xs font-weight-bold">{index +1}</td>
             <td className="text-xs font-weight-bold">{item.organisation}</td>
             {/* <td className="text-xs font-weight-bold">{item.firstname+ ' ' + item.lastname}</td>*/}
             <td className="text-xs font-weight-bold">{item.organisation_address}</td>
             <td className="text-xs font-weight-bold">{item.organisation_phone}</td>
             <td className="text-xs font-weight-bold">{item.organisation_email}</td>

</tr>
              );
        })

    }
           </tbody>
       </table>
           </div>
           </div>
)
{/*}*/}
}

export default DataTables;