import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

const ConstituenciesSummarySkillsDevelopmentBursaries = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()
  const handleSubmit = constituenciesID => {
    localStorage.setItem(
      'WardsSummarySkillsDevelopmentBursaries',
      JSON.stringify(constituenciesID)
    )
    navigate('/WardsSummarySkillsDevelopmentBursaries')
  }

  const [ip, setIP] = useState('')
  const [DBtableViewConstituencies, setDBtableViewConstituencies] = useState([])
  const [DBtableViewCouncilName, setDBtableViewCouncilName] = useState([])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstCouncilsID = localStorage.getItem(
    'ConstituenciesSummarySkillsDevelopmentBursaries'
  )
  const councilsID = JSON.parse(firstCouncilsID)
  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getViewConstituencies')
    formData.append('councilsID', councilsID)
    formData.append('table', 'skillsdevelopmentbursaryapplicationform')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewConstituencies(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getViewApplicantsFromCouncilName')
    formData.append('councilsID', councilsID)
    //formData.append('table', 'applyforcommunityproject')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewCouncilName(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text'>
                    {' '}
                    <span class='text-uppercase'>
                      {DBtableViewCouncilName.map(valName => {
                        return <>{valName.council} </>
                      })}
                    </span>
                    CONSTITUENCIES SUMMARY - SKILLS DEVELOPMENT BURSARIES{' '}
                  </div>
                  {/*  <!-- Grid row -->*/}
                  <div class='row'>
                    {DBtableViewConstituencies.map(val => {
                      return (
                        <>
                          <div class='col-xl-3 col-md-6 mb-4'>
                            {/*<!-- Panel -->*/}
                            <div class='card'>
                              <div
                                class='card-header text-uppercase white-text light-blue accent-2'
                                type='button'
                                onClick={() => {
                                  handleSubmit(val.constituenciesID)
                                }}
                              >
                                {val.constituency}
                              </div>

                              <div class='row mt-1'>
                                <div class='col-md-12 col-12 text-right pr-5'>
                                  <div class='font-small grey-text'>
                                    Total Applications
                                    <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                                      {val.applicantsTotal}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Approved Applications
                                    <span class='ml-4 mt-1 font-weight-bold black-text'>
                                      {val.totalApproved}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Pending
                                    <span class='ml-4 mt-1 font-weight-bold black-text'>
                                      {val.totalPending}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Unsuccessful Applications
                                    <span class='ml-4 mt-1 font-weight-bold black-text'>
                                      {val.totalRejected}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*<!-- Panel -->*/}
                          </div>
                        </>
                      )
                    })}
                    {/*<!-- Grid column -->*/}
                  </div>
                  {/* <!-- Grid row -->*/}
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default ConstituenciesSummarySkillsDevelopmentBursaries
