import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Header from './header'
const ApplyForProjects = () => {
  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;
  const OTP_4_DDIGITS_REGEX = /^\d{4}$/
  const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/
  const NRC_REGEX1 =/^\d{6}[/]\d{2}[/]\d{1}$/
  const NRC_REGEX2 = /ZMB[0-9]{10}$/

  const server_app_api_php = window.SERVER_APP_API_PHP
  const [validPhone, setValidPhone] = useState(false)
  const [community, setCommunity] = useState('');
  const [projectProposerNRC, setProjectProposerNRC] = useState('');
  const [projectProposerPhone, setProjectProposerPhone] = useState('');
  const [seconderNRC, setSeconderNRC] = useState('');
  const [seconderPhone, setSeconderPhone] = useState('');
  const [OTP, setOTP] = useState('')
  const [email, setEmail] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [validOTP, setValidOTP] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [validSeconderNRC, setValidSeconderNRC] = useState(false)
  const [seconderNRCFocus, setSeconderNRCFocus] = useState(false)
  const [validProjectProposerNRC, setValidProjectProposerNRC] = useState(false)
  const [projectProposerNRCFocus, setProjectProposerNRCFocus] = useState(false)

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    //var result = false
    let resultSeconder1 = NRC_REGEX1.test(seconderNRC)
    let resultSeconder2 = NRC_REGEX2.test(seconderNRC)
    if(resultSeconder1 === true){
      setValidSeconderNRC(true)
    }
    else if(resultSeconder2 === true){
      setValidSeconderNRC(true)
    }
    else{
      setValidSeconderNRC(false)
    }
    //alert(result2)
  }, [seconderNRC])

  useEffect(() => {
    //var result = false
    let resultPropoer1 = NRC_REGEX1.test(projectProposerNRC)
    let resultPropoer2 = NRC_REGEX2.test(projectProposerNRC)
    if(resultPropoer1 === true){
      setValidProjectProposerNRC(true)
    }
    else if(resultPropoer2 === true){
      setValidProjectProposerNRC(true)
    }
    else{
      setValidProjectProposerNRC(false)
    }
    //alert(result2)
  }, [projectProposerNRC])

  useEffect(() => {
    const result = OTP_4_DDIGITS_REGEX.test(OTP)
    if(result === true){
    var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'projectsComfirmOTP')
    formDataComfirmOTP.append('communityMakingTheApplication', community)
    formDataComfirmOTP.append('projectProposerPhone', projectProposerPhone)
    formDataComfirmOTP.append('email', email)
    formDataComfirmOTP.append('OTP', OTP)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data === 'OTP Is Correct'){
        alert(response.data)
        setValidOTP(true)
      }
      else if(response.data === 'OTP Exists But Other Details Are Not Matching'){
        setValidOTP(false)
      }
      else{
      alert(response.data)
      setValidOTP(false)
      }
      })
    }
  }, [OTP, community, projectProposerPhone, email])

  useEffect(() => {
    const result = PHONE_REGEX.test(projectProposerPhone)
    setValidPhone(result)
    if(result === true){
      setOTP('');
      setValidOTP(false)

      var formDataCreateOTP = new FormData(this)
      formDataCreateOTP.append('apiType', 'projectsCreateOTP')
      formDataCreateOTP.append('communityMakingTheApplication', community)
      formDataCreateOTP.append('projectProposerPhone', projectProposerPhone)
      formDataCreateOTP.append('email', email)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
        data: formDataCreateOTP,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        alert(response.data)
          //setBusinessNameDropDown(response.data)
        })
          }
  }, [projectProposerPhone])

  sessionStorage.removeItem('reloadCount')

  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])

  let navigate = useNavigate()
  const handleSubmit = async e =>  {
    //navigate('/ApplyForCommunityProject')
        //useEffect(() => {
          var formData = new FormData(this)
          formData.append('apiType', 'uploadApplyForCommunityProjectProgress')
          formData.append('community', community)
          formData.append('projectProposerNRC', projectProposerNRC)
          formData.append('projectProposerPhone', projectProposerPhone)
          formData.append('seconderNRC', seconderNRC)
          formData.append('seconderPhone', seconderPhone)
          formData.append('email', email)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            setCommunity('')
            setProjectProposerNRC('')
            setProjectProposerPhone('')
            setSeconderNRC('')
            setSeconderPhone('')
            setEmail('')
            setOTP('')
            if(response.data === 'Successfully Registered'){
              alert("Successfully Registered, Login Credentials Have Been Sent To Phone Number "+projectProposerPhone +" ... Redirecting To Login Page");
              //navigate('/SecondaryBoardingSchoolBursaryApplicationForm')
              //localStorage.setItem('clientType', JSON.stringify('First Timer'))
              navigate('/Login')
            }
            else if(response.data === 'Duplicate Entry, Failed To Register'){
              alert("You Already Have An Account With Us, Redirecting To Login Page");
              //navigate('/ApplyForSecondaryProgress');
              //localStorage.setItem('clientType', JSON.stringify('Returning'))
              navigate('/Login')
            }
            else{
              alert(response.data)
            }
            }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
        //}, [])
  }

  return (
    <>
     {/*} <>
        <Header />
      </> */}


  <main>
    <div class="container-fluid">

      
      <section class="mb-2 mt-5">

        
        <div class="card card-cascade narrower">

          
          <div class="view view-cascade gradient-card-header blue-gradient">
            <h5 class="mb-0">COMMUNITY PROJECTS APPLICATION PROGRESS</h5>
          </div>
          
          <div class="card-body card-body-cascade text-center table-responsive mb-5">

          <div class="row">
            <a class="text-left" href='Main' style={{ color: 'red' }} title='Back To The Home Page'>
            <i class='fas fa-home' style={{ fontSize: '100%' }} >Back</i> 
            </a>
            </div>

            <div class="row">
            
            <div class="col-md-4">
            <div class="md-outline form-sm text-left"> 
            <label>Community Making Project Application</label>              
                <input placeholder="" type="text" class="form-control form-control-sm" 
                value={community}
                onChange={e => setCommunity(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4">
            <div class="md-outline form-sm text-left">  
            <label>Community Project Seconder NRC
            <span className={validSeconderNRC ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={validSeconderNRC || !seconderNRC ? 'hide' : 'invalid'}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>             
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={seconderNRC}
                onChange={e => setSeconderNRC(e.target.value)}
                aria-invalid={validSeconderNRC ? 'false' : 'true'}
                          aria-describedby='seconderNRCnote'
                          onFocus={() => setSeconderNRCFocus(true)}
                          onBlur={() => setSeconderNRCFocus(false)}
                />
                  <p
                          id='seconderNRCnote'
                          className={
                            seconderNRCFocus && seconderNRC && !validSeconderNRC
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          It Should Be A Valid NRC Number
                          <br />
                          If You Are Using The NRC number which starts with ZMB, The letters (ZMB) should be capitalised. 
                        </p>
              </div>
</div> 
<div class="col-md-4">
            <div class="md-outline form-sm text-left">    
            <label>Community Project Seconder Phone</label>           
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={seconderPhone}
                onChange={e => setSeconderPhone(e.target.value)}
                />
              </div>
</div> 


<div class="col-md-4">
<div class="md-outline form-sm text-left">
  <label>Community Project Proposer NRC
  <span className={validProjectProposerNRC ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={validProjectProposerNRC || !projectProposerNRC ? 'hide' : 'invalid'}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
  </label>
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={projectProposerNRC}
                onChange={e => setProjectProposerNRC(e.target.value)}
                aria-invalid={validProjectProposerNRC ? 'false' : 'true'}
                          aria-describedby='projectProposerNRCnote'
                          onFocus={() => setProjectProposerNRCFocus(true)}
                          onBlur={() => setProjectProposerNRCFocus(false)}
                />
                  <p
                          id='projectProposerNRCnote'
                          className={
                            projectProposerNRCFocus && projectProposerNRC && !validProjectProposerNRC
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          It Should Be A Valid NRC Number
                          <br />
                          If You Are Using The NRC number which starts with ZMB, The letters (ZMB) should be capitalised. 
                        </p>
              </div>
</div>

<div class="col-md-4" >
            <div class="md-outline form-sm text-left">  
            <label>Email For Project Proposer
            <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validEmail || !email ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>             
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={email}
                onChange={e => setEmail(e.target.value)}
                aria-invalid={validEmail ? 'false' : 'true'}
                          aria-describedby='emailnote'
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                />
                <p
                          id='emailnote'
                          className={
                            emailFocus && email && !validEmail
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          4 to 24 characters. <br />
                          Let It Be A Valid Email Address.
                        </p>
              </div>
</div>

<div class="col-md-4">
            <div class="md-outline form-sm text-left">  
            <label>Community Project Proposer Phone
            <span className={validPhone ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validPhone || !projectProposerPhone ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>             
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                disabled={email === '' || projectProposerNRC === '' || seconderPhone === '' || seconderNRC === '' || community === ''}
                value={projectProposerPhone}
                onChange={e => setProjectProposerPhone(e.target.value)}

                aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                />
                <p
                            id='phonenote'
                            className={
                              phoneFocus && projectProposerPhone && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            There Should Be 10 Numerical Values.
                          </p>
              </div>
</div> 

<div class="col-md-12" hidden = {!validPhone}>
            <div class="md-outline form-sm text-left"> 
            <label>OTP Received Via Email SMS</label>              
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={OTP}
                onChange={e => setOTP(e.target.value)}
                />
              </div>
</div>


<div class='col-md-12 col-12'>
                          <button
                            class='form-control blue-gradient white-text'
                            onClick={() => {
                                handleSubmit()
                              }}
                              disabled = {email === '' || !validOTP || !validPhone || community === '' || projectProposerNRC === '' ||
                              projectProposerPhone === '' || seconderNRC === '' || seconderPhone === '' || OTP === ''}                               
                          >Next</button>
                        </div>

            </div>
            
          </div>
          

        </div>
        
        

      </section>
   
    </div>
  </main>
    </>
  )
}

export default ApplyForProjects
