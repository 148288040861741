import logo from './logo.svg'
import './App.css'
import Register from './Register'
import Login from './Login'
import Home from './components/Home'
import Layout from './components/Layout'
import Editor from './components/Editor'
import Admin from './components/Admin'
import Missing from './components/Missing'
import Unauthorized from './components/Unauthorized'
import Logout from './components/Logout'
import Linkage from './components/Linkage'
import Main from './components/Main'
import RegisterBusiness from './components/System_Setup'
import ViewBusinesses from './components/ViewBusinesses'
import RegisterBusinessBranch from './components/RegisterBusinessBranch'
import FileUploadTest from './components/FileUploadTest'
import Form1 from './components/Form1'
import RegisterUsers from './components/RegisterUsers'
import RegisterSuppliers from './components/RegisterSuppliers'
import RegisterProducts from './components/RegisterProducts'
import RegisterStock from './components/RegisterStock'
import ViewUsers from './components/ViewUsers'
import ViewSuppliers from './components/ViewSuppliers'
import ViewProducts from './components/ViewProducts'
import ViewStock from './components/ViewStock'
import Pos from './components/pos'
import DataTables from './components/DataTables'
import Tesseract from './components/Tesseract.tsx'
import GrantApplicationFormForYouthWomenAndCommunityEmpowerment from './components/GrantApplicationFormForYouthWomenAndCommunityEmpowerment'
import ApplyForCommunityProject from './components/ApplyForCommunityProject'
import SkillsDevelopmentBursaryApplicationForm from './components/SkillsDevelopmentBursaryApplicationForm'
import SecondaryBoardingSchoolBursaryApplicationForm from './components/SecondaryBoardingSchoolBursaryApplicationForm'
import ProvincesSummaryGrantsForYouthWomenAndCommunityEmpowerment from './components/ProvincesSummaryGrantsForYouthWomenAndCommunityEmpowerment'
import ProvincesSummaryCommunityProjects from './components/ProvincesSummaryCommunityProjects'
import ProvincesSummarySkillsDevelopmentBursaries from './components/ProvincesSummarySkillsDevelopmentBursaries'
import ProvincesSummarySecondaryBoardingSchoolsBursaries from './components/ProvincesSummarySecondaryBoardingSchoolsBursaries'
import ProvincesSummaryLoans from './components/ProvincesSummaryLoans'
import CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment from './components/CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
import CouncilsSummaryCommunityProjects from './components/CouncilsSummaryCommunityProjects'
import CouncilsSummarySkillsDevelopmentBursaries from './components/CouncilsSummarySkillsDevelopmentBursaries'
import CouncilsSummarySecondaryBoardingSchoolsBursaries from './components/CouncilsSummarySecondaryBoardingSchoolsBursaries'
import CouncilsSummaryLoans from './components/CouncilsSummaryLoans'
import ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment from './components/ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment'
import ConstituenciesSummaryLoans from './components/ConstituenciesSummaryLoans'
import ConstituenciesSummaryCommunityProjects from './components/ConstituenciesSummaryCommunityProjects'
import ConstituenciesSummarySkillsDevelopmentBursaries from './components/ConstituenciesSummarySkillsDevelopmentBursaries'
import ConstituenciesSummarySecondaryBoardingSchoolsBursaries from './components/ConstituenciesSummarySecondaryBoardingSchoolsBursaries'
import WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment from './components/WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
import WardsSummaryCommunityProjects from './components/WardsSummaryCommunityProjects'
import WardsSummarySkillsDevelopmentBursaries from './components/WardsSummarySkillsDevelopmentBursaries'
import WardsSummarySecondaryBoardingSchoolsBursaries from './components/WardsSummarySecondaryBoardingSchoolsBursaries'
import WardsSummaryLoans from './components/WardsSummaryLoans'
import ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport from './components/ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport'
import ApplicantsDetailedCommunityProjectsReport from './components/ApplicantsDetailedCommunityProjectsReport'
import ApplicantsDetailedSkillsDevelopmentBursariesReport from './components/ApplicantsDetailedSkillsDevelopmentBursariesReport'
import ApplicantsDetailedSecondaryBoardingSchoolsBursariesReport from './components/ApplicantsDetailedSecondaryBoardingSchoolsBursariesReport'
import ApplicantsDetailedLoansReport from './components/ApplicantsDetailedLoansReport'
import ApplicantGrantsForYouthWomenAndCommunityEmpowermentView from './components/ApplicantGrantsForYouthWomenAndCommunityEmpowermentView'
import ApplicantSecondaryBoardingSchoolsBursariesView from './components/ApplicantSecondaryBoardingSchoolsBursariesView'
import ApplicantLoansView from './components/ApplicantLoansView'
import ApplicantSkillsDevelopmentBursariesView from './components/ApplicantSkillsDevelopmentBursariesView'
import ApplicantCommunityProjectsView from './components/ApplicantCommunityProjectsView'
import LoginAdmin from './LoginAdmin'
import WDCRecordMinutes from './components/WDCRecordMinutes'
import CDFCRecordMinutes from './components/CDFCRecordMinutes'
import HomePage from './components/home/home'
import Loan from './components/Loan'
import ApplyForGrants from './components/ApplyForGrants'
import ApplyForLoans from './components/ApplyForLoans'
import ApplyForProjects from './components/ApplyForProjects'
import ApplyForSkills from './components/ApplyForSkills'
import ApplyForSecondary from './components/ApplyForSecondary'
import ApplyForGrantsProgress from './components/ApplyForGrantsProgress'
import ApplyForLoansProgress from './components/ApplyForLoansProgress'
import ApplyForProjectsProgress from './components/ApplyForProjectsProgress'
import ApplyForSkillsProgress from './components/ApplyForSkillsProgress'
import ApplyForSecondaryProgress from './components/ApplyForSecondaryProgress'
import AccountProjects from './components/AccountProjects'
import AccountGrants from './components/AccountGrants'
import AccountLoans from './components/AccountLoans'
import AccountSecondary from './components/AccountSecondary'
import AccountSkills from './components/AccountSkills'
import ForgotPassword from './components/ForgotPassword'

import { Routes, Route } from 'react-router-dom'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public Routes*/}
        <Route path='Home' element={<Home />} />
        <Route path='RegisterBusiness' element={<RegisterBusiness />} />
        <Route path='ViewBusinesses' element={<ViewBusinesses />} />
        <Route
          path='RegisterBusinessBranch'
          element={<RegisterBusinessBranch />}
        />
        <Route path='MAIN' element={<Main />} />
        <Route path='register' element={<Register />} />
        <Route path='linkage' element={<Linkage />} />
        <Route path='unauthorised' element={<Unauthorized />} />
        <Route path='FileUploadTest' element={<FileUploadTest />} />
        <Route path='Form1' element={<Form1 />} />

        {/* We want To Protect These Routes*/}
        <Route path='/' element={<Main />} />
        <Route
          path='GrantApplicationFormForYouthWomenAndCommunityEmpowerment'
          element={<GrantApplicationFormForYouthWomenAndCommunityEmpowerment />}
        />
        <Route
          path='ApplyForCommunityProject'
          element={<ApplyForCommunityProject />}
        />
        <Route
          path='SkillsDevelopmentBursaryApplicationForm'
          element={<SkillsDevelopmentBursaryApplicationForm />}
        />
        <Route
          path='SecondaryBoardingSchoolBursaryApplicationForm'
          element={<SecondaryBoardingSchoolBursaryApplicationForm />}
        />
        <Route
          path='ProvincesSummaryGrantsForYouthWomenAndCommunityEmpowerment'
          element={
            <ProvincesSummaryGrantsForYouthWomenAndCommunityEmpowerment />
          }
        />
        <Route
          path='ProvincesSummaryCommunityProjects'
          element={<ProvincesSummaryCommunityProjects />}
        />
        <Route
          path='ProvincesSummarySkillsDevelopmentBursaries'
          element={<ProvincesSummarySkillsDevelopmentBursaries />}
        />
        <Route
          path='ProvincesSummarySecondaryBoardingSchoolsBursaries'
          element={<ProvincesSummarySecondaryBoardingSchoolsBursaries />}
        />
		<Route
          path='ProvincesSummaryLoans'
          element={<ProvincesSummaryLoans />}
        />
        <Route
          path='CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
          element={
            <CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment />
          }
        />
        <Route
          path='CouncilsSummaryCommunityProjects'
          element={<CouncilsSummaryCommunityProjects />}
        />
        <Route
          path='CouncilsSummarySkillsDevelopmentBursaries'
          element={<CouncilsSummarySkillsDevelopmentBursaries />}
        />
        <Route
          path='CouncilsSummarySecondaryBoardingSchoolsBursaries'
          element={<CouncilsSummarySecondaryBoardingSchoolsBursaries />}
        />
		<Route
          path='CouncilsSummaryLoans'
          element={<CouncilsSummaryLoans />}
        />
        <Route
          path='ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment'
          element={
            <ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment />
          }
        />
		<Route
          path='ConstituenciesSummaryLoans'
          element={
            <ConstituenciesSummaryLoans />
          }
        />
        <Route
          path='ConstituenciesSummaryCommunityProjects'
          element={<ConstituenciesSummaryCommunityProjects />}
        />
        <Route
          path='ConstituenciesSummarySkillsDevelopmentBursaries'
          element={<ConstituenciesSummarySkillsDevelopmentBursaries />}
        />
        <Route
          path='ConstituenciesSummarySecondaryBoardingSchoolsBursaries'
          element={<ConstituenciesSummarySecondaryBoardingSchoolsBursaries />}
        />
        <Route
          path='WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
          element={<WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment />}
        />
        <Route
          path='WardsSummaryCommunityProjects'
          element={<WardsSummaryCommunityProjects />}
        />
        <Route
          path='WardsSummarySkillsDevelopmentBursaries'
          element={<WardsSummarySkillsDevelopmentBursaries />}
        />
        <Route
          path='WardsSummarySecondaryBoardingSchoolsBursaries'
          element={<WardsSummarySecondaryBoardingSchoolsBursaries />}
        />
		<Route
          path='WardsSummaryLoans'
          element={<WardsSummaryLoans />}
        />
        <Route
          path='ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport'
          element={
            <ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport />
          }
        />
        <Route
          path='ApplicantsDetailedCommunityProjectsReport'
          element={<ApplicantsDetailedCommunityProjectsReport />}
        />
        <Route
          path='ApplicantsDetailedSkillsDevelopmentBursariesReport'
          element={<ApplicantsDetailedSkillsDevelopmentBursariesReport />}
        />
        <Route
          path='ApplicantsDetailedSecondaryBoardingSchoolsBursariesReport'
          element={
            <ApplicantsDetailedSecondaryBoardingSchoolsBursariesReport />
          }
        />
		<Route
          path='ApplicantsDetailedLoansReport'
          element={
            <ApplicantsDetailedLoansReport />
          }
        />
		<Route
          path='ApplicantGrantsForYouthWomenAndCommunityEmpowermentView'
          element={
            <ApplicantGrantsForYouthWomenAndCommunityEmpowermentView />
          }
        />
		<Route
          path='ApplicantSecondaryBoardingSchoolsBursariesView'
          element={
            <ApplicantSecondaryBoardingSchoolsBursariesView />
          }
        />
		<Route
          path='ApplicantLoansView'
          element={
            <ApplicantLoansView />
          }
        />
		<Route
          path='ApplicantSkillsDevelopmentBursariesView'
          element={
            <ApplicantSkillsDevelopmentBursariesView />
          }
        />
		<Route
          path='ApplicantCommunityProjectsView'
          element={
            <ApplicantCommunityProjectsView />
          }
        />
        <Route path='Loan' element={<Loan />} />
        <Route path='ApplyForGrants' element={<ApplyForGrants />} />
        <Route path='ApplyForLoans' element={<ApplyForLoans />} />
        <Route path='ApplyForProjects' element={<ApplyForProjects />} />
        <Route path='ApplyForSkills' element={<ApplyForSkills />} />
        <Route path='ApplyForSecondary' element={<ApplyForSecondary />} />
        <Route path='ApplyForGrantsProgress' element={<ApplyForGrantsProgress />} />
        <Route path='ApplyForLoansProgress' element={<ApplyForLoansProgress />} />
        <Route path='ApplyForProjectsProgress' element={<ApplyForProjectsProgress />} />
        <Route path='ApplyForSkillsProgress' element={<ApplyForSkillsProgress />} />
        <Route path='ApplyForSecondaryProgress' element={<ApplyForSecondaryProgress />} />
        <Route path='AccountProjects' element={<AccountProjects />} />
        <Route path='AccountGrants' element={<AccountGrants />} />
        <Route path='AccountLoans' element={<AccountLoans />} />
        <Route path='AccountSecondary' element={<AccountSecondary />} />
        <Route path='AccountSkills' element={<AccountSkills />} />
        <Route path='ForgotPassword' element={<ForgotPassword />} />
        <Route path='WDCRecordMinutes' element={<WDCRecordMinutes />} />
        <Route path='CDFCRecordMinutes' element={<CDFCRecordMinutes />} />
        <Route path='HomePage' element={<HomePage />} />
        <Route path='Login' element={<Login />} />
        <Route path='RegisterUsers' element={<RegisterUsers />} />
        <Route path='RegisterSuppliers' element={<RegisterSuppliers />} />
        <Route path='RegisterProducts' element={<RegisterProducts />} />
        <Route path='RegisterStock' element={<RegisterStock />} />
        <Route path='ViewUsers' element={<ViewUsers />} />
        <Route path='ViewSuppliers' element={<ViewSuppliers />} />
        <Route path='ViewProducts' element={<ViewProducts />} />
        <Route path='ViewStock' element={<ViewStock />} />
        <Route path='Pos' element={<Pos />} />
        <Route path='DataTables' element={<DataTables />} />
        <Route path='LoginAdmin' element={<LoginAdmin />} />
        <Route path='editor' element={<Editor />} />
        <Route path='admin' element={<Admin />} />
        <Route path='Logout' element={<Logout />} />
        <Route path='Tesseract' element={<Tesseract />} />

        {/* catch all*/}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App
