import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Header from './header'
const ApplyForSecondary = () => {

  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;
  const OTP_4_DDIGITS_REGEX = /^\d{4}$/
  const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/
  const NRC_REGEX1 =/^\d{6}[/]\d{2}[/]\d{1}$/
  const NRC_REGEX2 = /ZMB[0-9]{10}$/

  const server_app_api_php = window.SERVER_APP_API_PHP
  const [validPhone, setValidPhone] = useState(false)
  const [contactPersonNRCNumber, setContactPersonNRCNumber] = useState('');
  const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState('');
  const [surname, setSurname] = useState('');
  const [firstName, setFirstName] = useState('');
  const [OTP, setOTP] = useState('')
  const [email, setEmail] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [validOTP, setValidOTP] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  
  const [validNrc, setValidNrc] = useState(false)
  const [nrcFocus, setNrcFocus] = useState(false)

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    //var result = false
    let result1 = NRC_REGEX1.test(contactPersonNRCNumber)
    let result2 = NRC_REGEX2.test(contactPersonNRCNumber)
    if(result1 === true){
      setValidNrc(true)
    }
    else if(result2 === true){
      setValidNrc(true)
    }
    else{
      setValidNrc(false)
    }
    //alert(result2)
  }, [contactPersonNRCNumber])

  useEffect(() => {
    const result = OTP_4_DDIGITS_REGEX.test(OTP)
    if(result === true){
    var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'secondaryComfirmOTP')
    formDataComfirmOTP.append('learnerFirstName', firstName)
    formDataComfirmOTP.append('learnerSurname', surname)
    formDataComfirmOTP.append('contactPersonNRCNumber', contactPersonNRCNumber)
    formDataComfirmOTP.append('contactPersonPhoneNumber', contactPersonPhoneNumber)
    formDataComfirmOTP.append('email', email)
    formDataComfirmOTP.append('OTP', OTP)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data === 'OTP Is Correct'){
        alert(response.data)
        setValidOTP(true)
      }
      else if(response.data === 'OTP Exists But Other Details Are Not Matching'){
        setValidOTP(false)
      }
      else{
      alert(response.data)
      setValidOTP(false)
      }
      })
    }
  }, [OTP, firstName, surname, contactPersonPhoneNumber, contactPersonNRCNumber, email])

  useEffect(() => {
    const result = PHONE_REGEX.test(contactPersonPhoneNumber)
    setValidPhone(result)
    if(result === true){
      setOTP('');
      setValidOTP(false)

      var formDataCreateOTP = new FormData(this)
      formDataCreateOTP.append('apiType', 'secondaryCreateOTP')
      formDataCreateOTP.append('learnerFirstName', firstName)
      formDataCreateOTP.append('learnerSurname', surname)
      formDataCreateOTP.append('contactPersonNRCNumber', contactPersonNRCNumber)
      formDataCreateOTP.append('contactPersonPhoneNumber', contactPersonPhoneNumber)
      formDataCreateOTP.append('email', email)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
        data: formDataCreateOTP,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        alert(response.data)
          //setBusinessNameDropDown(response.data)
        })
          }
  }, [contactPersonPhoneNumber])

  sessionStorage.removeItem('reloadCount')

  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])

  let navigate = useNavigate()
  const handleSubmit = async e =>  {
    //navigate('/SecondaryBoardingSchoolBursaryApplicationForm')
        //useEffect(() => {
          var formData = new FormData(this)
          formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationFormProgress')
          formData.append('contactPersonNRCNumber', contactPersonNRCNumber)
          formData.append('contactPersonPhoneNumber', contactPersonPhoneNumber)
          formData.append('surname', surname)
          formData.append('firstName', firstName)
          formData.append('email', email)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            setContactPersonNRCNumber('')
            setContactPersonPhoneNumber('')
            setSurname('')
            setFirstName('')
            setEmail('')
            setOTP('')
            if(response.data === 'Successfully Registered'){
              alert("Successfully Registered, Login Credentials Have Been Sent To Phone Number "+contactPersonPhoneNumber +" ... Redirecting To Login Page");
              //navigate('/SecondaryBoardingSchoolBursaryApplicationForm')
              //localStorage.setItem('clientType', JSON.stringify('First Timer'))
              navigate('/Login')
            }
            else if(response.data === 'Duplicate Entry, Failed To Register'){
              alert("You Already Have An Account With Us, Redirecting To Login Page");
              //navigate('/ApplyForSecondaryProgress');
              //localStorage.setItem('clientType', JSON.stringify('Returning'))
              navigate('/Login')
            }
            else{
              alert(response.data)
            }
              //setBusinessNameDropDown(response.data)
            }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
        //}, [])
  }

  return (
    <>
     {/*} <>
        <Header />
      </> */}


  <main>
    <div class="container-fluid">

      
      <section class="mb-2 mt-5">

        
        <div class="card card-cascade narrower">

          
          <div class="view view-cascade gradient-card-header blue-gradient">
            <h5 class="mb-0">SECONDARY SCHOOL BURSARY APPLICATION PROGRESS</h5>
          </div>
          
          <div class="card-body card-body-cascade text-center table-responsive mb-5">
                      
            <div class="row">
            <a class="text-left" href='Main' style={{ color: 'red' }} title='Back To The Home Page'>
            <i class='fas fa-home' style={{ fontSize: '100%' }} >Back</i> 
            </a>
            </div>
        
            <div class="row">

            <div class="col-md-4">
<div class="md-outline form-sm text-left">        
<label>Learner Surname</label>       
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={surname}
                onChange={e => setSurname(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4">
            <div class="md-outline form-sm text-left">
              <label>Learner First Name</label>             
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                />
              </div>
</div> 

            <div class="col-md-4">
            <div class="md-outline form-sm text-left"> 
            <label>Learner's Contact Person NRC
            <span className={validNrc ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={validNrc || !contactPersonNRCNumber ? 'hide' : 'invalid'}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>              
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={contactPersonNRCNumber}
                onChange={e => setContactPersonNRCNumber(e.target.value)}
                aria-invalid={validNrc ? 'false' : 'true'}
                          aria-describedby='nrcnote'
                          onFocus={() => setNrcFocus(true)}
                          onBlur={() => setNrcFocus(false)}
                />
                    <p
                          id='nrcnote'
                          className={
                            nrcFocus && contactPersonNRCNumber && !validNrc
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          It Should Be A Valid NRC Number
                          <br />
                          If You Are Using The NRC number which starts with ZMB, The letters (ZMB) should be capitalised. 
                        </p>
              </div>
</div>

<div class="col-md-6">
            <div class="md-outline form-sm text-left">  
            <label>Email For Learner's Contact Person
            <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validEmail || !email ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>             
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={email}
                onChange={e => setEmail(e.target.value)}
                aria-invalid={validEmail ? 'false' : 'true'}
                          aria-describedby='emailnote'
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                />
                <p
                          id='emailnote'
                          className={
                            emailFocus && email && !validEmail
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          4 to 24 characters. <br />
                          Let It Be A Valid Email Address.
                        </p>
              </div>
</div>

<div class="col-md-6">
            <div class="md-outline form-sm text-left">   
            <label>Learner's Contact Person Phone Number
            <span className={validPhone ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validPhone || !contactPersonPhoneNumber ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>            
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                disabled={email === '' || surname === '' || firstName === '' || contactPersonNRCNumber === ''}
                value={contactPersonPhoneNumber}
                onChange={e => setContactPersonPhoneNumber(e.target.value)}

                aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                />
                 <p
                            id='phonenote'
                            className={
                              phoneFocus && contactPersonPhoneNumber && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            There Should Be 10 Numerical Values.
                          </p>

              </div>
</div>

<div class="col-md-12" hidden = {!validPhone}>
            <div class="md-outline form-sm text-left">   
            <label>OTP Received Via Email Or SMS</label>            
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={OTP}
                onChange={e => setOTP(e.target.value)}
                />
              </div>
</div>


<div class='col-md-12 col-12'>
                          <button
                            class='form-control blue-gradient white-text'   
                            onClick={() => {
                                handleSubmit()
                              }}
                              disabled = {email === '' || !validOTP || !validPhone || contactPersonNRCNumber=== '' ||
                                 contactPersonPhoneNumber === '' ||
                                 firstName === '' || surname === '' || OTP === ''}                           
                          >Next</button>
                        </div>

            </div>
            
          </div>
          

        </div>
        
        

      </section>
   
    </div>
  </main>
    </>
  )
}

export default ApplyForSecondary
