import {useRef, useState, useEffect} from 'react';
import '../App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../api/axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const ViewStock = () => {
const userRef = useRef();
const errRef = useRef();


const [DBtableViewStock, setDBtableViewStock] = useState([]);

const [update, setUpdate] = useState('');

const firstSystemSetupID = localStorage.getItem("systemSetupID");
const systemSetupIDInitial = JSON.parse(firstSystemSetupID);
//alert("id = "+systemSetupIDInitial);
useEffect( () => {
    Axios.post("http://localhost:3001/api/getViewStock", {
              businessNameID : systemSetupIDInitial,		
}).then((response) => { 
    setDBtableViewStock(response.data);
})//.catch((err) => alert("ERROR : Check Your Network Connection"));
      },
      [])

const UpdateRecord = (system_setupID) => {
    Axios.put("http://localhost:3001/api/update",{
        id : system_setupID, 
        lname : update,
    });
    setUpdate('');
}

const deleteRecord = (system_setupID) => {
    Axios.delete(`http://localhost:3001/api/delete/${system_setupID}`);
    alert ("Successfully Deleted Record");
}

if (!$.fn.DataTable.isDataTable("#myTable")) {
            $(document).ready(function () {
              setTimeout(function () {
                $("#table").dataTable().fnDestroy();
                $("#table").DataTable({
                  pagingType: "full_numbers",
                  pageLength: 5,
                  processing: true,
                  dom: "fBrtip",
                  select: {
                    style: "single",
                  },
                  
                  buttons: [
                    {
                      extend: "pageLength",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "copy",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "csv",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "print",
                      customize: function (win) {
                        $(win.document.body).css("font-size", "10pt");
                        $(win.document.body)
                          .find("table")
                          .addClass("compact")
                          .css("font-size", "inherit");
                      },
                      className: "btn btn-primary bg-info",
                    },
					
                  ],
      
                  fnRowCallback: function (
                    nRow,
                    aData,
                    iDisplayIndex,
                    iDisplayIndexFull
                  ) {
                    var index = iDisplayIndexFull + 1;
                    $("td:first", nRow).html(index);
                    return nRow;
                  },
      
                  lengthMenu: [
                    [5, 10, 50, 100, 500, 1000, 5000, 10000, -1],
                    [5, 10, 50, 100, 500, 1000, 5000, 10000, "All"],
                  ],
                  columnDefs: [
                    {
                      targets: 0,
                      render: function (data, type, row, meta) {
                        return type === "export" ? meta.row + 1 : data;
                      },
                    },
                  ],
                });
              }, 1);
            });
          }
    return(
	 <header>
	<>
       <section class="fixed-sn white-skin">
<div class=" d-flex justify-content-center align-items-center">
  <div class="container">
    <div class="card">
    <div class="card-body">
  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}
				  
{/* Data Table View Starts Here*/}
<div class="">
{/*<div class="row">*/} 
<br />
<h6 class="form-header white-text bg-info">VIEW REGISTERED PRODUCTS</h6>
<table id="table" class="table table-striped table-bordered table-hover table-sm">

<thead class="form-header text-uppercase">
{/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
    <tr>
{/*
SELECT `stockID`, `system_setupID`, `system_setup_branchID`, `stock_batchID`, `productID`, 
`stock_quantity`, `stock_cost`, `suppliersID`, `product_unit_price`, `stockArrivalDate`, 
`stock_date`, `stock_time`, `stock_entered_by`, `stock_IP` FROM `stock` WHERE 1
*/}
    <th>S/N</th>
    {/*<th>Company</th>
    <th>Branch</th>*/}
    <th>product</th>
    <th>Quantity</th>
    <th>Cost</th>
    <th>Price</th>
    <th>Date(Arrival)</th>
    <th>Supplier</th>
    <th>UPDATE</th>
    <th>DELETE</th>
    </tr>
</thead>
<tbody>
{
    DBtableViewStock.map((val, index)=>{
return (
  
<tr>
{/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
    <td>{index +1}</td>
    {/*<td>{val.organisation}</td>
    <td>{val.branch_name}</td>*/}
    <td>{val.product}</td>
    <td>{val.stock_quantity}</td>
    <td>{val.stock_cost}</td>
    <td>{val.product_unit_price}</td>
    <td>{val.stockArrivalDate}</td>
    <td>{val.supplier_name}</td>
    <td> <button onClick={()=> {UpdateRecord(val.stockID)}}  class="btn btn-light" title="Update This Record"><span class="fa fa-edit blue-text"></span></button></td>
    <td><button onClick={()=> {deleteRecord(val.stockID)}} class="btn btn-light" title="Delete This Record From The Database"><span class="fa fa-trash red-text"></span></button></td>
</tr>

    );
    })
    }
    </tbody>
    </table>
    
</div>
{/* Data Table View Ends Here*/}

</div>
</div>
</div>
</div>
</section>

</>

 </header>
    )
}

export default ViewStock;