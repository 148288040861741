import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faInfo,
  faWarning,
  faStairs
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Header from './header'
const AccountProjects = () => {
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
  const PHONE_REGEX =
/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/


  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let id = localStorage.getItem('userID')
  id = JSON.parse(id)

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let clientID = localStorage.getItem('userID')
  clientID = JSON.parse(clientID)

  let login = localStorage.getItem('login')
  login = JSON.parse(login)

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if(loggedIn != 'Yes'){
    navigate('/Logout')
  }

  const handleHome = async e => {
navigate('/')
  }
  const handleApply = async e => {
    navigate('/ApplyForCommunityProject')
  }
  const handleLogout = async e => {
    navigate('/Logout')
  }

  const handleProgressReport = async e => {
    navigate('/ApplyForProjectsProgress')
  }

  const [validPhone, setValidPhone] = useState(false)
  const [community, setCommunity] = useState('');
  const [projectProposerNRC, setProjectProposerNRC] = useState('');
  const [projectProposerPhone, setProjectProposerPhone] = useState('');
  const [seconderNRC, setSeconderNRC] = useState('');
  const [seconderPhone, setSeconderPhone] = useState('');
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [comfirmPassword, setComfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
   const [newPasswordFocus, setNewPasswordFocus] = useState(false)
   const [comfirmPasswordFocus, setComfirmPasswordFocus] = useState(false)
  const [validOldPassword, setValidOldPassword] = useState(false)
   const [validNewPassword, setValidNewPassword] = useState(false)
    const [validComfirmPassword, setValidComfirmPassword] = useState(false)
  var [editPassword, setEditPassword] = useState('')

  useEffect(() => {
    const result = PHONE_REGEX.test(projectProposerPhone)
    setValidPhone(result)
  }, [projectProposerPhone])

   useEffect(() => {
    const result = PWD_REGEX.test(newPassword)
    setValidNewPassword(result)
  }, [newPassword])

   useEffect(() => {
    const result = PWD_REGEX.test(comfirmPassword)
    if(comfirmPassword === newPassword){
    setValidComfirmPassword(result)
    }
    else{
      setValidComfirmPassword(false)
    }
  }, [comfirmPassword, newPassword])

   useEffect(() => {
    setNewPassword('');
setComfirmPassword('');
 var formData = new FormData(this)
          formData.append('apiType', 'comfirmOldPassword')
          formData.append('id', clientID)
          formData.append('password', oldPassword)
          formData.append('table', 'applyforcommunityproject')
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/index.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            if(response.data === 'Correct Password'){
            setValidOldPassword(true)
            }
            else if(response.data === 'Wrong Password'){
            setValidOldPassword(false)
            }
            }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
       
    
  }, [oldPassword])

  useEffect(() => {
setOldPassword('');
setNewPassword('');
setComfirmPassword('');
setValidComfirmPassword(false)
setValidNewPassword(false)
setValidOldPassword(false)
  }, [editPassword])

  const handleSubmit = async e =>  {
          var formData = new FormData(this)
          formData.append('apiType', 'updateAccountProjects')
          formData.append('id', clientID)
          formData.append('community', community)
          formData.append('projectProposerNRC', projectProposerNRC)
          formData.append('projectProposerPhone', projectProposerPhone)
          formData.append('seconderNRC', seconderNRC)
          formData.append('seconderPhone', seconderPhone)
          formData.append('password', comfirmPassword)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/index.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            if(response.data === 'Succefully Updated Profile'){
              setOldPassword('')
              setNewPassword('')
              setComfirmPassword('')
             alert(response.data);
            }
            else{
              //alert(response.data);
              alert("System Error, Contact Local Council If this Error Persists");
            }
            }) .catch((err) => alert("ERROR : Check Your Network Connection"));
  }

  useEffect(() => {
 var formData = new FormData(this)
          formData.append('apiType', 'getAccountDetailsProjects')
          formData.append('id', clientID)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/index.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            setCommunity(response.data.community);
            setProjectProposerNRC(response.data.projectProposerNRC);
            setProjectProposerPhone(response.data.projectProposerPhone);
            setSeconderNRC(response.data.seconderNRC);
            setSeconderPhone(response.data.seconderPhone);
            //alert(response.data)
            }) //.catch((err) => alert("ERROR : Check Your Network Connection"));      
    
  }, [])


  return (
    <>
     {/*} <>
        <Header />
      </> */}


  <main>
    <div class="container-fluid">

      
    <section class="mb-3 mt-5">

        
<div class="card card-cascade narrower">

  
  <div class="view view-cascade gradient-card-header blue-gradient text-uppercase">
    <h5 class="mb-0">{userName} - Profile</h5>
  </div>
  
  <div class="card-body card-body-cascade text-center table-responsive">

    
  <div class='row'>
            <div class='col-lg-12 col-md-12 mb-1  text-left'>
              <label for='registerFullName'>
                Edit Password
              </label>
              <select
                class='custom-select d-block w-100'
                onChange={e => setEditPassword(e.target.value)}
                value={editPassword}
              >
                <option value=''>Choose ....</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            </div>

<div class='row' hidden = {editPassword === 'Yes'}>

    
<div class="col-md-4">
            <div class="text-left md-outline form-sm">  
            <label>Community Making Proposal</label>             
                <input type="text" class="form-control form-control-sm" 
                value={community}
                onChange={e => setCommunity(e.target.value)}
                />
              </div>
</div>

<div class="col-md-2">
            <div class="text-left md-outline form-sm"> 
            <label>Seconder NRC</label>              
                <input type="text" id="form31" 
                class="form-control form-control-sm" 
                value={seconderNRC}
                onChange={e => setSeconderNRC(e.target.value)}
                />
              </div>
</div> 
<div class="col-md-2">
            <div class="text-left md-outline form-sm"> 
            <label>Seconder Phone</label>              
                <input type="text" id="form31" 
                class="form-control form-control-sm" 
                value={seconderPhone}
                onChange={e => setSeconderPhone(e.target.value)}
                />
              </div>
</div> 


<div class="col-md-2">
<div class="text-left md-outline form-sm"> 
<label>Project Proposer NRC</label>             
                <input type="text" id="form31" 
                class="form-control form-control-sm" 
                value={projectProposerNRC}
                onChange={e => setProjectProposerNRC(e.target.value)}
                />
              </div>
</div>

<div class="col-md-2">
            <div class="text-left md-outline form-sm">  
            <label>Project Proposer Phone</label>             
                <input type="text" id="form31" 
                class="form-control form-control-sm" 
                value={projectProposerPhone}
                onChange={e => setProjectProposerPhone(e.target.value)}

                aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                />
                <p
                            id='phonenote'
                            className={
                              phoneFocus && projectProposerPhone && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            There Should Be 10 Numerical Values.
                          </p>
              </div>
</div>   

</div>

 <div class='row' hidden = {editPassword === '' || editPassword === 'No' || editPassword === undefined}>
<div class="col-md-4" >
            <div class="md-form form-sm">
            <label>Old Password</label>           
                <input type="password" id="form31" class="form-control form-control-sm" 
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4" >
            <div class="md-form form-sm">
            <label>New Password</label>            
                <input type="password" id="form31" class="form-control form-control-sm" 
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                disabled = {!validOldPassword}

                 aria-invalid={validNewPassword ? 'false' : 'true'}
                            aria-describedby='newPasswordNote'
                            onFocus={() => setNewPasswordFocus(true)}
                            onBlur={() => setNewPasswordFocus(false)}
                />

                <p
                            id='newPasswordNote'
                            className={
                              newPasswordFocus && newPassword && !validNewPassword
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Password Should Contain 8 Or More Values. It Should Consist Of :
                           <br /> Atleast one(1) Capital Letter
                           <br /> Atleast One(1) Special Character
                           <br /> and atleast (1) Number  
                          </p>
              </div>
</div>

<div class="col-md-4" >
            <div class="md-form form-sm">
            <label>Repeat New Password</label>            
                <input type="password" id="form31" class="form-control form-control-sm" 
                value={comfirmPassword}
                onChange={e => setComfirmPassword(e.target.value)}
                disabled = {!validOldPassword || !validNewPassword}

                 aria-invalid={validComfirmPassword ? 'false' : 'true'}
                            aria-describedby='comfirmPasswordNote'
                            onFocus={() => setComfirmPasswordFocus(true)}
                            onBlur={() => setComfirmPasswordFocus(false)}
                />

                 <p
                            id='comfirmPasswordNote'
                            className={
                              comfirmPasswordFocus && comfirmPassword && !validComfirmPassword
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                           Repeat Password Should Be the Same As New Password, It Should Contain 8 Or More Values and Consist Of :
                           <br /> Atleast one(1) Capital Letter
                           <br /> Atleast One(1) Special Character
                           <br /> and atleast (1) Number  
                          </p>
              </div>
</div>

<div class="col-md-3" hidden>
            <div class="md-form form-sm">
            <label>Email</label><br />           
                <input type="text" id="form31" class="form-control form-control-sm" 
                value={email}
                onChange={e => setEmail(e.target.value)}
                />
              </div>
</div>
</div>

<div class='row'>
<div class='col-md-12 col-12'>
                          <button
                            class='form-control blue-gradient white-text'
                            onClick={() => {
                                handleSubmit()
                              }}
                              disabled = {!validPhone || community === '' || projectProposerNRC === '' ||
                              projectProposerPhone === '' || seconderNRC === '' || seconderPhone === '' || editPassword === 'Yes' && (!validComfirmPassword || comfirmPassword === '')}                             
                          >Submit</button>
                        </div>

            </div>
    
  </div>
  

</div>



</section>
    
    
      <section>

       
        <div class="card card-cascade narrower">

        <div class="card-body card-body-cascade mb-5">
            
        <div class="row mt-1 mb-5">
              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleHome}
                ><i class='w-fa fas fa-home'></i>Home</button>
              </div>

              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleApply}
                ><i class='w-fa fas fa-edit'></i>Application Form</button>
              </div>

              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleProgressReport}
                ><span><FontAwesomeIcon icon={faStairs} />Application Progress </span>
                </button>
              </div>

              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleLogout}
                > <i class='w-fa fas fa-sign-out-alt'></i>Logout</button>
              </div>
            </div> 
         
         </div>
       
       </div>
     
     </section>
     
    </div>
  </main>
    </>
  )
}
export default AccountProjects
