import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
//import './admins/css/bootstrap.min.css'
//import './admins/css/mdb.min.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Header from './header'
const ApplyForGrants = () => {
  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;
  //const QUANTITY_REGEX = /^\d{1,9}$/
  const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

  const OTP_4_DDIGITS_REGEX = /^\d{4}$/
  const server_app_api_php = window.SERVER_APP_API_PHP
  const [validPhone, setValidPhone] = useState(false)
  const [nameOfGroupMakingApplication, setNameOfGroupMakingApplication] = useState('');
  const [TPIN, setTPIN] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTP, setOTP] = useState('')
  const [email, setEmail] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [validOTP, setValidOTP] = useState(false)

  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])
  
  useEffect(() => {
    const result = OTP_4_DDIGITS_REGEX.test(OTP)
    //setValidOTP(result)
    if(result === true){
    var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'grantsConfirmOTP')
    formDataComfirmOTP.append('TPIN', TPIN)
    formDataComfirmOTP.append('phoneNumber', phoneNumber)
    formDataComfirmOTP.append('email', email)
    formDataComfirmOTP.append('OTP', OTP)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data === 'OTP Is Correct'){
        alert(response.data)
        setValidOTP(true)
      }
      else if(response.data === 'OTP Exists But Other Details Are Not Matching'){
        setValidOTP(false)
      }
      else{
      alert(response.data)
      setValidOTP(false)
      }
      })
    }
    
  }, [OTP, phoneNumber, TPIN, email])

  useEffect(() => {
    const result = PHONE_REGEX.test(phoneNumber)
    setValidPhone(result)
    if(result === true){
      setOTP('');
      setValidOTP(false)

      var formDataCreateOTP = new FormData(this)
      formDataCreateOTP.append('apiType', 'grantsCreateOTP')
      formDataCreateOTP.append('TPIN', TPIN)
      formDataCreateOTP.append('phoneNumber', phoneNumber)
      formDataCreateOTP.append('nameOfGroupMakingApplication', nameOfGroupMakingApplication)
      formDataCreateOTP.append('email', email)

      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
        data: formDataCreateOTP,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        alert(response.data)
          //setBusinessNameDropDown(response.data)
        })
          }
  }, [phoneNumber])

  sessionStorage.removeItem('reloadCount')

  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])
  let navigate = useNavigate()
  const handleSubmit = async e =>  {
    //navigate('/GrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    //useEffect(() => {
      var formData = new FormData(this)
      formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowermentProgress')
      formData.append('nameOfGroupMakingApplication', nameOfGroupMakingApplication)
      formData.append('TPIN', TPIN)
      formData.append('accountNumber', accountNumber)
      formData.append('phoneNumber', phoneNumber)
      formData.append('email', email)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setNameOfGroupMakingApplication('')
        setTPIN('')
        setAccountNumber('')
        setPhoneNumber('')
        setEmail('')
        setOTP('')
        if(response.data === 'Successfully Registered'){
          alert("Successfully Registered, Login Credentials Have Been Sent To Phone Number "+phoneNumber +" ... Redirecting To Login Page");
          //navigate('/SecondaryBoardingSchoolBursaryApplicationForm')
          //localStorage.setItem('clientType', JSON.stringify('First Timer'))
          navigate('/Login')
        }
        else if(response.data === 'Duplicate Entry, Failed To Register'){
          alert("You Already Have An Account With Us, Redirecting To Login Page");
          //navigate('/ApplyForSecondaryProgress');
          //localStorage.setItem('clientType', JSON.stringify('Returning'))
          navigate('/Login')
        }
        else{
          alert(response.data)
        }
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  return (
    <>
     {/*} <>
        <Header />
      </> */}


  <main>
    <div class="container-fluid">

      
      <section class="mb-5 mt-5">

        
        <div class="card card-cascade narrower">

          
          <div class="view view-cascade gradient-card-header blue-gradient">
            <h5 class="mb-0">GRANTS APPLICATION PROGRESS</h5>
          </div>
          
          <div class="card-body card-body-cascade text-center table-responsive">

          <div class="row">
            <a class="text-left" href='Main' style={{ color: 'red' }} title='Back To The Home Page'>
            <i class='fas fa-home' style={{ fontSize: '100%' }} >Back</i> 
            </a>
            </div>
            
            <div class="row">
            
            <div class="col-md-4">
            <div class="md-outline form-sm text-left">  
            <label>Name Of Group Applying</label>             
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={nameOfGroupMakingApplication}
                onChange={e => setNameOfGroupMakingApplication(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4">
<div class="md-outline form-sm text-left"> 
<label>Group Applying ZRA TPIN</label>            
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={TPIN}
                onChange={e => setTPIN(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4">
            <div class="md-outline form-sm text-left">   
            <label>Group Bank Account Number</label>            
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
                />
              </div>
</div>  

<div class="col-md-6" >
            <div class="md-outline form-sm text-left">  
            <label>Email For Project Proposer
            <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validEmail || !email ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>             
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={email}
                onChange={e => setEmail(e.target.value)}
                aria-invalid={validEmail ? 'false' : 'true'}
                          aria-describedby='emailnote'
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                />
                <p
                          id='emailnote'
                          className={
                            emailFocus && email && !validEmail
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          4 to 24 characters. <br />
                          Let It Be A Valid Email Address.
                        </p>
              </div>
</div>

<div class="col-md-6">
            <div class="md-outline form-sm text-left"> 
            <label>Phone For Project Proposer
            <span className={validPhone ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validPhone || !phoneNumber ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>              
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                disabled ={email === '' || nameOfGroupMakingApplication === '' || TPIN === '' || accountNumber === ''}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}

                aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                />
                <p
                            id='phonenote'
                            className={
                              phoneFocus && phoneNumber && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            There Should Be 10 Numerical Values.
                          </p>
              </div>
</div>  

<div class="col-md-12" hidden = {!validPhone}>
            <div class="md-outline form-sm text-left">
              <label>OTP Received Via Email Or SMS</label>               
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={OTP}
                onChange={e => setOTP(e.target.value)}
                />
              </div>
</div>


<div class='col-md-12 col-12 mb-5'>
                          <button
                            class='form-control blue-gradient white-text'
                            onClick={() => {
                                handleSubmit()
                              }}
                              disabled = {email ==='' || !validOTP || !validPhone || nameOfGroupMakingApplication === '' ||
                              TPIN === '' || accountNumber === '' || phoneNumber === '' || OTP === ''}                          
                          >Next</button>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />

            </div>
            
          </div>
          

        </div>
        
        

      </section>
   
    </div>
  </main>
    </>
  )
}

export default ApplyForGrants
