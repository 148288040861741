
import {useRef, useState, useEffect} from 'react';
import '../App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../api/axios';
import $, { nodeName } from "jquery";
import "jquery/dist/jquery.min.js";
import {useNavigate} from 'react-router-dom';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/;
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ; 
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/;
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/; 
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const Pos = () => {
  const total = 0;
  const [ip,setIP] = useState('');
    const [posProductDropDown, setPosProductDropDown] = useState([]);
    const [posTable, setPosTable] = useState([]);
    const [paymentType, setPaymentType] = useState();
    const [posTableTotal, setPosTableTotal] = useState([]);
    const [receiptPrint, setReceiptPrint] = useState([]);
    const [receiptPrintTotal, setReceiptPrintTotal] = useState([]);
    const [receiptPrintAmountReceived, setReceiptPrintAmountReceived] = useState([]);
    const [receiptPrintChange, setReceiptPrintChange] = useState([]);
    const [itemQuantity, setItemQuantity] = useState();
    const [itemID, setItemID] = useState();
    const [itemQuantityHidden, setItemQuantityHidden] = useState(''); 
    const [itemStockIDHidden, setItemStockIDHidden] = useState('');
    const [onloadItemID, setOnloadItemID] = useState('');
    const [supervisorUsername, setSupervisorUsername] = useState();
    const [supervisorPassword, setSupervisorPassword] = useState();
    const [customerEmail, setCustomerEmail] = useState();
    const [amountReceived, setAmountReceived] = useState(0);

    

     //creating function to load ip address from the API
     const getData = async()=>{
      const res = await Axios.get('https://geolocation-db.com/json/')
      console.log(res.data);
      setIP(res.data.IPv4)
  }
useEffect(()=>{
      //passing getData method to the lifecycle method
      getData()
  },[ip])

 
  const firstSystemSetupID = localStorage.getItem("systemSetupID");
    const systemSetupIDInitial = JSON.parse(firstSystemSetupID);

    const firstUserID = localStorage.getItem("userID");
    const userIDInitial = JSON.parse(firstUserID);
    const userID = userIDInitial;
    //alert (userID);
    //alert("id = "+systemSetupIDInitial);
            useEffect( () => {
                Axios.post("http://localhost:3001/api/getPosProductDropDown", {
                          businessNameID : systemSetupIDInitial,		
          }).then((response) => { 
            setPosProductDropDown(response.data);
            
           var a = [response.data];
            localStorage.setItem('arr', JSON.stringify(a));
            //a = JSON.parse(localStorage.getItem('arr')) || [];
            //alert(a); 
           })//.catch((err) => alert("ERROR : Check Your Network Connection"));
                  },
                  [posProductDropDown])

               

                 
                  const handleSupervisorLogin = async (e) => 
                  {
                    //alert("Username "+ supervisorUsername +" Password = "+supervisorPassword);
                    Axios.post("http://localhost:3001/api/userLogin", {
                    user : supervisorUsername,
                    pwd : supervisorPassword,
                    user_IP : ip,
                  }).then((res) => {   
                    if(res.data !="Failed To Login, Check Your Credentials"){                  
                    res.data.map((val)=>{
                     localStorage.setItem("userPosition", JSON.stringify(val.position));                      
                      })
                    }
                    else{
                      alert(res.data);
                    }
                  })

                  }

                  let navigate = useNavigate();
                  const handleSupervisorLogout = async (e) =>
                  {
                    
                    Axios.post("http://localhost:3001/api/supervisorLogout", {
                      userID : userID,
                    }).then((res) => {   
                      if(res.data !="Failed To Login, Check Your Credentials"){                  
                      //res.data.map((val)=>{
                       localStorage.setItem("userPosition", JSON.stringify(res.data));                      
                        //})
                        //alert("Login Successful"+res.data);
                      }
                      else{
                        alert("Your Session Has Expired. We are Logging out");
                        
                        navigate("../Logout");
                      }
                    })
                    
                  }

                  
                 

                  const currentDate = new Date();
  const dateToday = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`;
  const timeNow = currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds();
                 
  const dateTodayPOSTable = `${currentDate.getDate()}${currentDate.getMonth()+1}${currentDate.getFullYear()}`;
  const timeNowPOSTable= currentDate.getHours() + '' + currentDate.getMinutes() + '' + currentDate.getSeconds();
                     
          const transactionIDSaved = localStorage.getItem("transactionID");
          const secondTransactionIDSaved = JSON.parse(transactionIDSaved);
          if(secondTransactionIDSaved === "0")
          {
          localStorage.setItem("transactionID", JSON.stringify(timeNowPOSTable+dateTodayPOSTable+userID));
          }
          const firstTransactionID = localStorage.getItem("transactionID");
          const transactionIDSecond = JSON.parse(firstTransactionID);

          const POSTransactionID1 = localStorage.getItem("POSTransactionID");
const POSTransactionID = JSON.parse(POSTransactionID1);

const transactionIDPrintReceipt1 = localStorage.getItem("transactionIDPrintReceipt");
const transactionIDPrintReceipt = JSON.parse(transactionIDPrintReceipt1);

const organisation_address = JSON.parse(localStorage.getItem("organisation_address"));
const organisation_phone = JSON.parse(localStorage.getItem("organisation_phone"));
const organisation_email = JSON.parse(localStorage.getItem("organisation_email"));
//alert(organisation_phone);

if(POSTransactionID === "" || POSTransactionID === null)
{
localStorage.setItem("POSTransactionID", JSON.stringify(userID+timeNowPOSTable+dateTodayPOSTable+userID));
}

                  const handleAddItems = async (e) => 
                  {
                    console.log("data " +itemQuantity);
                    if(itemID === undefined || itemQuantity ===undefined)
                    {
                      alert("please Fill Empty Fieds");
                    }
                    else{
                    alert("Added Item");
                    localStorage.setItem("transactionIDPrintReceipt", JSON.stringify("0")); // This line here Empties The transaction ID For Receipt Printing To Hide Print and Email Buttons

                    Axios.post("http://localhost:3001/api/insertPosTable", {
                          businessNameID : systemSetupIDInitial,	
                          itemID : itemID,
                          itemQuantity : itemQuantity,
                          transactionID : transactionIDSecond,
                          userID : userID,
                          POSTransactionID : POSTransactionID,
          })//.then((response) => { 
            //setPosTable(response.data);
           
                  //})
        }

        //([posProductDropDown])
                }

                const handlePay = async (e) => 
                {
                  
                 // if(paymentType !="Cash")
                  //{
                    //amountReceived = null;
                  //}  
                  alert("You Are Now Processing The Transaction");
                  
                  Axios.post("http://localhost:3001/api/insertPay", {
                        businessNameID : systemSetupIDInitial,	
                        dateToday : dateToday,
                        timeNow : timeNow,
                        system_setup_IP : ip,
                        paymentType : paymentType,
                        transactionID : transactionIDSecond,
                        userID : userID,
                        amountReceived : amountReceived,
        }).then((response) => { 
          //setPosTable(response.data);
          
          if(response.data ==="Transaction Processed Successfuly"){
            localStorage.setItem("transactionIDPrintReceipt", JSON.stringify(transactionIDSecond)); // This line here Sets The transaction ID For Receipt Printing To Show Print and Email Buttons
localStorage.setItem("transactionID", JSON.stringify("0")); // This line here Empties The transaction ID
          }
          else{
            alert("There Was A Problem Processing Transaction, Please Contact The Administrator Immediately");
          }
          alert (response.data);

                })
              }

              //This API Call Returns The Receipt Values For This Transaction
              useEffect( () => {
Axios.post("http://localhost:3001/api/getReceiptPrint", {
  transactionID : transactionIDPrintReceipt,
}).then((responseReceiptPrint) => { 
setReceiptPrint(responseReceiptPrint.data);
localStorage.setItem("responseReceiptPrint", JSON.stringify(responseReceiptPrint.data));
//alert(JSON.stringify(responseReceiptPrint));
})
},
[receiptPrint])

useEffect( () => {
Axios.post("http://localhost:3001/api/getReceiptPrintTotal", {
  transactionID : transactionIDPrintReceipt,
}).then((responseReceiptPrintTotal) => { 
setReceiptPrintTotal(responseReceiptPrintTotal.data);
})
},
[receiptPrintTotal])

useEffect( () => {
  Axios.post("http://localhost:3001/api/getReceiptPrintAmountReceived", {
    transactionID : transactionIDPrintReceipt,
  }).then((responseReceiptPrintAmountReceived) => { 
  setReceiptPrintAmountReceived(responseReceiptPrintAmountReceived.data);
  })
  },
  [receiptPrintAmountReceived])
  
  useEffect( () => {
  Axios.post("http://localhost:3001/api/getReceiptPrintChange", {
    transactionID : transactionIDPrintReceipt,
  }).then((responseReceiptPrintChange) => { 
  setReceiptPrintChange(responseReceiptPrintChange.data);  
localStorage.setItem("responseReceiptPrintTotal", JSON.stringify(responseReceiptPrintChange.data));
  })
  },
  [receiptPrintChange])
//The Receipt Call Ends Here

/*var existing= localStorage.getItem('namesArray');
existing = existing ? existing.split(',') : [];
alert("tuna");
existing.push(tuna);
localStorage.setItem('namesArray', existing.toString());*/
                 //prompt("New member name?");
                 //prompt("New member names?");
                 useEffect( () => {
                 Axios.post("http://localhost:3001/api/getPosTable", {
                  transactionID : transactionIDSecond,
  }).then((response) => { 
    setPosTable(response.data);
          })
        },
          [posTable])

          useEffect( () => {
          Axios.post("http://localhost:3001/api/getPosTableTotal", {
            transactionID : transactionIDSecond,
}).then((response) => { 
setPosTableTotal(response.data);
    })

  },
  [posTableTotal])

    const handlePOSTableDelete = async (e) => 
    {
      const posTableDeleteID = e.target.value;
      //alert(posTableDeleteID);
      //useEffect( () => {
      Axios.post("http://localhost:3001/api/posTableDeleteID", {
        posTableDeleteID : posTableDeleteID,
        //itemQuantityHidden : itemQuantityHidden, 
        //itemStockIDHidden : itemStockIDHidden,
}).then((response) => { 
//setPosTableTotal(response.data);
alert(response.data);
})

//},
//[posTable, posProductDropDown,posTableTotal, ])

  
  ([posTable, posProductDropDown,posTableTotal ])
    }

    const firstRolePosition = localStorage.getItem("userPosition");
    const secondRolePosition = JSON.parse(firstRolePosition);
//alert(secondRolePosition);

//alert(POSTransactionID);

const handleCustomerEmail = async (e) => 
{
  const firstUserOrganisation = localStorage.getItem("userOrganisation");
    const secondUserOrganisation = JSON.parse(firstUserOrganisation);

    const firstResponseReceiptPrintVar = localStorage.getItem("responseReceiptPrint");
    let secondResponseReceiptPrintVar = JSON.parse(firstResponseReceiptPrintVar);
    secondResponseReceiptPrintVar = (JSON.stringify(secondResponseReceiptPrintVar));

    
    const firstResponseReceiptPrintTotalVar = localStorage.getItem("responseReceiptPrintTotal");
    let secondResponseReceiptPrintTotalVar = JSON.parse(firstResponseReceiptPrintTotalVar);
    secondResponseReceiptPrintTotalVar = (JSON.stringify(secondResponseReceiptPrintTotalVar));

    const subject = "Receipt";
    const message = "We Have Sent The Receipt Via E - Mail";
	  const html = "Body";
   // const responseReceiptPrintVar = JSON.stringify(responseReceiptPrint); 
//alert(secondResponseReceiptPrintVar);
alert ("We Are Sending The Receipt To Our Customer Via Email...");
Axios.post("http://localhost:3001/api/emailSend", {
  //Axios.post("http://localhost:3001/api/DataJson", {
  customerEmail : customerEmail,
  secondUserOrganisation : secondUserOrganisation,
  dateToday : dateToday,
  timeNow : timeNow,
  organisation_addressToPrint : organisation_address,
  organisation_phoneToPrint : organisation_phone,
  organisation_emailToPrint : organisation_email,
  responseReceiptPrintVar : secondResponseReceiptPrintVar,
  responseReceiptPrintTotalVar : secondResponseReceiptPrintTotalVar,
  transactionIDSecondToPrint : transactionIDSecond,

}).then((response) => { 
//setPosTableTotal(response.data);
alert(response.data);
})

}

const testPDF = async (e) => 
{
  alert("Testing To PDF");
  Axios.get("http://localhost:3001/api/DataJson", {
 
}).then((response) => { 
//setPosTableTotal(response.data);
alert(response.data);
})
}


function   print() {
  var content = document.getElementById('printarea');
  var pri = document.getElementById('ifmcontentstoprint').contentWindow;
  pri.document.open();
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
}

/*const sendEmail = (e) => {
  e.preventDefault(); // this helps in making the form not to refresh when submitting
alert("We Are Submiting Form");
}
originalString = 'GeeksForGeeks';
            newString = originalString.replace(/G/g, '');
*/

            //let originalString = 'GeeksForGeeks';
            //let newString = originalString.replace('ee', '');
            //alert(newString);
    return(
	
	<main>
	  <div class="container-fluid">
      <section class="section card mb-1">
<div class="card">
  <div class="card-body">
    <div class="form-header">
    <h1 class="text-center my-1 h1">POINT OF SALE</h1>
    </div>

  <div class="row">

     {/*<!-- Modal: modalManagerLogin  -->*/}
     <div class="modal fade" id="modalManagerLogin" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog cascading-modal" role="document"  >
            {/*<!-- Content -->*/}
            <div class="modal-content" >

             {/* <!-- Header -->*/}
              <div class="modal-header light-blue darken-3 white-text ">
                <h4 class="">Supervisor Login :</h4>
                <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/*<!-- Body -->*/}
              <div class="modal-body mb-0" >

              <div class="row"> {/*Row Starts Here*/}
            {/*<!-- Grid column -->*/}
            <div class="col-md-12 mb-0.00001">
              <div class="md-form ">
                <label for="f3" class="">Username</label><br />
  <input type="text" id="f3" class="form-control form-control" value={supervisorUsername} onChange={(e) => setSupervisorUsername(e.target.value)} />
              </div>
            </div>
            {/*<!-- Grid column -->*/}
            
            </div> {/*Row Ends Here*/}

            <div class="row"> {/*Row Starts Here*/}
            {/*<!-- Grid column -->*/}
            <div class="col-md-12 mb-0.00001">
              <div class="md-form ">
                <label for="f3" class="">Password</label><br />
<input type="password" id="f3" class="form-control form-control" value={supervisorPassword} onChange={(e) => setSupervisorPassword(e.target.value)} />
              </div>
            </div>
            {/*<!-- Grid column -->*/}
            
            </div> {/*Row Ends Here*/}
            <button class="btn btn-primary" onClick={handleSupervisorLogin}><i class="w-fa fas fa-sign-in-alt"></i>Login</button>
            </div>{/*Modal Body Ends Here*/}
            
               
              </div>
            </div>
            {/*<!-- Content -->*/}
          </div>
    
       {/* <!-- Modal: modalManager .-->*/}

    	
  {/*<!-- Modal: modalAddItem  -->*/}
        <div class="modal fade" id="modalAddItem" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog cascading-modal" role="document"  >
            {/*<!-- Content -->*/}
            <div class="modal-content" >

             {/* <!-- Header -->*/}
              <div class="modal-header light-blue darken-3 white-text ">
                <h4 class="">Add item(Product)</h4>
                <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/*<!-- Body -->*/}
              <div class="modal-body mb-0" >
              {/*<!-- Grid row -->*/}
          <div class="row">

            {/*<!-- Grid column -->*/}
            <div class="col-lg-12 col-md-12 mb-4">

              {/*<!-- Name -->*/}
              <select class="custom-select d-block w-100"  searchable="Search here.." 
        onChange={(e) => setItemID(e.target.value)} required>
                <option value="" >Select Product</option>
                {
    posProductDropDown.map((val)=>{
return (
    <option value={val.stockID} disabled={val.stock_quantity === 0 || val.stock_quantity < itemQuantity }>{val.product} (Quantity =  {val.stock_quantity}) (Unit Price = K {val.product_unit_price})</option>
);

    })
}
              
              </select>
            </div>{/*<!-- Grid column -->*/}
            </div> {/*Row Ends Here*/}

            <div class="row"> {/*Row Starts Here*/}
            {/*<!-- Grid column -->*/}
            <div class="col-md-12 mb-4">
              <div class="md-form ">
                <label for="f3" class="">Quantity</label><br />
                <input type="number" id="f3" class="form-control form-control" value={itemQuantity}
        onChange={(e) => setItemQuantity(e.target.value)} required  />
              </div>
            </div>
            {/*<!-- Grid column -->*/}
            
            
            </div> {/*Row Ends Here*/}
            <button disabled={itemID === "" || itemID === undefined || itemQuantity ===undefined || itemQuantity === "" } class="btn btn-primary" onClick={handleAddItems}>Add <i class="w-fa fas fa-plus"></i></button>
            </div>{/*Modal Body Ends Here*/}
               
              </div>
            </div>
            {/*<!-- Content -->*/}
          </div>
    
       {/* <!-- Modal: modalAddItem .-->*/}

       {/*<!-- Modal: modalEmailReceipt  -->*/}
     <div class="modal fade" id="modalEmailReceipt" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog cascading-modal" role="document"  >
            {/*<!-- Content -->*/}
            <div class="modal-content" >

             {/* <!-- Header -->*/}
              <div class="modal-header light-blue darken-3 white-text ">
                <h4 class="">Send Receipt Via E - mail :</h4>
                <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/*<!-- Body -->*/}
              <div class="modal-body mb-0" >

              <div class="row"> {/*Row Starts Here*/}
            {/*<!-- Grid column -->*/}
            <div class="col-md-12 mb-0.00001">
              <div class="md-form ">
                <label for="f3" class="">E - mail</label><br />
  <input type="email" id="f3" class="form-control form-control" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
              </div>
            </div>
            {/*<!-- Grid column -->*/}
            
            </div> {/*Row Ends Here*/}

            <button class="btn btn-primary" onClick={handleCustomerEmail}>Send Email <i class="fas fa-paper-plane"></i></button>
            </div>{/*Modal Body Ends Here*/}
            
               
              </div>
            </div>
            {/*<!-- Content -->*/}
          </div>
    
       {/* <!-- Modal: modalEmailReceipt .-->*/}
       <div class="col-lg-3 col-md-1 ">
    {   
secondRolePosition ==="Cashier" ? (  
  <button class="btn btn-sm btn-red" data-toggle="modal" data-target="#modalManagerLogin">Supervisor Login <i class="w-fa fas fa-sign-in-alt"></i></button>
) : ( 
  <button class="btn btn-sm btn-red" onClick={handleSupervisorLogout}>Supervisor Logout <i class="w-fa fas fa-sign-out-alt"></i></button>

  )
}
</div>
<div class="col-lg-2 col-md-1">
  <button class="btn btn-sm btn-brown" data-toggle="modal" data-target="#modalAddItem">Add Item <i class="w-fa fas fa-plus"></i> </button>
  </div>
  <div class="col-lg-4 col-md-1">
{
transactionIDPrintReceipt != "0" ? (
  <>
  
  <button class="btn btn-sm btn-success" data-toggle="modal" data-target="#modalEmailReceipt">Email Receipt <i class="fas fa-paper-plane"></i></button>
  <button class="btn btn-sm btn-primary" onClick={print}>Print Receipt <i class="fa fa-print"></i></button> </>
) : (<h6></h6>)
}
</div>
<div class="col-lg-3 col-md-1">
  <button class="btn btn-sm btn-outline-primary text-right  h6" style={{cursor:'default', right:'0' }}>POS User Session ID: {POSTransactionID}</button>
</div>
<br />
<table id="" className="table align-items-center table-striped table-bordered table-hover table-sm"> {/*POS Table Starts Here*/}
<thead class="card-header text-uppercase white-text text-sm font-weight-bolder opacity-7 ps-2 ">
  <tr>
<th>Sn</th>
<th>PRODUCT</th>
<th>QUANTITY</th>
<th>UNIT PRICE</th>
<th>TOTAL PRODUCT PRICE</th>
{
secondRolePosition !="Cashier" ? (  <th>Delete</th>) : ( <input type="hidden"/>)
}
  </tr>
</thead>
<tbody>
{
  
    posTable.map((valPOSTable, index)=>{
      
return (
  
  <tr>
    <td>{index +1}</td>
    <td>{valPOSTable.product}</td>
    <td>{valPOSTable.itemQuantity}</td>
    <td>{valPOSTable.posTable_product_unit_price}</td>
    <td>{valPOSTable.total_price}</td>
    {
      
secondRolePosition !="Cashier" ? ( 
  <td>
       <button title="Remove This Item" class="btn btn-danger" value={valPOSTable.posTableID}  onClick={handlePOSTableDelete}>  <FontAwesomeIcon icon={faTimes} /> </button>
        </td>

) : ( <input type="hidden"/>)
}
    </tr>
    
);


    })
}


</tbody>
<tfoot>

{
  
  posTableTotal.map((valPOSTableTotal)=>{
return (
  
  valPOSTableTotal.total_prices != null ? (
<tr>
  <td>  
  <div class="row"> {/*Row Starts Here*/}
  {/*<!-- Grid column -->*/}
  <div class="col-md-8 mb-4">
  <label for="f3" class="">Payment Method</label>
    <select class="custom-select d-block w-100" onChange={(e) => setPaymentType(e.target.value)}>
      <option value="" >Choose</option>
      <option>Cash</option>
      <option>NHIMA</option>
    </select>
    </div>
            {/*<!-- Grid column -->*/}
    
            {/*<!-- Grid column -->*/}
            <div class="col-md-4 mb-4">
              
                
{
  paymentType ==="Cash" ? (
    <div class="">
      <label for="f3" class="">Amount Received</label>
                <input type="number" id="f3" class="form-control form-control" value={amountReceived}
        onChange={(e) => setAmountReceived(e.target.value)} required  />
        </div>
  ) :
  (
    <><label for="f3" class="">Amount Received</label>
    <input type="number" id="f3" class="form-control form-control" disabled  />
    </>
) 
}
              
            </div>
            {/*<!-- Grid column -->*/}
            
            
            </div> {/*Row Ends Here*/}
     </td>
  <td colspan="3" align='right'>  
  <h6>TOTAL : </h6> 
  
  {(amountReceived !=0 && valPOSTableTotal.total_prices <= amountReceived ) && (paymentType === "Cash" && paymentType != "" && paymentType != undefined) ? (<><h6>MONEY RECEIVED : </h6> <h6>CHANGE : </h6></>) : (<></>)}
  </td>
  
  <td  align='left'>
    <h6>K{valPOSTableTotal.total_prices}</h6>
    
    {(amountReceived !=0 && valPOSTableTotal.total_prices <= amountReceived) && (paymentType === "Cash" && paymentType != "" && paymentType != undefined) ? (<><h6>K{amountReceived}</h6> <h6>K{amountReceived - valPOSTableTotal.total_prices}</h6> </>) : (<></>)}
  
  {
    paymentType !="Cash" ? (
  <button disabled={paymentType === "" || paymentType === undefined} class="btn btn-primary" onClick={handlePay}>Check-Out</button> 
    ):
    (
  <button disabled={paymentType === "" || paymentType === undefined || valPOSTableTotal.total_prices > amountReceived} class="btn btn-primary" onClick={handlePay}>Check-Out Cash</button> 
    )
}
  </td>
  </tr>
) : (<h6></h6>)
  
);


  })
}

</tfoot>
</table> {/*POS Table Ends Here*/}

{/*Printing Receipt Starts Here*/}
<iframe id="ifmcontentstoprint" style={{
                        height: '0px',
                        width: '0px',
                        position: 'absolute'
                    }}></iframe>   
 <div id='printarea' style={{ display: 'none' }}>
  <table >
    <tr>
    <th>Sn</th>
<th>PRODUCT</th>
<th>QUANTITY</th>
<th>UNIT PRICE</th>
<th>TOTAL PRODUCT PRICE</th>
    </tr>

   {
  
  receiptPrint.map((valReceiptPrint, index)=>{
      
return (
  
  <tr>
    <td>{index +1}</td>
    <td>{valReceiptPrint.product}</td>
    <td>{valReceiptPrint.unit_quantity}</td>
    <td>{valReceiptPrint.product_unit_price}</td>
    <td>{valReceiptPrint.product_total_price}</td>
    </tr>
    ); 
})
}


{
  
  receiptPrintChange.map((valReceiptPrintTotal)=>{
return (
<tr>
  <td>  
   </td>
  <td colspan="3" align='right'>  
  <h4>TOTAL : </h4>
  <h4>Amount Received : </h4>
  <h4>Change : </h4>
  </td>
  
  <td>
    <h4>K{valReceiptPrintTotal.sum_total_price}</h4>
    <h4>K{valReceiptPrintTotal.amount_received}</h4>
    <h4>K{valReceiptPrintTotal.customer_change}</h4>
  </td>
  </tr>
  
);


  })
}
  </table> 
                    </div>
{/*Printing Receipt Ends Here*/}

    </div>
    </div>
    </div>
</section>
</div>
  </main>

    )

}

export default Pos;