import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faInfo,
  faWarning
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Header from './header'
const ApplyForSecondaryProgress = () => {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let id = localStorage.getItem('userID')
  id = JSON.parse(id)

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let clientID = localStorage.getItem('userID')
  clientID = JSON.parse(clientID)

  let login = localStorage.getItem('login')
  login = JSON.parse(login)

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if(loggedIn != 'Yes'){
    navigate('/Logout')
  }

  const handleHome = async e => {
navigate('/')
  }
  const handleApply = async e => {
    navigate('/SecondaryBoardingSchoolBursaryApplicationForm')
  }
  const handleLogout = async e => {
    navigate('/Logout')
  }
  const handleAccount = async e => {
    navigate('/AccountSecondary')
  }

  var [applicationSectionsComplete, setapplicationSectionsComplete] = useState('');
  var [statusWard, setstatusWard] = useState(''); 
  var [statusProcessedWard, setstatusProcessedWard] = useState(''); 
  var [processedWardDate, setprocessedWardDate] = useState(''); 
  var [processedByWard, setprocessedByWard] = useState(''); 
  var [approvedByWard, setapprovedByWard] = useState(''); 
  var [approvedByWardDate, setapprovedByWardDate] = useState(''); 
  var [reasonWard, setreasonWard] = useState(''); 
  var [statusConstituency, setstatusConstituency] = useState(''); 
  var [statusProcessedConstituency, setstatusProcessedConstituency] = useState(''); 
  var [processedByConstituency, setprocessedByConstituency] = useState(''); 
  var [processedConstituencyDate, setprocessedConstituencyDate] = useState(''); 
  var [approvedByConstituency, setapprovedByConstituency] = useState(''); 
  var [approvedConstituencyDate, setapprovedConstituencyDate] = useState(''); 
  var [reasonconstituency, setreasonconstituency] = useState(''); 
  var [statusCouncilPrincipal, setstatusCouncilPrincipal] = useState(''); 
  var [processedByCouncilPrincipal, setprocessedByCouncilPrincipal] = useState(''); 
  var [processedCouncilPrincipalDate, setprocessedCouncilPrincipalDate] = useState(''); 
  var [approvedByCouncilPrincipal, setapprovedByCouncilPrincipal] = useState(''); 
  var [approvedCouncilPrincipalDate, setapprovedCouncilPrincipalDate] = useState(''); 
  var [reasonCouncilPrincipal, setreasonCouncilPrincipal] = useState(''); 
  var [tenureCouncilPrincipal, settenureCouncilPrincipal] = useState(''); 
  var [statusPlanning, setstatusPlanning] = useState(''); 
  var [processedByPlanning, setprocessedByPlanning] = useState(''); 
  var [processedPlanningDate, setprocessedPlanningDate] = useState(''); 
  var [approvedByPlanning, setapprovedByPlanning] = useState(''); 
  var [approvedPlanningDate, setapprovedPlanningDate] = useState(''); 
  var [reasonPlanning, setreasonPlanning] = useState(''); 
  var [tenurePlanning, settenurePlanning] = useState(''); 
  var [statusFinance, setstatusFinance] = useState(''); 
  var [processedByFinance, setprocessedByFinance] = useState(''); 
  var [processedFinanceDate, setprocessedFinanceDate] = useState(''); 
  var [approvedByFinance, setapprovedByFinance] = useState(''); 
  var [approvedFinanceDate, setapprovedFinanceDate] = useState(''); 
  var [reasonFinance, setreasonFinance] = useState(''); 
  var [tenureFinance, settenureFinance] = useState(''); 
  var [statusEngineering, setstatusEngineering] = useState(''); 
  var [processedByEngineering, setprocessedByEngineering] = useState(''); 
  var [processedEngineeringDate, setprocessedEngineeringDate] = useState(''); 
  var [approvedByEngineering, setapprovedByEngineering] = useState(''); 
  var [approvedEngineeringDate, setapprovedEngineeringDate] = useState(''); 
  var [reasonEngineering, setreasonEngineering] = useState(''); 
  var [tenureEngineering, settenureEngineering] = useState(''); 
  var [statusProcurement, setstatusProcurement] = useState(''); 
  var [processedByProcurement, setprocessedByProcurement] = useState(''); 
  var [processedProcurementDate, setprocessedProcurementDate] = useState(''); 
  var [approvedByProcurement, setapprovedByProcurement] = useState(''); 
  var [approvedProcurementDate, setapprovedProcurementDate] = useState(''); 
  var [reasonProcurement, setreasonProcurement] = useState(''); 
  var [tenureProcurement, settenureProcurement] = useState(''); 
  var [statusProvince, setstatusProvince] = useState(''); 
  var [processedByProvince, setprocessedByProvince] = useState(''); 
  var [processedProvinceDate, setprocessedProvinceDate] = useState(''); 
  var [approvedByProvince, setapprovedByProvince] = useState(''); 
  var [approvedProvinceDate, setapprovedProvinceDate] = useState(''); 
  var [reasonProvince, setreasonProvince] = useState(''); 
  var [tenureProvince, settenureProvince] = useState(''); 
  var [statusMinistry, setstatusMinistry] = useState(''); 
  var [processedByMinistry, setprocessedByMinistry] = useState(''); 
  var [processedMinistryDate, setprocessedMinistryDate] = useState(''); 
  var [approvedByMinistry, setapprovedByMinistry] = useState(''); 
  var [approvedMinistryDate, setapprovedMinistryDate] = useState(''); 
  var [reasonMinistry, setreasonMinistry] = useState(''); 
  var [reasonProcessedWard, setreasonProcessedWard] = useState(''); 
  var [reasonProcessedConstituency, setreasonProcessedConstituency] = useState(''); 
  var [reasonProcessedPlanning, setreasonProcessedPlanning] = useState(''); 
  var [reasonProcessedFinance, setreasonProcessedFinance] = useState(''); 
  var [reasonProcessedEngineering, setreasonProcessedEngineering] = useState(''); 
  var [reasonProcessedProcurement, setreasonProcessedProcurement] = useState(''); 
  var [reasonProcessedCouncilPrincipal, setreasonProcessedCouncilPrincipal] = useState(''); 
  var [reasonProcessedProvince, setreasonProcessedProvince] = useState(''); 
  var [reasonProcessedMinistry, setreasonProcessedMinistry] = useState(''); 
  var [processedAmountWard, setprocessedAmountWard] = useState(''); 
  var [approvedAmountWard, setapprovedAmountWard] = useState(''); 
  var [processedAmountConstituency, setprocessedAmountConstituency] = useState(''); 
  var [approvedAmountConstituency, setapprovedAmountConstituency] = useState(''); 
  var [processedAmountProvince, setprocessedAmountProvince] = useState(''); 
  var [approvedAmountProvince, setapprovedAmountProvince] = useState(''); 
  var [processedAmountMinistry, setprocessedAmountMinistry] = useState(''); 
  var [approvedAmountMinistry, setapprovedAmountMinistry] = useState('');
  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()} - ${currentDate.getMonth() + 1} - ${currentDate.getFullYear()}`;

  useEffect(() => {
   
    var formDataCreateOTP = new FormData(this)
      formDataCreateOTP.append('apiType', 'secondaryCdfApplicationsProcessingProgressReport')
      formDataCreateOTP.append('dateToday', dateToday)
      formDataCreateOTP.append('id', id)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
        data: formDataCreateOTP,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        //alert(response.data.statusWard)
    setapplicationSectionsComplete(response.data.applicationSectionsComplete);
		setstatusWard(response.data.statusWard); 
		setstatusProcessedWard(response.data.statusProcessedWard); 
		setprocessedWardDate(response.data.processedWardDate); 
		setprocessedByWard(response.data.processedByWard); 
		setapprovedByWard(response.data.approvedByWard); 
		setapprovedByWardDate(response.data.approvedByWardDate); 
		setreasonWard(response.data.reasonWard); 
		setstatusConstituency(response.data.statusConstituency); 
		setstatusProcessedConstituency(response.data.statusProcessedConstituency); 
		setprocessedByConstituency(response.data.processedByConstituency); 
		setprocessedConstituencyDate(response.data.processedConstituencyDate); 
		setapprovedByConstituency(response.data.approvedByConstituency); 
		setapprovedConstituencyDate(response.data.approvedConstituencyDate); 
		setreasonconstituency(response.data.reasonconstituency); 
		setstatusCouncilPrincipal(response.data.statusCouncilPrincipal); 
		setprocessedByCouncilPrincipal(response.data.processedByCouncilPrincipal); 
		setprocessedCouncilPrincipalDate(response.data.processedCouncilPrincipalDate); 
		setapprovedByCouncilPrincipal(response.data.approvedByCouncilPrincipal); 
		setapprovedCouncilPrincipalDate(response.data.approvedCouncilPrincipalDate); 
		setreasonCouncilPrincipal(response.data.reasonCouncilPrincipal); 
		settenureCouncilPrincipal(response.data.tenureCouncilPrincipal); 
		setstatusPlanning(response.data.statusPlanning); 
		setprocessedByPlanning(response.data.processedByPlanning); 
		setprocessedPlanningDate(response.data.processedPlanningDate); 
		setapprovedByPlanning(response.data.approvedByPlanning); 
		setapprovedPlanningDate(response.data.approvedPlanningDate); 
		setreasonPlanning(response.data.reasonPlanning); 
		settenurePlanning(response.data.tenurePlanning); 
		setstatusFinance(response.data.statusFinance); 
		setprocessedByFinance(response.data.processedByFinance); 
		setprocessedFinanceDate(response.data.processedFinanceDate); 
		setapprovedByFinance(response.data.approvedByFinance); 
		setapprovedFinanceDate(response.data.approvedFinanceDate); 
		setreasonFinance(response.data.reasonFinance); 
		settenureFinance(response.data.tenureFinance); 
		setstatusEngineering(response.data.statusEngineering); 
		setprocessedByEngineering(response.data.processedByEngineering); 
		setprocessedEngineeringDate(response.data.processedEngineeringDate); 
		setapprovedByEngineering(response.data.approvedByEngineering); 
		setapprovedEngineeringDate(response.data.approvedEngineeringDate); 
		setreasonEngineering(response.data.reasonEngineering); 
		settenureEngineering(response.data.tenureEngineering); 
		setstatusProcurement(response.data.statusProcurement); 
		setprocessedByProcurement(response.data.processedByProcurement); 
		setprocessedProcurementDate(response.data.processedProcurementDate); 
		setapprovedByProcurement(response.data.approvedByProcurement); 
		setapprovedProcurementDate(response.data.approvedProcurementDate); 
		setreasonProcurement(response.data.reasonProcurement); 
		settenureProcurement(response.data.tenureProcurement); 
		setstatusProvince(response.data.statusProvince); 
		setprocessedByProvince(response.data.processedByProvince); 
		setprocessedProvinceDate(response.data.processedProvinceDate); 
		setapprovedByProvince(response.data.approvedByProvince); 
		setapprovedProvinceDate(response.data.approvedProvinceDate); 
		setreasonProvince(response.data.reasonProvince); 
		settenureProvince(response.data.tenureProvince); 
		setstatusMinistry(response.data.statusMinistry); 
		setprocessedByMinistry(response.data.processedByMinistry); 
		setprocessedMinistryDate(response.data.processedMinistryDate); 
		setapprovedByMinistry(response.data.approvedByMinistry); 
		setapprovedMinistryDate(response.data.approvedMinistryDate); 
		setreasonMinistry(response.data.reasonMinistry); 
		setreasonProcessedWard(response.data.reasonProcessedWard); 
		setreasonProcessedConstituency(response.data.reasonProcessedConstituency); 
		setreasonProcessedPlanning(response.data.reasonProcessedPlanning); 
		setreasonProcessedFinance(response.data.reasonProcessedFinance); 
		setreasonProcessedEngineering(response.data.reasonProcessedEngineering); 
		setreasonProcessedProcurement(response.data.reasonProcessedProcurement); 
		setreasonProcessedCouncilPrincipal(response.data.reasonProcessedCouncilPrincipal); 
		setreasonProcessedProvince(response.data.reasonProcessedProvince); 
		setreasonProcessedMinistry(response.data.reasonProcessedMinistry); 
		setprocessedAmountWard(response.data.processedAmountWard); 
		setapprovedAmountWard(response.data.approvedAmountWard); 
		setprocessedAmountConstituency(response.data.processedAmountConstituency); 
		setapprovedAmountConstituency(response.data.approvedAmountConstituency); 
		setprocessedAmountProvince(response.data.processedAmountProvince); 
		setapprovedAmountProvince(response.data.approvedAmountProvince); 
		setprocessedAmountMinistry(response.data.processedAmountMinistry); 
		setapprovedAmountMinistry(response.data.approvedAmountMinistryset);
        })
  }, [])

  let iconPreApplication = faTimes;
  let iconApplication = faTimes;
  let iconWDFC = faTimes;
  let iconCDFC = faTimes;
  let iconCouncil = faTimes;
  let iconMinistry = faTimes;

  let classPreApplication = '';
  let classApplication = '';
  let classWDFC = '';
  let classCDFC = '';
  let classCouncil = '';
  let classMinistry = '';

  let titlePreApplication = '';
  let titleApplication = '';
  let titleWDFC = 'WDFC Stands For : \nWard Development Fund Committee';
  let titleCDFC = 'CDFC Stands For : \nConstituency Development Fund Committee';
  let titleCouncil = '';
  let titleMinistry = '';

  iconPreApplication = faCheck;
  classPreApplication = 'completed';
  titlePreApplication = '';
  
if(applicationSectionsComplete === 'Empty'){
  iconApplication = faTimes;
  classApplication = '';
  titleApplication = 'Click On The \nApplication Form \nButton Below To Proceed To The Application Form To Complete Registration';
}
else if(applicationSectionsComplete === 'Completed'){
  iconApplication = faCheck;
  classApplication = 'completed';
  titleApplication = '';

}
else{
  iconApplication = faInfo;
  classApplication = 'completed';
  titleApplication = applicationSectionsComplete;
}

 /* if(statusWard === null){
    iconPreApplication = faCheck;
    classPreApplication = 'completed';
    titlePreApplication = '';
  }*/
//else{
 /* iconPreApplication = faCheck;
  classPreApplication = 'completed';
  titlePreApplication = '';*/
if(statusWard === 'Pending Approval By WDC'){
    iconWDFC = faInfo;
    classWDFC = 'completed';
    titleWDFC = 'Pending Approval By \nThe Ward Development Fund Committee. \n\n\n\nNote : WDFC Stands For Ward Development Fund Committee';
}
else if(statusProcessedWard === 'Not Recommended' && approvedByWard === null){
  iconWDFC = faInfo;
 classWDFC = 'completed';
 titleWDFC = 'Pending Approval By \nThe Ward Development Fund Committee. \n\n\n\nNote : WDFC Stands For Ward Development Fund Committee';
}

else if(statusProcessedWard === 'Defer' && approvedByWard === null){
  iconWDFC = faInfo;
 classWDFC = 'completed';
 titleWDFC = 'Pending Approval By \nThe Ward Development Fund Committee. \n\n\n\nNote : WDFC Stands For Ward Development Fund Committee';
}
else if(statusProcessedWard === 'Not Recommended' && approvedByWard != null){
  iconWDFC = faWarning;
 classWDFC = 'warning';
 titleWDFC = 'Your Application Was Not Approved \n'+reasonProcessedWard+ '\n\n\n\nNote : WDFC Stands For Ward Development Fund Committee';
}

else if(statusProcessedWard === 'Defer' && approvedByWard != null){
  iconWDFC = faWarning;
 classWDFC = 'warning';
 titleWDFC = 'Your Application Was Not Approved \n'+reasonProcessedWard+ '\n\n\n\nNote : WDFC Stands For Ward Development Fund Committee';
}
else if(statusProcessedWard === 'Recommended' && approvedByWard === null){
  iconWDFC = faInfo;
 classWDFC = 'completed';
 titleWDFC = 'Pending Approval By \nThe Ward Development Fund Committee. \n\n\n\nNote : WDFC Stands For Ward Development Fund Committee';
}

else if(statusProcessedWard === 'Recommended' && approvedByWard != null){
  iconWDFC = faCheck;
 classWDFC = 'completed';
 titleWDFC = 'WDFC Stands \nThe Ward Development Fund Committee.';
}
//reasonProcessedWard
/*Recommended
Not Recommended
Defer*/
/*statusProcessedWard
processedWardDate
processedByWard
approvedByWard
approvedByWardDate
reasonWard



processedAmountWard
approvedAmountWard*/
//}
if(statusConstituency === null) {
  iconCDFC = faTimes;
   classCDFC = '';
   titleCDFC = 'CDFC Stands \nThe Constituency Development Fund Committee';
}
else{
  if(statusConstituency === 'Pending Approval By CDFC'){
   iconCDFC = faInfo;
   classCDFC = 'completed';
   titleCDFC = 'Pending Approval By \nThe Constituency Development Fund Committee.\n\n\n\nNote : CDFC Stands For Constituency Development Fund Committee';
}
else if(statusProcessedConstituency === 'Not Approved' && approvedByConstituency === null){
 iconCDFC = faInfo;
classCDFC = 'completed';
titleCDFC = 'Pending Approval By \nThe Constituency Development Fund Committee. \n\n\n\nNote : CDFC Stands For Constituency Development Fund Committee';
}

else if(statusProcessedConstituency === 'Defer' && approvedByConstituency === null){
 iconCDFC = faInfo;
classCDFC = 'completed';
titleCDFC = 'Pending Approval By \nThe Constituency Development Fund Committee. \n\n\n\nNote : CDFC Stands For Constituency Development Fund Committee';
}
else if(statusProcessedConstituency === 'Not Approved' && approvedByConstituency != null){
iconCDFC = faWarning;
classCDFC = 'warning';
titleCDFC = 'Your Application Was Not Approved \n'+reasonProcessedConstituency+ '\n\n\n\nNote : CDFC Stands For Ward Development Fund Committee';
}

else if(statusProcessedConstituency === 'Defer' && approvedByConstituency != null){
iconCDFC = faWarning;
classCDFC = 'warning';
titleCDFC = 'Your Application Was Not Approved \n'+reasonProcessedConstituency+ '\n\n\n\nNote : CDFC Stands For Ward Development Fund Committee';
}
else if(statusProcessedConstituency === 'Approved' && approvedByConstituency === null){
iconCDFC = faInfo;
classCDFC = 'completed';
titleCDFC = 'Pending Approval By \nThe Constituency Development Fund Committee. \n\n\n\nNote : CDFC Stands For Constituency Development Fund Committee';
}

else if(statusProcessedConstituency === 'Approved' && approvedByConstituency != null){
iconCDFC = faCheck;
classCDFC = 'completed';
titleCDFC = '';
}
/*statusConstituency
statusProcessedConstituency
processedByConstituency
processedConstituencyDate
approvedByConstituency
approvedConstituencyDate
reasonconstituency

reasonProcessedConstituency

processedAmountConstituency
approvedAmountConstituency*/
}
if(statusPlanning === ''){}
else{
/*statusPlanning
processedByPlanning
processedPlanningDate
approvedByPlanning
approvedPlanningDate
reasonPlanning
tenurePlanning

reasonProcessedPlanning

statusFinance
processedByFinance
processedFinanceDate
approvedByFinance
approvedFinanceDate
reasonFinance
tenureFinance

reasonProcessedFinance

statusEngineering
processedByEngineering
processedEngineeringDate
approvedByEngineering
approvedEngineeringDate
reasonEngineering
tenureEngineering

reasonProcessedEngineering

statusProcurement
processedByProcurement
processedProcurementDate
approvedByProcurement
approvedProcurementDate
reasonProcurement
tenureProcurement

reasonProcessedProcurement

statusCouncilPrincipal
processedByCouncilPrincipal
processedCouncilPrincipalDate
approvedByCouncilPrincipal
approvedCouncilPrincipalDate
reasonCouncilPrincipal
tenureCouncilPrincipal

reasonProcessedCouncilPrincipal */
}
if(statusProvince === ''){}
else{
/*statusProvince
processedByProvince
processedProvinceDate
approvedByProvince
approvedProvinceDate
reasonProvince
tenureProvince

reasonProcessedProvince

processedAmountProvince
approvedAmountProvince*/
}
if(statusMinistry === ''){}
else{
/*statusMinistry
processedByMinistry
processedMinistryDate
approvedByMinistry
approvedMinistryDate
reasonMinistry

reasonProcessedMinistry

processedAmountMinistry
approvedAmountMinistry*/
}


/*processedAmountWard
approvedAmountWard
processedAmountConstituency
approvedAmountConstituency
processedAmountProvince
approvedAmountProvince
processedAmountMinistry
approvedAmountMinistry*/

  return (
    <>
     {/*} <>
        <Header />
      </> */}


  <main>
    <div class="container-fluid">

      
      <section class="mb-3 mt-5">

        
        <div class="card card-cascade narrower">

          
          <div class="view view-cascade gradient-card-header blue-gradient text-uppercase">
            <h5 class="mb-0">{userName} - Application Progress Report</h5>
          </div>
          
          <div class="card-body card-body-cascade text-center table-responsive">

            
            <div class="row">
              <div class="col-md-12">

                
                <ul class="stepper stepper-horizontal">

                  
                  <li class={classPreApplication} title = {titlePreApplication}>
                    <a href="#!">
                      <span class="circle"><FontAwesomeIcon icon={iconPreApplication} /></span>
                      <span class="label">Pre - Application</span>
                    </a>
                  </li>

                  <li class={classApplication} title = {titleApplication}>
                    <a href="#!">
                      <span class="circle"><FontAwesomeIcon icon={iconApplication} /></span>
                      <span class="label">Application</span>
                    </a>
                  </li>

                  <li class={classWDFC} title = {titleWDFC}>
                    <a href="#!">
                      <span class="circle"><FontAwesomeIcon icon={iconWDFC} /></span>
                      <span class="label">WDFC</span>
                    </a>
                  </li>

                  <li class={classCDFC} title = {titleCDFC}>
                    <a href="#!">
                      <span class="circle"><FontAwesomeIcon icon={iconCDFC} /></span>
                      <span class="label">CDFC</span>
                      
                    </a>
                  </li>

                  <li class={classCouncil} title = {titleCouncil}>
                    <a href="#!">
                      <span class="circle"><FontAwesomeIcon icon={iconCouncil} /></span>
                      <span class="label">Council</span>
                    </a>
                  </li>

                  <li class={classMinistry} title = {titleMinistry}>
                    <a href="#!">
                      <span class="circle"><FontAwesomeIcon icon={iconMinistry} /></span>
                      <span class="label">Ministry</span>
                    </a>
                  </li>

                 {/* <li class="completed">
                    <a href="#!">
                      <span class="circle">7</span>
                      <span class="label">Ministry</span>
                    </a>
                  </li>

                 
                  <li class="active">
                    <a href="#!">
                      <span class="circle">2</span>
                      <span class="label">Second step</span>
                    </a>
                  </li>

                  
                  <li class="warning">
                    <a href="#!">
                      <span class="circle"><i class="fas fa-exclamation"></i></span>
                      <span class="label">Third step</span>
                    </a>
                  </li>*/}

                </ul>
                

              </div>
            </div>
            
          </div>
          

        </div>
        
        

      </section>
    
    
      <section>

       
        <div class="card card-cascade narrower">

        <div class="card-body card-body-cascade mb-5">
            
        <div class="row mt-1 mb-5">
              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleHome}
                ><i class='w-fa fas fa-home'></i>Home</button>
              </div>

              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleApply}
                ><i class='w-fa fas fa-edit'></i>Application Form</button>
              </div>

              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleAccount}
                ><i class='w-fa fas fa-user'></i>Account</button>
              </div>

              <div class="col-md-3 text-left">
                <button class="btn btn-primary"
                onClick={handleLogout}
                > <i class='w-fa fas fa-sign-out-alt'></i>Logout</button>
              </div>
            </div> 
         
         </div>
       
       </div>
     
     </section>
     
    </div>
  </main>
    </>
  )
}

export default ApplyForSecondaryProgress
