import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import Header from './header'
import { TotalNationalApplicantsGraph } from './Views/Graphs/TotalNationalApplicantsGraph.js'
const Home = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  var [hideWardsTables, setHideWardsTables] = useState('Yes');
  var [hideWardsTablesStatus, setHideWardsTablesStatus] = useState('No');
  //hideWardsTables
  const hideWardsTablesBtn = hideWardsTablesValue => {
    if(hideWardsTablesValue === 'No'){
    setHideWardsTablesStatus('Yes')
    setHideWardsTables('No')
    }
    else if(hideWardsTablesValue === 'Yes'){
      setHideWardsTablesStatus('No')
      setHideWardsTables('Yes')
    }
  }
  var [democgraphicsSecondary, setDemocgraphicsSecondary] = useState('')
  var [democgraphicsSkills, setDemocgraphicsSkills] = useState('')
  var hidden = 'No';
  if (democgraphicsSecondary != '' && democgraphicsSkills != '') {
    democgraphicsSecondary = 'col-xl-12 col-md-12 mb-2'
    democgraphicsSkills = 'col-xl-12 col-md-12 mb-2'
    hidden = 'Yes';
  } else {
    democgraphicsSecondary = 'col-xl-6 col-md-12'
    democgraphicsSkills = 'col-xl-4 col-md-12'
    hidden = 'No';
  }

  var [province, setprovince] = useState('')
  const [provinceDropDown, setprovinceDropDown] = useState([])
  var [council, setcouncil] = useState('')
  const [councilDropDown, setcouncilDropDown] = useState([])
  var [constituency, setconstituency] = useState('')
  const [constituencyDropDown, setconstituencyDropDown] = useState([])
  var [ward, setward] = useState('')
  const [wardDropDown, setwardDropDown] = useState([])

  var [dateFrom, setDateFrom] = useState('')
  var [dateTo, setDateTo] = useState('')
  const [ageGroup, setAgeGroup] = useState('')
  const [gender, setGender] = useState('')
  const [totalApplicants, setTotalApplicants] = useState([])
  const [totalApprovedApplicants, setTotalApprovedApplicants] = useState([])
  const [totalPendingApplicants, setTotalPendingApplicants] = useState([])
  const [totalRejectedApplicants, setTotalRejectedApplicants] = useState([])
  const [
    applyforcommunityprojectApplicantsTotalApproved,
    setApplyforcommunityprojectApplicantsTotalApproved
  ] = useState([])
  const [
    grantapplicationformforyouthwomenandcommunityempowermentTotalApproved,
    setGrantapplicationformforyouthwomenandcommunityempowermentTotalApproved
  ] = useState([])
  const [
    secondaryboardingschoolbursaryapplicationformTotalApproved,
    setSecondaryboardingschoolbursaryapplicationformTotalApproved
  ] = useState([])
  const [
    skillsdevelopmentbursaryapplicationformTotalApproved,
    setSkillsdevelopmentbursaryapplicationformTotalApproved
  ] = useState([])
  const [
    loansapplicationformTotalApproved,
    setLoansapplicationformTotalApproved
  ] = useState([])
  const [
    applyforcommunityprojectApplicantsTotalPending,
    setApplyforcommunityprojectApplicantsTotalPending
  ] = useState([])
  const [
    grantapplicationformforyouthwomenandcommunityempowermentTotalPending,
    setGrantapplicationformforyouthwomenandcommunityempowermentTotalPending
  ] = useState([])
  const [
    secondaryboardingschoolbursaryapplicationformTotalPending,
    setSecondaryboardingschoolbursaryapplicationformTotalPending
  ] = useState([])
  const [
    skillsdevelopmentbursaryapplicationformTotalPending,
    setSkillsdevelopmentbursaryapplicationformTotalPending
  ] = useState([])
  const [
    loansapplicationformTotalPending,
    setLoansapplicationformTotalPending
  ] = useState([])
  const [
    applyforcommunityprojectApplicantsTotalRejected,
    setApplyforcommunityprojectApplicantsTotalRejected
  ] = useState([])
  const [
    grantapplicationformforyouthwomenandcommunityempowermentTotalRejected,
    setGrantapplicationformforyouthwomenandcommunityempowermentTotalRejected
  ] = useState([])
  const [
    secondaryboardingschoolbursaryapplicationformTotalRejected,
    setSecondaryboardingschoolbursaryapplicationformTotalRejected
  ] = useState([])
  const [
    skillsdevelopmentbursaryapplicationformTotalRejected,
    setSkillsdevelopmentbursaryapplicationformTotalRejected
  ] = useState([])
  const [
    loansapplicationformTotalRejected,
    setLoansapplicationformTotalRejected
  ] = useState([])
  const [
    applyforcommunityprojectApplicantsTotal,
    setApplyforcommunityprojectApplicantsTotalt
  ] = useState([])
  const [
    applyforcommunityprojectApplicantsApprovedThisYear,
    setApplyforcommunityprojectApplicantsApprovedThisYear
  ] = useState([])
  const [
    grantapplicationformforyouthwomenandcommunityempowermentTotal,
    setGrantapplicationformforyouthwomenandcommunityempowermentTotal
  ] = useState([])
  const [
    grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear,
    setGrantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
  ] = useState([])
  const [
    secondaryboardingschoolbursaryapplicationformTotal,
    setSecondaryboardingschoolbursaryapplicationformTotal
  ] = useState([])
  const [
    secondaryboardingschoolbursaryapplicationformApprovedThisYear,
    setSecondaryboardingschoolbursaryapplicationformApprovedThisYear
  ] = useState([])
  const [
    skillsdevelopmentbursaryapplicationformTotal,
    setSkillsdevelopmentbursaryapplicationformTotal
  ] = useState([])
  const [
    skillsdevelopmentbursaryapplicationformApprovedThisYear,
    setSkillsdevelopmentbursaryapplicationformApprovedThisYear
  ] = useState([])

  const [loansapplicationformTotal, setLoansapplicationformTotal] = useState([])
  const [
    loansapplicationformApprovedThisYear,
    setLoansapplicationformApprovedThisYear
  ] = useState([])

  const [
    applyforcommunityprojectApplicantsApprovedThisYearWardTable,
    setApplyforcommunityprojectApplicantsApprovedThisYearWardTable
  ] = useState([])
  const [
    grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYearWardTable,
    setGrantapplicationformforyouthwomenandcommunityempowermentApprovedThisYearWardTable
  ] = useState([])

  const [
    secondaryboardingschoolbursaryapplicationformApprovedThisYearWardTable,
    setSecondaryboardingschoolbursaryapplicationformApprovedThisYearWardTable
  ] = useState([])

  const [
    loansapplicationformApprovedThisYearWardTable,
    setLoansapplicationformApprovedThisYearWardTable
  ] = useState([])

  const [
    skillsdevelopmentbursaryapplicationformApprovedThisYearWardTable,
    setSkillsdevelopmentbursaryapplicationformApprovedThisYearWardTable
  ] = useState([])

  const [tableWards, setTableWards] = useState([])
  const [tableConstituencies, setTableConstituencies] = useState([])
  const [tableCouncils, setTableCouncils] = useState([])
  const [tableProvinces, setTableProvinces] = useState([])
  const [TableCouncilsName, setTableCouncilsName] = useState([])
  const [TableConstituenciesName, setTableConstituenciesName] = useState([])
  const [TableWardsName, setTableWardsName] = useState('')
  var [TableApplicantsWardsName, setTableApplicantsWardsName] = useState('')
  var [TableApplicantsWardsNameInitial, setTableApplicantsWardsNameInitial] = useState([])
  const [tableApplicantsWardsNameTableProjects, setTableApplicantsWardsNameTableProjects] = useState([]);
  const [tableApplicantsWardsNameTableGrants, setTableApplicantsWardsNameTableGrants] = useState([]);
  const [tableApplicantsWardsNameTableSecondary, setTableApplicantsWardsNameTableSecondary] = useState([]);
  const [tableApplicantsWardsNameTableSkills, setTableApplicantsWardsNameTableSkills] = useState([]);
  const [tableApplicantsWardsNameTableLoans, setTableApplicantsWardsNameTableLoans] = useState([]);

  
  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)

  var userInstitution = localStorage.getItem('userInstitution')
  userInstitution = JSON.parse(userInstitution)

  var userOrganisationcategory = localStorage.getItem(
    'userOrganisationcategory'
  )
  userOrganisationcategory = JSON.parse(userOrganisationcategory)

  var userOrganisation = localStorage.getItem('userOrganisation')
  userOrganisation = JSON.parse(userOrganisation)

  var loggedIn = localStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  //alert(userOrganisation)
  var login = localStorage.getItem('login')
  login = JSON.parse(login)

  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var userDepartment = localStorage.getItem('userDepartment')
  userDepartment = JSON.parse(userDepartment)

  var userSystemRole = localStorage.getItem('userSystemRole')
  userSystemRole = JSON.parse(userSystemRole)

  var userProvincesID = localStorage.getItem('userProvincesID')
  userProvincesID = JSON.parse(userProvincesID)

  var userCouncilsID = localStorage.getItem('userCouncilsID')
  userCouncilsID = JSON.parse(userCouncilsID)
  //alert(userCouncilsID);

  var userConstituenciesID = localStorage.getItem('userConstituenciesID')
  userConstituenciesID = JSON.parse(userConstituenciesID)

  var userWardsID = localStorage.getItem('userWardsID')
  userWardsID = JSON.parse(userWardsID)

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let userPhone = localStorage.getItem('userPhone')
  userPhone = JSON.parse(userPhone)

  let userNRC = localStorage.getItem('userNRC')
  userNRC = JSON.parse(userNRC)

  let userEmail = localStorage.getItem('userEmail')
  userEmail = JSON.parse(userEmail)

  let userGender = localStorage.getItem('userGender')
  userGender = JSON.parse(userGender)

  if(TableApplicantsWardsName === '' &&
  (userPosition === 'Councilor' ||
  userPosition === 'WDC Chairperson' ||
  userPosition === 'WDC Member') ){
    TableApplicantsWardsName = TableApplicantsWardsNameInitial;
  }
    //alert('We Are Here Inside Now');
  /*****************PROVINCE, COUNCIL, CONSTITUENCY AND WARDS DROPDOWNS STARTS HERE*************************/
    useEffect(() => {
      var nationalViewType = '';
      //alert(userCouncilsID);
       if(userInstitution === 'Province'){
        //userProvincesID = userProvincesID;
        nationalViewType = 'Single';        
       }
      var formDataViewProvinces = new FormData(this)
      formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
      formDataViewProvinces.append('provincesID', userProvincesID)
      formDataViewProvinces.append('nationalViewType', nationalViewType)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataViewProvinces,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setprovinceDropDown(response.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    }, [])

    //alert(userCouncilsID)
    useEffect(() => {
      var provinceViewType = '';
     //alert(userCouncilsID);
      if(council === '' && userInstitution === 'Council'){
        province = userCouncilsID;
        provinceViewType = 'Single';        
      }
      var formDataViewCouncils = new FormData(this)
      formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
      formDataViewCouncils.append('provincesID', province)
      formDataViewCouncils.append('provinceViewType', provinceViewType)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataViewCouncils,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(res => {
        setcouncilDropDown(res.data)
        //alert(res.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    }, [province, userCouncilsID]) //councilDropDown
  
    useEffect(() => {
      var councilViewType = '';
      if(constituency === ''  && (userPosition === 'CDF Committee Chairperson' || 
      userPosition === 'CDF Committee Member' || userPosition === 'MP')){
        council = userConstituenciesID;
        councilViewType = 'Single';        
      }
      //alert(userConstituenciesID)
      var formDataViewConstituencies = new FormData(this)
      formDataViewConstituencies.append('apiType','getViewConstituenciesDropDown')
      formDataViewConstituencies.append('councilsID', council)
      formDataViewConstituencies.append('councilViewType', councilViewType)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataViewConstituencies,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(res => {
        setconstituencyDropDown(res.data)
        //alert(res.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    }, [province, council, userConstituenciesID])
  
    useEffect(() => {
      var constituencyViewType = '';
      
      //if(ward === ''  && userInstitution === 'Council'){
        if(ward === '' && (userPosition === 'WDC Chairperson' || 
        userPosition === 'WDC Member' || userPosition === 'Councilor')){
        constituency = userWardsID;
        constituencyViewType = 'Single';
      }
      //alert(ward)
      var formDataViewWards = new FormData(this)
      formDataViewWards.append('apiType', 'getViewWardsDropDown')
      formDataViewWards.append('constituenciesID', constituency)
      formDataViewWards.append('constituencyViewType', constituencyViewType)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataViewWards,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(res => {
        setwardDropDown(res.data)
        //alert(res.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    }, [province, council, constituency, userWardsID])
/*****************PROVINCE, COUNCIL, CONSTITUENCY AND WARDS DROPDOWNS END HERE*************************/

    useEffect(() => {
    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummaryProjects')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //alert(response.data)
      setTableApplicantsWardsNameTableProjects(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummaryGrants')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //alert(response.data)
      setTableApplicantsWardsNameTableGrants(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummarySecondary')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableSecondary(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummarySkills')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableSkills(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummaryLoans')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableLoans(response.data)
    })
  }, [])
  //}
  

  
  useEffect(() => {
  var formDataWardTableName = new FormData(this)
  formDataWardTableName.append('reportType', 'councilTableName')
  formDataWardTableName.append('getID', userWardsID)
  formDataWardTableName.append('id', 'wardsID')
  formDataWardTableName.append('table', 'wards')
  formDataWardTableName.append('organisation', 'ward')
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
    data: formDataWardTableName,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(function (response) {
    
    setTableApplicantsWardsNameInitial(response.data.organisationName)
  })
}, [])
  //alert(userInstitution)

  useEffect(() => {
    var formDataFirstRow = new FormData(this)
    formDataFirstRow.append('reportType', 'firstRow')
    formDataFirstRow.append('userInstitution', userInstitution)
    formDataFirstRow.append('userPosition', userPosition)
    formDataFirstRow.append('provincesID', userProvincesID)
    formDataFirstRow.append('councilsID', userCouncilsID)
    formDataFirstRow.append('constituenciesID', userConstituenciesID)
    formDataFirstRow.append('wardsID', userWardsID)
    formDataFirstRow.append('firstRowType', 'firstRow')

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataFirstRow,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTotalApplicants(response.data.totalApplicants)
      setTotalApprovedApplicants(response.data.totalApprovedApplicants)
      setTotalPendingApplicants(response.data.totalPendingApplicants)
      setTotalRejectedApplicants(response.data.totalRejectedApplicants)

      setApplyforcommunityprojectApplicantsTotalApproved(
        response.data.applyforcommunityprojectApplicantsTotalApproved
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotalApproved(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotalApproved
      )
      setSecondaryboardingschoolbursaryapplicationformTotalApproved(
        response.data.secondaryboardingschoolbursaryapplicationformTotalApproved
      )
      setSkillsdevelopmentbursaryapplicationformTotalApproved(
        response.data.skillsdevelopmentbursaryapplicationformTotalApproved
      )
      setLoansapplicationformTotalApproved(
        response.data.loansapplicationformTotalApproved
      )
      setApplyforcommunityprojectApplicantsTotalPending(
        response.data.applyforcommunityprojectApplicantsTotalPending
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotalPending(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotalPending
      )
      setSecondaryboardingschoolbursaryapplicationformTotalPending(
        response.data.secondaryboardingschoolbursaryapplicationformTotalPending
      )
      setSkillsdevelopmentbursaryapplicationformTotalPending(
        response.data.skillsdevelopmentbursaryapplicationformTotalPending
      )
      setLoansapplicationformTotalPending(
        response.data.loansapplicationformTotalPending
      )
      setApplyforcommunityprojectApplicantsTotalRejected(
        response.data.applyforcommunityprojectApplicantsTotalRejected
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotalRejected(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotalRejected
      )
      setSecondaryboardingschoolbursaryapplicationformTotalRejected(
        response.data.secondaryboardingschoolbursaryapplicationformTotalRejected
      )
      setSkillsdevelopmentbursaryapplicationformTotalRejected(
        response.data.skillsdevelopmentbursaryapplicationformTotalRejected
      )
      setLoansapplicationformTotalRejected(
        response.data.loansapplicationformTotalRejected
      )

      setApplyforcommunityprojectApplicantsTotalt(
        response.data.applyforcommunityprojectApplicantsTotal
      )
      setApplyforcommunityprojectApplicantsApprovedThisYear(
        response.data.applyforcommunityprojectApplicantsApprovedThisYear
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotal(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotal
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
      )
      setSecondaryboardingschoolbursaryapplicationformTotal(
        response.data.secondaryboardingschoolbursaryapplicationformTotal
      )
      setSecondaryboardingschoolbursaryapplicationformApprovedThisYear(
        response.data
          .secondaryboardingschoolbursaryapplicationformApprovedThisYear
      )
      setSkillsdevelopmentbursaryapplicationformTotal(
        response.data.skillsdevelopmentbursaryapplicationformTotal
      )
      setSkillsdevelopmentbursaryapplicationformApprovedThisYear(
        response.data.skillsdevelopmentbursaryapplicationformApprovedThisYear
      )
      setLoansapplicationformTotal(response.data.loansapplicationformTotal)
      setLoansapplicationformApprovedThisYear(
        response.data.loansapplicationformApprovedThisYear
      )
      //alert(response.data)
    })//.catch((err) => alert(err));
  }, [])

  var handleSubmitSwitch = async e => {
    if (gender != '' || ageGroup != '') {
      democgraphicsSecondary = 'col-xl-12 col-md-12'
      democgraphicsSkills = 'col-xl-12 col-md-12'
    } else {
      democgraphicsSecondary = 'col-xl-6 col-md-12'
      democgraphicsSkills = 'col-xl-4 col-md-12'
    }

    var firstRowDemographicsType = '';
    if (gender != '' || ageGroup != '') {
      firstRowDemographicsType = 'firstRowDemographicsAgeAndSex';
    }
    if (gender === '' && ageGroup != '') {
      firstRowDemographicsType = 'firstRowDemographicsAge';
    }
    if (gender != '' && ageGroup === '') {
      firstRowDemographicsType = 'firstRowDemographicsSex';
    }
  //alert("Start Date"+dateFrom+ " End Date"+dateTo)
    var formDataFirstRowDemographics = new FormData(this)
    formDataFirstRowDemographics.append('reportType', 'firstRow')
    formDataFirstRowDemographics.append('userInstitution', userInstitution)
    formDataFirstRowDemographics.append('userPosition', userPosition)
    formDataFirstRowDemographics.append('provincesID', userProvincesID)
    formDataFirstRowDemographics.append('councilsID', userCouncilsID)
    formDataFirstRowDemographics.append(
      'constituenciesID',
      userConstituenciesID
    )
    formDataFirstRowDemographics.append('wardsID', userWardsID)
    formDataFirstRowDemographics.append('gender', gender)
    formDataFirstRowDemographics.append('province', province)
    formDataFirstRowDemographics.append('council', council)
    formDataFirstRowDemographics.append('constituency', constituency)
    formDataFirstRowDemographics.append('ward', ward)
    formDataFirstRowDemographics.append('dateFrom', dateFrom)
    formDataFirstRowDemographics.append('dateTo', dateTo)
    formDataFirstRowDemographics.append('ageGroup', ageGroup)
    formDataFirstRowDemographics.append('firstRowType', firstRowDemographicsType)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataFirstRowDemographics,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTotalApplicants(response.data.totalApplicants)
      setTotalApprovedApplicants(response.data.totalApprovedApplicants)
      setTotalPendingApplicants(response.data.totalPendingApplicants)
      setTotalRejectedApplicants(response.data.totalRejectedApplicants)

      setApplyforcommunityprojectApplicantsTotalApproved(
        response.data.applyforcommunityprojectApplicantsTotalApproved
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotalApproved(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotalApproved
      )
      setSecondaryboardingschoolbursaryapplicationformTotalApproved(
        response.data.secondaryboardingschoolbursaryapplicationformTotalApproved
      )
      setSkillsdevelopmentbursaryapplicationformTotalApproved(
        response.data.skillsdevelopmentbursaryapplicationformTotalApproved
      )
      setLoansapplicationformTotalApproved(
        response.data.loansapplicationformTotalApproved
      )
      setApplyforcommunityprojectApplicantsTotalPending(
        response.data.applyforcommunityprojectApplicantsTotalPending
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotalPending(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotalPending
      )
      setSecondaryboardingschoolbursaryapplicationformTotalPending(
        response.data.secondaryboardingschoolbursaryapplicationformTotalPending
      )
      setSkillsdevelopmentbursaryapplicationformTotalPending(
        response.data.skillsdevelopmentbursaryapplicationformTotalPending
      )
      setLoansapplicationformTotalPending(
        response.data.loansapplicationformTotalPending
      )
      setApplyforcommunityprojectApplicantsTotalRejected(
        response.data.applyforcommunityprojectApplicantsTotalRejected
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotalRejected(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotalRejected
      )
      setSecondaryboardingschoolbursaryapplicationformTotalRejected(
        response.data.secondaryboardingschoolbursaryapplicationformTotalRejected
      )
      setSkillsdevelopmentbursaryapplicationformTotalRejected(
        response.data.skillsdevelopmentbursaryapplicationformTotalRejected
      )
      setLoansapplicationformTotalRejected(
        response.data.loansapplicationformTotalRejected
      )

      setApplyforcommunityprojectApplicantsTotalt(
        response.data.applyforcommunityprojectApplicantsTotal
      )
      setApplyforcommunityprojectApplicantsApprovedThisYear(
        response.data.applyforcommunityprojectApplicantsApprovedThisYear
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentTotal(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentTotal
      )
      setGrantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
      )
      setSecondaryboardingschoolbursaryapplicationformTotal(
        response.data.secondaryboardingschoolbursaryapplicationformTotal
      )
      setSecondaryboardingschoolbursaryapplicationformApprovedThisYear(
        response.data
          .secondaryboardingschoolbursaryapplicationformApprovedThisYear
      )
      setSkillsdevelopmentbursaryapplicationformTotal(
        response.data.skillsdevelopmentbursaryapplicationformTotal
      )
      setSkillsdevelopmentbursaryapplicationformApprovedThisYear(
        response.data.skillsdevelopmentbursaryapplicationformApprovedThisYear
      )
      setLoansapplicationformTotal(response.data.loansapplicationformTotal)
      setLoansapplicationformApprovedThisYear(
        response.data.loansapplicationformApprovedThisYear
      )
      //alert(response.data)
      if (gender != '' || ageGroup != '') {
        setDemocgraphicsSecondary('col-xl-12 col-md-12')
        setDemocgraphicsSkills('col-xl-12 col-md-12')
      } else {
        setDemocgraphicsSecondary('')
        setDemocgraphicsSkills('')
      }
      setprovince('')
      setcouncil('')
      setconstituency('')
      setward('')
    })
  }

  useEffect(() => {
    var formDataNationalTable = new FormData(this)
    formDataNationalTable.append('reportType', 'nationalTable')
    //formDataNationalTable.append('provincesID', userProvincesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataNationalTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableProvinces(response.data)
      //alert(response.data)
    })
  }, [])

  useEffect(() => {
    var formDataProvinceTable = new FormData(this)
    formDataProvinceTable.append('reportType', 'provinceTable')
    formDataProvinceTable.append('provincesID', userProvincesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataProvinceTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableCouncils(response.data)
      //alert(response.data.TotalSalesReport)
    })
  }, [])

  useEffect(() => {
    var formDataCouncilTable = new FormData(this)
    formDataCouncilTable.append('reportType', 'councilTable')
    formDataCouncilTable.append('councilsID', userCouncilsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataCouncilTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableConstituencies(response.data)
      //alert(response.data.TotalSalesReport)
    })
  }, [])

  useEffect(() => {
    var formDataConstituencyTable = new FormData(this)
    formDataConstituencyTable.append('reportType', 'constituencyTable')
    formDataConstituencyTable.append('constituenciesID', userConstituenciesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataConstituencyTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableWards(response.data)
      //alert(response.data.TotalSalesReport)
    })
  }, [])

  useEffect(() => {
    var formDataFirstRow = new FormData(this)
    formDataFirstRow.append('reportType', 'wardTable')
    formDataFirstRow.append('wardsID', userWardsID)
    //alert('userWardsID' + userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataFirstRow,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setApplyforcommunityprojectApplicantsApprovedThisYearWardTable(
        response.data.applyforcommunityprojectApplicantsApprovedThisYear
      )

      setGrantapplicationformforyouthwomenandcommunityempowermentApprovedThisYearWardTable(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
      )

      setSecondaryboardingschoolbursaryapplicationformApprovedThisYearWardTable(
        response.data
          .secondaryboardingschoolbursaryapplicationformApprovedThisYear
      )

      setSkillsdevelopmentbursaryapplicationformApprovedThisYearWardTable(
        response.data.skillsdevelopmentbursaryapplicationformApprovedThisYear
      )
      setLoansapplicationformApprovedThisYearWardTable(
        response.data.loansapplicationformApprovedThisYear
      )
      //alert(response.data.TotalSalesReport)
      //alert(response.data.rodeen)
    })
  }, [])

  const displayProvincesDiv = provincesID => {
    if (userProvincesID === '' || userProvincesID != provincesID) {
      userProvincesID = provincesID
    }
    //alert(userCouncilsID)
    var formDataProvinceTable = new FormData(this)
    formDataProvinceTable.append('reportType', 'provinceTable')
    formDataProvinceTable.append('provincesID', userProvincesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataProvinceTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableCouncils(response.data)
      //alert(response.data.TotalSalesReport)
    })

    var formDataCouncilTableName = new FormData(this)
    formDataCouncilTableName.append('reportType', 'councilTableName')
    formDataCouncilTableName.append('getID', userProvincesID)
    formDataCouncilTableName.append('id', 'provincesID')
    formDataCouncilTableName.append('table', 'provinces')
    formDataCouncilTableName.append('organisation', 'province')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataCouncilTableName,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableCouncilsName(response.data.organisationName)
    })
    //alert(TableConstituenciesName)
  }

  const displayCouncilsDiv = councilsID => {
    if (userCouncilsID === '' || userCouncilsID != councilsID) {
      userCouncilsID = councilsID
    }
    //alert(userCouncilsID)
    var formDataCouncilTable = new FormData(this)
    formDataCouncilTable.append('reportType', 'councilTable')
    formDataCouncilTable.append('councilsID', userCouncilsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataCouncilTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableConstituencies(response.data)
      //alert(response.data.TotalSalesReport)
    })

    var formDataCouncilTableName = new FormData(this)
    formDataCouncilTableName.append('reportType', 'councilTableName')
    formDataCouncilTableName.append('getID', userCouncilsID)
    formDataCouncilTableName.append('id', 'councilsID')
    formDataCouncilTableName.append('table', 'councils')
    formDataCouncilTableName.append('organisation', 'council')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataCouncilTableName,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableConstituenciesName(response.data.organisationName)
    })
    //alert(TableConstituenciesName)
  }
  const displayConstituenciesDiv = constituenciesID => {
    if (
      userConstituenciesID === '' ||
      userConstituenciesID != constituenciesID
    ) {
      userConstituenciesID = constituenciesID
    }
    //alert(constituenciesID)
    var formDataConstituencyTable = new FormData(this)
    formDataConstituencyTable.append('reportType', 'constituencyTable')
    formDataConstituencyTable.append('constituenciesID', userConstituenciesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataConstituencyTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setTableWards(response.data)
      //setTableConstituenciesName(response.data)
      //alert(response.data.TotalSalesReport)
    })

    var formDataConstituencyTableName = new FormData(this)
    formDataConstituencyTableName.append('reportType', 'councilTableName')
    formDataConstituencyTableName.append('getID', userConstituenciesID)
    formDataConstituencyTableName.append('id', 'constituenciesID')
    formDataConstituencyTableName.append('table', 'constituencies')
    formDataConstituencyTableName.append('organisation', 'constituency')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataConstituencyTableName,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableWardsName(response.data.organisationName)
    })
  }
  const displayWardsDiv = wardsID => {
    if (userWardsID === '' || userWardsID != wardsID) {
      userWardsID = wardsID
    }
    var formDataFirstRow = new FormData(this)
    formDataFirstRow.append('reportType', 'wardTable')
    formDataFirstRow.append('wardsID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataFirstRow,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setApplyforcommunityprojectApplicantsApprovedThisYearWardTable(
        response.data.applyforcommunityprojectApplicantsApprovedThisYear
      )

      setGrantapplicationformforyouthwomenandcommunityempowermentApprovedThisYearWardTable(
        response.data
          .grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
      )

      setSecondaryboardingschoolbursaryapplicationformApprovedThisYearWardTable(
        response.data
          .secondaryboardingschoolbursaryapplicationformApprovedThisYear
      )

      setSkillsdevelopmentbursaryapplicationformApprovedThisYearWardTable(
        response.data.skillsdevelopmentbursaryapplicationformApprovedThisYear
      )
      setLoansapplicationformApprovedThisYearWardTable(
        response.data.loansapplicationformApprovedThisYear
      )
      //alert(response.data.TotalSalesReport)
    })

    var formDataWardTableName = new FormData(this)
    formDataWardTableName.append('reportType', 'councilTableName')
    formDataWardTableName.append('getID', userWardsID)
    formDataWardTableName.append('id', 'wardsID')
    formDataWardTableName.append('table', 'wards')
    formDataWardTableName.append('organisation', 'ward')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTableName,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsName(response.data.organisationName)
    })

    //setTableApplicantsWardsNameTable
    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummaryProjects')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableProjects(response.data)
    })

    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummaryGrants')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableGrants(response.data)
    })

    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummarySecondary')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableSecondary(response.data)
    })

    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummarySkills')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableSkills(response.data)
    })

    var formDataWardTable = new FormData(this)
    formDataWardTable.append('reportType', 'wardTableSummaryLoans')
    formDataWardTable.append('getID', userWardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      data: formDataWardTable,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      setTableApplicantsWardsNameTableLoans(response.data)
    })
  }

  const handleSubmitGrantsForYouthWomenAndCommunityEmpowerment = async e => {
    if (userInstitution === 'State House' || userInstitution === 'Ministry') {
      navigate('/ProvincesSummaryGrantsForYouthWomenAndCommunityEmpowerment')
    } else if (userInstitution === 'Province') {
      localStorage.setItem(
        'CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment',
        JSON.stringify(userProvincesID)
      )
      navigate('/CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment')
    } else if (
      userPosition === 'Mayor' ||
      userPosition === 'Town Clerk' ||
      userPosition === 'Council Chairperson' ||
      userPosition === 'Council Secretary' ||
      userPosition === 'Director' ||
      userPosition === 'DPO' ||
      userPosition === 'Head Of Section'
    ) {
      localStorage.setItem(
        'ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment',
        JSON.stringify(userCouncilsID)
      )
      navigate(
        '/ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment'
      )
    } else if (
      userPosition === 'MP' ||
      userPosition === 'CDF Committee Chairperson' ||
      userPosition === 'CDF Committee Member'
    ) {
      localStorage.setItem(
        'WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment',
        JSON.stringify(userConstituenciesID)
      )
      navigate('/WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment')
    } else if (
      userPosition === 'Councilor' ||
      userPosition === 'WDC Chairperson' ||
      userPosition === 'WDC Member'
    ) {
      localStorage.setItem(
        'ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport',
        JSON.stringify(userWardsID)
      )
      navigate(
        '/ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport'
      )
    }
  }
  const handleSubmitCommunityProjects = async e => {
    if (userInstitution === 'State House' || userInstitution === 'Ministry') {
      navigate('/ProvincesSummaryCommunityProjects')
    } else if (userInstitution === 'Province') {
      localStorage.setItem(
        'CouncilsSummaryCommunityProjects',
        JSON.stringify(userProvincesID)
      )
      navigate('/CouncilsSummaryCommunityProjects')
    } else if (
      userPosition === 'Mayor' ||
      userPosition === 'Town Clerk' ||
      userPosition === 'Council Chairperson' ||
      userPosition === 'Council Secretary' ||
      userPosition === 'Director' ||
      userPosition === 'DPO' ||
      userPosition === 'Head Of Section'
    ) {
      localStorage.setItem(
        'ConstituenciesSummaryCommunityProjects',
        JSON.stringify(userCouncilsID)
      )
      navigate('/ConstituenciesSummaryCommunityProjects')
    } else if (
      userPosition === 'MP' ||
      userPosition === 'CDF Committee Chairperson' ||
      userPosition === 'CDF Committee Member'
    ) {
      localStorage.setItem(
        'WardsSummaryCommunityProjects',
        JSON.stringify(userConstituenciesID)
      )
      navigate('/WardsSummaryCommunityProjects')
    } else if (
      userPosition === 'Councilor' ||
      userPosition === 'WDC Chairperson' ||
      userPosition === 'WDC Member'
    ) {
      localStorage.setItem(
        'ApplicantsDetailedCommunityProjectsReport',
        JSON.stringify(userWardsID)
      )
      navigate('/ApplicantsDetailedCommunityProjectsReport')
    }
  }
  const handleSubmitSkillsDevelopmentBursaries = async e => {
    if (userInstitution === 'State House' || userInstitution === 'Ministry') {
      navigate('/ProvincesSummarySkillsDevelopmentBursaries')
    } else if (userInstitution === 'Province') {
      localStorage.setItem(
        'CouncilsSummarySkillsDevelopmentBursaries',
        JSON.stringify(userProvincesID)
      )
      navigate('/CouncilsSummarySkillsDevelopmentBursaries')
    } else if (
      userPosition === 'Mayor' ||
      userPosition === 'Town Clerk' ||
      userPosition === 'Council Chairperson' ||
      userPosition === 'Council Secretary' ||
      userPosition === 'Director' ||
      userPosition === 'DPO' ||
      userPosition === 'Head Of Section'
    ) {
      localStorage.setItem(
        'ConstituenciesSummarySkillsDevelopmentBursaries',
        JSON.stringify(userCouncilsID)
      )
      navigate('/ConstituenciesSummarySkillsDevelopmentBursaries')
    } else if (
      userPosition === 'MP' ||
      userPosition === 'CDF Committee Chairperson' ||
      userPosition === 'CDF Committee Member'
    ) {
      localStorage.setItem(
        'WardsSummarySkillsDevelopmentBursaries',
        JSON.stringify(userConstituenciesID)
      )
      navigate('/WardsSummarySkillsDevelopmentBursaries')
    } else if (
      userPosition === 'Councilor' ||
      userPosition === 'WDC Chairperson' ||
      userPosition === 'WDC Member'
    ) {
      localStorage.setItem(
        'ApplicantsDetailedSkillsDevelopmentBursariesReport',
        JSON.stringify(userWardsID)
      )
      navigate('/ApplicantsDetailedSkillsDevelopmentBursariesReport')
    }
  }
  const handleSubmitSecondaryBoardingSchoolBursaries = async e => {
    if (userInstitution === 'State House' || userInstitution === 'Ministry') {
      navigate('/ProvincesSummarySecondaryBoardingSchoolsBursaries')
    } else if (userInstitution === 'Province') {
      localStorage.setItem(
        'CouncilsSummarySecondaryBoardingSchoolsBursaries',
        JSON.stringify(userProvincesID)
      )
      navigate('/CouncilsSummarySecondaryBoardingSchoolsBursaries')
    } else if (
      userPosition === 'Mayor' ||
      userPosition === 'Town Clerk' ||
      userPosition === 'Council Chairperson' ||
      userPosition === 'Council Secretary' ||
      userPosition === 'Director' ||
      userPosition === 'DPO' ||
      userPosition === 'Head Of Section'
    ) {
      localStorage.setItem(
        'ConstituenciesSummarySecondaryBoardingSchoolsBursaries',
        JSON.stringify(userCouncilsID)
      )
      navigate('/ConstituenciesSummarySecondaryBoardingSchoolsBursaries')
    } else if (
      userPosition === 'MP' ||
      userPosition === 'CDF Committee Chairperson' ||
      userPosition === 'CDF Committee Member'
    ) {
      localStorage.setItem(
        'WardsSummarySecondaryBoardingSchoolsBursaries',
        JSON.stringify(userConstituenciesID)
      )
      navigate('/WardsSummarySecondaryBoardingSchoolsBursaries')
    } else if (
      userPosition === 'Councilor' ||
      userPosition === 'WDC Chairperson' ||
      userPosition === 'WDC Member'
    ) {
      localStorage.setItem(
        'ApplicantsDetailedSecondaryBoardingSchoolsBursariesReport',
        JSON.stringify(userWardsID)
      )
      navigate('/ApplicantsDetailedSecondaryBoardingSchoolsBursariesReport')
    }
  }

  const handleSubmitLoans = async e => {
    if (userInstitution === 'State House' || userInstitution === 'Ministry') {
      navigate('/ProvincesSummaryLoans')
    } else if (userInstitution === 'Province') {
      localStorage.setItem(
        'CouncilsSummaryLoans',
        JSON.stringify(userProvincesID)
      )
      navigate('/CouncilsSummaryLoans')
    } else if (
      userPosition === 'Mayor' ||
      userPosition === 'Town Clerk' ||
      userPosition === 'Council Chairperson' ||
      userPosition === 'Council Secretary' ||
      userPosition === 'Director' ||
      userPosition === 'DPO' ||
      userPosition === 'Head Of Section'
    ) {
      localStorage.setItem(
        'ConstituenciesSummaryLoans',
        JSON.stringify(userCouncilsID)
      )
      navigate('/ConstituenciesSummaryLoans')
    } else if (
      userPosition === 'MP' ||
      userPosition === 'CDF Committee Chairperson' ||
      userPosition === 'CDF Committee Member'
    ) {
      localStorage.setItem(
        'WardsSummaryLoans',
        JSON.stringify(userConstituenciesID)
      )
      navigate('/WardsSummaryLoans')
    } else if (
      userPosition === 'Councilor' ||
      userPosition === 'WDC Chairperson' ||
      userPosition === 'WDC Member'
    ) {
      localStorage.setItem(
        'ApplicantsDetailedLoansReport',
        JSON.stringify(userWardsID)
      )
      navigate('/ApplicantsDetailedLoansReport')
    }
  }

  //handleClickViewLoans(val.id)
  const handleClickViewProjects = id => {
    localStorage.setItem('ApplicantCommunityProjectsView', JSON.stringify(id))
    navigate('/ApplicantCommunityProjectsView')
  }
  const handleClickViewGrants = id => {
    localStorage.setItem(
      'ApplicantGrantsForYouthWomenAndCommunityEmpowermentView',
      JSON.stringify(id)
    )
    navigate('/ApplicantGrantsForYouthWomenAndCommunityEmpowermentView')
  }
  const handleClickViewSecondary = id => {
    localStorage.setItem(
      'ApplicantSecondaryBoardingSchoolsBursariesView',
      JSON.stringify(id)
    )
    navigate('/ApplicantSecondaryBoardingSchoolsBursariesView')
  }
  const handleClickViewSkills = id => {
    localStorage.setItem(
      'ApplicantSkillsDevelopmentBursariesView',
      JSON.stringify(id)
    )
    navigate('/ApplicantSkillsDevelopmentBursariesView')
  }
  const handleClickViewLoans = id => {
    localStorage.setItem(
      'ApplicantLoansView',
      JSON.stringify(id)
    )
    navigate('/ApplicantLoansView')
  }
  useEffect(() => {
if(dateFrom === ''){
  setDateTo('');
  //alert("rodeen")
}
  }, [dateFrom])

  var councilUsersWidth = 'col-lg-4 col-md-12 mb-1 text-left font-small grey-text';
  if (userPosition == "Mayor" || userPosition == "Town Clerk" || userPosition == "Council Chairperson" || 
  userPosition === "Council Secretary"
	|| userPosition === "Director" || userPosition === "DPO" || userPosition === "Head Of Section" )
{
  councilUsersWidth = 'col-lg-6 col-md-12 mb-1 text-left font-small grey-text';
}
if (userInstitution === 'Council' && (userPosition ==='CDF Committee Chairperson' || 
userPosition ==='CDF Committee Member' || userPosition ==='MP'  ))
{
  councilUsersWidth = 'col-lg-12 col-md-12 mb-1 text-left font-small grey-text';
}

  return (
    <>
      <>
        <Header />
      </>
      <div class=''>
        <body class='col-12 bg-white'>
          {/*<body class='fixed-sn white-skin col-12'>*/}
          {/* Main layout */}
          <main>
            <div class='container-fluid '>
              {/*<div class='container-fluid'>*/}
              {/*Section Starts Here*/}
              <section class='mt-5'>
                {/* Grid row */}
                <div class='row mt-5'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-1 mt-5'>
                    {/* Card */}
                    <div class='card'>
                      <div class='row pb-4 pl-4 pr-4'>

                    {/* <!--Grid column -->*/}
                    <div class={councilUsersWidth}
                    hidden = {userInstitution != 'State House' && 
                    userInstitution != 'Ministry' && userInstitution != 'Province'}
                    >
                      <label for='registerFullName'>
                      <span class='fa fa-search green-text'></span>Provinces
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setprovince(e.target.value)}
                        value={province}

                      >
                        <option value=''>Choose ....</option>
                        {provinceDropDown.map(valProvince => {
                          return (
                            <option value={valProvince.provincesID}>
                              {valProvince.province}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div
                      class={councilUsersWidth}
                       
                      hidden = {userInstitution != 'State House' && userInstitution != 'Ministry' && 
                      userInstitution != 'Province' && 
                      (userInstitution === 'Council' && (userPosition ==='CDF Committee Member' || 
                      userPosition ==='CDF Committee Chairperson' || userPosition ==='MP' ||
                      userPosition ==='WDC Chairperson' || userPosition ==='WDC Member' || 
                      userPosition ==='Councilor'  ))
                    }
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                      <span class='fa fa-search green-text'></span>District
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setcouncil(e.target.value)}
                        value={council}
                        disabled={province === '' && (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province' )}
                                               
                      >
                        <option value=''>Choose ....</option>
                        {councilDropDown.map(valCouncil => {
                          return (
                            <option value={valCouncil.councilsID}>
                              {valCouncil.council}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--Grid column -->*/}

                    {/*<!--\Grid column-->*/}
                    <div
                      class={councilUsersWidth}
                      hidden = {userInstitution != 'State House' && userInstitution != 'Ministry' && 
                      userInstitution != 'Province' && 
                      (userInstitution === 'Council' && (userPosition ==='WDC Chairperson' || 
                      userPosition ==='WDC Member' || userPosition ==='Councilor'  ))
                    }
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                      <span class='fa fa-search green-text'></span>Constituency
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setconstituency(e.target.value)}
                        value={constituency}
                        
                        disabled={(province === '' && council === '') && (userInstitution === 'State House' || 
                        userInstitution === 'Ministry' || userInstitution === 'Province') || 
                        ((province === '' && council != '') && (userInstitution === 'State House' || 
                        userInstitution === 'Ministry' || userInstitution === 'Province')) || 
                        ((province != '' && council === '') && (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province')) || 
                        (userInstitution === 'Council' && ((userPosition ==='WDC Chairperson' || 
                        userPosition ==='WDC Member' || userPosition ==='Councilor'))) ||
                        ((userInstitution === 'Council' && council === '') && 
                        ((userPosition !='CDF Committee Chairperson' &&
                        userPosition !='CDF Committee Member' && userPosition !='MP')) )
                    } 
                   
                      >
                        <option value=''>Choose ....</option>
                        {constituencyDropDown.map(valConstituency => {
                          return (
                            <option value={valConstituency.constituenciesID}>
                              {valConstituency.constituency}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--Grid column -->*/}

                    {/* <!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left font-small grey-text'
                    >
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                      <span class='fa fa-search green-text'></span> Ward
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setward(e.target.value)}
                        value={ward}
                        /*disabled={(province === '' || council === '' || constituency === '') &&
                        (userPosition !='WDC Chairperson' && userPosition !='WDC Member' && 
                        userPosition !='Councilor'  )*/

                        disabled={(province === '' && council === '' && constituency === '') && 
                        (userInstitution === 'State House' || 
                        userInstitution === 'Ministry' || userInstitution === 'Province') || 
                        ((province === '' && council === '' && constituency != '') && 
                        (userInstitution === 'State House' || 
                        userInstitution === 'Ministry' || userInstitution === 'Province')) || 
                        ((province === '' && council != ''  && constituency === '') && 
                        (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province')) ||  
                        ((province != '' && council === ''  && constituency === '') && 
                        (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province')) || 
                        ((province != '' && council != ''  && constituency === '') && 
                        (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province')) ||  
                        ((province === '' && council != ''  && constituency != '') && 
                        (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province')) || 
                        ((province != '' && council === ''  && constituency != '') && 
                        (userInstitution === 'State House' ||
                        userInstitution === 'Ministry' || userInstitution === 'Province')) ||
                        ((userInstitution === 'Council' && (constituency === '') ) &&
                        ((userPosition !='WDC Chairperson' &&
                        userPosition !='WDC Member' && userPosition !='Councilor')))
                      }
                      >
                        <option value=''>Choose ....</option>
                        {wardDropDown.map(valWard => {
                          return (
                            <option value={valWard.wardsID}>
                              {valWard.ward}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--Grid column -->*/}

                      <div class='col-md-2 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-0'
                              aria-hidden='true'
                            ></i>
                            Start Date <i class='red-text'></i>{' '}
                          </label>
                          <input
                          type='date'
                            class='form-control'
                            onChange={e => setDateFrom(e.target.value)}
                            value={dateFrom}
                          />
                        </div>

                        <div class='col-md-2 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-0'
                              aria-hidden='true'
                            ></i>
                            End Date <i class='red-text'></i>{' '}
                          </label>
                          <input
                          disabled={dateFrom === ''}
                          type='date'
                            class='form-control'
                            onChange={e => setDateTo(e.target.value)}
                            value={dateTo}
                          />
                        </div>

                        <div class='col-md-2 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-0'
                              aria-hidden='true'
                            ></i>
                            Gender <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            onChange={e => setGender(e.target.value)}
                            value={gender}
                          >
                            <option value=''>...Choose .........</option>
                            <option value='Male'>Males</option>
                            <option value='Female'>Females</option>
                          </select>{' '}
                        </div>

                        <div class='col-md-2 col-12 font-small grey-text'>
                          <label>
                            <i
                              class='fa fa-search green-text floating md-5 mr-0'
                              aria-hidden='true'
                            ></i>
                            Age Group <i class='red-text'></i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            autoComplete='off'
                            onChange={e => setAgeGroup(e.target.value)}
                            value={ageGroup}
                          >
                            <option value=''>...Choose .........</option>
                            <option>The Youth</option>
                            <option>Older People</option>
                          </select>{' '}
                        </div>
                        
                        <div class='col-md-12 col-12'>
                          <button
                            class='form-control bg-primary white-text'
                            onClick={handleSubmitSwitch}
                            //disabled={gender === '' && ageGroup === ''}
                          ><i
                          class='fa fa-search floating md-5 mr-0'
                          aria-hidden='true'
                        ></i>Search</button>
                        </div>

                        {/*<div class='col-md-6 col-12'>
                          <label>From Date</label>
                          <input
                            disabled
                            type='date'
                            class='form-control'
                            value={fromDate}
                            onChange={e => setFromDate(e.target.value)}
                          />
                        </div>
                        <div class='col-md-6 col-12'>
                          <label>To Date</label>
                          <input
                            disabled
                            type='date'
                            class='form-control'
                            value={toDate}
                            onChange={e => setToDate(e.target.value)}
                          />
                          </div> */}
                      </div>
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/*Row Ends*/}
              </section>
              {/*Section Ends Here*/}
              {/* Section: Button icon */}
              <section
                hidden={
                  userInstitution != 'Education' ||
                  userOrganisationcategory != 'Education Skills'
                }
              >
                {/* Grid row */}
                <div class='row mt-5'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card mt-5'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a class='btn-floating btn success-color ml-4'>
                            <i class='fa fa-pen' aria-hidden='true'></i>
                          </a>
                        </div>
                      </div>
                      {/* Card Data */}
                      <div class='row mr-4 text-right'>
                        <div class='col-md-3 font-small grey-text'>
                          Total Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Accepted Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalApprovedApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Active Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalPendingApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Unreported Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalRejectedApplicants}
                          </span>
                        </div>
                      </div>

                      {/*Card content */}
                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase'>
                            Overall Number Of Learners
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a
                            type='button'
                            class='btn-floating btn secondary-color ml-4'
                            onClick={
                              handleSubmitGrantsForYouthWomenAndCommunityEmpowerment
                            }
                          >
                            <i
                              class='fa fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-7 col-7 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Learners
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotal
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Accepted Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Active Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unreported Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/*Card content */}
                      <div class='row my-1 text-left'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grade 8
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row End*/}
              </section>
              {/*Section: Button icon */}
              {/* Section: Button icon */}
              <section
                hidden={
                  userInstitution != 'Education' ||
                  userOrganisationcategory != 'Education Boarding'
                }
              >
                {/* Grid row */}
                <div class='row mt-5'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card mt-5'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a class='btn-floating btn success-color ml-4'>
                            <i class='fa fa-pen' aria-hidden='true'></i>
                          </a>
                        </div>
                      </div>
                      {/* Card Data */}
                      <div class='row mr-4 text-right'>
                        <div class='col-md-3 font-small grey-text'>
                          Total Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Accepted Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalApprovedApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Active Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalPendingApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Unreported Learners
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalRejectedApplicants}
                          </span>
                        </div>
                      </div>

                      {/*Card content */}
                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase'>
                            Overall Number Of Learners
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-6 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a
                            type='button'
                            class='btn-floating btn secondary-color ml-4'
                            onClick={
                              handleSubmitGrantsForYouthWomenAndCommunityEmpowerment
                            }
                          >
                            <i
                              class='fa fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-7 col-7 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Learners
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotal
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Accepted Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Active Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unreported Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/*Card content */}
                      <div class='row my-1 text-left'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grade 8
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}

                  {/* Grid column */}
                  <div class='col-xl-6 col-md-12 mb-2'>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          <a
                            type='button'
                            class='btn-floating btn red accent-2 ml-4'
                            onClick={
                              handleSubmitSecondaryBoardingSchoolBursaries
                            }
                          >
                            <i
                              class='fa fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-7 col-7 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Learners
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotal
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Accepted Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Active Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unreported Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/* Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grade 9
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row End*/}

                {/* Grid row Start*/}
                <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-4 col-md-12 mb-2'>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a
                            type='button'
                            class='btn-floating btn primary-color ml-4'
                            onClick={handleSubmitLoans}
                          >
                            <i
                              class='fa fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Learners
                            <span class='ml-4 mt-1  font-weight-bold black-text'>
                              {loansapplicationformTotal}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Accepted Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {loansapplicationformTotalApproved}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Active Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {loansapplicationformTotalPending}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unreported Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {loansapplicationformTotalRejected}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/*Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grade 10
                          </p>
                        </div>
                      </div>

                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}

                  {/* Grid column */}
                  <div class='col-xl-4 col-md-12 mb-2'>
                    {/* Card */}
                    <div class='card '>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          <a
                            type='button'
                            class='btn-floating btn light-blue lighten-1 ml-4'
                            onClick={handleSubmitSkillsDevelopmentBursaries}
                          >
                            <i
                              class='fas fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Learners
                            <span class='ml-4 font-weight-bold black-text'>
                              {skillsdevelopmentbursaryapplicationformTotal}{' '}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Accepted Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                skillsdevelopmentbursaryapplicationformTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Active Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                skillsdevelopmentbursaryapplicationformTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unreported Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                skillsdevelopmentbursaryapplicationformTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/* Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grade 11
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}

                  {/* Grid column */}
                  <div class='col-xl-4 col-md-12 mb-2'>
                    {/* Card */}
                    <div class='card '>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          <a
                            type='button'
                            class='btn-floating btn warning-color ml-4'
                            onClick={handleSubmitCommunityProjects}
                          >
                            <i
                              class='fa fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Learners
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotal}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Accepted Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotalApproved}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Active Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotalPending}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unreported Learners
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotalRejected}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/* Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grade 12
                          </p>
                        </div>
                      </div>

                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/*Section: Button icon */}
              {/* Section: Button icon */}
              <section hidden={userInstitution === 'Education'}>
                {/* Grid row */}
                <div class='row mt-1'>
                  {/* Grid column */}
                  <div class='col-xl-12 col-md-12 mb-4'>
                    {/* Card */}
                    <div class='card mt-1'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a class='btn-floating btn success-color ml-4'>
                            <i class='fa fa-pen' aria-hidden='true'></i>
                          </a>
                        </div>
                      </div>
                      {/* Card Data */}
                      <div class='row mr-4 text-right'>
                        <div class='col-md-3 font-small grey-text'>
                          Total Applications
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Approved Applications
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalApprovedApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Pending
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalPendingApplicants}
                          </span>
                        </div>
                        <div class='col-md-3 font-small grey-text'>
                          Unsuccessful Applications
                          <span class='ml-4 mt-1 font-weight-bold black-text'>
                            {totalRejectedApplicants}
                          </span>
                        </div>
                      </div>

                      {/*Card content */}
                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase'>
                            Overall Number Of Applications
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-6 col-md-12 mb-4' hidden={hidden === 'Yes'}>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a
                            type='button'
                            class='btn-floating btn secondary-color ml-4'
                            onClick={
                              handleSubmitGrantsForYouthWomenAndCommunityEmpowerment
                            }
                          >
                            <i class='' aria-hidden='true'>
                              ZMW
                            </i>
                          </a>
                        </div>

                        <div class='col-md-7 col-7 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Applications
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotal
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Approved Applications
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Pending
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unsuccessful Applications
                            <span class='ml-4 font-weight-bold black-text'>
                              {
                                grantapplicationformforyouthwomenandcommunityempowermentTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/*Card content */}
                      <div class='row my-1 text-left'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Grants For Youth, Women and Community Empowerment
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}

                  {/* Grid column 
                  <div class='col-xl-6 col-md-12 mb-2'>
                    */}
                  <div class={democgraphicsSecondary}>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-5 col-5 text-left pl-4'>
                          <a
                            type='button'
                            class='btn-floating btn red accent-2 ml-4'
                            onClick={
                              handleSubmitSecondaryBoardingSchoolBursaries
                            }
                          >
                            <i
                              class='fa fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-7 col-7 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Applications
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotal
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Approved Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Pending
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unsuccessful Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                secondaryboardingschoolbursaryapplicationformTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/* Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Secondary Boarding School Busaries
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row End*/}

                {/* Grid row Start*/}
                <div class='row'>
                  {/* Grid column */}
                  <div class='col-xl-4 col-md-12 mb-2' hidden={hidden === 'Yes'}>
                    {/* Card */}
                    <div class='card'>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          {/*<a type="button" class="btn-floating btn-lg primary-color ml-4"><i class="fas fa-dollar-sign"
                      aria-hidden="true"></i></a>*/}

                          <a
                            type='button'
                            class='btn-floating btn primary-color ml-4'
                            onClick={handleSubmitLoans}
                          >
                            <i class='' aria-hidden='true'>
                              ZMW
                            </i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Applications
                            <span class='ml-4 mt-1  font-weight-bold black-text'>
                              {loansapplicationformTotal}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Approved Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {loansapplicationformTotalApproved}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Pending
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {loansapplicationformTotalPending}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unsuccessful Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {loansapplicationformTotalRejected}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/*Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Loans
                          </p>
                        </div>
                      </div>

                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}

                  {/* Grid column 
                  <div class='col-xl-4 col-md-12 mb-2'>
                  */}
                  <div class={democgraphicsSkills}>
                    {/* Card */}
                    <div class='card '>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          <a
                            type='button'
                            class='btn-floating btn light-blue lighten-1 ml-4'
                            onClick={handleSubmitSkillsDevelopmentBursaries}
                          >
                            <i
                              class='fas fa-graduation-cap'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Applications
                            <span class='ml-4 font-weight-bold black-text'>
                              {skillsdevelopmentbursaryapplicationformTotal}{' '}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Approved Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                skillsdevelopmentbursaryapplicationformTotalApproved
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Pending
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                skillsdevelopmentbursaryapplicationformTotalPending
                              }
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unsuccessful Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {
                                skillsdevelopmentbursaryapplicationformTotalRejected
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/* Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Skills Development Busaries
                          </p>
                        </div>
                      </div>
                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}

                  {/* Grid column */}
                  <div class='col-xl-4 col-md-12 mb-2' hidden={hidden === 'Yes'}>
                    {/* Card */}
                    <div class='card '>
                      {/* Card Data */}
                      <div class='row mt-1'>
                        <div class='col-md-3 col-3 text-left pl-4'>
                          <a
                            type='button'
                            class='btn-floating btn warning-color ml-4'
                            onClick={handleSubmitCommunityProjects}
                          >
                            <i class='fas fa-users' aria-hidden='true'></i>
                          </a>
                        </div>

                        <div class='col-md-9 col-9 text-right pr-5'>
                          <div class='font-small grey-text'>
                            Total Applications
                            <span class='ml-4 mt-1 mb-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotal}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Approved Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotalApproved}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Pending
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotalPending}
                            </span>
                          </div>
                          <div class='mt-1 font-small grey-text'>
                            Unsuccessful Applications
                            <span class='ml-4 mt-1 font-weight-bold black-text'>
                              {applyforcommunityprojectApplicantsTotalRejected}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Card Data */}

                      {/* Card content */}

                      <div class='row my-1'>
                        <div class='col-md-12 col-12 text-left pl-4 pr-4'>
                          <p class='font-small font-up ml-4 font-weight-bold text-uppercase text-left'>
                            Community Projects
                          </p>
                        </div>
                      </div>

                      {/* Card content */}
                    </div>
                    {/* Card */}
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/*Section: Button icon */}
              {/* Section: National Summaries Categori-Wise Start*/}
              <section
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userSystemRole != 'Super Administrator'
                }
              >
                {/* Card 
                <div class='card'>
                  <div class='card-body'>
                    <h6
                      class='form-header white-text'
                      style={{ backgroundColor: 'green' }}
                    >
                      TOTAL NATIONAL APPLICANTS - Graph
                    </h6>
                    <div class='col-xl-12 col-lg-12 mb-4'>
                      <div class='view view-cascade gradient-card-header'>
                        {/*<div class='view view-cascade gradient-card-header indigo'>
                            <canvas id='lineChart-main' height='175'></canvas>
                              Bar,  Bubble,  Doughnut,  Line,  Pie,  PolarArea,  Radar,  Scatter
                            */}

                {/*<Bar data={data} />*/}
                <TotalNationalApplicantsGraph />
                {/* </div>
                    </div>
                  </div>
                </div>
                 Card */}
              </section>{' '}
              <br />
              {/*Section: National Summaries Categori-Wise End */}
              {/* Section: data tables */}
              <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userSystemRole != 'Super Administrator'
                }
              >
                
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>NATIONAL SUMMARY</center>
                        </div>
                        <table class='table large-header'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Province</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Grants</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Community Projects</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Skills Development</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Secondary Schools</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Loans</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {tableProvinces.map((valProvinces, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <a
                                      href='#provincesDiv'
                                      onClick={() => {
                                        displayProvincesDiv(
                                          valProvinces.provincesID
                                        )
                                      }}
                                    >
                                      {valProvinces.province}
                                    </a>
                                  </td>
                                  <td>
                                    {
                                      valProvinces.grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valProvinces.applyforcommunityprojectApplicantsApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valProvinces.skillsdevelopmentbursaryapplicationformApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valProvinces.secondaryboardingschoolbursaryapplicationformApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valProvinces.loansapplicationformApprovedThisYear
                                    }
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              {/* Section: data tables */}
              <section class='section mt-5'>
                {/*Grid row */}
                <div
                  id='provincesDiv'
                  class='row'
                  hidden={
                    userInstitution != 'State House' &&
                    userInstitution != 'Ministry' &&
                    userInstitution != 'Province'
                  }
                >
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                            PROVINCIAL SUMMARY
                            {userInstitution != 'Province' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableCouncilsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>District</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Grants</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Community Projects</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Skills Development</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Secondary School</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Loans</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {tableCouncils.map((valCouncils, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <a
                                      href='#councilsDiv'
                                      onClick={() => {
                                        displayCouncilsDiv(
                                          valCouncils.councilsID
                                        )
                                      }}
                                    >
                                      {valCouncils.council}
                                    </a>
                                  </td>
                                  <td>
                                    {
                                      valCouncils.grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valCouncils.applyforcommunityprojectApplicantsApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valCouncils.skillsdevelopmentbursaryapplicationformApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valCouncils.secondaryboardingschoolbursaryapplicationformApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valCouncils.loansapplicationformApprovedThisYear
                                    }
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              {/* Section: data tables */}
              <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userPosition != 'Director' &&
                  userPosition != 'DPO' &&
                  userPosition != 'Head Of Section' &&
                  userPosition != 'Mayor' &&
                  userPosition != 'Town Clerk' &&
                  userPosition != 'Council Chairperson' &&
                  userPosition != 'Council Secretary'
                }
              >
                {/*Grid row */}
                <div class='row' id='councilsDiv'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                            DISTRICT SUMMARY
                            {userInstitution != 'Council' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableConstituenciesName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Constituency</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Grants</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Community Projects</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Skills Development</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Secondary School</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Loans</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {tableConstituencies.map(
                              (valConstituencies, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      <a
                                        href='#constituenciesDiv'
                                        onClick={() => {
                                          displayConstituenciesDiv(
                                            valConstituencies.constituenciesID
                                          )
                                        }}
                                      >
                                        {valConstituencies.constituency}
                                      </a>
                                    </td>
                                    <td>
                                      {
                                        valConstituencies.grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
                                      }
                                    </td>
                                    <td>
                                      {
                                        valConstituencies.applyforcommunityprojectApplicantsApprovedThisYear
                                      }
                                    </td>
                                    <td>
                                      {
                                        valConstituencies.skillsdevelopmentbursaryapplicationformApprovedThisYear
                                      }
                                    </td>
                                    <td>
                                      {
                                        valConstituencies.secondaryboardingschoolbursaryapplicationformApprovedThisYear
                                      }
                                    </td>
                                    <td>
                                      {
                                        valConstituencies.loansapplicationformApprovedThisYear
                                      }
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              {/* Section: data tables */}
              <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userPosition != 'MP' &&
                  userPosition != 'CDF Committee Chairperson' &&
                  userPosition != 'CDF Committee Member' &&
                  userPosition != 'Director' &&
                  userPosition != 'DPO' &&
                  userPosition != 'Head Of Section' &&
                  userPosition != 'Mayor' &&
                  userPosition != 'Town Clerk' &&
                  userPosition != 'Council Chairperson' &&
                  userPosition != 'Council Secretary'
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                            CONSTITUENCY SUMMARY
                              <span class='text-uppercase'
                              hidden ={userPosition === 'MP' ||
                              userPosition === 'CDF Committee Chairperson' ||
                              userPosition === 'CDF Committee Member'}
                              >
                                - {TableWardsName}  
                              </span>
                              
                              <span class='text-uppercase'
                              hidden ={userPosition != 'MP' &&
                              userPosition != 'CDF Committee Chairperson' &&
                              userPosition != 'CDF Committee Member'}
                              >
                                - {userOrganisation}
                              </span>
                              
                          </center>
                        </div>
                        <table
                          class='table large-header'
                          id='constituenciesDiv'
                        >
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Ward</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Grants</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Community Projects</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Skills Development</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Secondary School</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Loans</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {tableWards.map((valwards, index) => {
                              return (
                                <tr>
                                  <td>{index + 1} </td>
                                  <td>
                                    <a
                                      href='#wardsDiv'
                                      onClick={() => {
                                        displayWardsDiv(valwards.wardsID)
                                      }}
                                    >
                                      {valwards.ward}
                                    </a>
                                  </td>
                                  <td>
                                    {
                                      valwards.grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valwards.applyforcommunityprojectApplicantsApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valwards.skillsdevelopmentbursaryapplicationformApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valwards.secondaryboardingschoolbursaryapplicationformApprovedThisYear
                                    }
                                  </td>
                                  <td>
                                    {
                                      valwards.loansapplicationformApprovedThisYear
                                    }
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              {/* Section: data tables */}
              <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userInstitution != 'Council' &&
                  userInstitution != 'Constituency' &&
                  userPosition != 'Councilor' &&
                  userPosition != 'WDC Chairperson' &&
                  userPosition != 'WDC Member'
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                            WARD SUMMARY - 
                            {userPosition != 'Councilor' &&
                            userPosition != 'WDF Committee Chairpeson' &&
                            userPosition != 'WDF Committee Member' ? (
                              <span class='text-uppercase'
                              type="button"
                              //hideWardsTables === 'Yes'
                              onClick={() => {
                                hideWardsTablesBtn(hideWardsTablesStatus)
                              }}
                              title='Click Here To View The Ward Tables'
                              >
                                {' '}
                                {TableApplicantsWardsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'
                              type="button"
                              onClick={() => {
                                hideWardsTablesBtn(hideWardsTablesStatus)
                              }}
                              title='Click Here To View The Ward Tables'
                              >
                                {' '}
                                 {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header' id='wardsDiv'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>Grants</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Community Projects</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Skills Development</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Secondary School</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Loans</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                {
                                  grantapplicationformforyouthwomenandcommunityempowermentApprovedThisYearWardTable
                                }
                              </td>
                              <td>
                                {
                                  applyforcommunityprojectApplicantsApprovedThisYearWardTable
                                }
                              </td>
                              <td>
                                {
                                  skillsdevelopmentbursaryapplicationformApprovedThisYearWardTable
                                }
                              </td>
                              <td>
                                {
                                  secondaryboardingschoolbursaryapplicationformApprovedThisYearWardTable
                                }
                              </td>
                              <td>
                                {loansapplicationformApprovedThisYearWardTable}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              
                            {/* Section: data tables */}
                            <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userInstitution != 'Council' &&
                  userInstitution != 'Constituency' &&
                  userPosition != 'Councilor' &&
                  userPosition != 'WDC Chairperson' &&
                  userPosition != 'WDC Member' ||
                  (hideWardsTables === 'Yes')
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                           COMMUNITY PROJECTS -  WARD SUMMARY TABLE
                            {userPosition != 'Councilor' &&
                            userPosition != 'WDF Committee Chairpeson' &&
                            userPosition != 'WDF Committee Member' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableApplicantsWardsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header' id='wardsDiv'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>Sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Proposer Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Proposer Phone</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>CDF Benefit</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Physical Address</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Project Estimated Cost</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                          {tableApplicantsWardsNameTableProjects.map((val, index) => {
                            return (
                            <tr>
                              
                              <td>
                                {
                                  index + 1
                                }
                              </td>
                              <td>
                                {
                                  val.name
                                }
                              </td>
                              <td>
                                {
                                  val.phone
                                }
                              </td>
                              <td>
                                {
                                  val.typeOfProject
                                }
                              </td>
                              <td>
                                {val.address}
                              </td>
                              <td>{val.whatIsTheEstimatedCostOfTheProject}</td>
                              <td><span title='View Applicant'
                              onClick={() => {
                                handleClickViewProjects(val.id)
                              }}
                              type = 'button'
                              class='fa fa-eye green-text'></span></td>
                            </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              
                                         {/* Section: data tables */}
                                         <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userInstitution != 'Council' &&
                  userInstitution != 'Constituency' &&
                  userPosition != 'Councilor' &&
                  userPosition != 'WDC Chairperson' &&
                  userPosition != 'WDC Member'||
                  (hideWardsTables === 'Yes')
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                          GRANTS FOR YOUTH, WOMEN AND COMMUNITY EMPOWERMENT - WARD SUMMARY TABLE
                            {userPosition != 'Councilor' &&
                            userPosition != 'WDF Committee Chairpeson' &&
                            userPosition != 'WDF Committee Member' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableApplicantsWardsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header' id='wardsDiv'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>Sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Grant Type</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Grant Amount</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>TPIN</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Bank ACC NO.</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Proposer Phone</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>ProjectProposerName</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Physical Address</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                          {tableApplicantsWardsNameTableGrants.map((val, index) => {
                            return (
                            <tr>
                              
                              <td>
                                {
                                  index + 1
                                }
                              </td>
                              <td>
                                {
                                  val.name
                                }
                              </td>
                              <td>
                                {
                                  val.grantType
                                }
                              </td>
                              <td>{val.grantAmount}</td>
                              <td>{val.TPIN}</td>
                              <td>{val.accountNumber}</td>
                              <td>{val.ProjectProposerPhone}</td>
                              <td>{val.ProjectProposerName}</td>
                              <td>
                                {val.address}
                              </td>
                              <td><span title='View Applicant'
                              onClick={() => {
                                handleClickViewGrants(val.id)
                              }}
                              type = 'button'
                              class='fa fa-eye green-text'></span></td>
                            </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              
                                         {/* Section: data tables */}
                                         <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userInstitution != 'Council' &&
                  userInstitution != 'Constituency' &&
                  userPosition != 'Councilor' &&
                  userPosition != 'WDC Chairperson' &&
                  userPosition != 'WDC Member'||
                  (hideWardsTables === 'Yes')
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                           SECONDARY SCHOOL BURSARIES - WARD SUMMARY TABLE
                            {userPosition != 'Councilor' &&
                            userPosition != 'WDF Committee Chairpeson' &&
                            userPosition != 'WDF Committee Member' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableApplicantsWardsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header' id='wardsDiv'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>Sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Gender</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>D.O.B</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Contact Phone</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Contact Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>School Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Learner Address</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Area</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                          {tableApplicantsWardsNameTableSecondary.map((val, index) => {
                            return (
                            <tr>
                              
                              <td>
                                {
                                  index + 1
                                }
                              </td>
                              <td>
                                {
                                  val.name
                                }
                              </td>
                              <td>{val.gender}</td>
                              <td>{val.dateOfBirth}</td>
                              <td>{val.contactPhone}</td>
                              <td>{val.contactName}</td>
                              <td>{val.nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted}</td>
                              <td>{val.learnerAddress}</td>
                              <td>
                                {
                                  val.area
                                }
                              </td>
                              <td><span title='View Applicant'
                              onClick={() => {
                                handleClickViewSecondary(val.id)
                              }}
                              type = 'button'
                              class='fa fa-eye green-text'></span></td>
                            </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
             
                                        {/* Section: data tables */}
                                        <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userInstitution != 'Council' &&
                  userInstitution != 'Constituency' &&
                  userPosition != 'Councilor' &&
                  userPosition != 'WDC Chairperson' &&
                  userPosition != 'WDC Member'||
                  (hideWardsTables === 'Yes')
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                            SKILLS DEVELOPMENT BURSARIES - WARD SUMMARY TABLE
                            {userPosition != 'Councilor' &&
                            userPosition != 'WDF Committee Chairpeson' &&
                            userPosition != 'WDF Committee Member' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableApplicantsWardsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header' id='wardsDiv'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>Sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>NRC</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Sex</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>School Leaver?</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Course</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Duration</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Institution</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Date Applied</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Postal Address</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                          {tableApplicantsWardsNameTableSkills.map((val, index) => {
                            return (
                            <tr>
                              
                              <td>
                                {
                                  index + 1
                                }
                              </td>
                              <td>
                                {
                                  val.name
                                }
                              </td>
                              <td>{val.NRCNumber}</td>
                              <td>{val.sex}</td>
                              <td>{val.SchoolLeaverOrNot}</td>
                              <td>{val.whatProgrammeOfStudyDoYouWishToPursue}</td>
                              <td>{val.durationOfProgramme}</td>
                              <td>{val.nameTheInstitutionWhereYouHaveBeenAccepted}</td>
                              <td>{val.applicationDate}</td>
                              <td>
                                {val.postalAddress}
                              </td>
                              <td><span title='View Applicant'
                              onClick={() => {
                                handleClickViewSkills(val.id)
                              }}
                              type = 'button'
                              class='fa fa-eye green-text'></span></td>
                            </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              
                                         {/* Section: data tables */}
                                         <section
                class='section mt-5'
                hidden={
                  userInstitution != 'State House' &&
                  userInstitution != 'Ministry' &&
                  userInstitution != 'Province' &&
                  userInstitution != 'Council' &&
                  userInstitution != 'Constituency' &&
                  userPosition != 'Councilor' &&
                  userPosition != 'WDC Chairperson' &&
                  userPosition != 'WDC Member'||
                  (hideWardsTables === 'Yes')
                }
              >
                {/*Grid row */}
                <div class='row'>
                  {/* Grid column */}

                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <div class='form-header white-text bg-success'>
                          <center>
                            LOANS - WARD SUMMARY TABLE
                            {userPosition != 'Councilor' &&
                            userPosition != 'WDF Committee Chairpeson' &&
                            userPosition != 'WDF Committee Member' ? (
                              <span class='text-uppercase'>
                                {' '}
                                - {TableApplicantsWardsName}{' '}
                              </span>
                            ) : (
                              <span class='text-uppercase'>
                                {' '}
                                - {userOrganisation}
                              </span>
                            )}
                          </center>
                        </div>
                        <table class='table large-header' id='wardsDiv'>
                          <thead>
                            <tr>
                              <th class='font-weight-bold'>
                                <strong>Sn</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Name</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Amount</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Business</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>TPIN</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Acc</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Phone</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Representative</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Date Applied</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Address</strong>
                              </th>
                              <th class='font-weight-bold'>
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                          {tableApplicantsWardsNameTableLoans.map((val, index) => {
                            return (
                            <tr>
                              
                              <td>
                                {
                                  index + 1
                                }
                              </td>
                              <td>
                                {
                                  val.name
                                }
                              </td>
                              <td>{val.loanAmount}</td>
                              <td>{val.typeOfBusiness}</td>
                              <td>{val.bankDetailsTpin}</td>
                              <td>{val.bankDetailsAccountNumber}</td>
                              <td>{val.authorizedRepresentativeCell}</td>
                              <td>{val.authorizedRepresentativeNameOfContactPerson}</td>
                              <td>{val.applicationDate}</td>
                              <td>
                                {val.address}
                              </td>
                              <td><span title='View Applicant' 
                              onClick={() => {
                                handleClickViewLoans(val.id)
                              }}
                              type = 'button'
                              class='fa fa-eye green-text'></span></td>
                            </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
              {/* Section: data tables */}
              <br />
              <br />
              <br />
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default Home
