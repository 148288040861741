import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport =
  () => {
    const server_app_api = window.SERVER_APP_API
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    const viewApplicant = id => {
      localStorage.setItem(
        'ApplicantGrantsForYouthWomenAndCommunityEmpowermentView',
        JSON.stringify(id)
      )
      navigate('/ApplicantGrantsForYouthWomenAndCommunityEmpowermentView')
    }

    const [ip, setIP] = useState('')
    const [DBtableViewWards, setDBtableViewWards] = useState([])
    const [DBtableViewWardName, setDBtableViewWardName] = useState([])

    const firstSystemSetupID = localStorage.getItem('systemSetupID')
    const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

    let firstWardsID = localStorage.getItem(
      //'WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
      'ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport'
    )
    firstWardsID = JSON.parse(firstWardsID)

    const deleteRecord = wardsID => {
      var formDataDeleteOneUser = new FormData(this)
      formDataDeleteOneUser.append('apiType', 'getViewSalesReport')
      formDataDeleteOneUser.append('posIDToDelete', wardsID)
      /*formDataDeleteOneUser.append('positionToDelete', secondUserPosition)
      formDataDeleteOneUser.append('userIDToDelete', secondUserID)*/
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataDeleteOneUser,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        alert(response.data)
      })
    }

    //creating function to load ip address from the API
    const getData = async () => {
      const res = await Axios.get('https://geolocation-db.com/json/')
      console.log(res.data)
      setIP(res.data.IPv4)
    }
    useEffect(() => {
      //passing getData method to the lifecycle method
      getData()
    }, [])

    useEffect(() => {
      var formData = new FormData(this)
      formData.append('apiType', 'getViewApplicantsFromWards')
      formData.append('wardsID', firstWardsID)
      formData.append(
        'table',
        'grantapplicationformforyouthwomenandcommunityempowerment'
      )
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setDBtableViewWards(response.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    }, [])

    useEffect(() => {
      var formData = new FormData(this)
      formData.append('apiType', 'getViewApplicantsFromWardName')
      formData.append('wardsID', firstWardsID)
      //formData.append('table', 'applyforcommunityproject')
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        setDBtableViewWardName(response.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    }, [])

    //initialize datatable
    $(function () {
      setTimeout(function () {
        $('#table').DataTable()
        addTableButtons()
      }, 3000)
    })

    function addTableButtons () {
      var table = $('#table').DataTable()

      new $.fn.dataTable.Buttons(table, {
        buttons: [
          {
            extend: 'copy',
            text: 'Copy <i class="fa fa-files-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
          },
          {
            extend: 'csv',
            text: 'CSV <i class="fa fa-text-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
          },
          {
            extend: 'excel',
            text: 'Excel <i class="fa fa-file-excel-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
          },
          //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
          {
            extend: 'pdf',
            text: 'PDF <i class="fa fa-file-pdf-o"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
          },
          {
            extend: 'print',
            text: 'Print <i class="fa fa-print"></i>',
            className: 'dtButton',
            exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
          }
        ]
      })

      table.buttons(0, null).containers().appendTo('#filterTopCriteria')
    }

    return (
      <>
        <>
          <Header />
        </>
        <br /> <br /> <br />
        <br /> <br />
        <main>
          {/*<form method="POST" encType="multipart/form-data">*/}
          <div class='container-fluid'>
            <div class='row'>
              <div class='col-md-12 mb-2'>
                <div class='card'>
                  <div class='card-body' id='faults'>
                    <div id='divShowMessageFromAjax'></div>
                    <div class='form-header white-text'>
                      {' '}
                      <span class='text-uppercase'>
                        {DBtableViewWardName.map(valName => {
                          return <>{valName.ward} </>
                        })}
                      </span>
                      APPLICANTS DETAILED REPORT - GRANTS FOR YOUTH WOMEN AND
                      COMMUNITY EMPOWERMENT{' '}
                    </div>
                    {/*  <!-- Grid row -->*/}
                    <div class='col-12'>
                      <center>
                        <div id='filterTopCriteria'></div>
                      </center>
                      <table
                        id='table'
                        class='table table-striped table-bordered table-hover table-sm'
                      >
                        <thead class='form-header text-uppercase'>
                          <tr>
                            <th>S/N</th>
                            <th>Applicant</th>
                            <th>TPIN</th>
                            <th>Project Proposer Name</th>
                            <th>Project Proposer Phone</th>
                            <th>Project Seconder Name</th>
                            <th>Project Seconder Phone</th>
                            <th>Amount</th>

                            <th>DELETE</th>
                            <th>VIEW</th>
                          </tr>
                        </thead>
                        <tbody>
                          {DBtableViewWards.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.nameOfGroupMakingApplication}</td>
                                <td>{val.TPIN}</td>
                                <td>{val.ProjectProposerName}</td>
                                <td>{val.ProjectProposerPhone}</td>
                                <td>{val.SeconderName}</td>
                                <td>{val.SeconderPhone}</td>
                                <td>{val.grantAmount}</td>

                                <td>
                                  <span
                                    type='button'
                                    class='fa fa-trash red-text'
                                    onClick={() => {
                                      deleteRecord(val.id)
                                    }}
                                    title='Delete This Record From The Database'
                                  ></span>
                                </td>
                                <td>
                                  <span
                                    type='button'
                                    class='fa fa-eye green-text'
                                    onClick={() => {
                                      viewApplicant(val.id)
                                    }}
                                    title='View Applicant Details'
                                  ></span>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                    {/* <!-- Grid row -->*/}
                    <br /> <br /> <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*</form>*/}
        </main>
      </>
    )
  }

export default ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport
