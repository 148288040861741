
import {useRef, useState, useEffect} from 'react';
import '../App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../api/axios';

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ; 
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const RegisterProducts = () => {

const businessNameRef = useRef();
const errRef = useRef();

//const [logo, setLogo] = useState('');
//const [pacraCertificate, setPacraCertificate] = useState('');

const [businessName, setBusinessName] = useState('');
const [productName, setProductName] = useState('');
const [ip,setIP] = useState('');
//const [userID,setUserID] = useState(userIDInitial);
const [businessNameDropDown, setBusinessNameDropDown] = useState([]);

//const [success, setSuccess ] = useState(false);


useEffect( () => {
	businessNameRef.current.focus();
},
[])



/*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/


   //creating function to load ip address from the API
    const getData = async()=>{
        const res = await Axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
    }
  useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])

    /*useEffect( () => {
        Axios.post("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            //console.log(response);
            });
        },
        [])*/
        const firstSystemSetupID = localStorage.getItem("systemSetupID");
const systemSetupIDInitial = JSON.parse(firstSystemSetupID);
//alert("id = "+systemSetupIDInitial);

        useEffect( () => {
            Axios.post("http://localhost:3001/api/getBusinessNameDropDown", {
                      businessNameID : systemSetupIDInitial,		
      }).then((response) => { 
        setBusinessNameDropDown(response.data);
       })//.catch((err) => alert("ERROR : Check Your Network Connection"));
              },
              [])


         
const handleSubmit = async (e) => 
{
    //alert("businessName = "+businessName);
    const currentDate = new Date();
    const dateToday = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`;
    const timeNow = currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds();
    
    const firstUserID = localStorage.getItem("userID");
    const userIDInitial = JSON.parse(firstUserID);
    var userID = userIDInitial;
    Axios.post("http://localhost:3001/api/insertRegisterProducts", {
        
        businessName : businessName,
        productName : productName,
        userID : userID,
		system_setup_IP : ip,
        dateToday : dateToday,
        timeNow : timeNow,
		
    }).then((res) => {	alert(res.data);
  
	  })  
	  .catch((err) => alert("ERROR : Kindly Contact Your Adminitrator"));
	}

    // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); //output= 2020-5-24
    //var time = new Date().toTimeString();
    
    //const time = `${currentDate.getTime()}`;
    //var today = new Date(),
    //alert("Time Is = "+timeNow+" Today's Date = "+date);
    return(
	
	<main>
	{/*<form method="POST" encType="multipart/form-data">*/}
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 mb-2">
          <div class="card">
            <div class="card-body" id="faults">
	<div id="divShowMessageFromAjax"></div>
	
		<div class="form-header white-text"> REGISTER PRODUCTS HERE </div>
         
{/*Start Of Row 1*/}
<div class="row">							
	<div class="col-lg-6 col-md-12 mb-1">
	<div class="md-form">
	<label >Business Name <i class="red-text">*</i> </label><br /><br />
<select class="custom-select d-block w-100"  id="businessName" onChange={(e) => setBusinessName(e.target.value)}
						 autoComplete = "off"  required
                         ref= {businessNameRef} >
<option value="" >Choose Business Name ..</option>
{
    businessNameDropDown.map((val)=>{
return (
    <option value={val.system_setupID}>{val.organisation} </option>
);

    })
}
                         </select>
					</div>
					</div>

                    <div class="col-lg-6 col-md-12 mb-1">
	<div class="md-form">
	<label >Product Name <i class="red-text">*</i> </label><br />
<input  type="text" class="form-control"  id="productName" onChange={(e) => setProductName(e.target.value)}
	
						 autoComplete = "off" value={productName} required
						/>
					</div>
					</div>
					
					</div> {/*End Of Row 1*/}


 
					
					 <p><span class="red-text">* Required</span> <span>- Do not leave Blank</span></p>
              <hr class="mb-1" />
              <button onClick= {handleSubmit} class="btn btn-primary"
			  disabled={businessName === '' || productName === '' }
			  >Submit</button>
						<br /> <br /> <br /> 
					
				
          </div>
        </div>
     
      </div>
    </div>
    </div>
	{/*</form>*/}
  </main>

    )

}

export default RegisterProducts;