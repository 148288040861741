
import {useRef, useState, useEffect, useContext, Component} from 'react';
import axios from '../api/axios';
class FileUploadTest extends Component {
    state={
        file:null
    }
    handleFile(e){
        console.log(e.target.files, "My Name Is Sinkala Rodeen Kanchele");
        console.log(e.target.files[0], "Also My Name Is Muchemwa Sinkala Rodeen Kanchele");
    let file = e.target.files[0]

    this.setState({file: file})
    }

    handleUpload(e){
        //console.log(this.state, "Hey Now Rodeen Is Uploading Files");
    let file = this.state.file
    let fd = new FormData()
    fd.append('file', file)
    fd.append('name',"Sinkala Rodeen Kanchele")
    axios.post("http://localhost:3001/api/insertTestFileUplod", fd).then((res) => {

        alert("Result : "+res);
  
      }).catch((err) => alert("ERROR MESSAGE : "+err));	
        /*axios({
            url: 'http://localhost:3001/api/insertTestFileUplod',
            method: "post" ,
            headers: {
                authorization: ''
            },
            data: formdata
        }).then((res)=>{
            console.log(res);
        },(err)=>{
console.log(err);
        }
        )*/
    


    }
    

    render ()
    {
    return(

<section className="view intro-2">
<div className="rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
  <div className="container">
<div className="row"> 
<div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">

	
	<div className="card wow fadeIn" data-wow-delay="0.3s">
                <div className="card-body">
	<div className="form-header purple-gradient">
                    <h3 className="font-weight-500 my-2 py-1"><i className="fas fa-user"></i> FileUploadTest:</h3>
                  </div>
 <div className="text-center"> <hr className="mt-4" /> <p > <a href="Home" className="red-text"> Home</a> </p> </div>
	 
     <form method="post" encType="multipart/form-data"> 
     <div className="row">
     <div className="col-lg-12 col-md-12 mb-1">
				  <div className="md-form">
						<label className="black-text">File TO Upload
						
						</label><br /><br />
						<input multiple  type="file" className="form-control" 
                         id="file" name="file"	
                         onChange={(e)=>this.handleFile(e)}
                         />
						
					</div>

<button onClick={(e)=>this.handleUpload(e)} className="btn btn-primary">Upload</button>

					</div>
    </div>
     </form>
     
     
     </div>
              </div>
</div>
</div>
</div>
</div>
</section>

    )

}

}

export default FileUploadTest;