import { useRef, useState, useEffect, useContext } from 'react'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faStairs,
  faIndustry,
  faLadderWater,
  faArrowLeft,
  faArrowRight,
  faForwardStep,
  faForwardFast,
  faBackwardFast,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import $, { nodeName } from 'jquery'
import 'jquery/dist/jquery.min.js'
import { useNavigate } from 'react-router-dom'
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;
//save
const GrantApplicationFormForYouthWomenAndCommunityEmpowerment = () => {
  let navigate = useNavigate()

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let clientID = localStorage.getItem('userID')
  clientID = JSON.parse(clientID)

  let login = localStorage.getItem('login')
  login = JSON.parse(login)

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if(loggedIn != 'Yes'){
    navigate('/Logout')
  }

  var [provinceDB, setprovinceDB] = useState('')
  if(provinceDB != ''){provinceDB = '('+provinceDB+')'}
  var [councilDB, setcouncilDB] = useState('')
  if(councilDB != ''){councilDB = '('+councilDB+')'}
  var [constituencyDB, setconstituencyDB] = useState('')
  if(constituencyDB != ''){constituencyDB = '('+constituencyDB+')'}
  var [wardDB, setwardDB] = useState('')
  if(wardDB != ''){wardDB = '('+wardDB+')'}

  const [grantType, setgrantType] = useState('')
  const [grantAmount, setgrantAmount] = useState('')
  const [nameOfGroupMakingApplication, setnameOfGroupMakingApplication] =
    useState('')
  const [province, setprovince] = useState('')
  const [provinceDropDown, setprovinceDropDown] = useState([])
  const [council, setcouncil] = useState('')
  const [councilDropDown, setcouncilDropDown] = useState([])
  const [constituency, setconstituency] = useState('')
  const [constituencyDropDown, setconstituencyDropDown] = useState([])
  const [ward, setward] = useState('')
  const [wardDropDown, setwardDropDown] = useState([])
  const [zone, setzone] = useState('')
  const [physicalAddress, setphysicalAddress] = useState('')
  const [
    dateWhenGroupWasRegisteredWithRelevantAuthorities,
    setdateWhenGroupWasRegisteredWithRelevantAuthorities
  ] = useState('')
  const [
    experienceInAProjectOfSimilarNature,
    setexperienceInAProjectOfSimilarNature
  ] = useState('')
  const [
    experienceInAProjectOfSimilarNatureYesReason,
    setexperienceInAProjectOfSimilarNatureYesReason
  ] = useState('')
  const [
    whatAreTheMainProblemsInYourCommunity,
    setwhatAreTheMainProblemsInYourCommunity
  ] = useState('')
  const [
    whatProblemIsTheProjectGoingToAddress,
    setwhatProblemIsTheProjectGoingToAddress
  ] = useState('')
  const [
    howDidTheGroupIdentifyTheProject,
    sethowDidTheGroupIdentifyTheProject
  ] = useState('')
  const [
    doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile,
    setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
  ] = useState('')
  const [
    projectIdentificationMinutesFile,
    setprojectIdentificationMinutesFile
  ] = useState('')

  const [projectIdentificationMinutes, setprojectIdentificationMinutes] =
    useState('')
  const [
    howWillTheProjectBenefitTheCommunity,
    sethowWillTheProjectBenefitTheCommunity
  ] = useState('')
  const [howManyDirectJobsWillBeCreated, sethowManyDirectJobsWillBeCreated] =
    useState('')
  const [
    haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years,
    sethaveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
  ] = useState('')
  const [organisationA, setorganisationA] = useState('')
  const [organisationAAmount, setorganisationAAmount] = useState('')
  const [organisationB, setorganisationB] = useState('')
  const [organisationBAmount, setorganisationBAmount] = useState('')
  const [whatIsTheStatusOfTheLoanTaken, setwhatIsTheStatusOfTheLoanTaken] =
    useState('')
  const [bankName, setbankName] = useState('')
  const [branch, setbranch] = useState('')
  const [sortOrBranchCode, setsortOrBranchCode] = useState('')
  const [swiftCode, setswiftCode] = useState('')
  const [accountNumber, setaccountNumber] = useState('')
  const [TPIN, setTPIN] = useState('')
  const [mobileMoneyWalletNameAndNumber, setmobileMoneyWalletNameAndNumber] =
    useState('')
  const [hasyourGroupReceivedAnyTraining, sethasyourGroupReceivedAnyTraining] =
    useState('')
  const [ifTrainedHowManyMembers, setifTrainedHowManyMembers] = useState('')
  const [
    ifYesFromWhichOrganizationAndHowLongWasTheTraining,
    setifYesFromWhichOrganizationAndHowLongWasTheTraining
  ] = useState('')
  const [
    typeOrUploadProjectCommitteemembers,
    settypeOrUploadProjectCommitteemembers
  ] = useState('')
  const [name, setname] = useState([{}])
  const [position, setposition] = useState([{}])
  const [sex, setsex] = useState([{}])
  const [nrc, setnrc] = useState([{}])
  const [inputList, setInputList] = useState([
    { name: '', position: '', sex: '', nrc: '' }
  ])
  var nameArrGrants = []
  var positionArrGrants = []
  var sexArrGrants = []
  var nrcArrGrants = []

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)

    list.map(val => {
      nameArrGrants.push(val.name)
      positionArrGrants.push(val.position)
      sexArrGrants.push(val.sex)
      nrcArrGrants.push(val.nrc)
    })
    localStorage.setItem('name', JSON.stringify(nameArrGrants))
    localStorage.setItem('position', JSON.stringify(positionArrGrants))
    localStorage.setItem('sex', JSON.stringify(sexArrGrants))
    localStorage.setItem('nrc', JSON.stringify(nrcArrGrants))
    //setItemID(JSON.stringify(rodeen))
    setname(JSON.stringify(nameArrGrants))
    setposition(JSON.stringify(positionArrGrants))
    setsex(JSON.stringify(sexArrGrants))
    setnrc(JSON.stringify(nrcArrGrants))
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)

    ///var rodeens = []
    list.map(val => {
      nameArrGrants.push(val.name)
      positionArrGrants.push(val.position)
      sexArrGrants.push(val.sex)
      nrcArrGrants.push(val.nrc)
    })
    localStorage.setItem('name', JSON.stringify(nameArrGrants))
    localStorage.setItem('position', JSON.stringify(positionArrGrants))
    localStorage.setItem('sex', JSON.stringify(sexArrGrants))
    localStorage.setItem('nrc', JSON.stringify(nrcArrGrants))
  }
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: '', position: '', sex: '', nrc: '' }])
  }

  const [declarationDate, setdeclarationDate] = useState('')
  const [signatoriesToTheApplicationName, setSignatoriesName] = useState([{}])
  const [signatoriesToTheApplicationPosition, setSignatoriesPosition] =
    useState([{}])
  const [signatoriesToTheApplicationNrc, setSignatoriesNrc] = useState([{}])

  var signatoriesNameArrGrants = []
  var signatoriesPositionArrGrants = []
  var signatoriesNrcArrGrants = []
  const [signatoriesList, setSignatoriesList] = useState([
    {
      signatoriesName: '',
      signatoriesPosition: '',
      signatoriesNrc: ''
    }
  ])

  const handleSignatoriesChange = (e, index) => {
    var { name, value } = e.target
    const signatoriesLists = [...signatoriesList]
    signatoriesLists[index][name] = value
    setSignatoriesList(signatoriesLists)

    signatoriesLists.map(val => {
      signatoriesNameArrGrants.push(val.signatoriesName)
      signatoriesPositionArrGrants.push(val.signatoriesPosition)
      signatoriesNrcArrGrants.push(val.signatoriesNrc)
    })
    localStorage.setItem(
      'signatoriesName',
      JSON.stringify(signatoriesNameArrGrants)
    )
    localStorage.setItem(
      'signatoriesPosition',
      JSON.stringify(signatoriesPositionArrGrants)
    )
    localStorage.setItem(
      'signatoriesNrc',
      JSON.stringify(signatoriesNrcArrGrants)
    )
    //setItemID(JSON.stringify(rodeen))
    setSignatoriesName(JSON.stringify(signatoriesNameArrGrants))
    setSignatoriesPosition(JSON.stringify(signatoriesPositionArrGrants))
    setSignatoriesNrc(JSON.stringify(signatoriesNrcArrGrants))
  }

  const handleSignatoriesRemove = index => {
    const signatoriesLists = [...signatoriesList]
    signatoriesLists.splice(index, 1)
    setSignatoriesList(signatoriesLists)

    signatoriesLists.map(val => {
      signatoriesNameArrGrants.push(val.signatoriesName)
      signatoriesPositionArrGrants.push(val.signatoriesPosition)
      signatoriesNrcArrGrants.push(val.signatoriesNrc)
    })
    localStorage.setItem(
      'signatoriesName',
      JSON.stringify(signatoriesNameArrGrants)
    )
    localStorage.setItem(
      'signatoriesPosition',
      JSON.stringify(signatoriesPositionArrGrants)
    )
    localStorage.setItem(
      'signatoriesNrc',
      JSON.stringify(signatoriesNrcArrGrants)
    )
    //setItemID(JSON.stringify(rodeen))
  }

  const handleSignatoriesAdd = () => {
    setSignatoriesList([
      ...signatoriesList,
      {
        signatoriesName: '',
        signatoriesPosition: '',
        signatoriesNrc: ''
      }
    ])
  }

  const [projectCommitteeMembersFile, setprojectCommitteeMembersFile] =
    useState('')

  const [
    projectCommitteeMembersMinuteFile,
    setprojectCommitteeMembersMinuteFile
  ] = useState('')
  const [ProjectProposerName, setProjectProposerName] = useState('')
  const [ProjectProposerNRC, setProjectProposerNRC] = useState('')
  const [ProjectProposerAddress, setProjectProposerAddress] = useState('')
  const [ProjectProposerPhone, setProjectProposerPhone] = useState('')
  const [ProjectProposerDate, setProjectProposerDate] = useState('')
  const [SeconderName, setSeconderName] = useState('')
  const [SeconderNRC, setSeconderNRC] = useState('')
  const [SeconderAddress, setSeconderAddress] = useState('')
  const [SeconderPhone, setSeconderPhone] = useState('')
  const [SeconderDate, setSeconderDate] = useState('')
  const [
    recommendationByTheWardDevelopmentCommittee,
    setrecommendationByTheWardDevelopmentCommittee
  ] = useState('')
  const [
    recommendationByTheWardDevelopmentCommitteeReason,
    setrecommendationByTheWardDevelopmentCommitteeReason
  ] = useState('')

  const saveProjectIdentificationMinutesFile = e => {
    setprojectIdentificationMinutesFile(e.target.files[0])
  }
  const saveProjectCommitteeMembersFile = e => {
    setprojectCommitteeMembersFile(e.target.files[0])
  }



  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setprovinceDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setcouncilDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province]) //councilDropDown

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setconstituencyDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setwardDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council, constituency])


useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('grantType', grantType)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [grantType])
  
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('grantAmount', grantAmount)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [grantAmount])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('nameOfGroupMakingApplication', nameOfGroupMakingApplication)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [nameOfGroupMakingApplication])
  
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('province', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [province])
  
   useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('council', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [council])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('constituency', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [constituency])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ward', ward)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ward])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('zone', zone)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [zone])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('physicalAddress', physicalAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [physicalAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('dateWhenGroupWasRegisteredWithRelevantAuthorities', dateWhenGroupWasRegisteredWithRelevantAuthorities)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [dateWhenGroupWasRegisteredWithRelevantAuthorities])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('experienceInAProjectOfSimilarNature', experienceInAProjectOfSimilarNature)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [experienceInAProjectOfSimilarNature])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('experienceInAProjectOfSimilarNatureYesReason', experienceInAProjectOfSimilarNatureYesReason)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [experienceInAProjectOfSimilarNatureYesReason])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('whatAreTheMainProblemsInYourCommunity', whatAreTheMainProblemsInYourCommunity)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [whatAreTheMainProblemsInYourCommunity])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('whatProblemIsTheProjectGoingToAddress', whatProblemIsTheProjectGoingToAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [whatProblemIsTheProjectGoingToAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('howDidTheGroupIdentifyTheProject', howDidTheGroupIdentifyTheProject)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [howDidTheGroupIdentifyTheProject])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile', doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('projectIdentificationMinutesFile', projectIdentificationMinutesFile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [projectIdentificationMinutesFile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('projectIdentificationMinutes', projectIdentificationMinutes)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [projectIdentificationMinutes])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('howWillTheProjectBenefitTheCommunity', howWillTheProjectBenefitTheCommunity)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [howWillTheProjectBenefitTheCommunity])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('howManyDirectJobsWillBeCreated', howManyDirectJobsWillBeCreated)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [howManyDirectJobsWillBeCreated])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years', haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('organisationA', organisationA)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [organisationA])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('organisationAAmount', organisationAAmount)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [organisationAAmount])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('organisationB', organisationB)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [organisationB])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('organisationBAmount', organisationBAmount)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [organisationBAmount])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('whatIsTheStatusOfTheLoanTaken', whatIsTheStatusOfTheLoanTaken)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [whatIsTheStatusOfTheLoanTaken])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('bankName', bankName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [bankName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('branch', branch)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [branch])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('sortOrBranchCode', sortOrBranchCode)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [sortOrBranchCode])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('swiftCode', swiftCode)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [swiftCode])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('accountNumber', accountNumber)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [accountNumber])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('TPIN', TPIN)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [TPIN])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('mobileMoneyWalletNameAndNumber', mobileMoneyWalletNameAndNumber)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [mobileMoneyWalletNameAndNumber])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('hasyourGroupReceivedAnyTraining', hasyourGroupReceivedAnyTraining)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [hasyourGroupReceivedAnyTraining])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ifTrainedHowManyMembers', ifTrainedHowManyMembers)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ifTrainedHowManyMembers])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ifYesFromWhichOrganizationAndHowLongWasTheTraining', ifYesFromWhichOrganizationAndHowLongWasTheTraining)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ifYesFromWhichOrganizationAndHowLongWasTheTraining])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('typeOrUploadProjectCommitteemembers', typeOrUploadProjectCommitteemembers)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [typeOrUploadProjectCommitteemembers])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('name', name)
    formData.append('position', position)
    formData.append('sex', sex)
	formData.append('nrc', nrc)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [name, position, sex, nrc])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('declarationDate', declarationDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [declarationDate])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('signatoriesToTheApplicationName', signatoriesToTheApplicationName)
    formData.append('signatoriesToTheApplicationPosition', signatoriesToTheApplicationPosition)
    formData.append('signatoriesToTheApplicationNrc', signatoriesToTheApplicationNrc)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [signatoriesToTheApplicationName, signatoriesToTheApplicationPosition, signatoriesToTheApplicationNrc])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('projectCommitteeMembersFile', projectCommitteeMembersFile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [projectCommitteeMembersFile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('projectCommitteeMembersMinuteFile', projectCommitteeMembersMinuteFile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [projectCommitteeMembersMinuteFile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ProjectProposerName', ProjectProposerName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ProjectProposerName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ProjectProposerNRC', ProjectProposerNRC)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ProjectProposerNRC])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ProjectProposerAddress', ProjectProposerAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ProjectProposerAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ProjectProposerPhone', ProjectProposerPhone)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ProjectProposerPhone])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('ProjectProposerDate', ProjectProposerDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ProjectProposerDate])
  
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('SeconderName', SeconderName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [SeconderName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('SeconderNRC', SeconderNRC)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [SeconderNRC])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('SeconderAddress', SeconderAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [SeconderAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('SeconderPhone', SeconderPhone)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [SeconderPhone])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('SeconderDate', SeconderDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [SeconderDate])


  const handleSubmit = async e => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment'
    )  

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }
  
  /*
  const handleSubmit = async e => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment'
    )
    formData.append('grantType', grantType)
    formData.append('grantAmount', grantAmount)
    formData.append(
      'nameOfGroupMakingApplication',
      nameOfGroupMakingApplication
    )
    formData.append('province', province)
    formData.append('council', council)
    formData.append('constituency', constituency)
    formData.append('ward', ward)
    formData.append('zone', zone)
    formData.append('physicalAddress', physicalAddress)
    formData.append(
      'dateWhenGroupWasRegisteredWithRelevantAuthorities',
      dateWhenGroupWasRegisteredWithRelevantAuthorities
    )
    formData.append(
      'experienceInAProjectOfSimilarNature',
      experienceInAProjectOfSimilarNature
    )
    formData.append(
      'experienceInAProjectOfSimilarNatureYesReason',
      experienceInAProjectOfSimilarNatureYesReason
    )
    formData.append(
      'whatAreTheMainProblemsInYourCommunity',
      whatAreTheMainProblemsInYourCommunity
    )
    formData.append(
      'whatProblemIsTheProjectGoingToAddress',
      whatProblemIsTheProjectGoingToAddress
    )
    formData.append(
      'howDidTheGroupIdentifyTheProject',
      howDidTheGroupIdentifyTheProject
    )
    formData.append(
      'doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile',
      doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
    )
    formData.append(
      'projectIdentificationMinutesFile',
      projectIdentificationMinutesFile
    )
    formData.append(
      'projectIdentificationMinutes',
      projectIdentificationMinutes
    )
    formData.append(
      'howWillTheProjectBenefitTheCommunity',
      howWillTheProjectBenefitTheCommunity
    )
    formData.append(
      'howManyDirectJobsWillBeCreated',
      howManyDirectJobsWillBeCreated
    )
    formData.append(
      'haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years',
      haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
    )
    formData.append('organisationA', organisationA)
    formData.append('organisationAAmount', organisationAAmount)
    formData.append('organisationB', organisationB)
    formData.append('organisationBAmount', organisationBAmount)
    formData.append(
      'whatIsTheStatusOfTheLoanTaken',
      whatIsTheStatusOfTheLoanTaken
    )
    formData.append('bankName', bankName)
    formData.append('branch', branch)
    formData.append('sortOrBranchCode', sortOrBranchCode)
    formData.append('swiftCode', swiftCode)
    formData.append('accountNumber', accountNumber)
    formData.append('TPIN', TPIN)
    formData.append(
      'mobileMoneyWalletNameAndNumber',
      mobileMoneyWalletNameAndNumber
    )
    formData.append(
      'hasyourGroupReceivedAnyTraining',
      hasyourGroupReceivedAnyTraining
    )
    formData.append('ifTrainedHowManyMembers', ifTrainedHowManyMembers)
    formData.append(
      'ifYesFromWhichOrganizationAndHowLongWasTheTraining',
      ifYesFromWhichOrganizationAndHowLongWasTheTraining
    )
    formData.append(
      'typeOrUploadProjectCommitteemembers',
      typeOrUploadProjectCommitteemembers
    )
    formData.append('name', name)
    formData.append('position', position)
    formData.append('sex', sex)
    formData.append('nrc', nrc)
    formData.append('declarationDate', declarationDate)
    formData.append(
      'signatoriesToTheApplicationName',
      signatoriesToTheApplicationName
    )
    formData.append(
      'signatoriesToTheApplicationPosition',
      signatoriesToTheApplicationPosition
    )
    formData.append(
      'signatoriesToTheApplicationNrc',
      signatoriesToTheApplicationNrc
    )
    formData.append('projectCommitteeMembersFile', projectCommitteeMembersFile)
    formData.append(
      'projectCommitteeMembersMinuteFile',
      projectCommitteeMembersMinuteFile
    )
    formData.append('ProjectProposerName', ProjectProposerName)
    formData.append('ProjectProposerNRC', ProjectProposerNRC)
    formData.append('ProjectProposerAddress', ProjectProposerAddress)
    formData.append('ProjectProposerPhone', ProjectProposerPhone)
    formData.append('ProjectProposerDate', ProjectProposerDate)
    formData.append('SeconderName', SeconderName)
    formData.append('SeconderNRC', SeconderNRC)
    formData.append('SeconderAddress', SeconderAddress)
    formData.append('SeconderPhone', SeconderPhone)
    formData.append('SeconderDate', SeconderDate)
    formData.append(
      'recommendationByTheWardDevelopmentCommittee',
      recommendationByTheWardDevelopmentCommittee
    )
    formData.append(
      'recommendationByTheWardDevelopmentCommitteeReason',
      recommendationByTheWardDevelopmentCommitteeReason
    )

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }

  */

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`
  const timeNow =
    currentDate.getHours() +
    ':' +
    currentDate.getMinutes() +
    ':' +
    currentDate.getSeconds()

    const handleHome = async e => {
      navigate('/')
        }
        const handleApplicationProgressReport = async e => {
          navigate('/ApplyForGrantsProgress')
        }
        const handleLogout = async e => {
          navigate('/Logout')
        }
        const handleAccount = async e => {
          navigate('/AccountGrants')
        }

        var [sectionAVisibility, setSectionAVisibility] = useState('');
        var [sectionBVisibility, setSectionBVisibility] = useState('');
        var [sectionCVisibility, setSectionCVisibility] = useState('');
//
const showSectionA = async e => {
  setSectionAVisibility('Show');
  setSectionBVisibility('Hidden');
  setSectionCVisibility('Hidden');
}
const showSectionB = async e => {
		var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'grantapplicationformforyouthwomenandcommunityempowerment')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionA')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		  //alert('Completed Section A, Going To Section B To Complete Registration');
  setSectionAVisibility('Hidden');
  setSectionBVisibility('Show');
  setSectionCVisibility('Hidden');
	  }
      })

}
const showSectionC = async e => {
		var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'grantapplicationformforyouthwomenandcommunityempowerment')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionB')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		  //alert('Completed Section A, Going To Section B To Complete Registration');
  setSectionAVisibility('Hidden');
  setSectionBVisibility('Hidden');
  setSectionCVisibility('Show');
	  }
      })

}
const handleFinish = async e => {
var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'grantapplicationformforyouthwomenandcommunityempowerment')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionC')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		  	   var formData = new FormData(this)
    formData.append('apiType', 'uploadGrantApplicationFormForYouthWomenAndCommunityEmpowerment')
    formData.append('clientID', clientID)
    formData.append('status', 'Pending Approval By WDC')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
	
		  alert('Successfully Completed Registration');
	  }
      })
}

useEffect(() => {
  var formData = new FormData(this)
  formData.append(
    'apiType',
    'getApplicantGrantsForYouthWomenAndCommunityEmpowermentView'
  )
  formData.append(
    'id',
    clientID
  )
  //formData.append('table', 'applyforcommunityproject')
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    //setDBtableViewWardName(response.data)
    setgrantType(response.data.grantType)
    setgrantAmount(response.data.grantAmount)
    setnameOfGroupMakingApplication(
      response.data.nameOfGroupMakingApplication
    )
    setprovinceDB(response.data.province)
    setcouncilDB(response.data.council)
    setconstituencyDB(response.data.constituency)
    setwardDB(response.data.ward)
    setzone(response.data.zone)
    setphysicalAddress(response.data.physicalAddress)
    setdateWhenGroupWasRegisteredWithRelevantAuthorities(
      response.data.dateWhenGroupWasRegisteredWithRelevantAuthorities
    )
    setexperienceInAProjectOfSimilarNature(
      response.data.experienceInAProjectOfSimilarNature
    )
    setexperienceInAProjectOfSimilarNatureYesReason(
      response.data.experienceInAProjectOfSimilarNatureYesReason
    )
    setwhatAreTheMainProblemsInYourCommunity(
      response.data.whatAreTheMainProblemsInYourCommunity
    )
    setwhatProblemIsTheProjectGoingToAddress(
      response.data.whatProblemIsTheProjectGoingToAddress
    )
    sethowDidTheGroupIdentifyTheProject(
      response.data.howDidTheGroupIdentifyTheProject
    )
    setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile(
      response.data.doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
    )
   // setprojectIdentificationMinutesFile(
     // response.data.projectIdentificationMinutesFile
    //)
    setprojectIdentificationMinutes(
      response.data.projectIdentificationMinutes
    )
    sethowWillTheProjectBenefitTheCommunity(
      response.data.howWillTheProjectBenefitTheCommunity
    )
    sethowManyDirectJobsWillBeCreated(
      response.data.howManyDirectJobsWillBeCreated
    )
    sethaveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years(
      response.data.haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
    )
    setorganisationA(response.data.organisationA)
    setorganisationAAmount(response.data.organisationAAmount)
    setorganisationB(response.data.organisationB)
    setorganisationBAmount(response.data.organisationBAmount)
    setwhatIsTheStatusOfTheLoanTaken(
      response.data.whatIsTheStatusOfTheLoanTaken
    )
    setbankName(response.data.bankName)
    setbranch(response.data.branch)
    setsortOrBranchCode(response.data.sortOrBranchCode)
    setswiftCode(response.data.swiftCode)
    setaccountNumber(response.data.accountNumber)
    setTPIN(response.data.TPIN)
    setmobileMoneyWalletNameAndNumber(
      response.data.mobileMoneyWalletNameAndNumber
    )
    sethasyourGroupReceivedAnyTraining(
      response.data.hasyourGroupReceivedAnyTraining
    )
    setifTrainedHowManyMembers(response.data.ifTrainedHowManyMembers)
    setifYesFromWhichOrganizationAndHowLongWasTheTraining(
      response.data.ifYesFromWhichOrganizationAndHowLongWasTheTraining
    )
    settypeOrUploadProjectCommitteemembers(
      response.data.typeOrUploadProjectCommitteemembers
    )
    setname(response.data.name)
    setposition(response.data.position)
    setsex(response.data.sex)
    setnrc(response.data.nrc)
    setdeclarationDate(response.data.declarationDate)
   
    //setprojectCommitteeMembersFile(response.data.projectCommitteeMembersFile)
    //setprojectCommitteeMembersMinuteFile(
      //response.data.projectCommitteeMembersMinuteFile
    //)
    setProjectProposerName(response.data.ProjectProposerName)
    setProjectProposerNRC(response.data.ProjectProposerNRC)
    setProjectProposerAddress(response.data.ProjectProposerAddress)
    setProjectProposerPhone(response.data.ProjectProposerPhone)
    setProjectProposerDate(response.data.ProjectProposerDate)
    setSeconderName(response.data.SeconderName)
    setSeconderNRC(response.data.SeconderNRC)
    setSeconderAddress(response.data.SeconderAddress)
    setSeconderPhone(response.data.SeconderPhone)
    setSeconderDate(response.data.SeconderDate)
    //setapplicationDate(response.data.applicationDate)

    
    //alert(response.data)
  }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
}, [])
  return (
    <>
      {/*<!-- Main layout -->*/}
      <div>
        <div class='container-fluid '>
          <div class='row mt-2' style={{ paddingTop: '5%' }}>
            {/* <!--Grid column-->*/}
            <div class='col-md-12 mb-0.00001'>
              {/* <!--Card-->*/}
              <div class='card'>
                {/*<!--Card content-->*/}
                <div class='card-body'>
                  <div
                    class='form-header white-text text-uppercase'
                    style={{ backgroundColor: 'green' }}
                  >
                    <center>
                     {userName} - Application Form
                    </center>
                  </div>
                 
                  <section>
                  <div class="row mt-1 mb-5">
              <div class="col-md-3 text-left">
              <span type = 'button'
                onClick={handleHome}
                ><i class='w-fa fas fa-home'></i>Home </span>
              </div>

              <div class="col-md-3 text-left">
              <span  type = 'button'
                onClick={handleApplicationProgressReport}
                ><FontAwesomeIcon icon={faStairs} />Application Processing Progress </span>
              </div>
              <div class="col-md-3 text-left">
              <span type = 'button'
                onClick={handleAccount}
                > <i class='w-fa fas fa-user'></i>Account </span>
              </div>

              <div class="col-md-3 text-left">
              <span type = 'button'
                onClick={handleLogout}
                > <i class='w-fa fas fa-sign-out-alt'></i>Logout </span>
              </div>
            </div> 

                    <div class='text-left'>
                      <center>
                        <img src={require('./img/coat of arms.jpg')} />
                        <strong>
                          <br />
                          CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> GRANT
                          APPLICATION FORM FOR YOUTH, WOMEN AND COMMUNITY
                          EMPOWERMENT
                        </strong>
                      </center>
                      <br />
                      <strong>Instructions:</strong> This application form
                      should be completed by the applicant and sent together
                      with supporting documents to the Chairperson, Ward
                      Development Committee. <br />
                      <u class='text-left'>
                        <b>NOTE:</b>
                      </u>{' '}
                      This form is not for sale.
                      <br />
                      <i class='text-left'>
                        Disclaimer: Completion of the form does not guarantee
                        the award of the Grant.
                      </i>
                    </div>

                    <br />
                    <div
                    hidden={sectionAVisibility === 'Hidden'}
                      class='text-left'
                      style={{ border: '1px solid black' }}
                    >
                      Seed money to Cooperatives, Clubs and Organised Groups to
                      support Community Savings Groups (e.g. Village Banking and
                      Chilimba)
                      <br />
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Grant Type <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={grantType}
                            onChange={e => setgrantType(e.target.value)}
                            id='grantType'
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <div hidden={sectionAVisibility === 'Hidden'}
                    style={{ border: '1px solid black' }}>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Grant Amount (ZMW) <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={grantAmount}
                            onChange={e => setgrantAmount(e.target.value)}
                            id='grantAmount'
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </section>
                  <br />
                  <div hidden={sectionAVisibility === 'Hidden'}
                   class='col-lg-3 col-md-12 mb-1 text-left'>
                    {/*<!--<div class="md-form">-->*/}
                    <label for='registerFullName'>
                      Application Date<span class='red-text'>*</span>{' '}
                    </label>
                    <input
                      type='text'
                      class='form-control'
                      placeholder={dateToday}
                      disabled
                    />
                    {/*<!--</div>-->*/}
                  </div>
                  <br />
                  <section hidden={sectionAVisibility === 'Hidden'}>
                  {/*<!--\form heading -->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION A: GENERAL DETAILS{' '}
                        <span class='yellow-text'>
                          (To be filled by the applicant)
                        </span>{' '}
                      </h6>
                    </center>
                  </div>
                  <br />
                  {/*<!--Grid row-->			*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*<!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Name of Club/Organised Group/Enterprise/Cooperative
                        making application <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        Placeholder=''
                        value={nameOfGroupMakingApplication}
                        onChange={e =>
                          setnameOfGroupMakingApplication(e.target.value)
                        }
                        id='nameOfGroupMakingApplication'
                        required
                      />

                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->				*/}
                  <center>
                    <h4>
                      <u>PROJECT LOCATION </u>
                    </h4>
                  </center>
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/* <!--Grid column -->*/}
                    <div class='col-lg-4 col-md-12 mb-1 text-left'>
                      <label for='registerFullName'>
                        Provinces<span class='red-text'>*</span> {provinceDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setprovince(e.target.value)}
                        id='province'
                        required
                      >
                        <option value=''>Choose ....</option>
                        {provinceDropDown.map(valProvince => {
                          return (
                            <option value={valProvince.provincesID}>
                              {valProvince.province}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01 text-left'
                      id='hideLocalAuthority'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Council<span class='red-text'>*</span> {councilDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setcouncil(e.target.value)}
                        id='province'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {councilDropDown.map(valCouncil => {
                          return (
                            <option value={valCouncil.councilsID}>
                              {valCouncil.council}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowlocalAuthority'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left'
                      id='hideConstituency'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Constituency<span class='red-text'>*</span> {constituencyDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setconstituency(e.target.value)}
                        id='constituency'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {constituencyDropDown.map(valConstituency => {
                          return (
                            <option value={valConstituency.constituenciesID}>
                              {valConstituency.constituency}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowConstituency'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left'
                      id='hideWard'
                    >
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Ward<span class='red-text'>*</span> {wardDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setward(e.target.value)}
                        id='ward'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {wardDropDown.map(valWard => {
                          return (
                            <option value={valWard.wardsID}>
                              {valWard.ward}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowWard'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Zone<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={zone}
                          onChange={e => setzone(e.target.value)}
                          id='zone'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-5 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Physical Address<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={physicalAddress}
                          onChange={e => setphysicalAddress(e.target.value)}
                          id='physicalAddress'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-12 col-md-12 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date when Club/Organised Group/Enterprise/Cooperative
                        was registered with relevant authorities
                        <span class='red-text'></span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={
                          dateWhenGroupWasRegisteredWithRelevantAuthorities
                        }
                        onChange={e =>
                          setdateWhenGroupWasRegisteredWithRelevantAuthorities(
                            e.target.value
                          )
                        }
                        id='dateWhenGroupWasRegisteredWithRelevantAuthorities'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->			*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-0.001 text-left'>
                      <label for='registerFullName'>
                        Does the Club/Organised Group/Enterprise/Cooperative
                        have any experience in a project of similar nature?{' '}
                        <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={experienceInAProjectOfSimilarNature}
                        onChange={e =>
                          setexperienceInAProjectOfSimilarNature(e.target.value)
                        }
                        id='experienceInAProjectOfSimilarNature'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--\Grid column-->*/}
                    {experienceInAProjectOfSimilarNature === 'No' ||
                    experienceInAProjectOfSimilarNature === '' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-0.001'
                          id='hideExperienceInAProjectOfSimilarNatureYesReason'
                        >
                          {/*<!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            <span class='red-text'></span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder='(If yes please give details)'
                            disabled
                          />
                          {/*<!--</div>-->*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <!--Grid column -->*/}
                    {experienceInAProjectOfSimilarNature === 'Yes' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-3 text-left'
                          id='divShowExperienceInAProjectOfSimilarNatureYesReason'
                        >
                          <label for='registerFullName'>
                            (If yes please give details)
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={experienceInAProjectOfSimilarNatureYesReason}
                            onChange={e =>
                              setexperienceInAProjectOfSimilarNatureYesReason(
                                e.target.value
                              )
                            }
                            id='experienceInAProjectOfSimilarNatureYesReason'
                            required
                          ></textarea>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--Grid row-->*/}
                  
                  <div class='row'>

                  <div class='col-md-12 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionB}
                  >
                    <FontAwesomeIcon icon={faForwardFast} /> 
  <span class='pl-2'>Next</span>
                  </button>
                  </div>
                </div>
                  </section>

                  <section hidden={sectionBVisibility === '' || sectionBVisibility === 'Hidden'}>
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION B: PROJECT IDENTIFICATION{' '}
                      </h6>
                    </center>
                  </div>
                  {/*      <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        What Are The Main Problems In Your Community?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder='Explain'
                        value={whatAreTheMainProblemsInYourCommunity}
                        onChange={e =>
                          setwhatAreTheMainProblemsInYourCommunity(
                            e.target.value
                          )
                        }
                        id='whatAreTheMainProblemsInYourCommunity'
                        required
                      ></textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*   <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        What Problem Is The Project Going To Address?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder='Explain'
                        value={whatProblemIsTheProjectGoingToAddress}
                        onChange={e =>
                          setwhatProblemIsTheProjectGoingToAddress(
                            e.target.value
                          )
                        }
                        id='whatProblemIsTheProjectGoingToAddress'
                        required
                      ></textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        How Did The Group Identify The Project? (Attach minutes
                        where applicable) <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={howDidTheGroupIdentifyTheProject}
                        onChange={e =>
                          sethowDidTheGroupIdentifyTheProject(e.target.value)
                        }
                        id='howDidTheGroupIdentifyTheProject'
                        required
                      ></textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*   <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Do You Want To Paste The Minutes Here Or You Want To
                        Upload Them On A file <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={
                          doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
                        }
                        onChange={e =>
                          setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile(
                            e.target.value
                          )
                        }
                        id='doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Paste</option>
                        <option>Upload File</option>
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->	*/}
                    {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                    'Upload File' ? (
                      <>
                        <div
                          class='col-md-12 mb-1 text-left'
                          id='projectIdentificationUploadFile'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Upload The Miniutes File{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <br />
                            <br />
                            <input
                              type='file'
                              class='form-control'
                              placeholder=''
                              //value={projectIdentificationMinutesFile}
                              //onChange={e =>
                              //setprojectIdentificationMinutesFile(
                              //e.target.value
                              //)
                              //}
                              onChange={saveProjectIdentificationMinutesFile}
                              id='projectIdentificationMinutesFile'
                            />
                          </div>
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*  <!--\Grid column-->	*/}

                    {/* <!--Grid column-->	*/}
                    {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                    'Paste' ? (
                      <>
                        {' '}
                        <div
                          class='col-md-12 mb-1'
                          id='projectIdentificationPaste'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Paste The Miniutes Here{' '}
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <br />
                            <textarea
                              class='form-control'
                              Placeholder=''
                              value={projectIdentificationMinutes}
                              onChange={e =>
                                setprojectIdentificationMinutes(e.target.value)
                              }
                              id='projectIdentificationMinutes'
                              required
                            ></textarea>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->   */}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-8 mb-1 text-left'>
                      {/*    <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        How Will The Project Benefit The Community?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={howWillTheProjectBenefitTheCommunity}
                        onChange={e =>
                          sethowWillTheProjectBenefitTheCommunity(
                            e.target.value
                          )
                        }
                        id='howWillTheProjectBenefitTheCommunity'
                        required
                      ></textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*    <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-4 mb-1 text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        How Many Direct Jobs Will Be Created?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <input
                        type='number'
                        class='form-control'
                        Placeholder=''
                        value={howManyDirectJobsWillBeCreated}
                        onChange={e =>
                          sethowManyDirectJobsWillBeCreated(e.target.value)
                        }
                        id='howManyDirectJobsWillBeCreated'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*    <!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row-->*/}

                  <div class='row'>
                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionA}
                  >
                    
  <span class='pr-2'>Previous</span>
  <FontAwesomeIcon icon={faBackwardFast} /> 
                  </button>
                  </div>

                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionC}
                  >
                    <FontAwesomeIcon icon={faForwardFast} /> 
  <span class='pl-2'>Next</span>
                  </button>
                  </div>
                </div>
                  </section>

                  <section hidden={sectionCVisibility === '' || sectionCVisibility === 'Hidden'}>
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION C: FINANCIAL ASSESSMENT{' '}
                      </h6>
                    </center>
                  </div>
                  {/* <!--Grid row-->				*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-0.001 text-left'>
                      <label for='registerFullName'>
                        Have You Taken Any Loan From Any Organisation In The
                        Last 3 Years? <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={
                          haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
                        }
                        onChange={e =>
                          sethaveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years(
                            e.target.value
                          )
                        }
                        id='haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/* <!--\Grid column-->*/}
                    {haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years ===
                      'No' ||
                    haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years ===
                      '' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-0.001 text-left'
                          id='hideHaveYouTakenAnyLoanFromAnyOrganisationInTheLast3YearsYesReason'
                        >
                          <br />
                          <center>
                            <h6 class='mt-2'>
                              If yes, from which organisation and how much was
                              the loan?
                            </h6>
                          </center>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              <span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Organisation A'
                              disabled
                            />
                          </div>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              <span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Organisation B'
                              disabled
                            />
                          </div>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              <span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='If yes, what is the status of the loan taken?'
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--Grid column -->*/}
                  </div>
                  {/*  <!--Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  {haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years ===
                  'Yes' ? (
                    <>
                      {' '}
                      <div
                        class='row'
                        id='divShowHaveYouTakenAnyLoanFromAnyOrganisationInTheLast3YearsYesReason'
                      >
                        {/* <!--Grid column-->		*/}
                        <div class='col-lg-12 col-md-12 mb-3 text-left'>
                          <center>
                            <h6 class='mt-3'>
                              If yes, from which organisation and how much was
                              the loan?
                            </h6>
                          </center>
                        </div>
                        <div class='col-lg-6 col-md-12 mb-3 text-left'>
                          <label for='registerFullName'>
                            Organisation A<span class='red-text'>*</span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={organisationA}
                            onChange={e => setorganisationA(e.target.value)}
                            id='organisationA'
                            required
                          />
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/* <!--Grid column-->			*/}
                        <div class='col-lg-6 col-md-12 mb-3 text-left'>
                          <label for='registerFullName'>
                            Organisation A Amount
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={organisationAAmount}
                            onChange={e =>
                              setorganisationAAmount(e.target.value)
                            }
                            id='organisationAAmount'
                            required
                          />
                        </div>
                        {/*<!--\Grid column-->*/}
                        {/*<!--Grid column-->			*/}
                        <div class='col-lg-3 col-md-12 mb-3 text-left'>
                          <label for='registerFullName'>
                            Organisation B<span class='red-text'>*</span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={organisationB}
                            onChange={e => setorganisationB(e.target.value)}
                            id='organisationB'
                            required
                          />
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*<!--Grid column-->		*/}
                        <div class='col-lg-3 col-md-12 mb-3 text-left'>
                          <label for='registerFullName'>
                            Organisation B Amount
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={organisationBAmount}
                            onChange={e =>
                              setorganisationBAmount(e.target.value)
                            }
                            id='organisationBAmount'
                            required
                          />
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*<!--Grid column-->			*/}
                        <div class='col-lg-6 col-md-12 mb-3 text-left'>
                          <label for='registerFullName'>
                            What Is The Status Of The Loan Taken?
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={whatIsTheStatusOfTheLoanTaken}
                            onChange={e =>
                              setwhatIsTheStatusOfTheLoanTaken(e.target.value)
                            }
                            id='whatIsTheStatusOfTheLoanTaken'
                            required
                          />
                        </div>
                        {/*  <!--\Grid column-->*/}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*<!--Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 mb-0.001'>
                      <center>
                        <h6>
                          Provide Bank Account Or Mobile Money Wallet Registered
                          For Your Club/Group/Enterprise/Cooperative:
                        </h6>
                      </center>
                    </div>

                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Bank Name<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankName}
                          onChange={e => setbankName(e.target.value)}
                          id='bankName'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->*/}
                    {/*<!--Grid column-->			*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          branch<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={branch}
                          onChange={e => setbranch(e.target.value)}
                          id='branch'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Sort / Branch Code<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={sortOrBranchCode}
                          onChange={e => setsortOrBranchCode(e.target.value)}
                          id='sortOrBranchCode'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Swift Code<span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={swiftCode}
                          onChange={e => setswiftCode(e.target.value)}
                          id='swiftCode'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Account Number<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={accountNumber}
                          onChange={e => setaccountNumber(e.target.value)}
                          id='accountNumber'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          TPIN<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={TPIN}
                          onChange={e => setTPIN(e.target.value)}
                          id='TPIN'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001 text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Mobile Money Wallet Name And Number
                        <span class='red-text'></span>{' '}
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={mobileMoneyWalletNameAndNumber}
                        onChange={e =>
                          setmobileMoneyWalletNameAndNumber(e.target.value)
                        }
                        id='mobileMoneyWalletNameAndNumber'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->			*/}
                    <div class='col-lg-12 col-md-12 mb-0.001 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Has your Club/Group/Enterprise/Cooperative received any
                        training in any of the following;
                        <br />
                        (a) Entrepreneurship/Business Skills
                        <br />
                        (b) Technical and Vocational Skills
                        <br />
                        (c) Savings
                        <br />
                        (d) Functional Literacy
                        <br />
                        (e) Financial literacy
                        <span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={hasyourGroupReceivedAnyTraining}
                        onChange={e =>
                          sethasyourGroupReceivedAnyTraining(e.target.value)
                        }
                        id='hasyourGroupReceivedAnyTraining'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->		*/}

                    {/*<!--\Grid column-->*/}
                    {hasyourGroupReceivedAnyTraining === 'No' ||
                    hasyourGroupReceivedAnyTraining === '' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-0.001'
                          id='hideHasyourGroupReceivedAnyTrainingYesReason'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              <span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='If Trained, How Many Members?'
                              disabled
                            />
                          </div>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              <span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='If Yes, From Which Organization And How Long Was The Training?'
                              disabled
                            />
                          </div>
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--Grid column -->*/}
                  </div>
                  {/* <!--Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  {hasyourGroupReceivedAnyTraining === 'Yes' ? (
                    <>
                      {' '}
                      <div
                        class='row'
                        id='divShowHasyourGroupReceivedAnyTrainingYesReason'
                      >
                        {/* <!--Grid column-->		*/}
                        <div class='col-lg-12 col-md-12 mb-0.001'>
                          <br />
                          <center>
                            <h6 class='mt-3'>If Trained</h6>
                          </center>
                        </div>
                        <div class='col-lg-6 col-md-12 mb-3'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              How Many Members?<span class='red-text'>*</span>{' '}
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={ifTrainedHowManyMembers}
                              onChange={e =>
                                setifTrainedHowManyMembers(e.target.value)
                              }
                              id='ifTrainedHowManyMembers'
                              required
                            />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/*<!--Grid column-->	*/}
                        <div class='col-lg-6 col-md-12 mb-3'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              From Which Organization And How Long Was The
                              Training?<span class='red-text'>*</span>{' '}
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={
                                ifYesFromWhichOrganizationAndHowLongWasTheTraining
                              }
                              onChange={e =>
                                setifYesFromWhichOrganizationAndHowLongWasTheTraining(
                                  e.target.value
                                )
                              }
                              id='ifYesFromWhichOrganizationAndHowLongWasTheTraining'
                              required
                            />
                          </div>
                        </div>
                        {/*<!--\Grid column-->		*/}
                      </div>{' '}
                    </>
                  ) : (
                    <></>
                  )}
                  {/*<!--Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Are You Going To Type or Upload The List Of Project
                        Committee Members <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={typeOrUploadProjectCommitteemembers}
                        onChange={e =>
                          settypeOrUploadProjectCommitteemembers(e.target.value)
                        }
                        id='typeOrUploadProjectCommitteemembers'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Type</option>
                        <option>Upload</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->			*/}
                  {typeOrUploadProjectCommitteemembers === 'Type' ? (
                    <>
                      {' '}
                      <div class='' id='projectCommitteeMembersType'>
                        {/*<!--Grid column-->*/}
                        <div class='col-md-12 mt-3'>
                          <center>
                            <i>
                              List Of Membership In The
                              Club/Group/Enterprise/Cooperative:
                            </i>
                          </center>
                        </div>
                        {/*<!--\Grid column--> */}

                        {/*for(projectCommitteeMembersTypeCount = 1 ; projectCommitteeMembersTypeCount <= 10 ; projectCommitteeMembersTypeCount ++ ) */}
                        {/*{*/}
                        {/*<!--Grid column-->*/}
                        <div class='col-md-1 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              {' '}
                              {/* $projectCommitteeMembersTypeCount; */}
                              <span class='red-text'></span>
                            </label>
                          </div>
                        </div>
                        {/*<!--\Grid column--> */}

                        {/*<!--Grid column-->*/}
                        {inputList.map((x, i) => {
                          return (
                            <>
                              <div class='row'>
                                <div class='col-md-4 mb-1'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      Name
                                      <span class='red-text'>*</span>
                                    </label>
                                    <br />
                                    <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      id='name'
                                      name='name'
                                      value={x.name}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </div>
                                </div>

                                <div class='col-md-3 mb-1'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      Position
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br />
                                    <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      id='position'
                                      name='position'
                                      value={x.position}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </div>
                                </div>
                                {/*<!--\Grid column--> */}
                                {/*<!--Grid column-->*/}
                                <div class='col-lg-2 col-md-2 mb-2 text-left'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      {' '}
                                      Sex <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br /> <br /> <br />
                                    <select
                                      class='custom-select d-block w-100'
                                      id='sex'
                                      name='sex'
                                      value={x.sex}
                                      onChange={e => handleInputChange(e, i)}
                                    >
                                      <option value=''>Choose...</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </div>
                                </div>
                                {/* <!--\Grid column-->	*/}
                                {/*<!--Grid column-->*/}
                                <div class='col-md-2 mb-1'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      NRC
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br />
                                    <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      id='nrc'
                                      name='nrc'
                                      value={x.nrc}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </div>
                                </div>

                                {inputList.length !== 1 && (
                                  <div class='col-lg-1 col-md-2 mb-2'>
                                    <div class='md-form'>
                                      <input
                                        type='button'
                                        class='form-control btn white-text red accent-2'
                                        value='Remove'
                                        onClick={() => handleRemoveClick(i)}
                                      />
                                    </div>
                                  </div>
                                )}
                                {/*<!--\Grid column-->	*/}

                                {/*<!--Grid column-->*/}
                                {inputList.length - 1 === i && (
                                  <div class='col-lg-12 col-md-2 mb-2'>
                                    <div class='md-form'>
                                      <input
                                        type='button'
                                        class='form-control btn white-text blue accent-2'
                                        value='Add'
                                        onClick={handleAddClick}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        })}
                        {/*<!--\Grid column--> 		*/}

                        {/*}*/}

                        <div class='col-md-12 mb-1'>
                          <center>
                            <h4>DECLARATION</h4>
                          </center>
                          <i>
                            We the undersigned, on{' '}
                            <input
                              type='date'
                              value={declarationDate}
                              onChange={e => setdeclarationDate(e.target.value)}
                              id='declarationDate'
                            />
                            declare that the information given herein is the
                            correct state of affairs to the best of my
                            knowledge. We will take full responsibility in the
                            event of abuse, mismanagement, defrauding of the
                            funds provided under this empowerment fund:
                          </i>
                        </div>
                        <div class='col-md-12 mb-0.001'>
                          <br />{' '}
                          <center>
                            <h4>SIGNATORIES TO THE APPLICATION</h4>
                          </center>{' '}
                        </div>

                        {/*for($signatoriesToTheApplicationCount = 1 ; $signatoriesToTheApplicationCount <= 5 ; $signatoriesToTheApplicationCount ++ ) */}
                        {/*{*/}

                        {/*<!--Grid column-->*/}
                        <div class='col-md-1 mb-0.001'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              {' '}
                              {/*signatoriesToTheApplicationCount; */}
                              <span class='red-text'></span>
                            </label>
                          </div>
                        </div>
                        {/*<!--\Grid column--> */}
                        {/*<!--Grid column-->*/}

                        {signatoriesList.map((SinglesignatoriesList, index) => {
                          return (
                            <>
                              <div class='row'>
                                <div class='col-md-4 mb-0.001'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      Name
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br />
                                    <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      value={
                                        SinglesignatoriesList.signatoriesName
                                      }
                                      id='signatoriesName'
                                      name='signatoriesName'
                                      onChange={e =>
                                        handleSignatoriesChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>

                                <div class='col-md-4 mb-0.001'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      Position
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br />
                                    <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      value={
                                        SinglesignatoriesList.signatoriesPosition
                                      }
                                      id='signatoriesPosition'
                                      name='signatoriesPosition'
                                      onChange={e =>
                                        handleSignatoriesChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>

                                <div class='col-md-3 mb-0.001'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      NRC
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br />
                                    <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      value={
                                        SinglesignatoriesList.signatoriesNrc
                                      }
                                      id='signatoriesNrc'
                                      name='signatoriesNrc'
                                      onChange={e =>
                                        handleSignatoriesChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>

                                {signatoriesList.length > 1 && (
                                  <div class='col-md-1 mb-1'>
                                    <div class='md-form'>
                                      <br /> <br /> <br />
                                      <input
                                        type='button'
                                        class='form-control btn white-text red accent-2'
                                        value='Remove'
                                        //onClick={handleSignatoriesRemove}
                                        onClick={() =>
                                          handleSignatoriesRemove(index)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}

                                {signatoriesList.length - 1 === index && (
                                  <div class='col-md-12 mb-1'>
                                    <div class='md-form'>
                                      <input
                                        type='button'
                                        class='form-control btn white-text blue accent-2'
                                        value='Add'
                                        onClick={() =>
                                          handleSignatoriesAdd(index)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        })}
                      </div>{' '}
                    </>
                  ) : (
                    <></>
                  )}
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  {typeOrUploadProjectCommitteemembers === 'Upload' ? (
                    <>
                      {' '}
                      <div class='row'>
                        {/*    <!--Grid column-->	*/}
                        <div
                          class='col-md-12 mb-1'
                          id='projectCommitteeMembersUpload'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Upload The project Committee Members File{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <br />
                            <br />
                            <input
                              type='file'
                              class='form-control'
                              placeholder=''
                              //value={projectCommitteeMembersFile}
                              //onChange={e =>
                              //setprojectCommitteeMembersFile(e.target.value)
                              //}
                              onChange={saveProjectCommitteeMembersFile}
                              id='projectCommitteeMembersFile'
                              required
                            />
                          </div>
                        </div>
                        {/*     <!--\Grid column-->	*/}
                      </div>{' '}
                    </>
                  ) : (
                    <></>
                  )}
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->	*/}
                    <div
                      class='col-md-12 mb-1'
                      id='projectCommitteeMembersUpload'
                      style={{ display: 'none' }}
                    >
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Upload The Minutes File{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <br />
                        <br />
                        <input
                          type='file'
                          class='form-control'
                          placeholder=''
                          value={projectCommitteeMembersMinuteFile}
                          onChange={e =>
                            setprojectCommitteeMembersMinuteFile(e.target.value)
                          }
                          id='projectCommitteeMembersMinuteFile'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <br />
                  <div class='col-md-12 mb-3 mb-5'>
                    <center>
                      <h4 class='red-text'>
                        Note: In the case where you have multiple members, the
                        signatories to the application must be limited up to 5
                        members.
                      </h4>
                    </center>
                  </div>
                  <h3>
                    <center>CONTACT PERSON(s): </center>
                  </h3>
                  <br />
                  <center>
                    <h4 class='red-text'>(Project Proposer)</h4>
                  </center>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*     <!--<div class="md-form">--->*/}
                      <label for='registerFullName'>
                        Name <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerName}
                        onChange={e => setProjectProposerName(e.target.value)}
                        id='ProjectProposerName'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 				*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        NRC <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerNRC}
                        onChange={e => setProjectProposerNRC(e.target.value)}
                        id='ProjectProposerNRC'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Address <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerAddress}
                        onChange={e =>
                          setProjectProposerAddress(e.target.value)
                        }
                        id='ProjectProposerAddress'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 		*/}
                    {/*   <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Phone <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerPhone}
                        onChange={e => setProjectProposerPhone(e.target.value)}
                        id='ProjectProposerPhone'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date <span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control datepicker'
                        placeholder=''
                        value={ProjectProposerDate}
                        onChange={e => setProjectProposerDate(e.target.value)}
                        id='ProjectProposerDate'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->			*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  <br />
                  <center>
                    <h4 class='red-text'>(Project Seconder)</h4>
                  </center>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*      <!--<div class="md-form">--->*/}
                      <label for='registerFullName'>
                        Name <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={SeconderName}
                        onChange={e => setSeconderName(e.target.value)}
                        id='SeconderName'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        NRC <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={SeconderNRC}
                        onChange={e => setSeconderNRC(e.target.value)}
                        id='SeconderNRC'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Address <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={SeconderAddress}
                        onChange={e => setSeconderAddress(e.target.value)}
                        id='SeconderAddress'
                      />
                      {/*  <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 			*/}
                    {/*   <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Phone <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={SeconderPhone}
                        onChange={e => setSeconderPhone(e.target.value)}
                        id='SeconderPhone'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date <span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control datepicker'
                        placeholder=''
                        value={SeconderDate}
                        onChange={e => setSeconderDate(e.target.value)}
                        id='SeconderDate'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->			*/}
                  </div>
                  {/* <!--\Grid row--> */}

                  <div class='row'>
                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionB}
                  >
                    
  <span class='pr-2'>Previous</span>
  <FontAwesomeIcon icon={faBackwardFast} /> 
                  </button>
                  </div>

                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={handleFinish}
                  >
                    <FontAwesomeIcon icon={faUpload} /> 
  <span class='pl-2'>Finish</span>
                  </button>
                  </div>
                </div>
                  </section>
                  <div
                    class='card-header'
                    style={{ backgroundColor: 'green', display: 'none' }}
                  >
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        SECTION D: RECOMMENDATION BY THE WARD DEVELOPMENT
                        COMMITTEE{' '}
                      </h6>
                    </center>
                  </div>
                  {/*  <!--Grid row--> */}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column--> */}
                    <div class='col-lg-5 col-md-2 mb-2'>
                      <label for='registerFullName'>
                        {' '}
                        Recommendation By The Ward Development Committee{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        disabled
                        class='custom-select d-block w-100'
                        name='recommendationByTheWardDevelopmentCommittee'
                        id='recommendationByTheWardDevelopmentCommittee'
                      >
                        <option value=''>Choose...</option>
                        <option>Recommended</option>
                        <option>Not Recommended</option>
                        <option>Deferred</option>
                      </select>
                    </div>
                    {/*   <!--\Grid column-->				 */}
                    {/*     <!--Grid column-->	*/}
                    <div class='col-md-7 mb-2'>
                      {/*       <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Reasons For Recommendation{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        disabled
                        class='form-control'
                        name='recommendationByTheWardDevelopmentCommitteeReason'
                        id='recommendationByTheWardDevelopmentCommitteeReason'
                        required
                      >
                        {' '}
                      </textarea>
                      {/* <!--</div>--> */}
                    </div>
                    {/*     <!--\Grid column-->				*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column--> */}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Name (WDC Chairperson): <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='wardDevelopmentCommitteeChairpersonName'
                          id='wardDevelopmentCommitteeChairpersonName'
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Sign: <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='wardDevelopmentCommitteeChairpersonSign'
                          id='wardDevelopmentCommitteeChairpersonSign'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      {/*     <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Date: <span class='red-text'></span>
                      </label>
                      <input
                        disabled
                        type='date'
                        class='form-control'
                        placeholder=''
                        name='wardDevelopmentCommitteeChairpersonDate'
                        id='wardDevelopmentCommitteeChairpersonDate'
                      />
                      {/* <!--</div>--> */}
                    </div>
                    {/* <!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  <div
                    class='card-header'
                    style={{ backgroundColor: 'green', display: 'none' }}
                  >
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        SECTION E: DECISION BY THE CONSTITUENCY DEVELOPMENT FUND
                        COMMITTEE{' '}
                      </h6>
                    </center>
                  </div>
                  {/* <!--Grid row--> */}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column--> */}
                    <div class='col-lg-5 col-md-2 mb-2'>
                      <label for='registerFullName'>
                        {' '}
                        Recommendation By The Constituency Development Committee{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        disabled
                        class='custom-select d-block w-100'
                        name='recommendationByTheConstituencyDevelopmentCommittee'
                        id='recommendationByTheConstituencyDevelopmentCommittee'
                      >
                        <option value=''>Choose...</option>
                        <option>Approved</option>
                        <option>Not Approved</option>
                      </select>
                    </div>
                    {/*  <!--\Grid column-->		*/}
                    {/*      <!--Grid column-->	*/}
                    <div class='col-md-7 mb-2'>
                      {/*      <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Reasons For Recommendation{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        disabled
                        class='form-control'
                        name='recommendationByTheConstituencyDevelopmentCommitteeReason'
                        id='recommendationByTheConstituencyDevelopmentCommitteeReason'
                        required
                      >
                        {' '}
                      </textarea>
                      {/* <!--</div>--> */}
                    </div>
                    {/*     <!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row' style={{ display: 'none' }}>
                    {/*<!--Grid column--> */}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Name (CDC Chairperson): <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='constituencyDevelopmentCommitteeChairpersonName'
                          id='constituencyDevelopmentCommitteeChairpersonName'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/* <!--Grid column--> */}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Sign: <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='constituencyDevelopmentCommitteeChairpersonSign'
                          id='constituencyDevelopmentCommitteeChairpersonSign'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date: <span class='red-text'></span>
                      </label>
                      <input
                        disabled
                        type='date'
                        class='form-control'
                        placeholder=''
                        name='constituencyDevelopmentCommitteeChairpersonDate'
                        id='constituencyDevelopmentCommitteeChairpersonDate'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row-->*/}
                  <button
                  hidden
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    onClick={handleSubmit}
                    id='submit'
                  >
                    Submit
                  </button>
                </div>
                {/* <!--/.Card-- br >*/}
              </div>
            </div>
            {/* <!--Grid row-->*/}
          </div>
        </div>
      </div>
      {/*<!-- Main layout -->*/}
    </>
  )
}

export default GrantApplicationFormForYouthWomenAndCommunityEmpowerment
