import { useRef, useState, useEffect, useContext } from 'react'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faAngleLeft,
  faStairs,
  faArrowLeft,
  faArrowRight,
  faForwardStep,
  faForwardFast,
  faBackwardFast,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import $, { nodeName } from 'jquery'
import 'jquery/dist/jquery.min.js'
import { useNavigate } from 'react-router-dom'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const SecondaryBoardingSchoolBursaryApplicationForm = () => {
  let navigate = useNavigate()

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let clientID = localStorage.getItem('userID')
  clientID = JSON.parse(clientID)

  let login = localStorage.getItem('login')
  login = JSON.parse(login)

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if(loggedIn != 'Yes'){
    navigate('/Logout')
  }

  var [provinceDB, setprovinceDB] = useState('')
  if(provinceDB != ''){provinceDB = '('+provinceDB+')'}
  var [councilDB, setcouncilDB] = useState('')
  if(councilDB != ''){councilDB = '('+councilDB+')'}
  var [constituencyDB, setconstituencyDB] = useState('')
  if(constituencyDB != ''){constituencyDB = '('+constituencyDB+')'}
  var [wardDB, setwardDB] = useState('')
  if(wardDB != ''){wardDB = '('+wardDB+')'}

  const [surname, setsurname] = useState('')
  const [firstName, setfirstName] = useState('')
  const [otherNames, setotherNames] = useState('')
  const [gender, setgender] = useState('')
  const [dateOfBirth, setdateOfBirth] = useState('')
  const [birthCertificate, setBirthCertificate] = useState('')
  const [nationality, setnationality] = useState('')
  const [nrc, setnrc] = useState('')
  const [province, setprovince] = useState('')
  const [provinceDropDown, setprovinceDropDown] = useState([])
  const [council, setcouncil] = useState('')
  const [councilDropDown, setcouncilDropDown] = useState([])
  const [constituency, setconstituency] = useState('')
  const [constituencyDropDown, setconstituencyDropDown] = useState([])
  const [ward, setward] = useState('')
  const [wardDropDown, setwardDropDown] = useState([])
  const [zone, setzone] = useState('')
  const [villageOrTownship, setvillageOrTownship] = useState('')
  const [doYouHaveADisability, setdoYouHaveADisability] = useState('')
  const [doYouHaveADisabilityYesReason, setdoYouHaveADisabilityYesReason] =
    useState('')
  const [doYouHaveADisabilityYesFile, setDoYouHaveADisabilityYesFile] =
    useState('')
  const [
    nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted,
    setnameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
  ] = useState('')
  const [lastSchoolAttended, setlastSchoolAttended] = useState('')
  const [lastGradeAttended, setlastGradeAttended] = useState('')
  const [areYouABoarder, setareYouABoarder] = useState('')
  const [whoHasBeenPayingYourSchoolFees, setwhoHasBeenPayingYourSchoolFees] =
    useState('')
  const [
    haveYouBeenSupportedByAnyOrganization,
    sethaveYouBeenSupportedByAnyOrganization
  ] = useState('')
  const [
    haveYouBeenSupportedByAnyOrganizationYesExplanation,
    sethaveYouBeenSupportedByAnyOrganizationYesExplanation
  ] = useState('')
  const [fatherStatus, setfatherStatus] = useState('')
  const [fatherStatusFile, setFatherStatusFile] = useState('')
  const [fatherSurname, setfatherSurname] = useState('')
  const [fatherFirstName, setfatherFirstName] = useState('')
  const [fatherOtherNames, setfatherOtherNames] = useState('')
  const [fatherDateOfBirth, setfatherDateOfBirth] = useState('')
  const [fatherTelephoneNo, setfatherTelephoneNo] = useState('')
  const [fatherResidentialAddress, setfatherResidentialAddress] = useState('')
  const [fatherEmailAddress, setfatherEmailAddress] = useState('')
  const [fatherOccupation, setfatherOccupation] = useState('')
  const [fatherEmployer, setfatherEmployer] = useState('')
  const [fatherDisability, setfatherDisability] = useState('')
  const [pleaseSpecifyFatherDisability, setpleaseSpecifyFatherDisability] =
    useState('')
  const [fatherDisabilityDocumentation, setFatherDisabilityDocumentation] =
    useState('')
  const [fatherMedicalCondition, setfatherMedicalCondition] = useState('')
  const [
    pleaseSpecifyFatherMedicalCondition,
    setpleaseSpecifyFatherMedicalCondition
  ] = useState('')
  const [
    fatherMedicalConditionDocumentation,
    setFatherMedicalConditionDocumentation
  ] = useState('')
  const [motherStatus, setmotherStatus] = useState('')
  const [motherStatusFile, setMotherStatusFile] = useState('')
  const [motherSurname, setmotherSurname] = useState('')
  const [motherFirstName, setmotherFirstName] = useState('')
  const [motherOtherNames, setmotherOtherNames] = useState('')
  const [motherDateOfBirth, setmotherDateOfBirth] = useState('')
  const [motherTelephoneNo, setmotherTelephoneNo] = useState('')
  const [motherResidentialAddress, setmotherResidentialAddress] = useState('')
  const [motherEmailAddress, setmotherEmailAddress] = useState('')
  const [motherOccupation, setmotherOccupation] = useState('')
  const [motherEmployer, setmotherEmployer] = useState('')
  const [motherDisability, setmotherDisability] = useState('')
  const [pleaseSpecifyMotherDisability, setpleaseSpecifyMotherDisability] =
    useState('')
  const [motherDisabilityDocumentation, setMotherDisabilityDocumentation] =
    useState('')
  const [motherMedicalCondition, setmotherMedicalCondition] = useState('')
  const [
    pleaseSpecifyMotherMedicalCondition,
    setpleaseSpecifyMotherMedicalCondition
  ] = useState('')
  const [
    motherMedicalConditionDocumentation,
    setMotherMedicalConditionDocumentation
  ] = useState('')
  const [guardianStatus, setguardianStatus] = useState('')
  const [guardianStatusFile, setGuardianStatusFile] = useState('')
  const [guardianSurname, setguardianSurname] = useState('')
  const [guardianFirstName, setguardianFirstName] = useState('')
  const [guardianOtherNames, setguardianOtherNames] = useState('')
  const [guardianDateOfBirth, setguardianDateOfBirth] = useState('')
  const [guardianTelephoneNo, setguardianTelephoneNo] = useState('')
  const [guardianResidentialAddress, setguardianResidentialAddress] =
    useState('')
  const [guardianEmailAddress, setguardianEmailAddress] = useState('')
  const [guardianOccupation, setguardianOccupation] = useState('')
  const [guardianEmployer, setguardianEmployer] = useState('')
  const [guardianDisability, setguardianDisability] = useState('')
  const [pleaseSpecifyGuardianDisability, setpleaseSpecifyGuardianDisability] =
    useState('')
  const [guardianDisabilityDocumentation, setGuardianDisabilityDocumentation] =
    useState('')
  const [guardianMedicalCondition, setguardianMedicalCondition] = useState('')
  const [
    pleaseSpecifyGuardianMedicalCondition,
    setpleaseSpecifyGuardianMedicalCondition
  ] = useState('')
  const [
    guardianMedicalConditionDocumentation,
    setGuardianMedicalConditionDocumentation
  ] = useState('')
  const [siblingname, setname] = useState('')
  const [siblingsex, setsex] = useState('')
  const [siblingage, setage] = useState('')
  const [siblingoccupation, setoccupation] = useState('')
  const [siblingAliveOrDeceasedStatus, setsiblingAliveOrDeceased] = useState('')

  var DependentsNameArr = []
  var DependentsSexArr = []
  var DependentsAgeArr = []
  var DependentsOccupationArr = []
  const [dependentsServiceList, setDependentsServiceList] = useState([
    {
      dependentsName: '',
      dependentsSex: '',
      dependentsAge: '',
      dependentsOccupation: ''
    }
  ])

  const handleDependentsServiceAdd = () => {
    setDependentsServiceList([
      ...dependentsServiceList,
      {
        dependentsName: '',
        dependentsSex: '',
        dependentsAge: '',
        dependentsOccupation: ''
      }
    ])
  }
  const handleDependentsServiceRemove = dependentsIndex => {
    const dependentsList = [...dependentsServiceList]
    dependentsList.splice(dependentsIndex, 1)
    setDependentsServiceList(dependentsList)

    dependentsList.map(val => {
      DependentsNameArr.push(val.dependentsName)
      DependentsSexArr.push(val.dependentsSex)
      DependentsAgeArr.push(val.dependentsAge)
      DependentsOccupationArr.push(val.dependentsOccupation)
    })
    localStorage.setItem('DependentsName', JSON.stringify(DependentsNameArr))
    localStorage.setItem('DependentsSex', JSON.stringify(DependentsSexArr))
    localStorage.setItem('DependentsAge', JSON.stringify(DependentsAgeArr))
    localStorage.setItem(
      'DependentsOccupation',
      JSON.stringify(DependentsOccupationArr)
    )
    //setItemID(JSON.stringify(rodeen))
  }
  const handleDependentsNameChange = (e, dependentsIndex) => {
    var { name, value } = e.target
    const dependentsList = [...dependentsServiceList]
    dependentsList[dependentsIndex][name] = value
    setDependentsServiceList(dependentsList)

    dependentsList.map(val => {
      DependentsNameArr.push(val.dependentsName)
      DependentsSexArr.push(val.dependentsSex)
      DependentsAgeArr.push(val.dependentsAge)
      DependentsOccupationArr.push(val.dependentsOccupation)
    })
    localStorage.setItem('DependentsName', JSON.stringify(DependentsNameArr))
    localStorage.setItem('DependentsSex', JSON.stringify(DependentsSexArr))
    localStorage.setItem('DependentsAge', JSON.stringify(DependentsAgeArr))
    localStorage.setItem(
      'DependentsOccupation',
      JSON.stringify(DependentsOccupationArr)
    )
    //setItemID(JSON.stringify(rodeen))
    setdependentsName(JSON.stringify(DependentsNameArr))
    setdependentsSex(JSON.stringify(DependentsSexArr))
    setdependentsAge(JSON.stringify(DependentsAgeArr))
    setdependentsOccupation(JSON.stringify(DependentsOccupationArr))
  }

  const [inputList, setInputList] = useState([
    { name: '', sex: '', age: '', occupation: '', siblingAliveOrDeceased: '' }
  ])
  var siblingNameArr = []
  var siblingSexArr = []
  var siblingAgeArr = []
  var siblingOccupationArr = []
  var siblingStatusArr = []

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)

    list.map(val => {
      siblingNameArr.push(val.name)
      siblingSexArr.push(val.sex)
      siblingAgeArr.push(val.age)
      siblingOccupationArr.push(val.occupation)
      siblingStatusArr.push(val.siblingAliveOrDeceased)
    })
    localStorage.setItem('siblingName', JSON.stringify(siblingNameArr))
    localStorage.setItem('siblingSex', JSON.stringify(siblingSexArr))
    localStorage.setItem('siblingAge', JSON.stringify(siblingAgeArr))
    localStorage.setItem(
      'siblingOccupation',
      JSON.stringify(siblingOccupationArr)
    )
    localStorage.setItem('siblingStatus', JSON.stringify(siblingStatusArr))
    //setItemID(JSON.stringify(rodeen))
    setname(JSON.stringify(siblingNameArr))
    setsex(JSON.stringify(siblingSexArr))
    setage(JSON.stringify(siblingAgeArr))
    setoccupation(JSON.stringify(siblingOccupationArr))
    setsiblingAliveOrDeceased(JSON.stringify(siblingStatusArr))
  }
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)

    ///var rodeens = []
    list.map(val => {
      siblingNameArr.push(val.name)
      siblingSexArr.push(val.sex)
      siblingAgeArr.push(val.age)
      siblingOccupationArr.push(val.occupation)
      siblingStatusArr.push(val.siblingAliveOrDeceased)
    })
    localStorage.setItem('siblingName', JSON.stringify(siblingNameArr))
    localStorage.setItem('siblingSex', JSON.stringify(siblingSexArr))
    localStorage.setItem('siblingAge', JSON.stringify(siblingAgeArr))
    localStorage.setItem(
      'siblingOccupation',
      JSON.stringify(siblingOccupationArr)
    )
    localStorage.setItem('siblingStatus', JSON.stringify(siblingStatusArr))
  }
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { name: '', sex: '', age: '', occupation: '', siblingAliveOrDeceased: '' }
    ])
  }

  /*console.log(
    'inputList == ' +
      inputList +
      ' Name Entered Here = ' +
      name +
      ' Sex Entered Here = ' +
      sex +
      ' Age Entered Here = ' +
      age +
      ' Occupation Entered Here = ' +
      occupation +
      ' Status Entered Here = ' +
      siblingAliveOrDeceased
  )*/

  const [dependentsName, setdependentsName] = useState([{}])
  const [dependentsSex, setdependentsSex] = useState([{}])
  const [dependentsAge, setdependentsAge] = useState([{}])
  const [dependentsOccupation, setdependentsOccupation] = useState([{}])
  //const dependentsOccupation = $('#dependentsOccupation')
  //var dependentsOccupation = $('#dependentsOccupation').val([])
  const [house, sethouse] = useState('')
  const [houseOther, sethouseOther] = useState('')
  const [typeOfHouseMainMaterialOfRoof, settypeOfHouseMainMaterialOfRoof] =
    useState('')
  const [typeOfHouseMainMaterialOfFloor, settypeOfHouseMainMaterialOfFloor] =
    useState('')
  const [typeOfHouseMainMaterialOfWall, settypeOfHouseMainMaterialOfWall] =
    useState('')
  const [toilet, settoilet] = useState('')
  const [water, setwater] = useState('')
  const [waterOther, setwaterOther] = useState('')
  const [sourceOfWater, setsourceOfWater] = useState('')
  const [availabilityOfElectricity, setavailabilityOfElectricity] = useState('')
  const [mainSourceOfIncome, setmainSourceOfIncome] = useState('')
  const [numberOfMealsPerDay, setnumberOfMealsPerDay] = useState('')
  const [numberOfMealsPerDayOther, setnumberOfMealsPerDayOther] = useState('')
  const [
    doesYourHouseholdHaveAnyOfTheFollowingDurableItems,
    setdoesYourHouseholdHaveAnyOfTheFollowingDurableItems
  ] = useState('')
  const [
    doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther,
    setdoesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
  ] = useState('')
  const [
    doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal,
    setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
  ] = useState('')
  const [cattle, setcattle] = useState('')
  const [goats, setgoats] = useState('')
  const [sheep, setsheep] = useState('')
  const [pigs, setpigs] = useState('')
  const [poutry, setpoutry] = useState('')
  const [
    doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther,
    setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
  ] = useState('')
  const [learnerName, setlearnerName] = useState('')
  const [learnerAddress, setlearnerAddress] = useState('')
  const [learnerPhone, setlearnerPhone] = useState('')
  const [learnerNRC, setlearnerNRC] = useState('')
  const [contactName, setcontactName] = useState('')
  const [contactAddress, setcontactAddress] = useState('')
  const [contactPhone, setcontactPhone] = useState('')
  const [contactNRC, setcontactNRC] = useState('')
  //
  const saveBirthCertificate = e => {
    setBirthCertificate(e.target.files[0])
  }
  const saveDoYouHaveADisabilityYesFile = e => {
    setDoYouHaveADisabilityYesFile(e.target.files[0])
  }

  const saveFatherStatusFile = e => {
    setFatherStatusFile(e.target.files[0])
  }
  const saveFatherDisabilityDocumentation = e => {
    setFatherDisabilityDocumentation(e.target.files[0])
  }
  const saveFatherMedicalConditionDocumentation = e => {
    setFatherMedicalConditionDocumentation(e.target.files[0])
  }
  const saveMotherStatusFile = e => {
    setMotherStatusFile(e.target.files[0])
  }
  const saveMotherDisabilityDocumentation = e => {
    setMotherDisabilityDocumentation(e.target.files[0])
  }
  const saveMotherMedicalConditionDocumentation = e => {
    setMotherMedicalConditionDocumentation(e.target.files[0])
  }

  const saveGuardianStatusFile = e => {
    setGuardianStatusFile(e.target.files[0])
  }
  const saveGuardianDisabilityDocumentation = e => {
    setGuardianDisabilityDocumentation(e.target.files[0])
  }
  const saveGuardianMedicalConditionDocumentation = e => {
    setGuardianMedicalConditionDocumentation(e.target.files[0])
  }

 

  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setprovinceDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setcouncilDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province]) //councilDropDown

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setconstituencyDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setwardDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council, constituency])

 
	useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('surname', surname)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [surname])
  
  	useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('firstName', firstName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [firstName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('otherNames', otherNames)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [otherNames])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('gender', gender)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [gender])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('dateOfBirth', dateOfBirth)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [dateOfBirth])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('birthCertificate', birthCertificate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [birthCertificate])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('nationality', nationality)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [nationality])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('nrc', nrc)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [nrc])
  
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('province', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [province])
  
   useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('council', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [council])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('constituency', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [constituency])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('ward', ward)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [ward])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('zone', zone)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [zone])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('villageOrTownship', villageOrTownship)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [villageOrTownship])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doYouHaveADisability', doYouHaveADisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doYouHaveADisability])
  
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doYouHaveADisabilityYesReason', doYouHaveADisabilityYesReason)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doYouHaveADisabilityYesReason])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doYouHaveADisabilityYesFile', doYouHaveADisabilityYesFile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doYouHaveADisabilityYesFile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('lastSchoolAttended', lastSchoolAttended)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [lastSchoolAttended])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('lastGradeAttended', lastGradeAttended)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [lastGradeAttended])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('areYouABoarder', areYouABoarder)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [areYouABoarder])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('whoHasBeenPayingYourSchoolFees', whoHasBeenPayingYourSchoolFees)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [whoHasBeenPayingYourSchoolFees])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('haveYouBeenSupportedByAnyOrganization', haveYouBeenSupportedByAnyOrganization)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [haveYouBeenSupportedByAnyOrganization])
  
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('haveYouBeenSupportedByAnyOrganizationYesExplanation', haveYouBeenSupportedByAnyOrganizationYesExplanation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [haveYouBeenSupportedByAnyOrganizationYesExplanation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherStatus', fatherStatus)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherStatus])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherStatusFile', fatherStatusFile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherStatusFile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherSurname', fatherSurname)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherSurname])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherFirstName', fatherFirstName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherFirstName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherOtherNames', fatherOtherNames)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherOtherNames])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherDateOfBirth', fatherDateOfBirth)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherDateOfBirth])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherTelephoneNo', fatherTelephoneNo)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherTelephoneNo])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherResidentialAddress', fatherResidentialAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherResidentialAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherEmailAddress', fatherEmailAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherEmailAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherOccupation', fatherOccupation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherOccupation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherEmployer', fatherEmployer)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherEmployer])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherDisability', fatherDisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherDisability])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pleaseSpecifyFatherDisability', pleaseSpecifyFatherDisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pleaseSpecifyFatherDisability])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherDisabilityDocumentation', fatherDisabilityDocumentation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherDisabilityDocumentation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherMedicalCondition', fatherMedicalCondition)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherMedicalCondition])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pleaseSpecifyFatherMedicalCondition', pleaseSpecifyFatherMedicalCondition)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pleaseSpecifyFatherMedicalCondition])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('fatherMedicalConditionDocumentation', fatherMedicalConditionDocumentation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [fatherMedicalConditionDocumentation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherStatus', motherStatus)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherStatus])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherStatusFile', motherStatusFile)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherStatusFile])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherSurname', motherSurname)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherSurname])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherFirstName', motherFirstName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherFirstName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherOtherNames', motherOtherNames)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherOtherNames])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherDateOfBirth', motherDateOfBirth)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherDateOfBirth])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherTelephoneNo', motherTelephoneNo)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherTelephoneNo])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherResidentialAddress', motherResidentialAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherResidentialAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherEmailAddress', motherEmailAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherEmailAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherOccupation', motherOccupation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherOccupation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherEmployer', motherEmployer)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherEmployer])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherDisability', motherDisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherDisability])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pleaseSpecifyMotherDisability', pleaseSpecifyMotherDisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pleaseSpecifyMotherDisability])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherDisabilityDocumentation', motherDisabilityDocumentation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherDisabilityDocumentation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherMedicalCondition', motherMedicalCondition)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherMedicalCondition])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pleaseSpecifyMotherMedicalCondition', pleaseSpecifyMotherMedicalCondition)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pleaseSpecifyMotherMedicalCondition])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('motherMedicalConditionDocumentation', motherMedicalConditionDocumentation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [motherMedicalConditionDocumentation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianStatus', guardianStatus)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianStatus])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianSurname', guardianSurname)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianSurname])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianFirstName', guardianFirstName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianFirstName])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianOtherNames', guardianOtherNames)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianOtherNames])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianDateOfBirth', guardianDateOfBirth)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianDateOfBirth])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianTelephoneNo', guardianTelephoneNo)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianTelephoneNo])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianResidentialAddress', guardianResidentialAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianResidentialAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianEmailAddress', guardianEmailAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianEmailAddress])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianOccupation', guardianOccupation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianOccupation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianEmployer', guardianEmployer)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianEmployer])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianDisability', guardianDisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianDisability])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pleaseSpecifyGuardianDisability', pleaseSpecifyGuardianDisability)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pleaseSpecifyGuardianDisability])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianDisabilityDocumentation', guardianDisabilityDocumentation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianDisabilityDocumentation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianMedicalCondition', guardianMedicalCondition)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianMedicalCondition])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pleaseSpecifyGuardianMedicalCondition', pleaseSpecifyGuardianMedicalCondition)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pleaseSpecifyGuardianMedicalCondition])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('guardianMedicalConditionDocumentation', guardianMedicalConditionDocumentation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [guardianMedicalConditionDocumentation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('name', siblingname)
	formData.append('sex', siblingsex)
	formData.append('age', siblingage)
	formData.append('occupation', siblingoccupation)
	formData.append('siblingAliveOrDeceased', siblingAliveOrDeceasedStatus)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [siblingname, siblingsex, siblingage, siblingoccupation, siblingAliveOrDeceasedStatus])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('dependentsName', dependentsName)
	formData.append('dependentsSex', dependentsSex)
	formData.append('dependentsAge', dependentsAge)
	formData.append('dependentsOccupation', dependentsOccupation)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [dependentsName, dependentsSex, dependentsAge, dependentsOccupation])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('house', house)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [house])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('houseOther', houseOther)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [houseOther])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('typeOfHouseMainMaterialOfRoof', typeOfHouseMainMaterialOfRoof)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [typeOfHouseMainMaterialOfRoof])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('typeOfHouseMainMaterialOfFloor', typeOfHouseMainMaterialOfFloor)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [typeOfHouseMainMaterialOfFloor])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('typeOfHouseMainMaterialOfWall', typeOfHouseMainMaterialOfWall)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [typeOfHouseMainMaterialOfWall])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('toilet', toilet)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [toilet])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('water', water)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [water])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('waterOther', waterOther)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [waterOther])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('sourceOfWater', sourceOfWater)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [sourceOfWater])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('availabilityOfElectricity', availabilityOfElectricity)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [availabilityOfElectricity])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('mainSourceOfIncome', mainSourceOfIncome)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [mainSourceOfIncome])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('numberOfMealsPerDay', numberOfMealsPerDay)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [numberOfMealsPerDay])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('numberOfMealsPerDayOther', numberOfMealsPerDayOther)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [numberOfMealsPerDayOther])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doesYourHouseholdHaveAnyOfTheFollowingDurableItems', doesYourHouseholdHaveAnyOfTheFollowingDurableItems)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doesYourHouseholdHaveAnyOfTheFollowingDurableItems])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther', doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther])
  
  
  useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal', doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('cattle', cattle)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [cattle])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('goats', goats)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [goats])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('sheep', sheep)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [sheep])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('pigs', pigs)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [pigs])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('poutry', poutry)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [poutry])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther', doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('learnerName', learnerName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [learnerName])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('learnerAddress', learnerAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [learnerAddress])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('learnerPhone', learnerPhone)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [learnerPhone])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('learnerNRC', learnerNRC)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [learnerNRC])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('contactName', contactName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [contactName])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('contactAddress', contactAddress)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [contactAddress])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('contactPhone', contactPhone)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [contactPhone])

useEffect(() => {
  var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('contactNRC', contactNRC)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, [contactNRC])
  
   const handleSubmit = async e => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'uploadSecondaryBoardingSchoolBursaryApplicationForm'
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }
  
  /*
  const handleSubmit = async e => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'uploadSecondaryBoardingSchoolBursaryApplicationForm'
    )
    formData.append('surname', surname)
    formData.append('firstName', firstName)
    formData.append('otherNames', otherNames)
    formData.append('gender', gender)
    formData.append('dateOfBirth', dateOfBirth)
    formData.append('birthCertificate', birthCertificate)
    formData.append('nationality', nationality)
    formData.append('nrc', nrc)
    formData.append('province', province)
    formData.append('council', council)
    formData.append('constituency', constituency)
    formData.append('ward', ward)
    formData.append('zone', zone)
    formData.append('villageOrTownship', villageOrTownship)
    formData.append('doYouHaveADisability', doYouHaveADisability)
    formData.append(
      'doYouHaveADisabilityYesReason',
      doYouHaveADisabilityYesReason
    )
    formData.append('doYouHaveADisabilityYesFile', doYouHaveADisabilityYesFile)
    formData.append(
      'nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted',
      nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
    )
    formData.append('lastSchoolAttended', lastSchoolAttended)
    formData.append('lastGradeAttended', lastGradeAttended)
    formData.append('areYouABoarder', areYouABoarder)
    formData.append(
      'whoHasBeenPayingYourSchoolFees',
      whoHasBeenPayingYourSchoolFees
    )
    formData.append(
      'haveYouBeenSupportedByAnyOrganization',
      haveYouBeenSupportedByAnyOrganization
    )
    formData.append(
      'haveYouBeenSupportedByAnyOrganizationYesExplanation',
      haveYouBeenSupportedByAnyOrganizationYesExplanation
    )
    formData.append('fatherStatus', fatherStatus)
    formData.append('fatherStatusFile', fatherStatusFile)
    formData.append('fatherSurname', fatherSurname)
    formData.append('fatherFirstName', fatherFirstName)
    formData.append('fatherOtherNames', fatherOtherNames)
    formData.append('fatherDateOfBirth', fatherDateOfBirth)
    formData.append('fatherTelephoneNo', fatherTelephoneNo)
    formData.append('fatherResidentialAddress', fatherResidentialAddress)
    formData.append('fatherEmailAddress', fatherEmailAddress)
    formData.append('fatherOccupation', fatherOccupation)
    formData.append('fatherEmployer', fatherEmployer)
    formData.append('fatherDisability', fatherDisability)
    formData.append(
      'pleaseSpecifyFatherDisability',
      pleaseSpecifyFatherDisability
    )
    formData.append(
      'fatherDisabilityDocumentation',
      fatherDisabilityDocumentation
    )
    formData.append('fatherMedicalCondition', fatherMedicalCondition)
    formData.append(
      'pleaseSpecifyFatherMedicalCondition',
      pleaseSpecifyFatherMedicalCondition
    )
    formData.append(
      'fatherMedicalConditionDocumentation',
      fatherMedicalConditionDocumentation
    )
    formData.append('motherStatus', motherStatus)
    formData.append('motherStatusFile', motherStatusFile)
    formData.append('motherSurname', motherSurname)
    formData.append('motherFirstName', motherFirstName)
    formData.append('motherOtherNames', motherOtherNames)
    formData.append('motherDateOfBirth', motherDateOfBirth)
    formData.append('motherTelephoneNo', motherTelephoneNo)
    formData.append('motherResidentialAddress', motherResidentialAddress)
    formData.append('motherEmailAddress', motherEmailAddress)
    formData.append('motherOccupation', motherOccupation)
    formData.append('motherEmployer', motherEmployer)
    formData.append('motherDisability', motherDisability)
    formData.append(
      'pleaseSpecifyMotherDisability',
      pleaseSpecifyMotherDisability
    )
    formData.append(
      'motherDisabilityDocumentation',
      motherDisabilityDocumentation
    )
    formData.append('motherMedicalCondition', motherMedicalCondition)
    formData.append(
      'pleaseSpecifyMotherMedicalCondition',
      pleaseSpecifyMotherMedicalCondition
    )
    formData.append(
      'motherMedicalConditionDocumentation',
      motherMedicalConditionDocumentation
    )
    formData.append('guardianStatus', guardianStatus)
    formData.append('guardianStatusFile', guardianStatusFile)
    formData.append('guardianSurname', guardianSurname)
    formData.append('guardianFirstName', guardianFirstName)
    formData.append('guardianOtherNames', guardianOtherNames)
    formData.append('guardianDateOfBirth', guardianDateOfBirth)
    formData.append('guardianTelephoneNo', guardianTelephoneNo)
    formData.append('guardianResidentialAddress', guardianResidentialAddress)
    formData.append('guardianEmailAddress', guardianEmailAddress)
    formData.append('guardianOccupation', guardianOccupation)
    formData.append('guardianEmployer', guardianEmployer)
    formData.append('guardianDisability', guardianDisability)
    formData.append(
      'pleaseSpecifyGuardianDisability',
      pleaseSpecifyGuardianDisability
    )
    formData.append(
      'guardianDisabilityDocumentation',
      guardianDisabilityDocumentation
    )
    formData.append('guardianMedicalCondition', guardianMedicalCondition)
    formData.append(
      'pleaseSpecifyGuardianMedicalCondition',
      pleaseSpecifyGuardianMedicalCondition
    )
    formData.append(
      'guardianMedicalConditionDocumentation',
      guardianMedicalConditionDocumentation
    )
    formData.append('name', siblingname)
    formData.append('sex', siblingsex)
    formData.append('age', siblingage)
    formData.append('occupation', siblingoccupation)
    formData.append('siblingAliveOrDeceased', siblingAliveOrDeceasedStatus)
    formData.append('dependentsName', dependentsName)
    formData.append('dependentsSex', dependentsSex)
    formData.append('dependentsAge', dependentsAge)
    formData.append('dependentsOccupation', dependentsOccupation)

    formData.append('house', house)
    formData.append('houseOther', houseOther)
    formData.append(
      'typeOfHouseMainMaterialOfRoof',
      typeOfHouseMainMaterialOfRoof
    )
    formData.append(
      'typeOfHouseMainMaterialOfFloor',
      typeOfHouseMainMaterialOfFloor
    )
    formData.append(
      'typeOfHouseMainMaterialOfWall',
      typeOfHouseMainMaterialOfWall
    )
    formData.append('toilet', toilet)
    formData.append('water', water)
    formData.append('waterOther', waterOther)
    formData.append('sourceOfWater', sourceOfWater)
    formData.append('availabilityOfElectricity', availabilityOfElectricity)
    formData.append('mainSourceOfIncome', mainSourceOfIncome)
    formData.append('numberOfMealsPerDay', numberOfMealsPerDay)
    formData.append('numberOfMealsPerDayOther', numberOfMealsPerDayOther)
    formData.append(
      'doesYourHouseholdHaveAnyOfTheFollowingDurableItems',
      doesYourHouseholdHaveAnyOfTheFollowingDurableItems
    )
    formData.append(
      'doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther',
      doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
    )
    formData.append(
      'doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal',
      doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
    )
    formData.append('cattle', cattle)
    formData.append('goats', goats)
    formData.append('sheep', sheep)
    formData.append('pigs', pigs)
    formData.append('poutry', poutry)
    formData.append(
      'doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther',
      doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
    )
    formData.append('learnerName', learnerName)
    formData.append('learnerAddress', learnerAddress)
    formData.append('learnerPhone', learnerPhone)
    formData.append('learnerNRC', learnerNRC)
    formData.append('contactName', contactName)
    formData.append('contactAddress', contactAddress)
    formData.append('contactPhone', contactPhone)
    formData.append('contactNRC', contactNRC)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }
  */

  const items = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine'
  ]

  const handleHome = async e => {
    navigate('/')
      }
      const handleApplicationProgressReport = async e => {
        navigate('/ApplyForSecondaryProgress')
      }
      const handleLogout = async e => {
        navigate('/Logout')
      }
      const handleAccount = async e => {
        navigate('/AccountSecondary')
      }
      var [sectionAVisibility, setSectionAVisibility] = useState('');
      var [sectionBVisibility, setSectionBVisibility] = useState('');
      var [sectionCVisibility, setSectionCVisibility] = useState('');
      var [sectionDVisibility, setSectionDVisibility] = useState('');
      var [sectionEVisibility, setSectionEVisibility] = useState('');
//
const showSectionA = async e => {
setSectionAVisibility('Show');
setSectionBVisibility('Hidden');
setSectionCVisibility('Hidden');
setSectionDVisibility('Hidden');
setSectionEVisibility('Hidden');
}
const showSectionB = async e => {
	var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'secondaryboardingschoolbursaryapplicationform')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionA')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		 // alert('Completed Section A, Going To Section B To Complete Registration');
setSectionAVisibility('Hidden');
setSectionBVisibility('Show');
setSectionCVisibility('Hidden');
setSectionDVisibility('Hidden');
setSectionEVisibility('Hidden');
	  }
      })

}
const showSectionC = async e => {
		var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'secondaryboardingschoolbursaryapplicationform')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionB')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		 // alert('Completed Section A, Going To Section B To Complete Registration');
setSectionAVisibility('Hidden');
setSectionBVisibility('Hidden');
setSectionCVisibility('Show');
setSectionDVisibility('Hidden');
setSectionEVisibility('Hidden');
	  }
      })

}
const showSectionD = async e => {
		var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'secondaryboardingschoolbursaryapplicationform')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionC')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		 // alert('Completed Section A, Going To Section B To Complete Registration');
  setSectionAVisibility('Hidden');
  setSectionBVisibility('Hidden');
  setSectionCVisibility('Hidden');
  setSectionDVisibility('Show');
  setSectionEVisibility('Hidden');
	  }
      })

  }
  const showSectionE = async e => {
	  	var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'secondaryboardingschoolbursaryapplicationform')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionD')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		 // alert('Completed Section A, Going To Section B To Complete Registration');
  setSectionAVisibility('Hidden');
  setSectionBVisibility('Hidden');
  setSectionCVisibility('Hidden');
  setSectionDVisibility('Hidden');
  setSectionEVisibility('Show');
	  }
      })

  }
const handleFinish = async e => {
var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'applicationFormSectionsCompleted')
    formDataComfirmOTP.append('tableName', 'secondaryboardingschoolbursaryapplicationform')
    formDataComfirmOTP.append('id', clientID)
    formDataComfirmOTP.append('section', 'sectionE')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/cdfApplicationsProcessingProgressReport.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data != 'Uploaded Successfully'){
        alert(response.data)
      }
	  else{
		  	   var formData = new FormData(this)
    formData.append('apiType', 'uploadSecondaryBoardingSchoolBursaryApplicationForm')
    formData.append('clientID', clientID)
    formData.append('status', 'Pending Approval By WDC')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
	
		  alert('Successfully Completed Registration');
	  }
      })
}

useEffect(() => {
  var formData = new FormData(this)
  formData.append(
    'apiType',
    'getApplicantSecondaryBoardingSchoolsBursariesView'
  )
  formData.append('id', clientID)
  //formData.append('table', 'applyforcommunityproject')
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    setsurname(response.data.surname)
    setfirstName(response.data.firstName)
    setotherNames(response.data.otherNames)
    setgender(response.data.gender)
    setdateOfBirth(response.data.dateOfBirth)
    //setbirthCertificate(response.data.birthCertificate)
    setnationality(response.data.nationality)
    setnrc(response.data.nrc)
    setprovinceDB(response.data.province)
    setcouncilDB(response.data.council)
    setconstituencyDB(response.data.constituency)
    setwardDB(response.data.ward)
    setzone(response.data.zone)
    setvillageOrTownship(response.data.villageOrTownship)
    setdoYouHaveADisability(response.data.doYouHaveADisability)
    setdoYouHaveADisabilityYesReason(
      response.data.doYouHaveADisabilityYesReason
    )
    //setdoYouHaveADisabilityYesFile(response.data.doYouHaveADisabilityYesFile)
    setnameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted(
      response.data.nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
    )
    setlastSchoolAttended(response.data.lastSchoolAttended)
    setlastGradeAttended(response.data.lastGradeAttended)
    setareYouABoarder(response.data.areYouABoarder)
    setwhoHasBeenPayingYourSchoolFees(
      response.data.whoHasBeenPayingYourSchoolFees
    )
    sethaveYouBeenSupportedByAnyOrganization(
      response.data.haveYouBeenSupportedByAnyOrganization
    )
    sethaveYouBeenSupportedByAnyOrganizationYesExplanation(
      response.data.haveYouBeenSupportedByAnyOrganizationYesExplanation
    )
    setfatherStatus(response.data.fatherStatus)
    //setfatherStatusFile(response.data.fatherStatusFile)
    setfatherSurname(response.data.fatherSurname)
    setfatherFirstName(response.data.fatherFirstName)
    setfatherOtherNames(response.data.fatherOtherNames)
    setfatherDateOfBirth(response.data.fatherDateOfBirth)
    setfatherTelephoneNo(response.data.fatherTelephoneNo)
    setfatherResidentialAddress(response.data.fatherResidentialAddress)
    setfatherEmailAddress(response.data.fatherEmailAddress)
    setfatherOccupation(response.data.fatherOccupation)
    setfatherEmployer(response.data.fatherEmployer)
    setfatherDisability(response.data.fatherDisability)
    setpleaseSpecifyFatherDisability(
      response.data.pleaseSpecifyFatherDisability
    )
    //setfatherDisabilityDocumentation(
      //response.data.fatherDisabilityDocumentation
    //)
    setfatherMedicalCondition(response.data.fatherMedicalCondition)
    setpleaseSpecifyFatherMedicalCondition(
      response.data.pleaseSpecifyFatherMedicalCondition
    )
    //setfatherMedicalConditionDocumentation(
      //response.data.fatherMedicalConditionDocumentation
    //)
    setmotherStatus(response.data.motherStatus)
    //setmotherStatusFile(response.data.motherStatusFile)
    setmotherSurname(response.data.motherSurname)
    setmotherFirstName(response.data.motherFirstName)
    setmotherOtherNames(response.data.motherOtherNames)
    setmotherDateOfBirth(response.data.motherDateOfBirth)
    setmotherTelephoneNo(response.data.motherTelephoneNo)
    setmotherResidentialAddress(response.data.motherResidentialAddress)
    setmotherEmailAddress(response.data.motherEmailAddress)
    setmotherOccupation(response.data.motherOccupation)
    setmotherEmployer(response.data.motherEmployer)
    setmotherDisability(response.data.motherDisability)
    setpleaseSpecifyMotherDisability(
      response.data.pleaseSpecifyMotherDisability
    )
    //setmotherDisabilityDocumentation(
      //response.data.motherDisabilityDocumentation
    //)
    setmotherMedicalCondition(response.data.motherMedicalCondition)
    setpleaseSpecifyMotherMedicalCondition(
      response.data.pleaseSpecifyMotherMedicalCondition
    )
    //setmotherMedicalConditionDocumentation(
      //response.data.motherMedicalConditionDocumentation
    //)
    setguardianStatus(response.data.guardianStatus)
    //setguardianStatusFile(response.data.guardianStatusFile)
    setguardianSurname(response.data.guardianSurname)
    setguardianFirstName(response.data.guardianFirstName)
    setguardianOtherNames(response.data.guardianOtherNames)
    setguardianDateOfBirth(response.data.guardianDateOfBirth)
    setguardianTelephoneNo(response.data.guardianTelephoneNo)
    setguardianResidentialAddress(response.data.guardianResidentialAddress)
    setguardianEmailAddress(response.data.guardianEmailAddress)
    setguardianOccupation(response.data.guardianOccupation)
    setguardianEmployer(response.data.guardianEmployer)
    setguardianDisability(response.data.guardianDisability)
    setpleaseSpecifyGuardianDisability(
      response.data.pleaseSpecifyGuardianDisability
    )
    //setguardianDisabilityDocumentation(
      //response.data.guardianDisabilityDocumentation
    //)
    setguardianMedicalCondition(response.data.guardianMedicalCondition)
    setpleaseSpecifyGuardianMedicalCondition(
      response.data.pleaseSpecifyGuardianMedicalCondition
    )
    //setguardianMedicalConditionDocumentation(
      //response.data.guardianMedicalConditionDocumentation
    //)
    sethouse(response.data.house)
    sethouseOther(response.data.houseOther)
    settypeOfHouseMainMaterialOfRoof(
      response.data.typeOfHouseMainMaterialOfRoof
    )
    settypeOfHouseMainMaterialOfFloor(
      response.data.typeOfHouseMainMaterialOfFloor
    )
    settypeOfHouseMainMaterialOfWall(
      response.data.typeOfHouseMainMaterialOfWall
    )
    settoilet(response.data.toilet)
    setwater(response.data.water)
    setwaterOther(response.data.waterOther)
    setsourceOfWater(response.data.sourceOfWater)
    setavailabilityOfElectricity(response.data.availabilityOfElectricity)
    setmainSourceOfIncome(response.data.mainSourceOfIncome)
    setnumberOfMealsPerDay(response.data.numberOfMealsPerDay)
    setnumberOfMealsPerDayOther(response.data.numberOfMealsPerDayOther)
    setdoesYourHouseholdHaveAnyOfTheFollowingDurableItems(
      response.data.doesYourHouseholdHaveAnyOfTheFollowingDurableItems
    )
    setdoesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther(
      response.data.doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
    )
    setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal(
      response.data.doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
    )
    setcattle(response.data.cattle)
    setgoats(response.data.goats)
    setsheep(response.data.sheep)
    setpigs(response.data.pigs)
    setpoutry(response.data.poutry)
    setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther(
      response.data
        .doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
    )
    setlearnerName(response.data.learnerName)
    setlearnerAddress(response.data.learnerAddress)
    setlearnerPhone(response.data.learnerPhone)
    setlearnerNRC(response.data.learnerNRC)
    setcontactName(response.data.contactName)
    setcontactAddress(response.data.contactAddress)
    setcontactPhone(response.data.contactPhone)
    setcontactNRC(response.data.contactNRC)

    //alert(response.data)
  }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
}, [])
  return (
    <>
      {/*<!-- Main layout -->*/}
      <div>
        <div class='container-fluid '>
          <div class='row mt-2' style={{ paddingTop: '5%' }}>
            {/* <!--Grid column-->*/}
            <div class='col-md-12 mb-0.00001'>
              {/* <!--Card-->*/}
              <div class='card'>
                {/*<!--Card content-->*/}
                <div class='card-body'>
                  <div
                    class='form-header white-text text-uppercase'
                    style={{ backgroundColor: 'green' }}
                  >
                      <center>
                     {userName} - Application Form
                    </center>
                  </div>
                 
                  <section>
                  <div class="row mt-1 mb-5">
              <div class="col-md-3 text-left">
              <span type = 'button'
                onClick={handleHome}
                ><i class='w-fa fas fa-home'></i>Home </span>
              </div>

              <div class="col-md-3 text-left">
              <span  type = 'button'
                onClick={handleApplicationProgressReport}
                ><FontAwesomeIcon icon={faStairs} />Application Processing Progress </span>
              </div>

              <div class="col-md-3 text-left">
              <span type = 'button'
                onClick={handleAccount}
                > <i class='w-fa fas fa-user'></i>Account </span>
              </div>

              <div class="col-md-3 text-left">
              <span type = 'button'
                onClick={handleLogout}
                > <i class='w-fa fas fa-sign-out-alt'></i>Logout </span>
              </div>
            </div> 
                    <div class='text-left'>
                      <center>
                        {' '}
                        <img src={require('./img/coat of arms.jpg')} />
                        <strong>
                          <br />
                          CONSTITUENCY DEVELOPMENT FUND (CDF) <br />
                          SECONDARY BOARDING SCHOOL BURSARY APPLICATION FORM
                        </strong>
                      </center>
                      <br />
                      <strong>Instructions:</strong> This application form
                      should be completed by the applicant and sent together
                      with supporting documents to the Chairperson, Ward
                      Development Committee. <br />
                      <u>
                        <b>NOTE:</b>
                      </u>
                      This form is not for sale.
                      <br />
                      <i>
                        Disclaimer: Completion of the form does not guarantee
                        the award of Secondary Boarding School Bursary.
                      </i>
                    </div>

                    <br />
                  </section>
                
                  <section hidden={sectionAVisibility === 'Hidden'}>
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION A: GENERAL DETAILS{' '}
                        <span class='yellow-text'>
                          (To be filled by the applicant)
                        </span>{' '}
                      </h6>
                    </center>
                  </div>
                  <br />
                  <div id='divShowMessageFromAjax'></div>
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Surname<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={surname}
                          onChange={e => setsurname(e.target.value)}
                          id='surname'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          First Name<span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={firstName}
                          onChange={e => setfirstName(e.target.value)}
                          id='firstName'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Other Names<span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={otherNames}
                          onChange={e => setotherNames(e.target.value)}
                          id='otherNames'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Gender<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={gender}
                        onChange={e => setgender(e.target.value)}
                        id='gender'
                      >
                        <option value=''>Choose...</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*       <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->	*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-6 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Date Of Birth <span class='red-text'>*</span>
                        </label>
                        <br />
                        <br />
                        <input
                          type='date'
                          class='form-control'
                          placeholder=''
                          value={dateOfBirth}
                          onChange={e => setdateOfBirth(e.target.value)}
                          id='dateOfBirth'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->	*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-md-6 mb-0.001 text-left'>
                      {/*<div class="md-form">*/}
                      <label for='registerFullName'>
                        (Attach birth certificate where Possible){' '}
                        <span class='red-text'></span>
                      </label>
                      <br />
                      <br />
                      <input
                        type='file'
                        class='form-control'
                        placeholder=''
                        /*value={birthCertificate}
                        onChange={e => setbirthCertificate(e.target.value)}
                        */
                        onChange={saveBirthCertificate}
                        id='birthCertificate'
                      />
                      {/*</div>*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->	*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*   <!--Grid column-->	*/}
                    <div class='col-md-6 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Nationality <span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={nationality}
                          onChange={e => setnationality(e.target.value)}
                          id='nationality'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->	*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-md-6 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          NRC NO. (where applicable){' '}
                          <span class='red-text'></span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={nrc}
                          onChange={e => setnrc(e.target.value)}
                          id='nrc'
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->	*/}
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/* <!--Grid column -->*/}
                    <div class='col-lg-4 col-md-12 mb-1 text-left'>
                      <label for='registerFullName'>
                        Provinces<span class='red-text'>*</span> {provinceDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setprovince(e.target.value)}
                        id='province'
                        required
                      >
                        <option value=''>Choose ....</option>
                        {provinceDropDown.map(valProvince => {
                          return (
                            <option value={valProvince.provincesID}>
                              {valProvince.province}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01 text-left'
                      id='hideLocalAuthority'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Council<span class='red-text'>*</span>{councilDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setcouncil(e.target.value)}
                        id='province'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {councilDropDown.map(valCouncil => {
                          return (
                            <option value={valCouncil.councilsID}>
                              {valCouncil.council}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01 text-left'
                      style={{ display: 'none' }}
                      id='divShowlocalAuthority'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left'
                      id='hideConstituency'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Constituency<span class='red-text'>*</span> {constituencyDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setconstituency(e.target.value)}
                        id='constituency'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {constituencyDropDown.map(valConstituency => {
                          return (
                            <option value={valConstituency.constituenciesID}>
                              {valConstituency.constituency}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowConstituency'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left'
                      id='hideWard'
                    >
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Ward<span class='red-text'>*</span> {wardDB}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setward(e.target.value)}
                        id='ward'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {wardDropDown.map(valWard => {
                          return (
                            <option value={valWard.wardsID}>
                              {valWard.ward}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowWard'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Zone<span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={zone}
                          onChange={e => setzone(e.target.value)}
                          id='zone'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-5 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Village/Township <span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={villageOrTownship}
                          onChange={e => setvillageOrTownship(e.target.value)}
                          id='villageOrTownship'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/* <!-- <div class="md-form">->*/}
                      <label for='registerFullName'>
                        Do you have a disability/special need?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <br />
                      <br />
                      <select
                        class='custom-select d-block w-100'
                        value={doYouHaveADisability}
                        onChange={e => setdoYouHaveADisability(e.target.value)}
                        id='doYouHaveADisability'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/* <!--</div>->*/}
                    </div>
                    {/* <!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div
                      class='col-md-12 mb-0.001 text-left'
                      id='hideDoYouHaveADisability'
                    >
                      {/*  <!--  <div class="md-form">-->	*/}
                      {doYouHaveADisability === 'No' ||
                      doYouHaveADisability === '' ? (
                        <>
                          <label for='registerFullName'>
                            If Yes, Please Specify And Attach Relevant
                            Documentation <span class='red-text'></span>
                          </label>
                          <input class='form-control' disabled /> <br />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*  <!--</div>-->	*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->			*/}
                  {/*<!--Grid row-->*/}
                  <div class='row' id='showDoYouHaveADisability'>
                    {/*   <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1'>
                      <div class='md-form'>
                        {doYouHaveADisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Disability{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <br />
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={doYouHaveADisabilityYesReason}
                              onChange={e =>
                                setdoYouHaveADisabilityYesReason(e.target.value)
                              }
                              id='doYouHaveADisabilityYesReason'
                              required
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--\Grid column-->	*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*<div class="md-form">*/}
                      {doYouHaveADisability === 'Yes' ? (
                        <>
                          <label for='registerFullName'>
                            Attach Relevant Disability Documentation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={doYouHaveADisabilityYesFile}
                            onChange={e =>
                              setdoYouHaveADisabilityYesFile(e.target.value)
                            }*/
                            onChange={saveDoYouHaveADisabilityYesFile}
                            id='doYouHaveADisabilityYesFile'
                            required
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/*</div>*/}
                    </div>
                    {/*  <!--\Grid column-->			*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <div class='row'>

<div class='col-md-12 mb-1'>
<button
  class='btn btn-success  btn-md btn-block mb-5 mt-3'
  type='submit'
  id='submit'
  onClick={showSectionB}
>
<FontAwesomeIcon icon={faForwardFast} /> 
  <span class='pl-2'>Next</span>
</button>
</div>
</div>
                  </section>

            <section hidden={sectionBVisibility === '' || sectionBVisibility === 'Hidden'}>
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        B. SCHOOL DETAILS (where you are enrolled or have been
                        accepted) <br />
                        (Attach a copy of an acceptance letter or confirmation
                        letter from the school, this is a must)
                      </h6>
                    </center>
                  </div>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Name Of School Where You Are Enrolled Or Have Been
                        Accepted <span class='red-text'>*</span>
                      </label>
                      <input
                        class='form-control'
                        Placeholder=''
                        value={
                          nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
                        }
                        onChange={e =>
                          setnameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted(
                            e.target.value
                          )
                        }
                        id='nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*<!--     <div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Last School Attended <span class='red-text'>*</span>
                      </label>
                      <input
                        class='form-control'
                        Placeholder=''
                        value={lastSchoolAttended}
                        onChange={e => setlastSchoolAttended(e.target.value)}
                        id='lastSchoolAttended'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*<!--    <div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Last Grade Attended <span class='red-text'>*</span>
                      </label>
                      <input
                        class='form-control'
                        Placeholder=''
                        value={lastGradeAttended}
                        onChange={e => setlastGradeAttended(e.target.value)}
                        id='lastGradeAttended'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Are you/ where you a boarder?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={areYouABoarder}
                        onChange={e => setareYouABoarder(e.target.value)}
                        id='areYouABoarder'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*    <!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*    <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Who has been paying your school fees{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <input
                        class='form-control'
                        Placeholder=''
                        value={whoHasBeenPayingYourSchoolFees}
                        onChange={e =>
                          setwhoHasBeenPayingYourSchoolFees(e.target.value)
                        }
                        id='whoHasBeenPayingYourSchoolFees'
                        required
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*   <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Have you been supported by any organization?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={haveYouBeenSupportedByAnyOrganization}
                        onChange={e =>
                          sethaveYouBeenSupportedByAnyOrganization(
                            e.target.value
                          )
                        }
                        id='haveYouBeenSupportedByAnyOrganization'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}

                    {/*	<!--Grid column-->	*/}
                    <div
                      class='col-md-12 mb-0.001 text-left'
                      id='hideHaveYouBeenSupportedByAnyOrganization'
                    >
                      {/*	<!-- <div class="md-form">-->	*/}
                      {haveYouBeenSupportedByAnyOrganization === 'No' ||
                      haveYouBeenSupportedByAnyOrganization === '' ? (
                        <>
                          <label for='registerFullName'>
                            (if yes kindly give details){' '}
                            <span class='red-text'></span>
                          </label>
                          <input class='form-control' disabled /> <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->	*/}
                    </div>
                    {/*     <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*  <!--Grid row-->*/}
                  <div
                    class='row'
                    id='showHaveYouBeenSupportedByAnyOrganization'
                  >
                    {/*   <!--Grid column-->	*/}
                    <div class='col-md-12 mb-1'>
                      <div class='md-form'>
                        {haveYouBeenSupportedByAnyOrganization === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              kindly give details
                              <span class='red-text'>*</span>
                            </label>
                            <br /> <br /> <br />
                            <textarea
                              class='form-control'
                              Placeholder=''
                              value={
                                haveYouBeenSupportedByAnyOrganizationYesExplanation
                              }
                              onChange={e =>
                                sethaveYouBeenSupportedByAnyOrganizationYesExplanation(
                                  e.target.value
                                )
                              }
                              id='haveYouBeenSupportedByAnyOrganizationYesExplanation'
                              required
                            ></textarea>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/*    <!--\Grid column-->		*/}
                  </div>

                  <div class='row'>
                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionA}
                  >
                     
  <span class='pr-2'>Previous</span>
  <FontAwesomeIcon icon={faBackwardFast} />
                  </button>
                  </div>

                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionC}
                  >
                    <FontAwesomeIcon icon={faForwardFast} /> 
  <span class='pl-2'>Next</span>
                  </button>
                  </div>
                </div>
</section>
                  <section hidden={sectionCVisibility === '' || sectionCVisibility === 'Hidden'}>
                  {/*<!--\Grid row-->   */}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        C. DETAILS OF PARENTS /GUARDIANS{' '}
                      </h6>
                    </center>
                  </div>
                  <div class='row'>
                    <div class='col-md-12 mb-1'>
                      <br />
                      <center>
                        <h6>FATHER</h6>
                      </center>
                    </div>
                  </div>
                  {/*  <!--\Grid row--> */}
                  {/*  <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-6 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Is Your Father ? <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={fatherStatus}
                        onChange={e => setfatherStatus(e.target.value)}
                        id='fatherStatus'
                      >
                        <option value=''>Choose...</option>
                        <option>Alive</option>
                        <option>Deceased</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}

                    {/*  <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*   <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Attach documentation where applicable
                        <span class='red-text'></span>
                      </label>
                      <input
                        type='file'
                        class='form-control'
                        Placeholder=''
                        /*value={fatherStatusFile}
                        onChange={e => setfatherStatusFile(e.target.value)}
                        */
                        onChange={saveFatherStatusFile}
                        id='fatherStatusFile'
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row--> */}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Surname<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherSurname}
                          onChange={e => setfatherSurname(e.target.value)}
                          id='fatherSurname'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          First Name<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherFirstName}
                          onChange={e => setfatherFirstName(e.target.value)}
                          id='fatherFirstName'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Other Names<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherOtherNames}
                          onChange={e => setfatherOtherNames(e.target.value)}
                          id='fatherOtherNames'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*     <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date of Birth<span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={fatherDateOfBirth}
                        onChange={e => setfatherDateOfBirth(e.target.value)}
                        id='fatherDateOfBirth:'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*       <!--\Grid column-->		*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Telephone No<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherTelephoneNo}
                          onChange={e => setfatherTelephoneNo(e.target.value)}
                          id='fatherTelephoneNo'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->		*/}
                  </div>
                  {/*  <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row'>
                    {/*  <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Residential Address<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherResidentialAddress}
                          onChange={e =>
                            setfatherResidentialAddress(e.target.value)
                          }
                          id='fatherResidentialAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Email Address<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherEmailAddress}
                          onChange={e => setfatherEmailAddress(e.target.value)}
                          id='fatherEmailAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Occupation<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherOccupation}
                          onChange={e => setfatherOccupation(e.target.value)}
                          id='fatherOccupation'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Employer/Nature of Business
                          <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fatherEmployer}
                          onChange={e => setfatherEmployer(e.target.value)}
                          id='fatherEmployer'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->	*/}
                  </div>
                  {/*  <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Does Your father have a disability/special need?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={fatherDisability}
                        onChange={e => setfatherDisability(e.target.value)}
                        id='fatherDisability'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}
                    {/*<!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='hideFatherDisability'
                    >
                      {/* <!--   <div class="md-form">-->*/}
                      {fatherDisability === 'No' || fatherDisability === '' ? (
                        <>
                          <label for='registerFullName'>
                            If yes, please specify and attach relevant
                            documentation <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            disabled
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row' id='showFatherDisabilityYes'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      <div class='md'>
                        {fatherDisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Disability{' '}
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <textarea
                              class='form-control'
                              placeholder=''
                              value={pleaseSpecifyFatherDisability}
                              onChange={e =>
                                setpleaseSpecifyFatherDisability(e.target.value)
                              }
                              id='pleaseSpecifyFatherDisability'
                            >
                              {' '}
                            </textarea>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/*	 <!--\Grid column--> */}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*         <!--<div class="md-form">-->*/}
                      {fatherDisability === 'Yes' ? (
                        <>
                          <label for='registerFullName'>
                            Attach Relevant Disability Documentation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={fatherDisabilityDocumentation}
                            onChange={e =>
                              setfatherDisabilityDocumentation(e.target.value)
                            }*/
                            onChange={saveFatherDisabilityDocumentation}
                            id='fatherDisabilityDocumentation'
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*  <!--Grid row--> */}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Does father have medical condition?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={fatherMedicalCondition}
                        onChange={e =>
                          setfatherMedicalCondition(e.target.value)
                        }
                        id='fatherMedicalCondition'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}
                    {/*<!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='hideFatherMedicalCondition'
                    >
                      {/*<!--<div class="md-form">-->*/}
                      {fatherMedicalCondition === 'No' ||
                      fatherMedicalCondition === '' ? (
                        <>
                          <label for='registerFullName'>
                            If yes, please specify and attach relevant
                            documentation <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            disabled
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*  <!--Grid row--> */}
                  <div class='row' id='showFatherMedicalConditionYes'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      <div class='md'>
                        {fatherMedicalCondition === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Medical Condition Documentation{' '}
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <textarea
                              class='form-control'
                              placeholder=''
                              value={pleaseSpecifyFatherMedicalCondition}
                              onChange={e =>
                                setpleaseSpecifyFatherMedicalCondition(
                                  e.target.value
                                )
                              }
                              id='pleaseSpecifyFatherMedicalCondition'
                            >
                              {' '}
                            </textarea>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*        <!--<div class="md-form">-->*/}
                      {fatherMedicalCondition === 'Yes' ? (
                        <>
                          <label for='registerFullName'>
                            Attach Relevant Medical Condition Documentation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={fatherMedicalConditionDocumentation}
                            onChange={e =>
                              setfatherMedicalConditionDocumentation(
                                e.target.value
                              )                            
                            }*/
                            onChange={saveFatherMedicalConditionDocumentation}
                            id='fatherMedicalConditionDocumentation'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/*<!--\Grid row--> */}
                  <div class='row'>
                    <div class='col-md-12 mb-1'>
                      <br />
                      <center>
                        <h6>MOTHER</h6>
                      </center>
                    </div>
                  </div>
                  {/*  <!--\Grid row--> */}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-6 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Is Your Mother ? <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={motherStatus}
                        onChange={e => setmotherStatus(e.target.value)}
                        id='motherStatus'
                      >
                        <option value=''>Choose...</option>
                        <option>Alive</option>
                        <option>Deceased</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*    <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*    <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Attach documentation where applicable
                        <span class='red-text'></span>
                      </label>
                      <input
                        type='file'
                        class='form-control'
                        Placeholder=''
                        /*value={motherStatusFile}
                        onChange={e => setmotherStatusFile(e.target.value)}
                        */
                        onChange={saveMotherStatusFile}
                        id='motherStatusFile'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*   <!--\Grid column-->			*/}
                  </div>
                  {/*<!--\Grid row--> */}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Surname<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherSurname}
                          onChange={e => setmotherSurname(e.target.value)}
                          id='motherSurname'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          First Name<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherFirstName}
                          onChange={e => setmotherFirstName(e.target.value)}
                          id='motherFirstName'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Other Names<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherOtherNames}
                          onChange={e => setmotherOtherNames(e.target.value)}
                          id='motherOtherNames'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date of Birth<span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={motherDateOfBirth}
                        onChange={e => setmotherDateOfBirth(e.target.value)}
                        id='motherDateOfBirth:'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*    <!--\Grid column-->		*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Telephone No<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherTelephoneNo}
                          onChange={e => setmotherTelephoneNo(e.target.value)}
                          id='motherTelephoneNo'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row--> */}
                  {/*<!--Grid row--> */}
                  <div class='row'>
                    {/*  <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Residential Address<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherResidentialAddress}
                          onChange={e =>
                            setmotherResidentialAddress(e.target.value)
                          }
                          id='motherResidentialAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Email Address<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherEmailAddress}
                          onChange={e => setmotherEmailAddress(e.target.value)}
                          id='motherEmailAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Occupation<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherOccupation}
                          onChange={e => setmotherOccupation(e.target.value)}
                          id='motherOccupation'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Employer/Nature of Business
                          <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={motherEmployer}
                          onChange={e => setmotherEmployer(e.target.value)}
                          id='motherEmployer'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Does Your Mother Have A Disability/Special Need?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={motherDisability}
                        onChange={e => setmotherDisability(e.target.value)}
                        id='motherDisability'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}
                    {/* <!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='hideMotherDisability'
                    >
                      {/* <!--  <div class="md-form">-->*/}
                      {motherDisability === 'No' || motherDisability === '' ? (
                        <>
                          <label for='registerFullName'>
                            If yes, please specify and attach relevant
                            documentation <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            disabled
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row' id='showMotherDisabilityYes'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      <div class='md'>
                        {motherDisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Disability{' '}
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <textarea
                              class='form-control'
                              placeholder=''
                              value={pleaseSpecifyMotherDisability}
                              onChange={e =>
                                setpleaseSpecifyMotherDisability(e.target.value)
                              }
                              id='pleaseSpecifyMotherDisability'
                            >
                              {' '}
                            </textarea>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*     <!--<div class="md-form">-->*/}
                      {motherDisability === 'Yes' ? (
                        <>
                          <label for='registerFullName'>
                            Attach Relevant Disability Documentation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={motherDisabilityDocumentation}
                            onChange={e =>
                              setmotherDisabilityDocumentation(e.target.value)
                            }*/
                            onChange={saveMotherDisabilityDocumentation}
                            id='motherDisabilityDocumentation'
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*  <!--Grid row--> */}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Does Mother Have Medical Condition?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={motherMedicalCondition}
                        onChange={e =>
                          setmotherMedicalCondition(e.target.value)
                        }
                        id='motherMedicalCondition'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/* <!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='hideMotherMedicalCondition'
                    >
                      {/* <!-- <div class="md-form">-->*/}
                      {motherMedicalCondition === 'No' ||
                      motherMedicalCondition === '' ? (
                        <>
                          <label for='registerFullName'>
                            If yes, please specify and attach relevant
                            documentation <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            disabled
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row' id='showMotherMedicalConditionYes'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      <div class='md'>
                        {motherMedicalCondition === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Medical Condition{' '}
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <textarea
                              class='form-control'
                              placeholder=''
                              value={pleaseSpecifyMotherMedicalCondition}
                              onChange={e =>
                                setpleaseSpecifyMotherMedicalCondition(
                                  e.target.value
                                )
                              }
                              id='pleaseSpecifyMotherMedicalCondition'
                            >
                              {' '}
                            </textarea>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      {motherMedicalCondition === 'Yes' ? (
                        <>
                          <label for='registerFullName'>
                            Attach Relevant Medical Documentation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={motherMedicalConditionDocumentation}
                            onChange={e =>
                              setmotherMedicalConditionDocumentation(
                                e.target.value
                              )
                            }*/
                            onChange={saveMotherMedicalConditionDocumentation}
                            id='motherMedicalConditionDocumentation'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row-->     */}
                  <div class='row'>
                    <div class='col-md-12 mb-1'>
                      <br />
                      <center>
                        <h6>GUARDIAN</h6>
                      </center>
                    </div>
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-6 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Is Your Guardian ? <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={guardianStatus}
                        onChange={e => setguardianStatus(e.target.value)}
                        id='guardianStatus'
                      >
                        <option value=''>Choose...</option>
                        <option>Alive</option>
                        <option>Deceased</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}

                    {/*  <!--Grid column-->	*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*    <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Attach documentation where applicable
                        <span class='red-text'></span>
                      </label>
                      <input
                        type='file'
                        class='form-control'
                        Placeholder=''
                        /*value={guardianStatusFile}
                        onChange={e => setguardianStatusFile(e.target.value)}
                        */
                        onChange={saveGuardianStatusFile}
                        id='guardianStatusFile'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*     <!--\Grid column-->			*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <-- Grid row --> */}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Surname<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianSurname}
                          onChange={e => setguardianSurname(e.target.value)}
                          id='guardianSurname'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          First Name<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianFirstName}
                          onChange={e => setguardianFirstName(e.target.value)}
                          id='guardianFirstName'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Other Names<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianOtherNames}
                          onChange={e => setguardianOtherNames(e.target.value)}
                          id='guardianOtherNames'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->		*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date of Birth<span class='red-text'></span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={guardianDateOfBirth}
                        onChange={e => setguardianDateOfBirth(e.target.value)}
                        id='guardianDateOfBirth:'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*     <!--\Grid column-->		*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Telephone No<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianTelephoneNo}
                          onChange={e => setguardianTelephoneNo(e.target.value)}
                          id='guardianTelephoneNo'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*<!--Grid row--> */}
                  <div class='row'>
                    {/*  <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Residential Address<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianResidentialAddress}
                          onChange={e =>
                            setguardianResidentialAddress(e.target.value)
                          }
                          id='guardianResidentialAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*   <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Email Address<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianEmailAddress}
                          onChange={e =>
                            setguardianEmailAddress(e.target.value)
                          }
                          id='guardianEmailAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*     <!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-2 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Occupation<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianOccupation}
                          onChange={e => setguardianOccupation(e.target.value)}
                          id='guardianOccupation'
                          required
                        />
                      </div>
                    </div>
                    {/*    <!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Employer/Nature of Business
                          <span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={guardianEmployer}
                          onChange={e => setguardianEmployer(e.target.value)}
                          id='guardianEmployer'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*   <!--Grid row--> */}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Does Your Guardian Have A Disability/Special need?{' '}
                        <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={guardianDisability}
                        onChange={e => setguardianDisability(e.target.value)}
                        id='guardianDisability'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}
                    {/* <!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='hideGuardianDisability'
                    >
                      {/* <!-- <div class="md-form">--> */}
                      {guardianDisability === 'No' ||
                      guardianDisability === '' ? (
                        <>
                          <label for='registerFullName'>
                            If yes, please specify and attach relevant
                            documentation <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            disabled
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--</div>--> */}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*  <!--Grid row--> */}
                  <div class='row' id='showGuardianDisabilityYes'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      <div class='md'>
                        {guardianDisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Disability{' '}
                              <span class='red-text'></span>
                            </label>
                            <textarea
                              class='form-control'
                              placeholder=''
                              value={pleaseSpecifyGuardianDisability}
                              onChange={e =>
                                setpleaseSpecifyGuardianDisability(
                                  e.target.value
                                )
                              }
                              id='pleaseSpecifyGuardianDisability'
                            >
                              {' '}
                            </textarea>{' '}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*         <!--<div class="md-form">-->*/}
                      {guardianDisability === 'Yes' ? (
                        <>
                          <label for='registerFullName'>
                            Attach Relevant Disability Documentation{' '}
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={guardianDisabilityDocumentation}
                            onChange={e =>
                              setguardianDisabilityDocumentation(e.target.value)
                            }*/
                            onChange={saveGuardianDisabilityDocumentation}
                            id='guardianDisabilityDocumentation'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/*<!--\Grid row--> */}
                  {/*  <!--Grid row--> */}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        Does Guardian have medical condition?{' '}
                        <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={guardianMedicalCondition}
                        onChange={e =>
                          setguardianMedicalCondition(e.target.value)
                        }
                        id='guardianMedicalCondition'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/* <!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='hideGuardianMedicalCondition'
                    >
                      {/*<!--<div class="md-form">--> */}
                      {guardianMedicalCondition === 'No' ||
                      guardianMedicalCondition === '' ? (
                        <>
                          <label for='registerFullName'>
                            If yes, please specify and attach relevant
                            documentation <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            disabled
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--</div>> */}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*  <!--Grid row--> */}
                  <div class='row' id='showGuardianMedicalConditionYes'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      <div class='md'>
                        {guardianMedicalCondition === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Please Specify Medical Condition{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <textarea
                              class='form-control'
                              placeholder=''
                              value={pleaseSpecifyGuardianMedicalCondition}
                              onChange={e =>
                                setpleaseSpecifyGuardianMedicalCondition(
                                  e.target.value
                                )
                              }
                              id='pleaseSpecifyGuardianMedicalCondition'
                            >
                              {' '}
                            </textarea>{' '}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      {guardianMedicalCondition === 'Yes' ? (
                        <>
                          {' '}
                          <label for='registerFullName'>
                            Attach Relevant Medical Condition Documentation{' '}
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            placeholder=''
                            /*value={guardianMedicalConditionDocumentation}
                            onChange={e =>
                              setguardianMedicalConditionDocumentation(
                                e.target.value
                              )
                            }*/
                            onChange={saveGuardianMedicalConditionDocumentation}
                            id='guardianMedicalConditionDocumentation'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>

                  <div class='row'>
                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionB}
                  >
                    
  <span class='pr-2'>Previous</span>
  <FontAwesomeIcon icon={faBackwardFast} /> 
                  </button>
                  </div>

                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionD}
                  >
                    <FontAwesomeIcon icon={faForwardFast} /> 
  <span class='pl-2'>Next</span>
                  </button>
                  </div>
                </div>
</section>

                  <section hidden={sectionDVisibility === '' || sectionDVisibility === 'Hidden'}>
                  {/* <!--\Grid row--> */}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        D. INFORMATION ON SIBLINGS/DEPENDANTS TO
                        PARENTS/GUARDIANS
                        <br />
                        (if siblings/dependents are in school, indicate who is
                        supporting them)
                      </h6>
                    </center>
                  </div>
                  {/*<!--Grid row--> */}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-12 mb-1'>
                      <br />{' '}
                      <center>
                        <h6>
                          <u>DETAILS OF SIBLINGS</u>
                        </h6>
                      </center>
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <div class='row'>
                          <div class='col-md-3 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Name <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                id='name'
                                name='name'
                                value={x.name}
                                onChange={e => handleInputChange(e, i)}
                              />
                            </div>
                          </div>

                          <div class='col-lg-2 col-md-2 mb-2'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Sex <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br /> <br />
                              <select
                                class='custom-select d-block w-100'
                                name='sex'
                                value={x.sex}
                                onChange={e => handleInputChange(e, i)}
                              >
                                <option value=''>Choose...</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>

                          <div class='col-md-1 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Age<span class='red-text'>*</span>
                              </label>
                              <br /> <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                id='age'
                                name='age'
                                value={x.age}
                                onChange={e => handleInputChange(e, i)}
                              />
                            </div>
                          </div>

                          <div class='col-md-3 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Occupation<span class='red-text'>*</span>
                              </label>
                              <br />
                              <br />
                              <input
                                type='Text'
                                class='form-control'
                                placeholder=''
                                id='occupation'
                                name='occupation'
                                value={x.occupation}
                                onChange={e => handleInputChange(e, i)}
                              />
                            </div>
                          </div>

                          <div class='col-lg-2 col-md-2 mb-2 text-left'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Status <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br /> <br />
                              <select
                                class='custom-select d-block w-100'
                                id='siblingAliveOrDeceased'
                                name='siblingAliveOrDeceased'
                                value={x.siblingAliveOrDeceased}
                                onChange={e => handleInputChange(e, i)}
                              >
                                <option value=''>Choose...</option>
                                <option>Alive</option>
                                <option>Deceased</option>
                              </select>
                            </div>
                          </div>

                          {inputList.length !== 1 && (
                            <div class='col-lg-1 col-md-2'>
                              <div class='md-form'>
                                <br />
                                <br />
                                <br />
                                <input
                                  type='button'
                                  class='form-control btn white-text red accent-2'
                                  value='Remove'
                                  onClick={() => handleRemoveClick(i)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div class='row'>
                          {inputList.length - 1 === i && (
                            <div class='col-md-12 mb-1'>
                              <div class='md-form'>
                                <input
                                  type='button'
                                  class='form-control btn white-text blue accent-2 col-12'
                                  value='Add'
                                  onClick={handleAddClick}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  })}
                  <div class='row'>
                    <div class='col-md-12 mb-1'>
                      <br />{' '}
                      <center>
                        <u>
                          <h6>DEPENDENTS TO PARENTS/GUARDIANS</h6>
                        </u>
                      </center>
                    </div>
                  </div>
                  {dependentsServiceList.map(
                    (singleDependentsServiceList, dependentsIndex) => {
                      return (
                        <>
                          <div class='row' key={dependentsIndex}>
                            <div class='col-md-3 mb-1'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Name
                                  <span class='red-text'>*</span>
                                </label>
                                <br /> <br />
                                <input
                                  type='Text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    singleDependentsServiceList.dependentsName
                                  }
                                  onChange={e =>
                                    handleDependentsNameChange(
                                      e,
                                      dependentsIndex
                                    )
                                  }
                                  id='dependentsName'
                                  name='dependentsName'
                                />
                              </div>
                            </div>

                            <div class='col-lg-2 col-md-2 mb-2 text-left'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  {' '}
                                  Sex <span class='red-text'>*</span>
                                </label>{' '}
                                <br /> <br /> <br />
                                <select
                                  class='custom-select d-block w-100'
                                  value={
                                    singleDependentsServiceList.dependentsSex
                                  }
                                  onChange={e =>
                                    handleDependentsNameChange(
                                      e,
                                      dependentsIndex
                                    )
                                  }
                                  id='dependentsSex'
                                  name='dependentsSex'
                                >
                                  <option value=''>Choose...</option>
                                  <option>Male</option>
                                  <option>Female</option>
                                </select>
                              </div>
                            </div>

                            <div class='col-md-1 mb-1'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Age
                                  <span class='red-text'>*</span>
                                </label>{' '}
                                <br /> <br />
                                <input
                                  type='Text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    singleDependentsServiceList.dependentsAge
                                  }
                                  onChange={e =>
                                    handleDependentsNameChange(
                                      e,
                                      dependentsIndex
                                    )
                                  }
                                  id='dependentsAge'
                                  name='dependentsAge'
                                />
                              </div>
                            </div>

                            <div class='col-md-5 mb-1'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Occupation
                                  <span class='red-text'>*</span>
                                </label>{' '}
                                <br /> <br />
                                <input
                                  type='Text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    singleDependentsServiceList.dependentsOccupation
                                  }
                                  onChange={e =>
                                    handleDependentsNameChange(
                                      e,
                                      dependentsIndex
                                    )
                                  }
                                  id='dependentsOccupation'
                                  name='dependentsOccupation'
                                />
                              </div>
                            </div>

                            {dependentsServiceList.length > 1 && (
                              <div class='col-md-1 mb-1'>
                                <div class='md-form'>
                                  <br /> <br /> <br />
                                  <input
                                    type='button'
                                    class='form-control btn white-text red accent-2'
                                    value='Remove'
                                    onClick={handleDependentsServiceRemove}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div class='row'>
                            {dependentsServiceList.length - 1 ===
                              dependentsIndex && (
                              <div class='col-md-12 mb-1'>
                                <div class='md-form'>
                                  <input
                                    type='button'
                                    class='form-control btn white-text blue accent-2'
                                    value='Add'
                                    onClick={() =>
                                      handleDependentsServiceAdd(
                                        dependentsIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    }
                  )}

<div class='row'>
                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionC}
                  >
                      
  <span class='pr-2'>Previous</span>
  <FontAwesomeIcon icon={faBackwardFast} /> 
                  </button>
                  </div>

                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionE}
                  >
                      <FontAwesomeIcon icon={faForwardFast} /> 
  <span class='pl-2'>Next</span>
                  </button>
                  </div>
                </div>
</section>

<section hidden={sectionEVisibility === '' || sectionEVisibility === 'Hidden'}>
                  {/*<!--\Grid row--> */}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        E. FAMILY SOCIAL-ECONOMIC STATUS(Tick where applicable)
                        – to be confirmed by CWAC/CDA{' '}
                      </h6>
                    </center>
                  </div>
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        House <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={house}
                        onChange={e => sethouse(e.target.value)}
                        id='house'
                      >
                        <option value=''>Choose...</option>
                        <option>Owned </option>
                        <option>Rented </option>
                        <option>Inherited </option>
                        <option>Sublet</option>
                        <option>Other (Specify)</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->	*/}

                    {/* <!--Grid column-->*/}
                    <div class='col-md-12 mb-1 text-left' id='showHouse'>
                      {/*   <!--<div class="md-form">-->*/}
                      {house === 'Other (Specify)' ? (
                        <>
                          <label for='registerFullName'>
                            {' '}
                            Specify Other<span class='red-text'></span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={houseOther}
                            onChange={e => sethouseOther(e.target.value)}
                            id='houseOther'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*   <!--Grid column-->*/}
                    <div class='col-lg-4 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Type of House - Main Material of roof{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={typeOfHouseMainMaterialOfRoof}
                        onChange={e =>
                          settypeOfHouseMainMaterialOfRoof(e.target.value)
                        }
                        id='typeOfHouseMainMaterialOfRoof'
                      >
                        <option value=''>Choose...</option>
                        <option>Asbestos sheets</option>
                        <option>Asbestos Tiles</option>
                        <option>Other Non-asbestos tiles</option>
                        <option>Iron sheets</option>
                        <option>Grass/wood/thatch</option>
                        <option>Concrete</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->					*/}

                    {/*  <!--Grid column-->*/}
                    <div class='col-lg-4 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Type of House - Main Material of floor{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={typeOfHouseMainMaterialOfFloor}
                        onChange={e =>
                          settypeOfHouseMainMaterialOfFloor(e.target.value)
                        }
                        id='typeOfHouseMainMaterialOfFloor'
                      >
                        <option value=''>Choose...</option>
                        <option>Earth/Sand</option>
                        <option>Wood planks</option>
                        <option>Palm/bamboo</option>
                        <option>
                          Finished floor (wood tiles,concrete, vinyl etc.)
                        </option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div class='col-lg-4 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Type of House - Main material of wall{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={typeOfHouseMainMaterialOfWall}
                        onChange={e =>
                          settypeOfHouseMainMaterialOfWall(e.target.value)
                        }
                        id='typeOfHouseMainMaterialOfWall'
                      >
                        <option value=''>Choose...</option>
                        <option>Natural walls (Mud, cane, palm, trunks)</option>
                        <option>
                          Rudimentary walls (stone or bamboo with mud etc.)
                        </option>
                        <option>
                          Finished walls (bricks, cement, wood planks, etc.)
                        </option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->				*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Toilet<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={toilet}
                        onChange={e => settoilet(e.target.value)}
                        id='toilet'
                      >
                        <option value=''>Choose...</option>
                        <option>Inside the house</option>
                        <option>Outside the house</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->*/}
                  </div>
                  {/*  <!--\Grid row-->					*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Water<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={water}
                        onChange={e => setwater(e.target.value)}
                        id='water'
                      >
                        <option value=''>Choose...</option>
                        <option>Piped</option>
                        <option>Well</option>
                        <option>Shallow Well</option>
                        <option>Other (specify)</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->	*/}
                    {/*	 <!--Grid column-->*/}
                    <div class='col-md-12 mb-1 text-left' id='showWater'>
                      {/*    <!--<div class="md-form">-->*/}
                      {water === 'Other (specify)' ? (
                        <>
                          <label for='registerFullName'>
                            {' '}
                            Specify Other<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={waterOther}
                            onChange={e => setwaterOther(e.target.value)}
                            id='waterOther'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-lg-3 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Source Of Water<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={sourceOfWater}
                        onChange={e => setsourceOfWater(e.target.value)}
                        id='sourceOfWater'
                      >
                        <option value=''>Choose...</option>
                        <option>Communal </option>
                        <option>Own premises</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->	*/}
                    {/*	<!--Grid column-->*/}
                    <div class='col-lg-3 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Availability Of Electricity
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={availabilityOfElectricity}
                        onChange={e =>
                          setavailabilityOfElectricity(e.target.value)
                        }
                        id='availabilityOfElectricity'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes </option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->	*/}
                    {/*	 <!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/*        <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Main Source Of Income<span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={mainSourceOfIncome}
                        onChange={e => setmainSourceOfIncome(e.target.value)}
                        id='mainSourceOfIncome'
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Number Of Meals Per Day<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={numberOfMealsPerDay}
                        onChange={e => setnumberOfMealsPerDay(e.target.value)}
                        id='numberOfMealsPerDay'
                      >
                        <option value=''>Choose...</option>
                        <option>One</option>
                        <option>Two</option>
                        <option>Three</option>
                        <option>Other (specify)</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/* <!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='showNumberOfMealsPerDay'
                    >
                      {/*       <!--<div class="md-form">-->*/}
                      {numberOfMealsPerDay === 'Other (specify)' ? (
                        <>
                          <label for='registerFullName'>
                            {' '}
                            Specify Other<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={numberOfMealsPerDayOther}
                            onChange={e =>
                              setnumberOfMealsPerDayOther(e.target.value)
                            }
                            id='numberOfMealsPerDayOther'
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Does Your Household Have Any Of The Following Durable
                        Items?<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={
                          doesYourHouseholdHaveAnyOfTheFollowingDurableItems
                        }
                        onChange={e =>
                          setdoesYourHouseholdHaveAnyOfTheFollowingDurableItems(
                            e.target.value
                          )
                        }
                        id='doesYourHouseholdHaveAnyOfTheFollowingDurableItems'
                      >
                        <option value=''>Choose...</option>
                        <option>Tractor</option>
                        <option>Plough</option>
                        <option>Hammer Mill</option>
                        <option>Car/Truck</option>
                        <option>Other (specify)</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->	*/}
                    {/* <!--Grid column-->*/}
                    <div
                      class='col-md-12 mb-1 text-left'
                      id='showDoesYourHouseholdHaveAnyOfTheFollowingDurableItems'
                    >
                      {/*     <!--<div class="md-form">-->*/}
                      {doesYourHouseholdHaveAnyOfTheFollowingDurableItems ===
                      'Other (specify)' ? (
                        <>
                          <label for='registerFullName'>
                            {' '}
                            Specify Other<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={
                              doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
                            }
                            onChange={e =>
                              setdoesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther(
                                e.target.value
                              )
                            }
                            id='doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther'
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> */}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        Does Your Household Own Poultry, Livestock Or Any Other
                        Farm Animal? If Yes, How Many?
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={
                          doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
                        }
                        onChange={e =>
                          setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal(
                            e.target.value
                          )
                        }
                        id='doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*	<!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  {doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal ===
                  'Yes' ? (
                    <>
                      {' '}
                      <div
                        class='row'
                        id='showDoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal'
                      >
                        <div class='col-md-12 mb-3 text-left'>
                          <br />
                          <center>
                            <h6>
                              <u>Specify How Many</u>
                            </h6>
                          </center>
                        </div>
                        {/* <!--Grid column-->*/}
                        <div class='col-md-2 mb-1 text-left'>
                          {/*        <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            {' '}
                            Cattle<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={cattle}
                            onChange={e => setcattle(e.target.value)}
                            id='cattle'
                          />
                          {/*	<!--</div>-->*/}
                        </div>
                        {/*<!--\Grid column--> */}
                        {/*<!--Grid column-->*/}
                        <div class='col-md-2 mb-1 text-left'>
                          {/*      <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            Goats<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={goats}
                            onChange={e => setgoats(e.target.value)}
                            id='goats'
                          />
                          {/*	<!--</div>-->*/}
                        </div>
                        {/*<!--\Grid column--> */}
                        {/*<!--Grid column-->*/}
                        <div class='col-md-1 mb-1 text-left'>
                          {/*        <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            Sheep<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={sheep}
                            onChange={e => setsheep(e.target.value)}
                            id='sheep'
                          />
                          {/*	<!--</div>-->*/}
                        </div>
                        {/*<!--\Grid column--> */}
                        {/*<!--Grid column-->*/}
                        <div class='col-md-1 mb-1 text-left'>
                          {/*      <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            Pigs<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={pigs}
                            onChange={e => setpigs(e.target.value)}
                            id='pigs'
                          />
                          {/*	<!--</div>-->*/}
                        </div>
                        {/*<!--\Grid column--> */}
                        {/*<!--Grid column-->*/}
                        <div class='col-md-2 mb-1 text-left'>
                          {/*     <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            Poutry<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={poutry}
                            onChange={e => setpoutry(e.target.value)}
                            id='poutry'
                          />
                          {/*<!--</div>-->*/}
                        </div>
                        {/* <!--\Grid column--> */}

                        {/*<!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          {/*        <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            {' '}
                            Specify Others<span class='red-text'>*</span>
                          </label>
                          <input
                            type='Text'
                            class='form-control'
                            placeholder=''
                            value={
                              doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
                            }
                            onChange={e =>
                              setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther(
                                e.target.value
                              )
                            }
                            id='doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther'
                          />
                          {/*	<!--</div>-->*/}
                        </div>
                        {/* <!--\Grid column--> */}
                      </div>{' '}
                    </>
                  ) : (
                    <></>
                  )}
                  {/*<!--\Grid row-->*/}
                  <br />
                  <center>
                    <h4 class='red-text'>Applicant (Learner)</h4>
                  </center>
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*      <!--<div class="md-form">--->*/}
                      <label for='registerFullName'>
                        Name <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={learnerName}
                        onChange={e => setlearnerName(e.target.value)}
                        id='learnerName'
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Address <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={learnerAddress}
                        onChange={e => setlearnerAddress(e.target.value)}
                        id='learnerAddress'
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*        <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Phone (where applicable)<span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={learnerPhone}
                        onChange={e => setlearnerPhone(e.target.value)}
                        id='learnerPhone'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 				 */}
                    {/*   <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*        <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        NRC (where applicable)<span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={learnerNRC}
                        onChange={e => setlearnerNRC(e.target.value)}
                        id='learnerNRC'
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <br />
                  <center>
                    <h3 class='red-text'>Contact Person For Applicant</h3>
                  </center>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*        <!--<div class="md-form">--->*/}
                      <label for='registerFullName'>
                        Name <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={contactName}
                        onChange={e => setcontactName(e.target.value)}
                        id='contactName'
                      />
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Address <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={contactAddress}
                        onChange={e => setcontactAddress(e.target.value)}
                        id='contactAddress'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*         <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Phone <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={contactPhone}
                        onChange={e => setcontactPhone(e.target.value)}
                        id='contactPhone'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*   <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        NRC <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={contactNRC}
                        onChange={e => setcontactNRC(e.target.value)}
                        id='contactNRC'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 	*/}
                  </div>
                  {/* <!--\Grid row-->*/}

                  <div class='row'>
                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={showSectionD}
                  >
                     
  <span class='pr-2'>Previous</span>
  <FontAwesomeIcon icon={faBackwardFast} /> 
                  </button>
                  </div>

                  <div class='col-md-6 mb-1'>
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    id='submit'
                    onClick={handleFinish}
                  >
                     <FontAwesomeIcon icon={faUpload} /> 
  <span class='pl-2'>Finish</span>
                  </button>
                  </div>
                </div>
                  </section>


                  <br />
                  <div
                    class='card-header'
                    style={{ backgroundColor: 'green', display: 'none' }}
                  >
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        SECTION F: RECOMMENDATION BY THE WARD DEVELOPMENT
                        COMMITTEE{' '}
                      </h6>
                    </center>
                  </div>
                  {/*<!--Grid row-->*/}
                  <div class='row' style={{ display: 'none' }}>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-5 col-md-2 mb-2'>
                      <label for='registerFullName'>
                        {' '}
                        Recommendation By The Ward Development Committee{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        disabled
                        class='custom-select d-block w-100'
                        name='recommendationByTheWardDevelopmentCommittee'
                        id='recommendationByTheWardDevelopmentCommittee'
                      >
                        <option value=''>Choose...</option>
                        <option>Recommended</option>
                        <option>Not Recommended</option>
                        <option>Deferred</option>
                      </select>
                    </div>
                    {/* <!--\Grid column-->				 */}
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-7 mb-2'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Reasons For Recommendation{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        disabled
                        class='form-control'
                        name='recommendationByTheWardDevelopmentCommitteeReason'
                        id='recommendationByTheWardDevelopmentCommitteeReason'
                        required
                      >
                        {' '}
                      </textarea>
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->			*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*        <!--Grid row-->*/}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Name (WDC Chairperson): <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='wardDevelopmentCommitteeChairpersonName'
                          id='wardDevelopmentCommitteeChairpersonName'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Designation: <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='wardDevelopmentCommitteeChairpersonDesignation'
                          id='wardDevelopmentCommitteeChairpersonDesignation'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date: <span class='red-text'></span>
                      </label>
                      <input
                        disabled
                        type='date'
                        class='form-control'
                        placeholder=''
                        name='wardDevelopmentCommitteeChairpersonDate'
                        id='wardDevelopmentCommitteeChairpersonDate'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->				*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <div
                    class='card-header'
                    style={{ backgroundColor: 'green', display: 'none' }}
                  >
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        SECTION G: DECISION BY THE CONSTITUENCY DEVELOPMENT FUND
                        COMMITTEE{' '}
                      </h6>
                    </center>
                  </div>
                  {/* <!--Grid row-->*/}
                  <div class='row' style={{ display: 'none' }}>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-5 col-md-2 mb-2'>
                      <label for='registerFullName'>
                        {' '}
                        Recommendation By The Constituency Development Committee{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        disabled
                        class='custom-select d-block w-100'
                        name='recommendationByTheConstituencyDevelopmentCommittee'
                        id='recommendationByTheConstituencyDevelopmentCommittee'
                      >
                        <option value=''>Choose...</option>
                        <option>Approved</option>
                        <option>Not Approved</option>
                      </select>
                    </div>
                    {/* <!--\Grid column-->				 */}
                    {/*   <!--Grid column-->	*/}
                    <div class='col-md-7 mb-2'>
                      {/*   <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Reasons For Recommendation{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        disabled
                        class='form-control'
                        name='recommendationByTheConstituencyDevelopmentCommitteeReason'
                        id='recommendationByTheConstituencyDevelopmentCommitteeReason'
                        required
                      >
                        {' '}
                      </textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*     <!--\Grid column-->				*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*  <!--Grid row-->*/}
                  <div class='row' style={{ display: 'none' }}>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Name (CDC Chairperson): <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='constituencyDevelopmentCommitteeChairpersonName'
                          id='constituencyDevelopmentCommitteeChairpersonName'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Designation: <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='constituencyDevelopmentCommitteeChairpersonDesignation'
                          id='constituencyDevelopmentCommitteeChairpersonDesignation'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date: <span class='red-text'></span>
                      </label>
                      <input
                        disabled
                        type='date'
                        class='form-control'
                        placeholder=''
                        name='constituencyDevelopmentCommitteeChairpersonDate'
                        id='constituencyDevelopmentCommitteeChairpersonDate'
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->				*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <button
                  hidden
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    onClick={handleSubmit}
                    id='submit'
                  >
                    Submit
                  </button>
                </div>
                {/* <!--/.Card-- br >*/}
              </div>
            </div>
            {/* <!--Grid row-->*/}
          </div>
        </div>
      </div>
      {/*<!-- Main layout -->*/}
    </>
  )
}

export default SecondaryBoardingSchoolBursaryApplicationForm
