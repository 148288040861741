import { useRef, useState, useEffect, useContext } from 'react'
//import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '.././api/axios'
//import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

export const TotalNationalApplicantsGraph = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [totalSalesReport, setTotalSalesReport] = useState([])
  const [daySalesReport, setDaySalesReport] = useState([])
  const [totalInventoryReport, setTotalInventoryReport] = useState('')
  const [totalInventoryReportInitial, setTotalInventoryReportInitial] =
    useState('')
  const [totalProfitReport, setTotalProfitReport] = useState('')
  const [totalProfitReportDay, setTotalProfitReportDay] = useState('')

  const [costTotalBusinessValue, setCostTotalBusinessValue] = useState('')
  const [sellingPriceTotalBusinessValue, setSellingPriceTotalBusinessValue] =
    useState('')
  const [dailySalesTrends, setDailySalesTrends] = useState('')
  const [
    TotalCDFApplicantsNationalGraphLabels,
    setTotalCDFApplicantsNationalGraphLabels
  ] = useState([])
  const [
    TotalCDFApplicantsNationalGraphData,
    setTotalCDFApplicantsNationalGraphData
  ] = useState([])

  const [
    TotalCDFApplicantsProvincesGraphLabels,
    setTotalCDFApplicantsProvincesGraphLabels
  ] = useState([])
  const [
    TotalCDFApplicantsProvincesGraphData,
    setTotalCDFApplicantsProvincesGraphData
  ] = useState([])

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  useEffect(() => {
  var formDataSecondRowData = new FormData(this)
  formDataSecondRowData.append(
    'reportType',
    'TotalCDFApplicantsNationalGraphData'
  )
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
    //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
    data: formDataSecondRowData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(function (response) {
    //handle success
    setTotalCDFApplicantsNationalGraphData(response.data)
    //alert(response.data[0])
  })
  }, [])

  useEffect(() => {
  var formDataSecondRowLabels = new FormData(this)
  formDataSecondRowLabels.append(
    'reportType',
    'TotalCDFApplicantsNationalGraphLabels'
  )
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
    //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
    data: formDataSecondRowLabels,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(function (response) {
    //handle success
    setTotalCDFApplicantsNationalGraphLabels(response.data)
    //alert(response.data[0])
  })
  }, [])

  useEffect(() => {
  var formDataSecondRowData = new FormData(this)
  formDataSecondRowData.append(
    'reportType',
    'TotalCDFApplicantsProvincesGraphData'
  )
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
    //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
    data: formDataSecondRowData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(function (response) {
    //handle success
    setTotalCDFApplicantsProvincesGraphData(response.data)
    //alert(response.data[0])
  })
  }, [])

  useEffect(() => {
  var formDataSecondRowLabels = new FormData(this)
  formDataSecondRowLabels.append(
    'reportType',
    'TotalCDFApplicantsProvincesGraphLabels'
  )
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
    //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
    data: formDataSecondRowLabels,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(function (response) {
    //handle success
    setTotalCDFApplicantsProvincesGraphLabels(response.data)
    //alert(response.data[0])
  })
  }, [])

  const data = {
    //labels: dailySalesTrendsLabels,
    labels: TotalCDFApplicantsNationalGraphLabels,
    datasets: [
      {
        label: 'APPLICANTS',
        //data: dailySalesTrendsData,
        data: TotalCDFApplicantsNationalGraphData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, 1)',
        borderColor: '#02b844'
      }
    ]
  }

  const dataProvinces = {
    //labels: dailySalesTrendsLabels,
    labels: TotalCDFApplicantsProvincesGraphLabels,
    datasets: [
      {
        label: 'PROVINCIAL TOTAL APPLICANTS',
        //data: dailySalesTrendsData,
        data: TotalCDFApplicantsProvincesGraphData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, 1)',
        borderColor: '#02b844'
      }
    ]
  }

  return (
    <div class='row mt-4'>
      <div class='col-xl-6 col-lg-12 mt-5'>
        <div class='card'>
          <div class='card-body'>
            <h6 class='form-header white-text bg-success'>
              TOTAL APPLICANTS - NATIONAL
            </h6>

            <div class='view view-cascade gradient-card-header'>
              <Bar data={data} />
            </div>
          </div>
        </div>
      </div>

      <>
        <div class='col-lg-6 col-md-12 mt-5'>
          <div class='card mb-4'>
            <div class='card-body'>
              <h6 class='form-header white-text bg-success'>
                TOTAL APPLICANTS - PROVINCES
              </h6>
              <Bar data={dataProvinces} />
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default TotalNationalApplicantsGraph
