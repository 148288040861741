
import {useRef, useState, useEffect} from 'react';
import '../App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../api/axios';

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ; 
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const RegisterBusiness = () => {

const BusinessNameRef = useRef();
const errRef = useRef();

//const [logo, setLogo] = useState('');
//const [pacraCertificate, setPacraCertificate] = useState('');
const [BusinessName, setBusinessName] = useState('');
const [branch, setBranch] = useState('');
const [pacraID, setPacraID] = useState('');
const [address, setAddress] = useState('');
const [ip,setIP] = useState('');

const [phone, setPhone] = useState('');
const [validPhone, setValidPhone] = useState(false);
const [phoneFocus, setPhoneFocus ] = useState(false);

const [email, setEmail] = useState('');
const [validEmail, setValidEmail ] = useState(false);
const [emailFocus, setEmailFocus ] = useState(false);
const [pwd, setPwd] = useState('');
const [validPwd, setValidPwd] = useState(false);
const [pwdFocus, setPwdFocus] = useState(false);

const [matchPwd, setMatchPwd] = useState("Q2MRmYkf3du4yWZ@");
const [validMatch, setValidMatch] = useState(false);
const [matchFocus, setMatchFocus] = useState(false);

const [errMsg, setErrMsg ] = useState('');
//const [success, setSuccess ] = useState(false);

const [logoFile, setLogoFile] = useState();
const [logoFileName, setLogoFileName] = useState("");
const [pacraCertificateFile, setPacraCertificateFile] = useState();
const [pacraCertificateFileName, setPacraCertificateFileName] = useState("");
	  


useEffect( () => {
	BusinessNameRef.current.focus();
},
[])

useEffect( () => {
    const result = PHONE_REGEX.test(phone);
	setValidPhone(result);
},
[phone])

useEffect( () => {
    const result = EMAIL_REGEX.test(email);
	setValidEmail(result);
},
[email])

useEffect( () => {
    const result = PWD_REGEX.test(pwd);
	setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
},
[pwd, matchPwd])

useEffect( () => {
	setErrMsg('');
},
[phone, email, pwd, matchPwd])

   //creating function to load ip address from the API
    const getData = async()=>{
        const res = await Axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
    }
  useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])


  /*const [logoFile, setLogoFile] = useState();
  const [logoFileName, setLogoFileName] = useState("");

  const [pacraCertificateFile, setPacraCertificateFile] = useState();
  const [pacraCertificateFileName, setPacraCertificateFileName] = useState("");
 
      const saveLogoFile = (e) => {
        setLogoFile(e.target.files[0]);
        setLogoFileName(e.target.files[0].name);
      };
	  
      const savePacraCertificateFile = (e) => {
        setPacraCertificateFile(e.target.files[0]);
        setPacraCertificateFileName(e.target.files[0].name);
      };*/
const saveLogoFile = (e) => {
        setLogoFile(e.target.files[0]);
        setLogoFileName(e.target.files[0].name);
      };
	  
const savePacraCertificateFile = (e) => {
        setPacraCertificateFile(e.target.files[0]);
        setPacraCertificateFileName(e.target.files[0].name);
      };
	  
const handleSubmit = async (e) => 
{
    //setSuccess(true);
//const formData = new FormData();


	  
  // handle validations
/*  const logo = e.target.files[0];
  if (logo.size > 1024){
    onLogoFileSelectError({ error: "File size cannot exceed more than 1MB" });
  }else{ onLogoFileSelectSuccess(logo);}

  const pacraCertificate = e.target.files[0];
  if (pacraCertificate.size > 1024){
    onPacraCertificateFileSelectError({ error: "File size cannot exceed more than 1MB" });
  }else{ onPacraCertificateFileSelectSuccess(pacraCertificate);}
*/
	/*formData.append("logoFile", logoFile);
    formData.append("logoFileName", logoFileName);
	formData.append("pacraCertificateFile", pacraCertificateFile);
    formData.append("pacraCertificateFileName", pacraCertificateFileName);
		
	formData.append("businessName" , BusinessName);
	formData.append("branch" , branch);
	formData.append("pacraID" , pacraID);
	formData.append("address" , address);
	formData.append("phone" , phone);
	formData.append("email" , email);
	formData.append("pwd" , pwd);
	formData.append("system_setup_IP" , ip);*/

/*
  
    Axios.post("http://localhost:3001/api/insert", {
        lname : user, 
        fname : pwd,
    });
*/

  /*Axios.post("http://localhost:3001/api/insertRegisterBusiness", formData).then((res) => {

      alert("Result : "+res);

    })

    .catch((err) => alert("ERROR MESSAGE : "+err));	*/
	
   
    Axios.post("http://localhost:3001/api/insertRegisterBusiness", {
        
		businessName : BusinessName,
		branch : branch,
		pacraID : pacraID,
		address : address,
		phone : phone,
		email : email,
		pwd : pwd,
		system_setup_IP : ip,
		
    }).then((res) => {	alert(res.data);
  
	  })  
	  .catch((err) => alert("ERROR : Kindly Contact Your Adminitrator"));
	
    //alert ("Successfully Uploaded");
	/*return(
	<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">&times;</button>
Successfully Uploaded</div>
	)*/
	
    //console.log(user);
    //console.log(pwd);
	//alert ("Successfully Uploaded : " +pacraCertificateFileName);
	//alert(JSON.stringify(formData));
}

    return(
	
	<main>
	{/*<form method="POST" encType="multipart/form-data">*/}
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 mb-2">
          <div class="card">
            <div class="card-body" id="faults">
	<div id="divShowMessageFromAjax"></div>
	 <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
	aria-live = "assertive"> {errMsg} </p>
		<div class="form-header white-text"> REGISTER BUSSINESS HERE  <a href="Home">  - Home</a> </div>
              {/*<div class="row">	
				 <div class="col-lg-6 col-md-12 mb-1"  >
				  <div class="md-form">
						<label>Business Logo </label><br /><br />
						<input  type="file" class="form-control"  id="logo"  
						onChange={saveLogoFile}
						/>
					</div>
					</div>
					
					<div class="col-lg-6 col-md-12 mb-1">
				  <div class="md-form">
						<label> PACRA Certificate</label><br /><br />
						<input  type="file" class="form-control"  id="pacraCertificate"
						onChange={savePacraCertificateFile}
						/>
					</div>
					</div>
				</div>*/} {/*End Of Row 1*/}
							<input  type="hidden" class="form-control"  id="ip" value={ip} />
<div class="row">							
				  <div class="col-lg-6 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Business Name <i class="red-text">*</i> </label><br />
						<input  type="text" class="form-control"  id="BusinessName" onChange={(e) => setBusinessName(e.target.value)}
						 ref= {BusinessNameRef}
						 autoComplete = "off" value={BusinessName} required
						/>
					</div>
					</div>
					
					<div class="col-lg-6 col-md-12 mb-1"  >
				  <div class="md-form">
						<label for="registerFullName">Phone <i class="red-text">*</i>
						 <span className={validPhone ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validPhone || !phone ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
						</label><br />
						<input  type="text" class="form-control"  id="phone" onChange={(e) => setPhone(e.target.value)}
						
						autoComplete = "off"
        value={phone}
        required
        aria-invalid={validPhone ? "false" : "true"}
        aria-describedby = "phonenote"
		onFocus={() => setPhoneFocus(true)}
        onBlur={() => setPhoneFocus(false)}
		/>
		<p
                        id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            there should be 10 or more, but less than 14 numerical values. <br />
                            You can also add + followed by your country or area phone number code.
                    </p>
					</div>
					</div>

					</div> {/*End Of Row 2*/}
					
              <div class="row">	
					
					<div class="col-lg-6 col-md-12 mb-1">
				  <div class="md-form">
				  
						<label>Email <i class="red-text">*</i>
						 <span className={validEmail ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validEmail || !email ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
						</label><br />
						
						<input  type="text" class="form-control"  id="email" onChange={(e) => setEmail(e.target.value)}
							autoComplete = "off"
        value={email}
        required
        aria-invalid={validEmail ? "false" : "true"}
        aria-describedby = "emailnote"
        onFocus={() => setEmailFocus(true)}
        onBlur={() => setEmailFocus(false)}					
						
						/>
						<p
                        id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            4 to 24 characters. <br />
                            Let It Be A Valid Email Address.
                    </p>
						
					</div>
					</div>
					
					
					
			  			  
			  {/*<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Branch Name<i class="red-text">*</i></label><br />
						<input  type="text" class="form-control"  id="branch"  onChange={(e) => setBranch(e.target.value)} 
						autoComplete = "off" value={branch} required
						/>
					</div>
					</div>	
									
					<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">PACRA Business ID
						
						</label><br />
						<input  type="text" class="form-control"  id="pacraID" onChange={(e) => setPacraID(e.target.value)}
						autoComplete = "off" value={pacraID}
						/>
						
					</div>
					</div>*/}
					
					<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label>Password <i class="red-text">*</i> 
						<span className={validPwd ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validPwd || !pwd ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
						</label><br />
						<input  type="password" class="form-control"  id="password" onChange={(e) => setPwd(e.target.value)}
						required
aria-invalid={validPwd ? "false" : "true"}
        aria-describedby = "pwdnote"
        onFocus={() => setPwdFocus(true)}
        onBlur={() => setPwdFocus(false)}
						/>
						
						  <p
                        id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            Password Should be 8 to 24 characters. <br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters : <span aria-label = "exclamation mark">!</span>
                            <span aria-label = "at symbol">@</span>
                            <span aria-label = "hashtag">#</span>
                            <span aria-label = "dollar sign">$</span>
                            <span aria-label = "percent">%</span>
                    </p>
					</div>
					</div>
					
					<div class="col-lg-3 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Repeat Password <i class="red-text">*</i>
						<span className={validMatch && matchPwd ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validMatch || !matchPwd ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>

						</label><br />
						<input  type="password" class="form-control"  id="repeatPassword" onChange={(e) => setMatchPwd(e.target.value)}
						
						 required
aria-invalid={validMatch ? "false" : "true"}
        aria-describedby = "confirmnote"
        onFocus={() => setMatchFocus(true)}
        onBlur={() => setMatchFocus(false)}

						/>
						  <p
                        id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            Must match the first password input field.
                    </p>
					</div>
					</div>
					
				</div> {/*End Of Row 3*/}
				

 <div class="row">	
					<div class="col-lg-12 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Address <i class="red-text">*</i> </label><br />
						<textarea  type="text" class="form-control"  id="address" onChange={(e) => setAddress(e.target.value)}
						autoComplete = "off" value={address} required

						/>
					</div>
					</div>
					
			</div> {/*End Of Row 4*/}
					
					 <p><span class="red-text">* Required</span> <span>- Do not leave Blank</span></p>
              <hr class="mb-1" />
              <button onClick= {handleSubmit} class="btn btn-primary"
			  disabled={BusinessName ===''  || address==='' || !validPhone || !validEmail || !validPwd || !validMatch ? true : false}
			  >Submit</button>
						
					
				
          </div>
        </div>
     
      </div>
    </div>
    </div>
	{/*</form>*/}
  </main>

    )

}

export default RegisterBusiness;