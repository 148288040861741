import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ApplicantCommunityProjectsView = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let ApplicantCommunityProjectsViewID = localStorage.getItem(
    //'WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
    'ApplicantCommunityProjectsView'
  )
  ApplicantCommunityProjectsViewID = JSON.parse(
    ApplicantCommunityProjectsViewID
  )

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)
  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var [
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework,
    setIsTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
  ] = useState('')
  var [
    isTheProjectBenefittingAWideScopeOfCommunityMembers,
    setIsTheProjectBenefittingAWideScopeOfCommunityMembers
  ] = useState('')
  var [signedMinutesOfCommunityMeetings, setSignedMinutesOfCommunityMeetings] =
    useState('')
  var [
    proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction,
    setProofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
  ] = useState('')
  var [isTheProjectFeasible, setIsTheProjectFeasible] = useState('')
  var [
    isTheProjectLocatedWithinTheConstituency,
    setIsTheProjectLocatedWithinTheConstituency
  ] = useState('')
  var [
    willTheProjectBeJointlyFinancedWithAnotherConstituency,
    setWillTheProjectBeJointlyFinancedWithAnotherConstituency
  ] = useState('')
  var [
    howManyDirectJobsWillBeCreatedByTheProject,
    setHowManyDirectJobsWillBeCreatedByTheProject
  ] = useState('')
  var [
    recommendationLetterFromTheCommunityLeaders,
    setRecommendationLetterFromTheCommunityLeaders
  ] = useState('')
  var [
    hasTheCommunityContributionBeenAgreedUpon,
    setHasTheCommunityContributionBeenAgreedUpon
  ] = useState('')

  var [
    recommendationByTheWardDevelopmentCommittee,
    setRecommendationByTheWardDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheWardDevelopmentCommitteeReason,
    setRecommendationByTheWardDevelopmentCommitteeReason
  ] = useState('')

  var [
    recommendationByTheConstituencyDevelopmentCommittee,
    setRecommendationByTheConstituencyDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheConstituencyDevelopmentCommitteeReason,
    setRecommendationByTheConstituencyDevelopmentCommitteeReason
  ] = useState('')

  /*alert(
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
  )*/
  const handleSubmitWards = async e => {
    //useEffect(() => {
    /*alert(
      isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
    )*/
    var formDataRecommendationByWards = new FormData(this)
    formDataRecommendationByWards.append('apiType', 'recommendationByWards')
    formDataRecommendationByWards.append(
      'applicantID',
      ApplicantCommunityProjectsViewID
    )
    formDataRecommendationByWards.append('userID', userID)
    formDataRecommendationByWards.append(
      'recommendation',
      recommendationByTheWardDevelopmentCommittee
    )
    formDataRecommendationByWards.append(
      'reason',
      recommendationByTheWardDevelopmentCommitteeReason
    )
    formDataRecommendationByWards.append('table', 'applyforcommunityproject')
    formDataRecommendationByWards.append(
      'isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework',
      isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
    )
    formDataRecommendationByWards.append(
      'isTheProjectBenefittingAWideScopeOfCommunityMembers',
      isTheProjectBenefittingAWideScopeOfCommunityMembers
    )
    formDataRecommendationByWards.append(
      'signedMinutesOfCommunityMeetings',
      signedMinutesOfCommunityMeetings
    )
    formDataRecommendationByWards.append(
      'proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction',
      proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
    )
    formDataRecommendationByWards.append(
      'isTheProjectFeasible',
      isTheProjectFeasible
    )
    formDataRecommendationByWards.append(
      'isTheProjectLocatedWithinTheConstituency',
      isTheProjectLocatedWithinTheConstituency
    )
    formDataRecommendationByWards.append(
      'willTheProjectBeJointlyFinancedWithAnotherConstituency',
      willTheProjectBeJointlyFinancedWithAnotherConstituency
    )
    formDataRecommendationByWards.append(
      'howManyDirectJobsWillBeCreatedByTheProject',
      howManyDirectJobsWillBeCreatedByTheProject
    )
    formDataRecommendationByWards.append(
      'recommendationLetterFromTheCommunityLeaders',
      recommendationLetterFromTheCommunityLeaders
    )
    formDataRecommendationByWards.append(
      'hasTheCommunityContributionBeenAgreedUpon',
      hasTheCommunityContributionBeenAgreedUpon
    )

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)
      setDBisTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework(
        response.data
          .isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
      )
      setDBisTheProjectBenefittingAWideScopeOfCommunityMembers(
        response.data.isTheProjectBenefittingAWideScopeOfCommunityMembers
      )
      setDBsignedMinutesOfCommunityMeetings(
        response.data.signedMinutesOfCommunityMeetings
      )
      setDBproofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction(
        response.data
          .proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
      )
      setDBisTheProjectFeasible(response.data.isTheProjectFeasible)
      setDBisTheProjectLocatedWithinTheConstituency(
        response.data.isTheProjectLocatedWithinTheConstituency
      )
      setDBwillTheProjectBeJointlyFinancedWithAnotherConstituency(
        response.data.willTheProjectBeJointlyFinancedWithAnotherConstituency
      )
      setDBhowManyDirectJobsWillBeCreatedByTheProject(
        response.data.howManyDirectJobsWillBeCreatedByTheProject
      )
      setDBrecommendationLetterFromTheCommunityLeaders(
        response.data.recommendationLetterFromTheCommunityLeaders
      )
      setDBhasTheCommunityContributionBeenAgreedUpon(
        response.data.hasTheCommunityContributionBeenAgreedUpon
      )
      //alert(response.data)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const handleSubmitConstituencies = async e => {
    //useEffect(() => {
    var formDataRecommendationByConstituencies = new FormData(this)
    formDataRecommendationByConstituencies.append(
      'apiType',
      'recommendationByConstituencies'
    )
    formDataRecommendationByConstituencies.append(
      'applicantID',
      ApplicantCommunityProjectsViewID
    )
    formDataRecommendationByConstituencies.append('userID', userID)
    formDataRecommendationByConstituencies.append(
      'recommendation',
      recommendationByTheConstituencyDevelopmentCommittee
    )
    formDataRecommendationByConstituencies.append(
      'reason',
      recommendationByTheConstituencyDevelopmentCommitteeReason
    )
    formDataRecommendationByConstituencies.append(
      'table',
      'applyforcommunityproject'
    )
    formDataRecommendationByConstituencies.append(
      'isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework',
      isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
    )
    formDataRecommendationByConstituencies.append(
      'isTheProjectBenefittingAWideScopeOfCommunityMembers',
      isTheProjectBenefittingAWideScopeOfCommunityMembers
    )
    formDataRecommendationByConstituencies.append(
      'signedMinutesOfCommunityMeetings',
      signedMinutesOfCommunityMeetings
    )
    formDataRecommendationByConstituencies.append(
      'proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction',
      proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
    )
    formDataRecommendationByConstituencies.append(
      'isTheProjectFeasible',
      isTheProjectFeasible
    )
    formDataRecommendationByConstituencies.append(
      'isTheProjectLocatedWithinTheConstituency',
      isTheProjectLocatedWithinTheConstituency
    )
    formDataRecommendationByConstituencies.append(
      'willTheProjectBeJointlyFinancedWithAnotherConstituency',
      willTheProjectBeJointlyFinancedWithAnotherConstituency
    )
    formDataRecommendationByConstituencies.append(
      'howManyDirectJobsWillBeCreatedByTheProject',
      howManyDirectJobsWillBeCreatedByTheProject
    )
    formDataRecommendationByConstituencies.append(
      'recommendationLetterFromTheCommunityLeaders',
      recommendationLetterFromTheCommunityLeaders
    )
    formDataRecommendationByConstituencies.append(
      'hasTheCommunityContributionBeenAgreedUpon',
      hasTheCommunityContributionBeenAgreedUpon
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )
      setDBisTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework(
        response.data
          .isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
      )
      setDBisTheProjectBenefittingAWideScopeOfCommunityMembers(
        response.data.isTheProjectBenefittingAWideScopeOfCommunityMembers
      )
      setDBsignedMinutesOfCommunityMeetings(
        response.data.signedMinutesOfCommunityMeetings
      )
      setDBproofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction(
        response.data
          .proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
      )
      setDBisTheProjectFeasible(response.data.isTheProjectFeasible)
      setDBisTheProjectLocatedWithinTheConstituency(
        response.data.isTheProjectLocatedWithinTheConstituency
      )
      setDBwillTheProjectBeJointlyFinancedWithAnotherConstituency(
        response.data.willTheProjectBeJointlyFinancedWithAnotherConstituency
      )
      setDBhowManyDirectJobsWillBeCreatedByTheProject(
        response.data.howManyDirectJobsWillBeCreatedByTheProject
      )
      setDBrecommendationLetterFromTheCommunityLeaders(
        response.data.recommendationLetterFromTheCommunityLeaders
      )
      setDBhasTheCommunityContributionBeenAgreedUpon(
        response.data.hasTheCommunityContributionBeenAgreedUpon
      )
      //alert(response.data)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const [ip, setIP] = useState('')
  const [
    nameOfCommunityMakingTheProposal,
    setnameOfCommunityMakingTheProposal
  ] = useState([])
  const [province, setprovince] = useState([])
  const [council, setcouncil] = useState([])
  const [constituency, setconstituency] = useState([])
  const [ward, setward] = useState([])
  const [zone, setzone] = useState([])
  const [landTitle, setlandTitle] = useState([])
  const [typeOfProject, settypeOfProject] = useState([])
  const [
    estimatedPopulationBenefitingFromTheProject,
    setestimatedPopulationBenefitingFromTheProject
  ] = useState([])
  const [communityBasedProjectBefore, setcommunityBasedProjectBefore] =
    useState([])
  const [
    communityBasedProjectBeforeYesReason,
    setcommunityBasedProjectBeforeYesReason
  ] = useState([])
  const [fundingFromAnyOrganization, setfundingFromAnyOrganization] = useState(
    []
  )
  const [
    fundingFromAnyOrganizationYesReason,
    setfundingFromAnyOrganizationYesReason
  ] = useState([])
  const [mainProblemsInTheArea, setmainProblemsInTheArea] = useState([])
  const [
    problemsIsTheProposedProjectTryingToAddress,
    setproblemsIsTheProposedProjectTryingToAddress
  ] = useState([])
  const [
    howDidTheCommunityIdentifyTheProject,
    sethowDidTheCommunityIdentifyTheProject
  ] = useState([])
  const [
    doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile,
    setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
  ] = useState([])
  const [
    projectIdentificationMinutesFile,
    setprojectIdentificationMinutesFile
  ] = useState([])
  const [projectIdentificationMinutes, setprojectIdentificationMinutes] =
    useState([])
  const [
    hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny,
    sethasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny
  ] = useState([])
  const [
    hasAnythingBeenDoneBeforeToAddressTheProblemExplanation,
    sethasAnythingBeenDoneBeforeToAddressTheProblemExplanation
  ] = useState([])
  const [
    stateTheBeneficiariesOfTheProposedProject,
    setstateTheBeneficiariesOfTheProposedProject
  ] = useState([])
  const [
    whatIsTheEstimatedCostOfTheProject,
    setwhatIsTheEstimatedCostOfTheProject
  ] = useState([])
  const [
    howWillTheCommunityContributeTowardsTheCostOfTheProject,
    sethowWillTheCommunityContributeTowardsTheCostOfTheProject
  ] = useState([])
  const [
    typeOrUploadProjectCommitteemembers,
    settypeOrUploadProjectCommitteemembers
  ] = useState([])
  const [projectCommitteeMembersFile, setprojectCommitteeMembersFile] =
    useState([])
  const [
    howWillTheCommunityEnsureProjectSustainability,
    sethowWillTheCommunityEnsureProjectSustainability
  ] = useState([])
  const [ProjectProposerName, setProjectProposerName] = useState([])
  const [ProjectProposerNRC, setProjectProposerNRC] = useState([])
  const [ProjectProposerAddress, setProjectProposerAddress] = useState([])
  const [ProjectProposerPhone, setProjectProposerPhone] = useState([])
  const [ProjectProposerDate, setProjectProposerDate] = useState([])
  const [SeconderName, setSeconderName] = useState([])
  const [SeconderNRC, setSeconderNRC] = useState([])
  const [SeconderAddress, setSeconderAddress] = useState([])
  const [SeconderPhone, setSeconderPhone] = useState([])
  const [SeconderDate, setSeconderDate] = useState([])
  const [committeeMembersList, setCommitteeMembers] = useState([])
  const [applicationDate, setapplicationDate] = useState([])

  var [statusWardDB, setDBstatusWard] = useState([])
  var [statusProcessedWardDB, setDBstatusProcessedWard] = useState([])
  var [processedWardDateDB, setDBprocessedWardDate] = useState([])
  var [processedByWardDB, setDBprocessedByWard] = useState([])
  var [approvedByWardDB, setDBapprovedByWard] = useState([])
  var [approvedByWardDateDB, setDBapprovedByWardDate] = useState([])
  var [reasonWardDB, setDBreasonWard] = useState([])
  var [statusConstituencyDB, setDBstatusConstituency] = useState([])
  var [statusProcessedConstituencyDB, setDBstatusProcessedConstituency] =
    useState([])
  var [processedByConstituencyDB, setDBprocessedByConstituency] = useState([])
  var [processedConstituencyDateDB, setDBprocessedConstituencyDate] = useState(
    []
  )
  var [approvedByConstituencyDB, setDBapprovedByConstituency] = useState([])
  var [approvedConstituencyDateDB, setDBapprovedConstituencyDate] = useState([])
  var [reasonconstituencyDB, setDBreasonconstituency] = useState([])
  var [statusCouncilPrincipalDB, setDBstatusCouncilPrincipal] = useState([])
  var [processedByCouncilPrincipalDB, setDBprocessedByCouncilPrincipal] =
    useState([])
  var [processedCouncilPrincipalDateDB, setDBprocessedCouncilPrincipalDate] =
    useState([])
  var [approvedByCouncilPrincipalDB, setDBapprovedByCouncilPrincipal] =
    useState([])
  var [approvedCouncilPrincipalDateDB, setDBapprovedCouncilPrincipalDate] =
    useState([])
  var [reasonCouncilPrincipalDB, setDBreasonCouncilPrincipal] = useState([])
  var [tenureCouncilPrincipalDB, setDBtenureCouncilPrincipal] = useState([])
  var [statusPlanningDB, setDBstatusPlanning] = useState([])
  var [processedByPlanningDB, setDBprocessedByPlanning] = useState([])
  var [processedPlanningDateDB, setDBprocessedPlanningDate] = useState([])
  var [approvedByPlanningDB, setDBapprovedByPlanning] = useState([])
  var [approvedPlanningDateDB, setDBapprovedPlanningDate] = useState([])
  var [reasonPlanningDB, setDBreasonPlanning] = useState([])
  var [tenurePlanningDB, setDBtenurePlanning] = useState([])
  var [statusFinanceDB, setDBstatusFinance] = useState([])
  var [processedByFinanceDB, setDBprocessedByFinance] = useState([])
  var [processedFinanceDateDB, setDBprocessedFinanceDate] = useState([])
  var [approvedByFinanceDB, setDBapprovedByFinance] = useState([])
  var [approvedFinanceDateDB, setDBapprovedFinanceDate] = useState([])
  var [reasonFinanceDB, setDBreasonFinance] = useState([])
  var [tenureFinanceDB, setDBtenureFinance] = useState([])
  var [statusEngineeringDB, setDBstatusEngineering] = useState([])
  var [processedByEngineeringDB, setDBprocessedByEngineering] = useState([])
  var [processedEngineeringDateDB, setDBprocessedEngineeringDate] = useState([])
  var [approvedByEngineeringDB, setDBapprovedByEngineering] = useState([])
  var [approvedEngineeringDateDB, setDBapprovedEngineeringDate] = useState([])
  var [reasonEngineeringDB, setDBreasonEngineering] = useState([])
  var [tenureEngineeringDB, setDBtenureEngineering] = useState([])
  var [statusProcurementDB, setDBstatusProcurement] = useState([])
  var [processedByProcurementDB, setDBprocessedByProcurement] = useState([])
  var [processedProcurementDateDB, setDBprocessedProcurementDate] = useState([])
  var [approvedByProcurementDB, setDBapprovedByProcurement] = useState([])
  var [approvedProcurementDateDB, setDBapprovedProcurementDate] = useState([])
  var [reasonProcurementDB, setDBreasonProcurement] = useState([])
  var [tenureProcurementDB, setDBtenureProcurement] = useState([])
  var [statusMinistryDB, setDBstatusMinistry] = useState([])
  var [processedByMinistryDB, setDBprocessedByMinistry] = useState([])
  var [processedMinistryDateDB, setDBprocessedMinistryDate] = useState([])
  var [approvedByMinistryDB, setDBapprovedByMinistry] = useState([])
  var [approvedMinistryDateDB, setDBapprovedMinistryDate] = useState([])
  var [reasonMinistryDB, setDBreasonMinistry] = useState([])
  var [reasonProcessedWardDB, setDBreasonProcessedWard] = useState([])
  var [reasonProcessedConstituencyDB, setDBreasonProcessedConstituency] =
    useState([])
  var [reasonProcessedPlanningDB, setDBreasonProcessedPlanning] = useState([])
  var [reasonProcessedFinanceDB, setDBreasonProcessedFinance] = useState([])
  var [reasonProcessedEngineeringDB, setDBreasonProcessedEngineering] =
    useState([])
  var [reasonProcessedProcurementDB, setDBreasonProcessedProcurement] =
    useState([])
  var [
    reasonProcessedCouncilPrincipalDB,
    setDBreasonProcessedCouncilPrincipal
  ] = useState([])
  var [reasonProcessedMinistryDB, setDBreasonProcessedMinistry] = useState([])
  var [processedAmountWardDB, setDBprocessedAmountWard] = useState([])
  var [approvedAmountWardDB, setDBapprovedAmountWard] = useState([])
  var [processedAmountConstituencyDB, setDBprocessedAmountConstituency] =
    useState([])
  var [approvedAmountConstituencyDB, setDBapprovedAmountConstituency] =
    useState([])
  var [processedAmountMinistryDB, setDBprocessedAmountMinistry] = useState([])
  var [approvedAmountMinistryDB, setDBapprovedAmountMinistry] = useState([])

  var [
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkDB,
    setDBisTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
  ] = useState([])
  var [
    isTheProjectBenefittingAWideScopeOfCommunityMembersDB,
    setDBisTheProjectBenefittingAWideScopeOfCommunityMembers
  ] = useState([])
  var [
    signedMinutesOfCommunityMeetingsDB,
    setDBsignedMinutesOfCommunityMeetings
  ] = useState([])
  var [
    proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionDB,
    setDBproofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
  ] = useState([])
  var [isTheProjectFeasibleDB, setDBisTheProjectFeasible] = useState([])
  var [
    isTheProjectLocatedWithinTheConstituencyDB,
    setDBisTheProjectLocatedWithinTheConstituency
  ] = useState([])
  var [
    willTheProjectBeJointlyFinancedWithAnotherConstituencyDB,
    setDBwillTheProjectBeJointlyFinancedWithAnotherConstituency
  ] = useState([])
  var [
    howManyDirectJobsWillBeCreatedByTheProjectDB,
    setDBhowManyDirectJobsWillBeCreatedByTheProject
  ] = useState([])
  var [
    recommendationLetterFromTheCommunityLeadersDB,
    setDBrecommendationLetterFromTheCommunityLeaders
  ] = useState([])
  var [
    hasTheCommunityContributionBeenAgreedUponDB,
    setDBhasTheCommunityContributionBeenAgreedUpon
  ] = useState([])
  var [statusProvinceDB, setDBstatusProvince] = useState([])
  var [processedByProvinceDB, setDBprocessedByProvince] = useState([])
  var [processedProvinceDateDB, setDBprocessedProvinceDate] = useState([])
  var [approvedByProvinceDB, setDBapprovedByProvince] = useState([])
  var [approvedProvinceDateDB, setDBapprovedProvinceDate] = useState([])
  var [reasonProvinceDB, setDBreasonProvince] = useState([])
  var [reasonProcessedProvinceDB, setDBreasonProcessedProvince] = useState([])
  var [processedAmountProvinceDB, setDBprocessedAmountProvince] = useState([])
  var [approvedAmountProvinceDB, setDBapprovedAmountProvince] = useState([])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantCommunityProjectsViewCommitteeMembers'
    )
    formData.append('id', ApplicantCommunityProjectsViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setCommitteeMembers(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  //alert(ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID)
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getApplicantCommunityProjectsView')
    formData.append('id', ApplicantCommunityProjectsViewID)
    //formData.append('table', 'applyforcommunityproject')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => {
        //setDBtableViewWardName(response.data)
        setnameOfCommunityMakingTheProposal(
          response.data.nameOfCommunityMakingTheProposal
        )
        setprovince(response.data.province)
        setcouncil(response.data.council)
        setconstituency(response.data.constituency)
        setward(response.data.ward)
        setzone(response.data.zone)
        setlandTitle(response.data.landTitle)
        settypeOfProject(response.data.typeOfProject)
        setestimatedPopulationBenefitingFromTheProject(
          response.data.estimatedPopulationBenefitingFromTheProject
        )
        setcommunityBasedProjectBefore(
          response.data.communityBasedProjectBefore
        )
        setcommunityBasedProjectBeforeYesReason(
          response.data.communityBasedProjectBeforeYesReason
        )
        setfundingFromAnyOrganization(response.data.fundingFromAnyOrganization)
        setfundingFromAnyOrganizationYesReason(
          response.data.fundingFromAnyOrganizationYesReason
        )
        setmainProblemsInTheArea(response.data.mainProblemsInTheArea)
        setproblemsIsTheProposedProjectTryingToAddress(
          response.data.problemsIsTheProposedProjectTryingToAddress
        )
        sethowDidTheCommunityIdentifyTheProject(
          response.data.howDidTheCommunityIdentifyTheProject
        )
        setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile(
          response.data
            .doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
        )
        setprojectIdentificationMinutesFile(
          response.data.projectIdentificationMinutesFile
        )
        setprojectIdentificationMinutes(
          response.data.projectIdentificationMinutes
        )
        sethasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny(
          response.data.hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny
        )
        sethasAnythingBeenDoneBeforeToAddressTheProblemExplanation(
          response.data.hasAnythingBeenDoneBeforeToAddressTheProblemExplanation
        )
        setstateTheBeneficiariesOfTheProposedProject(
          response.data.stateTheBeneficiariesOfTheProposedProject
        )
        setwhatIsTheEstimatedCostOfTheProject(
          response.data.whatIsTheEstimatedCostOfTheProject
        )
        sethowWillTheCommunityContributeTowardsTheCostOfTheProject(
          response.data.howWillTheCommunityContributeTowardsTheCostOfTheProject
        )
        settypeOrUploadProjectCommitteemembers(
          response.data.typeOrUploadProjectCommitteemembers
        )
        setprojectCommitteeMembersFile(
          response.data.projectCommitteeMembersFile
        )
        sethowWillTheCommunityEnsureProjectSustainability(
          response.data.howWillTheCommunityEnsureProjectSustainability
        )
        setProjectProposerName(response.data.ProjectProposerName)
        setProjectProposerNRC(response.data.ProjectProposerNRC)
        setProjectProposerAddress(response.data.ProjectProposerAddress)
        setProjectProposerPhone(response.data.ProjectProposerPhone)
        setProjectProposerDate(response.data.ProjectProposerDate)
        setSeconderName(response.data.SeconderName)
        setSeconderNRC(response.data.SeconderNRC)
        setSeconderAddress(response.data.SeconderAddress)
        setSeconderPhone(response.data.SeconderPhone)
        setSeconderDate(response.data.SeconderDate)
        setapplicationDate(response.data.applicationDate)

        setDBstatusWard(response.data.statusWard)
        setDBstatusProcessedWard(response.data.statusProcessedWard)
        setDBprocessedWardDate(response.data.processedWardDate)
        setDBprocessedByWard(response.data.processedByWard)
        setDBapprovedByWard(response.data.approvedByWard)
        setDBapprovedByWardDate(response.data.approvedByWardDate)
        setDBreasonWard(response.data.reasonWard)
        setDBstatusConstituency(response.data.statusConstituency)
        setDBstatusProcessedConstituency(
          response.data.statusProcessedConstituency
        )
        setDBprocessedByConstituency(response.data.processedByConstituency)
        setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
        setDBapprovedByConstituency(response.data.approvedByConstituency)
        setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
        setDBreasonconstituency(response.data.reasonconstituency)
        setDBstatusCouncilPrincipal(response.data.statusCouncilPrincipal)
        setDBprocessedByCouncilPrincipal(
          response.data.processedByCouncilPrincipal
        )
        setDBprocessedCouncilPrincipalDate(
          response.data.processedCouncilPrincipalDate
        )
        setDBapprovedByCouncilPrincipal(
          response.data.approvedByCouncilPrincipal
        )
        setDBapprovedCouncilPrincipalDate(
          response.data.approvedCouncilPrincipalDate
        )
        setDBreasonCouncilPrincipal(response.data.reasonCouncilPrincipal)
        setDBtenureCouncilPrincipal(response.data.tenureCouncilPrincipal)
        setDBstatusPlanning(response.data.statusPlanning)
        setDBprocessedByPlanning(response.data.processedByPlanning)
        setDBprocessedPlanningDate(response.data.processedPlanningDate)
        setDBapprovedByPlanning(response.data.approvedByPlanning)
        setDBapprovedPlanningDate(response.data.approvedPlanningDate)
        setDBreasonPlanning(response.data.reasonPlanning)
        setDBtenurePlanning(response.data.tenurePlanning)
        setDBstatusFinance(response.data.statusFinance)
        setDBprocessedByFinance(response.data.processedByFinance)
        setDBprocessedFinanceDate(response.data.processedFinanceDate)
        setDBapprovedByFinance(response.data.approvedByFinance)
        setDBapprovedFinanceDate(response.data.approvedFinanceDate)
        setDBreasonFinance(response.data.reasonFinance)
        setDBtenureFinance(response.data.tenureFinance)
        setDBstatusEngineering(response.data.statusEngineering)
        setDBprocessedByEngineering(response.data.processedByEngineering)
        setDBprocessedEngineeringDate(response.data.processedEngineeringDate)
        setDBapprovedByEngineering(response.data.approvedByEngineering)
        setDBapprovedEngineeringDate(response.data.approvedEngineeringDate)
        setDBreasonEngineering(response.data.reasonEngineering)
        setDBtenureEngineering(response.data.tenureEngineering)
        setDBstatusProcurement(response.data.statusProcurement)
        setDBprocessedByProcurement(response.data.processedByProcurement)
        setDBprocessedProcurementDate(response.data.processedProcurementDate)
        setDBapprovedByProcurement(response.data.approvedByProcurement)
        setDBapprovedProcurementDate(response.data.approvedProcurementDate)
        setDBreasonProcurement(response.data.reasonProcurement)
        setDBtenureProcurement(response.data.tenureProcurement)
        setDBstatusProvince(response.data.statusProvince)
        setDBprocessedByProvince(response.data.processedByProvince)
        setDBprocessedProvinceDate(response.data.processedProvinceDate)
        setDBapprovedByProvince(response.data.approvedByProvince)
        setDBapprovedProvinceDate(response.data.approvedProvinceDate)
        setDBreasonProvince(response.data.reasonProvince)
        setDBstatusMinistry(response.data.statusMinistry)
        setDBprocessedByMinistry(response.data.processedByMinistry)
        setDBprocessedMinistryDate(response.data.processedMinistryDate)
        setDBapprovedByMinistry(response.data.approvedByMinistry)
        setDBapprovedMinistryDate(response.data.approvedMinistryDate)
        setDBreasonMinistry(response.data.reasonMinistry)
        setDBreasonProcessedWard(response.data.reasonProcessedWard)
        setDBreasonProcessedConstituency(
          response.data.reasonProcessedConstituency
        )
        setDBreasonProcessedPlanning(response.data.reasonProcessedPlanning)
        setDBreasonProcessedFinance(response.data.reasonProcessedFinance)
        setDBreasonProcessedEngineering(
          response.data.reasonProcessedEngineering
        )
        setDBreasonProcessedProcurement(
          response.data.reasonProcessedProcurement
        )
        setDBreasonProcessedCouncilPrincipal(
          response.data.reasonProcessedCouncilPrincipal
        )
        setDBreasonProcessedProvince(response.data.reasonProcessedProvince)
        setDBreasonProcessedMinistry(response.data.reasonProcessedMinistry)
        setDBprocessedAmountWard(response.data.processedAmountWard)
        setDBapprovedAmountWard(response.data.approvedAmountWard)
        setDBprocessedAmountConstituency(
          response.data.processedAmountConstituency
        )
        setDBapprovedAmountConstituency(
          response.data.approvedAmountConstituency
        )
        setDBprocessedAmountProvince(response.data.processedAmountProvince)
        setDBapprovedAmountProvince(response.data.approvedAmountProvince)
        setDBprocessedAmountMinistry(response.data.processedAmountMinistry)
        setDBapprovedAmountMinistry(response.data.approvedAmountMinistry)

        setDBisTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework(
          response.data
            .isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
        )
        setDBisTheProjectBenefittingAWideScopeOfCommunityMembers(
          response.data.isTheProjectBenefittingAWideScopeOfCommunityMembers
        )
        setDBsignedMinutesOfCommunityMeetings(
          response.data.signedMinutesOfCommunityMeetings
        )
        setDBproofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction(
          response.data
            .proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
        )
        setDBisTheProjectFeasible(response.data.isTheProjectFeasible)
        setDBisTheProjectLocatedWithinTheConstituency(
          response.data.isTheProjectLocatedWithinTheConstituency
        )
        setDBwillTheProjectBeJointlyFinancedWithAnotherConstituency(
          response.data.willTheProjectBeJointlyFinancedWithAnotherConstituency
        )
        setDBhowManyDirectJobsWillBeCreatedByTheProject(
          response.data.howManyDirectJobsWillBeCreatedByTheProject
        )
        setDBrecommendationLetterFromTheCommunityLeaders(
          response.data.recommendationLetterFromTheCommunityLeaders
        )
        setDBhasTheCommunityContributionBeenAgreedUpon(
          response.data.hasTheCommunityContributionBeenAgreedUpon
        )

        setIsTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework(
          response.data
            .isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
        )
        setIsTheProjectBenefittingAWideScopeOfCommunityMembers(
          response.data.isTheProjectBenefittingAWideScopeOfCommunityMembers
        )
        setSignedMinutesOfCommunityMeetings(
          response.data.signedMinutesOfCommunityMeetings
        )
        setProofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction(
          response.data
            .proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
        )
        setIsTheProjectFeasible(response.data.isTheProjectFeasible)
        setIsTheProjectLocatedWithinTheConstituency(
          response.data.isTheProjectLocatedWithinTheConstituency
        )
        setWillTheProjectBeJointlyFinancedWithAnotherConstituency(
          response.data.willTheProjectBeJointlyFinancedWithAnotherConstituency
        )
        setHowManyDirectJobsWillBeCreatedByTheProject(
          response.data.howManyDirectJobsWillBeCreatedByTheProject
        )
        setRecommendationLetterFromTheCommunityLeaders(
          response.data.recommendationLetterFromTheCommunityLeaders
        )
        setHasTheCommunityContributionBeenAgreedUpon(
          response.data.hasTheCommunityContributionBeenAgreedUpon
        )

        //alert(response.data)
      })
      //.catch(err => alert('ERROR : Check Your Network Connection'))
      .catch(err => alert(err))
  }, [])

  /*
  if (
    statusWardDB != 'Pending Approval By WDC' &&
    recommendationByTheWardDevelopmentCommittee === ''
  ) {
    recommendationByTheWardDevelopmentCommittee = statusWardDB
  }

  if (
    reasonWardDB != '' &&
    recommendationByTheWardDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheWardDevelopmentCommitteeReason = reasonWardDB
  }

  if (
    statusConstituencyDB != 'Pending Approval By CDFC' &&
    recommendationByTheConstituencyDevelopmentCommittee === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommittee = statusConstituencyDB
  }

  if (
    reasonconstituencyDB != '' &&
    recommendationByTheConstituencyDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommitteeReason =
      reasonconstituencyDB
  }

  let wardDevelopmentCommitteeChairpersonName = ''
  let wardDevelopmentCommitteeMemberName = ''
  let wardDevelopmentCommitteeChairpersonDate = ''
  let wardDevelopmentCommitteeMemberDate = ''
  let constituencyDevelopmentCommitteeChairpersonName = ''
  let constituencyDevelopmentCommitteeMemberName = ''
  let constituencyDevelopmentCommitteeChairpersonDate = ''
  let constituencyDevelopmentCommitteeMemberDate = ''
  let wardDevelopmentCommitteePosition = 'Name (WDC Chairperson):'
  let wardDevelopmentCommitteePositionMember = 'Name (WDC Member):'
  let constituencyDevelopmentCommitteePosition = 'Name (CDFC Chairperson):'
  let constituencyDevelopmentCommitteePositionMember = 'Name (CDFC Member):'
  //alert(approvedByWardDB)
  if (
    processedByWardDB != '' &&
    processedByWardDB != null &&
    (approvedByWardDB === '' || approvedByWardDB === null)
  ) {
    wardDevelopmentCommitteeMemberName = processedByWardDB
    wardDevelopmentCommitteeMemberDate = processedWardDateDB
    wardDevelopmentCommitteePositionMember = 'Processed By (WDC Member):'
  }
  if (approvedByWardDB != '' && approvedByWardDB != null) {
    wardDevelopmentCommitteeChairpersonName = approvedByWardDB
    wardDevelopmentCommitteeChairpersonDate = approvedByWardDateDB
    wardDevelopmentCommitteePosition = 'Approved By (WDC Chairperson):'
  }

  if (
    processedByConstituencyDB != '' &&
    processedByConstituencyDB != null &&
    (approvedByConstituencyDB === '' || approvedByConstituencyDB === null)
  ) {
    constituencyDevelopmentCommitteeMemberName = processedByConstituencyDB
    constituencyDevelopmentCommitteeMemberDate = processedConstituencyDateDB
    constituencyDevelopmentCommitteePositionMember =
      'Processed By (CDFC Member):'
  }

  if (approvedByConstituencyDB != '' && approvedByConstituencyDB != null) {
    constituencyDevelopmentCommitteeChairpersonName = approvedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate = approvedConstituencyDateDB
    constituencyDevelopmentCommitteePosition = 'Approved By (CDFC Chairperson):'
  }
*/
  var [
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkCheck,
    setCheckisTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
  ] = useState('')
  var [
    isTheProjectBenefittingAWideScopeOfCommunityMembersCheck,
    setCheckisTheProjectBenefittingAWideScopeOfCommunityMembers
  ] = useState('')
  var [
    signedMinutesOfCommunityMeetingsCheck,
    setChecksignedMinutesOfCommunityMeetings
  ] = useState('')
  var [
    proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionCheck,
    setCheckproofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
  ] = useState('')
  var [isTheProjectFeasibleCheck, setCheckisTheProjectFeasible] = useState('')
  var [
    isTheProjectLocatedWithinTheConstituencyCheck,
    setCheckisTheProjectLocatedWithinTheConstituency
  ] = useState('')
  var [
    willTheProjectBeJointlyFinancedWithAnotherConstituencyCheck,
    setCheckwillTheProjectBeJointlyFinancedWithAnotherConstituency
  ] = useState('')
  var [
    howManyDirectJobsWillBeCreatedByTheProjectCheck,
    setCheckhowManyDirectJobsWillBeCreatedByTheProject
  ] = useState('')
  var [
    recommendationLetterFromTheCommunityLeadersCheck,
    setCheckrecommendationLetterFromTheCommunityLeaders
  ] = useState('')
  var [
    hasTheCommunityContributionBeenAgreedUponCheck,
    setCheckhasTheCommunityContributionBeenAgreedUpon
  ] = useState('')

  const isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkBtn =
    async e => {
      setIsTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework(
        'false'
      )
    }
  const isTheProjectBenefittingAWideScopeOfCommunityMembersBtn = async e => {
    setIsTheProjectBenefittingAWideScopeOfCommunityMembers('false')
  }
  const signedMinutesOfCommunityMeetingsBtn = async e => {
    setSignedMinutesOfCommunityMeetings('false')
  }
  const proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionBtn =
    async e => {
      setProofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction('false')
    }
  const isTheProjectFeasibleBtn = async e => {
    setIsTheProjectFeasible('false')
  }
  const isTheProjectLocatedWithinTheConstituencyBtn = async e => {
    setIsTheProjectLocatedWithinTheConstituency('false')
  }
  const willTheProjectBeJointlyFinancedWithAnotherConstituencyBtn = async e => {
    setWillTheProjectBeJointlyFinancedWithAnotherConstituency('false')
  }
  const howManyDirectJobsWillBeCreatedByTheProjectBtn = async e => {
    setHowManyDirectJobsWillBeCreatedByTheProject('false')
  }
  const recommendationLetterFromTheCommunityLeadersBtn = async e => {
    setRecommendationLetterFromTheCommunityLeaders('false')
  }
  const hasTheCommunityContributionBeenAgreedUponBtn = async e => {
    setHasTheCommunityContributionBeenAgreedUpon('false')
  }

  /*if (
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkCheck ===
    'false'
  ) {
    alert(
      isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkCheck
    )
  }*/

  if (
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework ===
    'false'
  ) {
    isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkDB =
      'false'
  }
  if (isTheProjectBenefittingAWideScopeOfCommunityMembers === 'false') {
    isTheProjectBenefittingAWideScopeOfCommunityMembersDB = 'false'
  }
  if (signedMinutesOfCommunityMeetings === 'false') {
    signedMinutesOfCommunityMeetingsDB = 'false'
  }
  if (
    proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction === 'false'
  ) {
    proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionDB = 'false'
  }
  if (isTheProjectFeasible === 'false') {
    isTheProjectFeasibleDB = 'false'
  }
  if (isTheProjectLocatedWithinTheConstituency === 'false') {
    isTheProjectLocatedWithinTheConstituencyDB = 'false'
  }
  if (willTheProjectBeJointlyFinancedWithAnotherConstituency === 'false') {
    willTheProjectBeJointlyFinancedWithAnotherConstituencyDB = 'false'
  }
  if (howManyDirectJobsWillBeCreatedByTheProject === 'false') {
    howManyDirectJobsWillBeCreatedByTheProjectDB = 'false'
  }
  if (recommendationLetterFromTheCommunityLeaders === 'false') {
    recommendationLetterFromTheCommunityLeadersDB = 'false'
  }
  if (hasTheCommunityContributionBeenAgreedUpon === 'false') {
    hasTheCommunityContributionBeenAgreedUponDB = 'false'
  }

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text'>
                    {' '}
                    <span class='text-uppercase'>
                      {nameOfCommunityMakingTheProposal} APPLICATION FORM -
                      COMMUNITY PROJECTS
                    </span>
                  </div>
                  {/*  <!-- Grid row -->*/}
                  <div class='col-12'>
                    <br /> <br />
                    <section>
                      <div class='text-left'>
                        <center>
                          {' '}
                          <img src={require('./img/coat of arms.jpg')} />
                          <strong>
                            <br />
                            CONSTITUENCY DEVELOPMENT FUND (CDF) <br />{' '}
                            APPLICATION FORM FOR COMMUNITY PROJECTS
                          </strong>
                        </center>
                        <br />
                        <strong>Instructions:</strong> This application form
                        should be completed by the applicant and Uploaded
                        together with supporting documents. <br />
                        <u>
                          <b>NOTE:</b>
                        </u>{' '}
                        This form is not for sale.
                        <br />
                        <i>
                          Disclaimer: Completion of the form does not guarantee
                          the approval of the Project.
                        </i>
                      </div>

                      <br />
                      <div
                        style={{ border: '1px solid black' }}
                        class='text-left'
                      >
                        Types of projects: Construction and rehabilitation of
                        flush toilets or water borne sanitation system,
                        Construction of Police Posts, Construction of foot
                        bridges, Community libraries, sports centres, feeder
                        roads, dip tanks, rehabilitation of markets,
                        rehabilitation and construction of community halls,
                        construction and rehabilitation of health posts, and
                        others with community benefit and job creation.
                        <br />
                      </div>

                      <br />
                    </section>
                    {/*<form
                    name='photo'
                    id='imageUploadForm'
                    enctype='multipart/form-data'
                    action=''
                    method='post'
                  >*/}
                    <br />
                    <div class='col-lg-3 col-md-12 mb-1 text-left'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Application Date<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={applicationDate}
                        />
                      </div>
                    </div>
                    <br />
                    {/*<!--\form heading -->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION A: GENERAL PROPOSAL DETAILS
                          <span class='yellow-text'>
                            (To be filled by the applicant)
                          </span>{' '}
                        </h6>
                      </center>
                    </div>
                    <br />
                    <div id='divShowMessageFromAjax'></div>
                    {/*<!--Grid row-->			*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Name of Community making the proposal{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={nameOfCommunityMakingTheProposal}
                        />

                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->				*/}
                    <center>
                      <h4>
                        <u>PROJECT LOCATION </u>
                      </h4>
                    </center>
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/* <!--Grid column -->*/}
                      <div class='col-lg-6 col-md-12 mb-1  text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Provinces<span class='red-text'>*</span>
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={province}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->*/}

                      {/* <!--Grid column-->*/}
                      <div
                        class='col-lg-6 col-md-12 mb-0.01  text-left'
                        id='hideLocalAuthority'
                      >
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Council<span class='red-text'>*</span>{' '}
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={council}
                          />
                        </div>
                      </div>

                      {/*<!--\Grid column-->*/}
                      <div
                        class='col-lg-6 col-md-12 mb-1  text-left'
                        id='hideConstituency'
                      >
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Constituency<span class='red-text'>*</span>{' '}
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={constituency}
                          />
                        </div>
                      </div>

                      <div
                        class='col-lg-3 col-md-12 mb-1  text-left'
                        id='hideWard'
                      >
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Ward<span class='red-text'>*</span>{' '}
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={ward}
                          />
                        </div>
                      </div>

                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Zone<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={zone}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-1  text-left'>
                        {/*<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Land Title/Ownership (where applicable
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={landTitle}
                        />
                        {/*</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->		*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-3 col-md-2 mb-1  text-left'>
                        <label for='registerFullName'>
                          Type of Project<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={typeOfProject}
                        />
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-5 mb-1  text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Estimated population benefiting from the project{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={estimatedPopulationBenefitingFromTheProject}
                        />
                        {/*<--</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*<!--Grid row-->			*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-0.001  text-left'>
                        <label for='registerFullName'>
                          Has the community been involved in any community based
                          project before? <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={communityBasedProjectBefore}
                        />
                      </div>

                      {communityBasedProjectBefore === 'Yes' ? (
                        <>
                          <div
                            class='col-lg-12 col-md-12 mb-3  text-left'
                            id='divShowCommunityBasedProjectBeforeYesReason'
                          >
                            <label for='registerFullName'>
                              (If yes please give details)
                              <span class='red-text'>*</span>{' '}
                            </label>
                            <textarea
                              class='form-control'
                              value={communityBasedProjectBeforeYesReason}
                            ></textarea>
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--Grid row-->*/}
                    {/* <!--Grid row-->		*/}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-0.001 text-left'>
                        <label for='registerFullName'>
                          Has the Community received any funding from any
                          organization <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={fundingFromAnyOrganization}
                        />
                      </div>

                      {fundingFromAnyOrganization === 'Yes' ? (
                        <>
                          <div
                            class='col-lg-12 col-md-12 mb-3 text-left'
                            id='divShowFundingFromAnyOrganizationYesReason'
                          >
                            <label for='registerFullName'>
                              If yes which organization and when and for what
                              activities?<span class='red-text'>*</span>{' '}
                            </label>
                            <textarea
                              class='form-control'
                              value={fundingFromAnyOrganizationYesReason}
                            ></textarea>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--\Grid column-->*/}
                    </div>
                    {/* <!--Grid row-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION B: PROJECT IDENTIFICATION{' '}
                        </h6>
                      </center>
                    </div>
                    {/*      <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*<!--<div class="md-form">-->	*/}
                        <label for='registerFullName'>
                          What are the main problems in the area?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          value={mainProblemsInTheArea}
                        ></textarea>
                      </div>
                      {/*<!--</div>-->	*/}
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*   <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Which of these problems is the proposed project trying
                          to address and how?<span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          Placeholder=''
                          value={problemsIsTheProposedProjectTryingToAddress}
                        ></textarea>
                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*  <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          How did the community identify the project? (Attach
                          minutes where applicable){' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          Placeholder=''
                          value={howDidTheCommunityIdentifyTheProject}
                        ></textarea>
                        {/*<!--</div>-->*/}
                      </div>
                      {/*   <!--\Grid column-->	*/}
                    </div>
                    <div class='row'>
                      {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                      'Upload File' ? (
                        <>
                          <div
                            class='col-md-12 mb-1 text-left'
                            id='projectIdentificationUploadFile'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Upload The Miniutes File{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                disabled
                              />
                            </div>
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*  <!--\Grid column-->	*/}

                      {/* <!--Grid column-->	*/}
                      {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                      'Paste' ? (
                        <>
                          {' '}
                          <div
                            class='col-md-12 mb-1'
                            id='projectIdentificationPaste'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Paste The Miniutes Here{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br />
                              <textarea
                                class='form-control'
                                value={projectIdentificationMinutes}
                              ></textarea>
                            </div>
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->   */}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          {' '}
                          Has anything been done before to address the problem
                          and if any, was it successfully implemented?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={
                            hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny
                          }
                        />
                      </div>
                      {/*<!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      {hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny ===
                      'Yes' ? (
                        <>
                          <div
                            class='col-md-12 mb-1 text-left'
                            id='hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAnyYesExplain'
                          >
                            {/*   <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              Explain if it was successfully implemented{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <textarea
                              class='form-control'
                              Placeholder=''
                              value={
                                hasAnythingBeenDoneBeforeToAddressTheProblemExplanation
                              }
                            ></textarea>
                            {/*	<!--</div>-->*/}
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*   <!--\Grid column-->			*/}
                    </div>
                    {/*  <!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          State the beneficiaries of the proposed project{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          value={stateTheBeneficiariesOfTheProposedProject}
                        ></textarea>
                        {/*	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->		*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*	<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*	<!--<div class="md-form">-->	*/}
                        <label for='registerFullName'>
                          What Is The Project Estimated Cost (ZMW):
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={whatIsTheEstimatedCostOfTheProject}
                        />
                        {/*	<!--</div>-->	*/}
                      </div>
                      {/*  <!--\Grid column-->*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-md-9 mb-1 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          How Will The Community Contribute Towards The Cost Of
                          The Project? (In terms of maintenance fees, water
                          supply, labor etc.) <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          Placeholder=''
                          value={
                            howWillTheCommunityContributeTowardsTheCostOfTheProject
                          }
                        ></textarea>
                        {/*	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {typeOrUploadProjectCommitteemembers === 'Type' ? (
                      <>
                        {' '}
                        <div id='projectCommitteeMembersType'>
                          {/*<!--Grid column-->*/}
                          <div class='col-md-12 mb-0.001'>
                            <br />
                            <center>
                              <i>
                                List Of Membership In The
                                Club/Group/Enterprise/Cooperative:
                              </i>
                            </center>
                          </div>
                          {/*<!--\Grid column--> */}

                          {committeeMembersList.map((x, i) => {
                            return (
                              <>
                                <div class='row'>
                                  <div class='col-md-1 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        {i + 1} .
                                      </label>
                                    </div>
                                  </div>

                                  <div class='col-md-4 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        Name
                                        <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br /> <br />
                                      <input
                                        type='Text'
                                        class='form-control'
                                        value={x.communityProjectName}
                                      />
                                    </div>
                                  </div>

                                  <div class='col-md-3 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        Position
                                        <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br /> <br />
                                      <input
                                        type='Text'
                                        class='form-control'
                                        value={x.communityProjectPosition}
                                      />
                                    </div>
                                  </div>

                                  <div class='col-lg-2 col-md-2 mb-2 text-left'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        {' '}
                                        Sex <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br /> <br />
                                      <input
                                        type='text'
                                        class='form-control'
                                        value={x.communityProjectSex}
                                      />
                                    </div>
                                  </div>

                                  <div class='col-md-2 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        NRC
                                        <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br /> <br />
                                      <input
                                        type='Text'
                                        class='form-control'
                                        value={x.communityProjectNrc}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <!--Grid row-->*/}
                    {typeOrUploadProjectCommitteemembers === 'Upload' ? (
                      <div class='row'>
                        {/*<!--Grid column-->	*/}
                        <div
                          class='col-md-12 mb-1 text-left'
                          id='projectCommitteeMembersUpload'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Committee Members File{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <br />
                            <br />
                            <input type='text' class='form-control' disabled />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->	*/}
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* <!--\Grid row-->*/}
                    {/*   <!--Grid row-->*/}
                    <div class='row'>
                      {/*  <!--Grid column-->	*/}
                      <div class='col-md-12 mb-2 text-left'>
                        {/*   <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          How Will The Community Ensure Project Sustainability?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          value={howWillTheCommunityEnsureProjectSustainability}
                        >
                          {' '}
                        </textarea>
                        {/* 	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/*  <!--\Grid row-->*/}
                    <br />
                    <h3>
                      <center>CONTACT PERSON(s): </center>
                    </h3>
                    <br />
                    <center>
                      <h4 class='red-text'>(Project Proposer)</h4>
                    </center>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*     <!--<div class="md-form">--->*/}
                        <label for='registerFullName'>
                          Name <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={ProjectProposerName}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 				*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          NRC <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={ProjectProposerNRC}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Address <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={ProjectProposerAddress}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 		*/}
                      {/*   <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Phone <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={ProjectProposerPhone}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Date <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control datepicker'
                          placeholder=''
                          value={ProjectProposerDate}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->			*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    <br />
                    <center>
                      <h4 class='red-text'>(Project Seconder)</h4>
                    </center>
                    {/* <!--Grid row-->*/}
                    <div class='row mb-4'>
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*      <!--<div class="md-form">--->*/}
                        <label for='registerFullName'>
                          Name <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={SeconderName}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          NRC <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={SeconderNRC}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Address <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={SeconderAddress}
                        />
                        {/*  <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 			*/}
                      {/*   <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Phone <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={SeconderPhone}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Date <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control datepicker'
                          placeholder=''
                          value={SeconderDate}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->			*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    <section class='section mt-5'>
                      {/*Grid row */}
                      <div class='row'>
                        {/* Grid column */}

                        <div class='col-lg-12 col-md-12'>
                          <div class='card mb-4'>
                            <div class='card-body'>
                              <div
                                class='form-header white-text'
                                style={{ backgroundColor: 'green' }}
                              >
                                <center>
                                  <span class='text-uppercase'>
                                    Application Checklist
                                  </span>
                                </center>
                              </div>
                              <table class='table large-header'>
                                <thead class='text-left'>
                                  <tr>
                                    <th class='font-weight-bold'>
                                      <strong>No</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Checklist</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Yes / No</strong>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody class='text-left'>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      Is the project related to key development
                                      priorities of the District included in the
                                      Integrated Development Plan or any local
                                      development framework?
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            value={
                                              isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework
                                            }
                                            onChange={e =>
                                              setIsTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFramework(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox1'
                                          title='Cancel Check'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            isTheProjectRelatedToKeyDevelopmentPrioritiesOfTheDistrictIncludedInTheIntegratedDevelopmentPlanOrAnyLocalDevelopmentFrameworkBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>
                                      Is the project benefitting a wide scope of
                                      community members?
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {isTheProjectBenefittingAWideScopeOfCommunityMembersDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            value={
                                              isTheProjectBenefittingAWideScopeOfCommunityMembers
                                            }
                                            onChange={e =>
                                              setIsTheProjectBenefittingAWideScopeOfCommunityMembers(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox2'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            isTheProjectBenefittingAWideScopeOfCommunityMembersDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            isTheProjectBenefittingAWideScopeOfCommunityMembersBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>
                                      Signed Minutes of Community meetings
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {signedMinutesOfCommunityMeetingsDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            value={
                                              signedMinutesOfCommunityMeetings
                                            }
                                            onChange={e =>
                                              setSignedMinutesOfCommunityMeetings(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox3'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            signedMinutesOfCommunityMeetingsDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            signedMinutesOfCommunityMeetingsBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>
                                      Proof of ownership documents in-case of
                                      projects involving construction (title
                                      deeds, community land)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            value={
                                              proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction
                                            }
                                            onChange={e =>
                                              setProofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstruction(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox4'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            proofOfOwnershipDocumentsIncaseOfProjectsInvolvingConstructionBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>Is the project feasible?</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {isTheProjectFeasibleDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            value={isTheProjectFeasible}
                                            onChange={e =>
                                              setIsTheProjectFeasible(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox5'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            isTheProjectFeasibleDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={isTheProjectFeasibleBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>
                                      Is the project located within the
                                      Constituency?
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {isTheProjectLocatedWithinTheConstituencyDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            value={
                                              isTheProjectLocatedWithinTheConstituency
                                            }
                                            onChange={e =>
                                              setIsTheProjectLocatedWithinTheConstituency(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox6'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            isTheProjectLocatedWithinTheConstituencyDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            isTheProjectLocatedWithinTheConstituencyBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>
                                      Will the project be jointly financed with
                                      another Constituency? If yes, name the
                                      Constituencies
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {willTheProjectBeJointlyFinancedWithAnotherConstituencyDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            value={
                                              willTheProjectBeJointlyFinancedWithAnotherConstituency
                                            }
                                            onChange={e =>
                                              setWillTheProjectBeJointlyFinancedWithAnotherConstituency(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox7'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            willTheProjectBeJointlyFinancedWithAnotherConstituencyDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            willTheProjectBeJointlyFinancedWithAnotherConstituencyBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>
                                      How many direct jobs will be created by
                                      the project?
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {howManyDirectJobsWillBeCreatedByTheProjectDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            value={
                                              howManyDirectJobsWillBeCreatedByTheProject
                                            }
                                            onChange={e =>
                                              setHowManyDirectJobsWillBeCreatedByTheProject(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox8'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            howManyDirectJobsWillBeCreatedByTheProjectDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            howManyDirectJobsWillBeCreatedByTheProjectBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>9</td>
                                    <td>
                                      Recommendation letter from the community
                                      leaders i.e. WDC, Councilors
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationLetterFromTheCommunityLeadersDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            value={
                                              recommendationLetterFromTheCommunityLeaders
                                            }
                                            onChange={e =>
                                              setRecommendationLetterFromTheCommunityLeaders(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox9'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationLetterFromTheCommunityLeadersDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            recommendationLetterFromTheCommunityLeadersBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>10</td>
                                    <td>
                                      Has the community contribution been agreed
                                      upon? If yes, indicate
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {hasTheCommunityContributionBeenAgreedUponDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            value={
                                              hasTheCommunityContributionBeenAgreedUpon
                                            }
                                            onChange={e =>
                                              setHasTheCommunityContributionBeenAgreedUpon(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox10'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            hasTheCommunityContributionBeenAgreedUponDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            hasTheCommunityContributionBeenAgreedUponBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Grid column */}
                      </div>
                    </section>
                    <section>
                      <div
                        class='card-header mt-5 mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION D: RECOMMENDATION BY THE WARD DEVELOPMENT
                            COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/*  <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Ward Development Committee{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            //name='recommendationByTheWardDevelopmentCommittee'
                            value={recommendationByTheWardDevelopmentCommittee}
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Recommended</option>
                            <option>Not Recommended</option>
                            <option>Defer</option>
                          </select>
                        </div>
                        {/*   <!--\Grid column-->				 */}
                        {/*     <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*       <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            name='recommendationByTheWardDevelopmentCommitteeReason'
                            value={
                              recommendationByTheWardDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                            required
                          >
                            {' '}
                          </textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->				*/}
                      </div>
                      {/* <!--\Grid row--> */}
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (WDC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonSign'
                              id='wardDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row--> */}

                      <section
                        class='section mt-5'
                        hidden={
                          processedByWardDB === '' || processedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>
                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Proccessed By
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={processedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={processedWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByWardDB === '' || approvedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>
                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (WDC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={approvedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={approvedByWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        onClick={handleSubmitWards}
                        disabled={
                          recommendationByTheWardDevelopmentCommittee === '' ||
                          recommendationByTheWardDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        Submit
                      </button>
                    </section>
                    <section
                      hidden={
                        userPosition === 'Councilor' ||
                        userPosition === 'WDC Chairperson' ||
                        userPosition === 'WDC Member'
                      }
                    >
                      <div
                        class='card-header mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION E: DECISION BY THE CONSTITUENCY DEVELOPMENT
                            FUND COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Constituency Development
                            Committee <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            value={
                              recommendationByTheConstituencyDevelopmentCommittee
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Approved</option>
                            <option>Not Approved</option>
                          </select>
                        </div>
                        {/*  <!--\Grid column-->		*/}
                        {/*      <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*      <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons For Recommendation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            value={
                              recommendationByTheConstituencyDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                          ></textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row-->*/}
                      {/* <!--Grid row-->*/}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/*<!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (CDFC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonSign'
                              id='constituencyDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                        {/* <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                      </div>

                      <section
                        class='section mt-5'
                        hidden={
                          processedByConstituencyDB === '' ||
                          processedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Processed By :
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={processedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={processedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByConstituencyDB === '' ||
                          approvedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonconstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (CDFC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={approvedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={approvedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        disabled={
                          recommendationByTheConstituencyDevelopmentCommittee ===
                            '' ||
                          recommendationByTheConstituencyDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                        onClick={handleSubmitConstituencies}
                      >
                        Submit
                      </button>
                      {/* <!-- Grid row -->*/}
                    </section>
                  </div>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default ApplicantCommunityProjectsView
