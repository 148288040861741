
import {useRef, useState, useEffect, useContext} from 'react';
import AuthContext from './context/AuthProvider';
import axios from './api/axios';
import {useNavigate, Navigate, Routes} from 'react-router-dom';
//import {ReactSession} from 'react-client-session';
//import Session from './api/Session';
//export {Sessions} from './Login';
const LOGIN_URL = '/auth';
const LoginAdmin = () => {
const {setAuth} = useContext(AuthContext);
const userRef = useRef();
const errRef = useRef();

const [user, setUser] = useState('');
const [pwd, setPwd ] = useState('');
const [errMsg, setErrMsg ] = useState('');
const [success, setSuccess ] = useState(false);
let navigate = useNavigate();
//const [data, setData] = useState([]);
const [data, setData] = useState("Iam Zambian");

useEffect(() => {
  localStorage.setItem('names', JSON.stringify(data));
}, [data]);

//localStorage.setItem("surname", JSON.stringify("Kanchele"));
  //const first = localStorage.getItem("userID");
    //const second = JSON.parse(first);
	//let second="Again Rodeen In Error";
     //alert(second);
	 
	 /*
	 // To store data
localStorage.setItem('Name', 'Rahul');

// To retrieve data
localStorage.getItem('Name');

// To clear a specific item
localStorage.removeItem('Name');

// To clear the whole data stored in localStorage
localStorage.clear();	 
	 */


useEffect( () => {
	userRef.current.focus();
},
[])
useEffect( () => {
	setErrMsg('');
},
[user, pwd])

const [ip,setIP] = useState('');

//creating function to load ip address from the API
    const getData = async()=>{
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
    }
  useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])

const handleSubmit = async (e) => 
{
    axios.post("http://localhost:3001/api/businessLogin", {

		user : user,
		pwd : pwd,
		user_IP : ip,
		
    }).then((res) => {
		
		if(res.data ==="Super Administrator"){
			localStorage.setItem("userID", JSON.stringify("0"));
				localStorage.setItem("systemSetupID", JSON.stringify("0"));
				localStorage.setItem("systemSetupBranchID", JSON.stringify("0"));
				localStorage.setItem("userPosition", JSON.stringify("Super Administrator"));
				localStorage.setItem("userRole", JSON.stringify("Super Administrator"));
                localStorage.setItem("login", JSON.stringify("Business"));
				//alert("User Id = "+val.id+"  UserName = "+val.lname);
				alert("Successfully Logged In");
navigate('/Home');
			}
		else if(res.data !="Failed To Login, Check Your Credentials"){
			res.data.map((val)=>{
			localStorage.setItem("userID", JSON.stringify(val.system_setupID));
				localStorage.setItem("systemSetupID", JSON.stringify(val.system_setupID));
				localStorage.setItem("systemSetupBranchID", JSON.stringify(val.system_setupID));
				localStorage.setItem("userPosition", JSON.stringify("Business"));
				localStorage.setItem("userRole", JSON.stringify("Business"));
                localStorage.setItem("login", JSON.stringify("Business"));
				//alert("User Id = "+val.id+"  UserName = "+val.lname);
				alert("Successfully Logged In");
navigate('/Home');
			})
			//alert("Successfully Logged In")
		}		
		else{
			alert(res.data);
		}
		
		
  
	  })  
	  .catch((err) => alert("ERROR : Kindly Contact Your Administrator")); 
}
	
	//Session.setName("Some Guy");
	//alert(Session.getName());
/*var Sessions = (function() {
    var full_name;
  
    var getName = function() {
      return full_name="my name of rodeen";    // Or pull this from cookie/localStorage
    };
  
    var setName = function(name) {
      full_name = name;     
      // Also set this in cookie/localStorage
    };
  //ReactSession.setStoreType("localStorage");
    return {
      getName: getName,
      setName: setName
    }
  
  })();*/
  
  
    return(
	 
	<>
	
<section class="view intro-2" id="login">
<div class=" rgba-indigo-light h-100 d-flex justify-content-center align-items-center" id="mask">
  <div class="container">
<div class="row"> 
<div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
 
   
	
	<div class="card wow fadeIn" data-wow-delay="0.3s">
                <div class="card-body" id="card-body">
	<div class="form-header purple-gradient">
                    <h3 class="font-weight-500 my-2 py-1">Business Admin Log in:</h3>
                  </div>
				  {/* <form onSubmit= {handleSubmit}> */}
        
		
		<div class="md-form">
		{/*<i class="fas fa-user prefix white-text"></i>*/}
                    <input type="text" id="username"  class="form-control"
        ref= {userRef} autoComplete = "off"
        onChange={(e) => setUser(e.target.value)}
        value={user}
        required
					/>
                    <label >Your Username</label>
                  </div>
		
		
		<div class="md-form">
		{/*<i class="fas fa-lock prefix white-text"></i>*/}
                    <input type="password" id="password"  class="form-control"
       onChange={(e) => setPwd(e.target.value)}
        value={pwd}
        required				
					/>
                    <label>Your password</label>
                  </div>
         <div class="text-center">
                    <button  class="btn purple-gradient btn-lg" onClick= {handleSubmit}  disabled={user ===''  || pwd ===''}>Login</button>
					
						{/* <hr class="mt-4" />
                    <p >
            Need An Account ? <br />
            
            <a href="register" class="blue-text"> Sign Up</a>
	</p>*/}
                  </div>
				  {/*<div class="text-center"> <hr class="mt-4" /> <p > <a href="Home" class="blue-text"> Home</a> </p> </div>*/}
				  
        
		{/*</form>*/}
	 </div>
              </div>
</div>
</div>
</div>
</div>
<hr />
<br />
<br />
<br />
<br />
<br />
<br />
</section> 

</>

 
    )

}

//export {Sessions} from './Login';
export default LoginAdmin;