import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../App.css'
import Header from './header'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
const NRC_REGEX = /(\d{5}[/]\d{2}[/]\d{1})/g
//[ZMB]\d{10})
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const RegisterUsers = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  let navigate = useNavigate()

  const [wardsID, setWardsID] = useState('')
  const [constituenciesID, setConstituenciesID] = useState('')
  const [provincesID, setProvinceID] = useState('')
  const [councilsID, setCouncilsID] = useState('')
  const [provinces, setProvinces] = useState([])
  const [councils, setCouncils] = useState([])
  const [constituencies, setConstituencies] = useState([])
  const [wards, setWards] = useState([])

  const [institution, setInstitution] = useState('')
  const [position, setPosition] = useState('')
  var [department, setDepartment] = useState('')
  const [systemRole, setSystemRole] = useState('')
  var [schoolType, setSchoolType] = useState('')
  var [schoolName, setSchoolName] = useState('')
  var [schoolNameOther, setSchoolNameOther] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [nrc, setNrc] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')

  /*if (schoolName === 'Other') {
    schoolName = schoolNameOther
  }*/

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)

  var userInstitution = localStorage.getItem('userInstitution')
  userInstitution = JSON.parse(userInstitution)

  var userOrganisation = localStorage.getItem('userOrganisation')
  userOrganisation = JSON.parse(userOrganisation)

  var loggedIn = localStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)

  var login = localStorage.getItem('login')
  login = JSON.parse(login)

  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var userDepartment = localStorage.getItem('userDepartment')
  userDepartment = JSON.parse(userDepartment)

  var userSystemRole = localStorage.getItem('userSystemRole')
  userSystemRole = JSON.parse(userSystemRole)

  var userProvincesID = localStorage.getItem('userProvincesID')
  userProvincesID = JSON.parse(userProvincesID)

  var userCouncilsID = localStorage.getItem('userCouncilsID')
  userCouncilsID = JSON.parse(userCouncilsID)

  var userConstituenciesID = localStorage.getItem('userConstituenciesID')
  userConstituenciesID = JSON.parse(userConstituenciesID)

  var userWardsID = localStorage.getItem('userWardsID')
  userWardsID = JSON.parse(userWardsID)

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let userPhone = localStorage.getItem('userPhone')
  userPhone = JSON.parse(userPhone)

  let userNRC = localStorage.getItem('userNRC')
  userNRC = JSON.parse(userNRC)

  let userEmail = localStorage.getItem('userEmail')
  userEmail = JSON.parse(userEmail)

  let userGender = localStorage.getItem('userGender')
  userGender = JSON.parse(userGender)

  //alert(provincesID)
  /* const businessNameRef = useRef()
  const errRef = useRef()

  //const [employeeImage, setEmployeeImage] = useState('')
  //const [employeeThumbPrint, setEmployeeThumbPrint] = useState('')
  //const [businessName, setBusinessName] = useState('')
  //const [businessBranchName, setBusinessBranchName] = useState('')
  //const [lastName, setLastName] = useState('')
  //const [firstName, setFirstName] = useState('')
  //const [position, setPosition] = useState('')
  //const [role, setRole] = useState('')
  const [ip, setIP] = useState('')
  const firstUserID = localStorage.getItem('userID')
  const userIDInitial = JSON.parse(firstUserID)
  const [userID, setUserID] = useState(userIDInitial)*/

  //const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  //const [nrc, setNrc] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  //const [email, setEmail] = useState('')
  const [validNrc, setValidNrc] = useState(false)
  const [nrcFocus, setNrcFocus] = useState(false)

  /*useEffect(() => {
    businessNameRef.current.focus()
  }, [])*/

  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = NRC_REGEX.test(nrc)
    setValidNrc(result)
  }, [nrc])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  //alert("id = "+systemSetupIDInitial);
*/

  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setProvinces(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', provincesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setCouncils(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [provincesID])

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', councilsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setConstituencies(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [provincesID, councilsID])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituenciesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setWards(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [provincesID, councilsID, constituenciesID])

  var roleClassAttr = ''
  var departmentLabelName = ''
  var provinceClassAttr = ''
  //var councilClassAttr = ''
  var constituencyClassAttr = ''
  var wardClassAttr = ''
  if (
    position === 'Director' ||
    position === 'DPO' ||
    position === 'Head Of Section' ||
    position === 'CDF Committee Member'
  ) {
    roleClassAttr = 'col-lg-3 col-md-2 mb-0.001'
    //setDepartment('')
    //alert(department)
  } else {
    roleClassAttr = 'col-lg-6 col-md-2 mb-0.001'

    //department = ''
    //alert(department)
  }

  if (position === 'CDF Committe Member') {
    departmentLabelName = 'CDF Committee'
  } else {
    departmentLabelName = 'Department'
  }
  //alert(roleClassAttr)
  let handleSubmitDisabled = ''
  if (
    position === 'Mayor' ||
    position === 'Town Clerk' ||
    position === 'Council Chairperson' ||
    position === 'Council Secretary'
  ) {
    handleSubmitDisabled =
      institution === '' ||
      provincesID === '' ||
      councilsID === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === ''
  }
  if (
    position === 'Director' ||
    position === 'DPO' ||
    position === 'Head Of Section'
  ) {
    handleSubmitDisabled =
      institution === '' ||
      provincesID === '' ||
      councilsID === '' ||
      position === '' ||
      department === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === ''
  }

  if (
    position === 'MP' ||
    position === 'CDF Committee Chairperson' ||
    position === 'CDF Committee Member'
  ) {
    constituencyClassAttr = 'col-lg-12 col-md-12 mb-0.001'
    handleSubmitDisabled =
      institution === '' ||
      provincesID === '' ||
      councilsID === '' ||
      constituenciesID === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === ''
  }
  if (institution === 'Education') {
    constituencyClassAttr = 'col-lg-3 col-md-12 mb-0.001'
    handleSubmitDisabled =
      institution === '' ||
      provincesID === '' ||
      councilsID === '' ||
      constituenciesID === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === '' ||
      schoolType === '' ||
      schoolName === ''
  } else {
    constituencyClassAttr = 'col-lg-6 col-md-12 mb-0.001'
  }

  if (
    position === 'Councilor' ||
    position === 'WDC Chairperson' ||
    position === 'WDC Member'
  ) {
    handleSubmitDisabled =
      institution === '' ||
      provincesID === '' ||
      councilsID === '' ||
      constituenciesID === '' ||
      wardsID === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === ''
  }
  if (
    institution === 'State House' ||
    institution === 'Ministry' ||
    institution === 'Public'
  ) {
    handleSubmitDisabled =
      institution === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === ''
  }
  if (institution === 'Province') {
    provinceClassAttr = 'col-lg-12 col-md-12 mb-0.001'
    handleSubmitDisabled =
      institution === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === ''
  } else {
    provinceClassAttr = 'col-lg-6 col-md-12 mb-0.001'
  }
  if (institution === 'Education') {
    wardClassAttr = 'col-lg-3 col-md-12 mb-0.001'
  }
  if (institution != 'Education') {
    wardClassAttr = 'col-lg-6 col-md-12 mb-0.001'
  }

  if (schoolName === 'Other') {
    handleSubmitDisabled =
      institution === '' ||
      provincesID === '' ||
      councilsID === '' ||
      constituenciesID === '' ||
      position === '' ||
      systemRole === '' ||
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      nrc === '' ||
      email === '' ||
      gender === '' ||
      schoolType === '' ||
      schoolName === '' ||
      schoolNameOther === ''
  }

  const handleSubmit = async e => {
    var formDataUpload = new FormData(this)
    formDataUpload.append('apiType', 'postUploadUserRegistration')
    formDataUpload.append('institution', institution)
    formDataUpload.append('position', position)
    formDataUpload.append('department', department)
    formDataUpload.append('cdfCommittee', department)
    formDataUpload.append('systemRole', systemRole)
    formDataUpload.append('schoolType', schoolType)
    formDataUpload.append('schoolName', schoolName)
    formDataUpload.append('schoolNameOther', schoolNameOther)
    formDataUpload.append('provincesID', provincesID)
    formDataUpload.append('councilsID', councilsID)
    formDataUpload.append('constituenciesID', constituenciesID)
    formDataUpload.append('wardsID', wardsID)
    formDataUpload.append('firstName', firstName)
    formDataUpload.append('lastName', lastName)
    formDataUpload.append('phone', phone)
    formDataUpload.append('nrc', nrc)
    formDataUpload.append('email', email)
    formDataUpload.append('gender', gender)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/registerUsers.php`,
      data: formDataUpload,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      alert(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div class='form-header white-text'>
                    {' '}
                    REGISTER USERS HERE{' '}
                  </div>
                  <div class='row'>
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class=''>
                        <label for='registerFullName' style={{ float: 'left' }}>
                          Institution
                          <span class='red-text'></span>
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          name='category'
                          id='category'
                          onChange={e => setInstitution(e.target.value)}
                        >
                          <option value=''>Choose...</option>
                          <option>Education</option>
                          {/*<option>Ward</option>
                          <option>Constituency</option>*/}
                          <option>Council</option>
                          <option hidden={userInstitution === 'Council'}>
                            Province
                          </option>
                          <option
                            hidden={
                              userInstitution === 'Council' ||
                              userInstitution === 'Province'
                            }
                          >
                            Ministry
                          </option>
                          <option
                            hidden={
                              userInstitution === 'Council' ||
                              userInstitution === 'Province' ||
                              userInstitution === 'Ministry'
                            }
                          >
                            State House
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class=''>
                        <label for='registerFullName' style={{ float: 'left' }}>
                          Position
                          <span class='red-text'></span>
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          name='category'
                          id='category'
                          onChange={e => setPosition(e.target.value)}
                          disabled={institution === 'Public'}
                        >
                          <option value=''>Choose....</option>
                          {institution === 'Council' ? (
                            <>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                WDC Member
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                WDC Chairperson
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Councilor
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson'
                                }
                              >
                                CDF Committee Member
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                CDF Committee Chairperson
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                MP
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Head Of Section
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                DPO
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Mayor' ||
                                  userPosition === 'Council Chairperson' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Director
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Town Clerk' ||
                                  userPosition === 'Mayor' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Council Secretary
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Town Clerk' ||
                                  userPosition === 'Council Secretary' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Council Chairperson
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Council Secretary' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Town Clerk
                              </option>
                              <option
                                hidden={
                                  userPosition === 'Council Secretary' ||
                                  userPosition === 'WDC Chairperson' ||
                                  userPosition === 'CDF Committee Chairperson'
                                }
                              >
                                Mayor
                              </option>
                            </>
                          ) : (
                            <></>
                          )}

                          {institution === 'Province' ? (
                            <>
                              <option>PLGO</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {institution === 'Ministry' ? (
                            <>
                              <option
                                hidden={
                                  userPosition ===
                                    'Permanent Secretary Technical' ||
                                  userPosition ===
                                    'Permanent Secretary Administration' ||
                                  userPosition === 'Deputy Director ICT'
                                }
                              >
                                Minister
                              </option>
                              <option
                                hidden={
                                  userPosition ===
                                    'Permanent Secretary Administration' ||
                                  userPosition === 'Deputy Director ICT'
                                }
                              >
                                Permanent Secretary Technical
                              </option>
                              <option
                                hidden={
                                  userPosition ===
                                    'Permanent Secretary Technical' ||
                                  userPosition === 'Deputy Director ICT'
                                }
                              >
                                Permanent Secretary Administration
                              </option>
                              <option
                                hidden={
                                  userPosition ===
                                    'Permanent Secretary Administration' ||
                                  userPosition === 'Deputy Director ICT'
                                }
                              >
                                Director ICT
                              </option>
                              <option
                                hidden={
                                  userPosition ===
                                    'Permanent Secretary Administration' ||
                                  userPosition === 'Deputy Director ICT'
                                }
                              >
                                Deputy Director ICT
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                          {institution === 'State House' ? (
                            <>
                              <option
                                hidden={userPosition === 'The Vice President'}
                              >
                                The Vice President
                              </option>
                              <option
                                hidden={userPosition === 'The Vice President'}
                              >
                                The President
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                          {institution === 'Education' ? (
                            <>
                              <option>Teacher</option>
                              <option>Deputy Head Teacher</option>
                              <option>Head Teacher</option>
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>

                    {position === 'Director' ||
                    position === 'DPO' ||
                    position === 'Head Of Section' ||
                    position === 'CDF Committee Member' ? (
                      <>
                        <div class='col-lg-3 col-md-12 mb-0.001'>
                          <div class=''>
                            <label
                              for='registerFullName'
                              style={{ float: 'left' }}
                            >
                              {departmentLabelName}
                              <span class='red-text'></span>
                            </label>
                            <select
                              class='custom-select d-block w-100'
                              name='category'
                              id='category'
                              onChange={e => setDepartment(e.target.value)}
                            >
                              <option value=''>Choose... </option>
                              <option>Finance</option>
                              <option>Planning</option>
                              <option>Engineering</option>
                              <option>Procurement</option>
                              <option>Audit</option>
                              <option>IT</option>
                            </select>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div class={roleClassAttr}>
                      <label for='registerFullName' style={{ float: 'left' }}>
                        System Role
                        <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        name='role'
                        id='role'
                        onChange={e => setSystemRole(e.target.value)}
                      >
                        <option value=''>Choose... </option>
                        <option>User</option>
                        <option>Station Administrator</option>
                        <option>System Administrator</option>
                      </select>
                    </div>
                  </div>
                  <div
                    hidden={
                      institution != 'Education' &&
                      institution != 'Council' &&
                      institution != 'Province'
                    }
                  >
                    <div class='row'>
                      <div class={provinceClassAttr}>
                        <div class=''>
                          <label
                            for='registerFullName'
                            style={{ float: 'left' }}
                          >
                            Province
                            <span class='red-text'></span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='province'
                            id='province'
                            onChange={e => setProvinceID(e.target.value)}
                          >
                            <option value=''>Choose....</option>
                            {provinces.map(valProvince => {
                              return (
                                <option value={valProvince.provincesID}>
                                  {valProvince.province}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div
                        class='col-lg-6 col-md-12 mb-0.001'
                        hidden={institution === 'Province'}
                      >
                        <div class=''>
                          <label
                            for='registerFullName'
                            style={{ float: 'left' }}
                          >
                            Council
                            <span class='red-text'></span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='council'
                            id='council'
                            onChange={e => setCouncilsID(e.target.value)}
                          >
                            <option value=''>Choose.... </option>
                            {councils.map(valCouncil => {
                              return (
                                <option value={valCouncil.councilsID}>
                                  {valCouncil.council}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      class='row'
                      hidden={
                        (institution === 'Province') | (position == 'Mayor') ||
                        position == 'Town Clerk' ||
                        position == 'Council Chairperson' ||
                        position == 'Council Secretary' ||
                        position === 'Director' ||
                        position === 'DPO' ||
                        position === 'Head Of Section'
                      }
                    >
                      <div class={constituencyClassAttr}>
                        <div class=''>
                          <label
                            for='registerFullName'
                            style={{ float: 'left' }}
                          >
                            Constituency
                            <span class='red-text'></span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='constituency'
                            id='constituency'
                            onChange={e => setConstituenciesID(e.target.value)}
                          >
                            <option value=''>Choose..... </option>
                            {constituencies.map(valConstituency => {
                              return (
                                <option
                                  value={valConstituency.constituenciesID}
                                >
                                  {valConstituency.constituency}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div
                        class={wardClassAttr}
                        hidden={
                          position == 'MP' ||
                          position == 'CDF Committee Chairperson' ||
                          position == 'CDF Committee Member'
                        }
                      >
                        <div class=''>
                          <label
                            for='registerFullName'
                            style={{ float: 'left' }}
                          >
                            Ward
                            <span class='red-text'></span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            name='ward'
                            id='ward'
                            onChange={e => setWardsID(e.target.value)}
                          >
                            <option value=''>Choose.... </option>
                            {wards.map(valWard => {
                              return (
                                <option value={valWard.wardsID}>
                                  {valWard.ward}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div
                        class='col-md-3 mb-0.001'
                        hidden={institution != 'Education'}
                      >
                        <label for='registerFullName' style={{ float: 'left' }}>
                          School Type
                          <span class='red-text'></span>
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          name='role'
                          id='role'
                          onChange={e => setSchoolType(e.target.value)}
                        >
                          <option value=''>Choose... </option>
                          <option>Secondary Boarding School</option>
                          <option>Skills Development School</option>
                        </select>
                      </div>

                      <div
                        class='col-md-3 mb-0.001'
                        hidden={institution != 'Education'}
                      >
                        <label for='registerFullName' style={{ float: 'left' }}>
                          School Name
                          <span class='red-text'></span>
                        </label>
                        <select
                          class='custom-select d-block w-100'
                          name='role'
                          id='role'
                          onChange={e => setSchoolName(e.target.value)}
                        >
                          <option value=''>Choose... </option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*<!--Grid row .-->*/}
                  <div class='row' hidden={schoolName != 'Other'}>
                    <div class='col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Type School Name Here <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          name='fname'
                          id='fname'
                          value={schoolNameOther}
                          onChange={e => setSchoolNameOther(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/*Start Of Row 2*/}
                  <div class='row'>
                    <div class='col-md-3 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          First Name <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          name='fname'
                          id='fname'
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Last Name <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          name='lname'
                          id='lname'
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Phone <i class='red-text'>*</i>
                          <span className={validPhone ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={
                              validPhone || !phone ? 'hide' : 'invalid'
                            }
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          name='phone'
                          id='phone'
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          aria-invalid={validPhone ? 'false' : 'true'}
                          aria-describedby='phonenote'
                          onFocus={() => setPhoneFocus(true)}
                          onBlur={() => setPhoneFocus(false)}
                        />
                        <p
                          id='phonenote'
                          className={
                            phoneFocus && phone && !validPhone
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          there should be 10 or more, but less than 14 numerical
                          values. <br />
                          You can also add + followed by your country or area
                          phone number code.
                        </p>
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          NRC <i class='red-text'>*</i>
                          <span className={validNrc ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={validNrc || !nrc ? 'hide' : 'invalid'}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          name='nrc'
                          id='nrc'
                          value={nrc}
                          onChange={e => setNrc(e.target.value)}
                          aria-invalid={validNrc ? 'false' : 'true'}
                          aria-describedby='nrcnote'
                          onFocus={() => setNrcFocus(true)}
                          onBlur={() => setNrcFocus(false)}
                        />
                        <p
                          id='nrcnote'
                          className={
                            nrcFocus && nrc && !validNrc
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          It Should Be A Valid NRC Number
                          <br />
                          The First Set Should Contain 6 Digits followed By a
                          Forward Slash (/), Then <br /> two Digits again
                          followed by another Forward Slash (/) then a Single
                          Digit.
                        </p>
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  {/*<!--Grid row 2-->*/}
                  <div class='row'>
                    <div class='col-md-6 mb-0.001'>
                      <div class='md-form'>
                        <label>
                          Email <i class='red-text'>*</i>
                          <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validEmail || !email ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          name='email'
                          id='email'
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          aria-invalid={validEmail ? 'false' : 'true'}
                          aria-describedby='emailnote'
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                        />

                        <p
                          id='emailnote'
                          className={
                            emailFocus && email && !validEmail
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          4 to 24 characters. <br />
                          Let It Be A Valid Email Address.
                        </p>
                      </div>
                    </div>

                    <div class='col-lg-6 col-md-2 mb-0.001'>
                      <label for='registerFullName' style={{ float: 'left' }}>
                        Gender
                        <span class='red-text'></span>
                      </label>

                      <select
                        class='custom-select d-block w-100'
                        name='gender'
                        id='gender'
                        onChange={e => setGender(e.target.value)}
                      >
                        <option value=''>Choose...</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                  </div>
                  {/*<!--\Grid row-->*/}
                  <hr class='mb-1' />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-primary'
                    disabled={handleSubmitDisabled}
                  >
                    Submit
                  </button>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default RegisterUsers
