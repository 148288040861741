import { useRef, useState, useEffect, useContext } from 'react'
import AuthContext from './context/AuthProvider'
import axios from './api/axios'
import { useNavigate, Navigate, Routes } from 'react-router-dom'
import Header from './components/header'
//import {ReactSession} from 'react-client-session';
//import Session from './api/Session';
//export {Sessions} from './Login';
const LOGIN_URL = '/auth'
const Login = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const { setAuth } = useContext(AuthContext)
  const userRef = useRef()
  const errRef = useRef()

  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;

  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)
  let navigate = useNavigate()
  //const [data, setData] = useState([]);

  sessionStorage.removeItem('reloadCount')

  useEffect(() => {
    localStorage.removeItem('userID')
    /*localStorage.removeItem("systemSetupID");
				localStorage.removeItem("systemSetupBranchID");
				localStorage.removeItem("userPosition");
				localStorage.removeItem("userRole");
				localStorage.removeItem("POSTransactionID");
				localStorage.removeItem('transactionIDPrintReceipt');
				localStorage.removeItem('transactionID');
				localStorage.removeItem('userOrganisation');
				localStorage.removeItem('userName');
				
				
sessionStorage.removeItem('reloadCount');
sessionStorage.removeItem('reloadCountHome');*/
  }, [])

  //localStorage.setItem("surname", JSON.stringify("Kanchele"));
  //const first = localStorage.getItem("userID");
  //const second = JSON.parse(first);
  //let second="Again Rodeen In Error";
  //alert(second);

  /*
	 // To store data
localStorage.setItem('Name', 'Rahul');

// To retrieve data
localStorage.getItem('Name');

// To clear a specific item
localStorage.removeItem('Name');

// To clear the whole data stored in localStorage
localStorage.clear();	 
	 */

  useEffect(() => {
    userRef.current.focus()
  }, [])
  useEffect(() => {
    setErrMsg('')
  }, [user, pwd])

  const [ip, setIP] = useState('')

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const handleSubmit = async e => {
    //  "homepage": "http://www.sims.kanchinnovativetechnologies.online/", 6585667
    //http://sims.kanchinnovativetechnologies.online/
    /*axios
      .post(`${server_app_api_php}/reactphp/login.php`, {
        //.post(`${server_app_api}/api/userLogin/`, {
        //axios.post("http://localhost:3001/api/userLogin/", {

        user: user,
        pwd: pwd,
        user_IP: ip
      })*/
    //  "homepage": "http://www.sims.kanchinnovativetechnologies.online/", 6585667
    //http://sims.kanchinnovativetechnologies.online/

    var formDataLogin = new FormData(this)
    formDataLogin.append('user', user)
    formDataLogin.append('pwd', pwd)
    formDataLogin.append('user_IP', ip)
    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/login.php`,
      data: formDataLogin,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        //alert(res.data);
        if (res.data === 'Super Administrator') {
          localStorage.setItem('userID', JSON.stringify(''))
          localStorage.setItem('userInstitution', JSON.stringify('GRZ-MLGRD'))
          localStorage.setItem(
            'userPosition',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem('userDepartment', JSON.stringify(''))
          localStorage.setItem(
            'userSystemRole',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem('userProvincesID', JSON.stringify(''))
          localStorage.setItem('userCouncilsID', JSON.stringify(''))
          localStorage.setItem('userConstituenciesID', JSON.stringify(''))
          localStorage.setItem('userWards', JSON.stringify(''))
          localStorage.setItem(
            'userName',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem('userPhone', JSON.stringify(''))
          localStorage.setItem('userNrc', JSON.stringify(''))
          localStorage.setItem('userEmail', JSON.stringify(''))
          localStorage.setItem('userGender', JSON.stringify(''))
          localStorage.setItem('userOrganisation', JSON.stringify('GRZ-MLGRD'))
          localStorage.setItem('loggedIn', JSON.stringify('Yes'))
          sessionStorage.setItem('loggedIn', JSON.stringify('Yes'))
          localStorage.setItem('login', JSON.stringify('User'))

          //alert("User Id = "+val.id+"  UserName = "+val.lname); 
          //alert('Successfully Logged In')
          navigate('/Home')
        //} else if (res.data != 'Failed To Login, Check Your Credentials') {
        } else if (res.data != 'Failed To Login, Check Your Credentials') {
          //alert(res.data.login);
          if(res.data.login === 'User'){
          localStorage.setItem('userID', JSON.stringify(res.data.userID))
          localStorage.setItem(
            'userInstitution',
            JSON.stringify(res.data.userInstitution)
          )
          localStorage.setItem(
            'userPosition',
            JSON.stringify(res.data.userPosition)
          )
          localStorage.setItem(
            'userDepartment',
            JSON.stringify(res.data.userDepartment)
          )
          localStorage.setItem(
            'userSystemRole',
            JSON.stringify(res.data.userSystemRole)
          )
          localStorage.setItem(
            'userProvincesID',
            JSON.stringify(res.data.userProvincesID)
          )
          localStorage.setItem(
            'userCouncilsID',
            JSON.stringify(res.data.userCouncilsID)
          )
          localStorage.setItem(
            'userConstituenciesID',
            JSON.stringify(res.data.userConstituenciesID)
          )
          localStorage.setItem(
            'userWardsID',
            JSON.stringify(res.data.userWardsID)
          )
          localStorage.setItem(
            'userOrganisationcategory',
            JSON.stringify(res.data.userOrganisationcategory)
          )
          localStorage.setItem(
            'userEducationBoardingID',
            JSON.stringify(res.data.userEducationBoardingID)
          )
          localStorage.setItem(
            'userEducationSkillsID',
            JSON.stringify(res.data.userEducationSkillsID)
          )
          localStorage.setItem(
            'userSchoolName',
            JSON.stringify(res.data.userSchoolName)
          )
          localStorage.setItem('userName', JSON.stringify(res.data.userName))
          localStorage.setItem('userPhone', JSON.stringify(res.data.userPhone))
          localStorage.setItem('userNrc', JSON.stringify(res.data.userNrc))
          localStorage.setItem('userEmail', JSON.stringify(res.data.userEmail))
          localStorage.setItem(
            'userGender',
            JSON.stringify(res.data.userGender)
          )
          localStorage.setItem(
            'userOrganisation',
            JSON.stringify(res.data.userOrganisation)
          )
          sessionStorage.setItem('loggedIn', JSON.stringify(res.data.loggedIn))
          localStorage.setItem('loggedIn', JSON.stringify(res.data.loggedIn))
          localStorage.setItem('login', JSON.stringify(res.data.login))

          //alert("User Id = "+val.id+"  UserName = "+val.lname);
          //alert('Successfully Logged In')
          navigate('/Home')
          }
          else if(res.data.login === 'Client'){
            localStorage.setItem('userID', JSON.stringify(res.data.userID))
            localStorage.setItem('userName', JSON.stringify(res.data.userName))
            localStorage.setItem('login', JSON.stringify(res.data.login))
            localStorage.setItem('loggedIn', JSON.stringify(res.data.loggedIn))
            sessionStorage.setItem('loggedIn', JSON.stringify(res.data.loggedIn))

            if(res.data.clientType === 'Grant'){
              navigate('/ApplyForGrantsProgress')
          }
          if(res.data.clientType === 'Loan'){
            navigate('/ApplyForLoansProgress')
          }
          if(res.data.clientType === 'Project'){
            navigate('/ApplyForProjectsProgress')
          }
          if(res.data.clientType === 'Secondary'){
            navigate('/ApplyForSecondaryProgress')
          }
          if(res.data.clientType === 'Skills'){
            navigate('/ApplyForSkillsProgress')
          }
        }
        } else {
          alert(res.data)
        }
      })
      .catch(err => alert('ERROR : Kindly Contact Your Administrator'))
    //.catch((err) => alert(err));
  }

  //Session.setName("Some Guy");
  //alert(Session.getName());
  /*var Sessions = (function() {
    var full_name;
  
    var getName = function() {
      return full_name="my name of rodeen";    // Or pull this from cookie/localStorage
    };
  
    var setName = function(name) {
      full_name = name;     
      // Also set this in cookie/localStorage
    };
  //ReactSession.setStoreType("localStorage");
    return {
      getName: getName,
      setName: setName
    }
  
  })();*/

  return (
    <>
      {/*<>
        <Header />
      </>*/}
      <section class='view intro-2' id='login'>
        <div
          class=' rgba-indigo-light h-100 d-flex justify-content-center align-items-center'
          id='mask'
        >
          <div class='container'>
            <div class='row'>
              <div class='col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
                <div class='card wow fadeIn' data-wow-delay='0.3s'>
                  <div class='card-body' id='card-body'>
                    <div class='form-header blue-gradient'>
                      <h3 class='font-weight-500 my-2 py-1'>
                        <i class='fas fa-user'></i> Login
                      </h3>
                    </div>
                    {/* <form onSubmit= {handleSubmit}> */}

                    <div class='md-form'>
                      {/*<i class="fas fa-user prefix white-text"></i>*/}
                      <input
                        type='text'
                        id='username'
                        class='form-control'
                        ref={userRef}
                        autoComplete='off'
                        onChange={e => setUser(e.target.value)}
                        value={user}
                        required
                      />
                      <label>Your Username</label>
                    </div>

                    <div class="row">
                    <div class="col-lx-9 col-lg-9 col-md-9">
                    <div class='md-form'>
                      {/*<i class="fas fa-lock prefix white-text"></i>*/}
                      <input
                        type={changePassword ? "password" : "text"}
                        id='password'
                        class='form-control md-outline'
                        onChange={e => setPwd(e.target.value)}
                        value={pwd}
                        required
                      />
                      <label>Your password</label>
                      </div>
                      </div>

                      <div class="col-lx-3 col-lg-3 col-md-3 mb-5">
                      <div class='md-form'>
                      <label type='button' class='fa fa-eye green-text'
                 onClick={() => { setChangePassword(changeIcon); }} > 
                 Password 
                 </label>
                 
                    </div>
                    </div>
                    </div>
                    <div class='text-center'>
                      <button
                        class='btn blue-gradient btn-lg'
                        onClick={handleSubmit}
                        disabled={user === '' || pwd === ''}
                      >
                        Login
                      </button>

                      <div class='inline-ul text-center d-flex justify-content-center'>
                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ width: '40%' }}>
                              <a
                                href='Main'
                                style={{ color: 'red' }}
                                title='Back To The Home Page'
                              >
                                <i
                                  class='fas fa-home'
                                  style={{ fontSize: '100%' }}
                                >
                                  Back
                                </i>{' '}
                              </a>
                            </td>
                            <td class='blue-text'>|</td>
                            <td>
                              <a href='ForgotPassword'>
                                <i class='fab fa red-text'>Forgot Password ?</i>
                              </a>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    {/*<div class="text-center"> <hr class="mt-4" /> <p > <a href="Home" class="blue-text"> Home</a> </p> </div>*/}

                    {/*</form>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
    </>
  )
}

//export {Sessions} from './Login';
export default Login
