import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ApplicantSecondaryBoardingSchoolsBursariesView = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let ApplicantSecondaryBoardingSchoolsBursariesViewID = localStorage.getItem(
    //'WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
    'ApplicantSecondaryBoardingSchoolsBursariesView'
  )
  ApplicantSecondaryBoardingSchoolsBursariesViewID = JSON.parse(
    ApplicantSecondaryBoardingSchoolsBursariesViewID
  )

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)
  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var [
    recommendationFromPreviousSponsor,
    setRecommendationFromPreviousSponsor
  ] = useState('')
  var [birthCertificatesOfApplicant, setBirthCertificatesOfApplicant] =
    useState('')
  var [deathCertificatesOfParents, setDeathCertificateOfParents] = useState('')
  var [paySlipsOfParent, setPaySlipsOfParent] = useState('')
  var [medicalRecordOfParent, setMedicalRecordOfParent] = useState('')
  var [disabilityCardOfParent, setDisabilityCardOfParent] = useState('')
  var [
    recommendationFromTraditionalLeadership,
    setRecommendationFromTraditionalLeadership
  ] = useState('')
  var [
    recommendationFromCommunityWelfareAssistanceCommittee,
    setRecommendationFromCommunityWelfareAssistanceCommittee
  ] = useState('')
  var [acceptanceLetter, setAcceptanceLetter] = useState('')
  var [copyOfApplicationForm, setCopyOfApplicationForm] = useState('')
  var [
    applicantToSignEachAndEveryPageOfThisApplicationDocument,
    setApplicantToSignEachAndEveryPageOfThisApplicationDocument
  ] = useState('')
  var [learnerIsOfSchoolGoingAge, setLearnerIsOfSchoolGoingAge] = useState('')
  var [
    aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting,
    setALearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
  ] = useState('')
  var [
    learnerMeetsMostOfTheFollowingCriteria,
    setLearnerMeetsMostOfTheFollowingCriteria
  ] = useState('')
  var [
    deathCertificatesOfParentsOrGuardian,
    setDeathCertificatesOfParentsOrGuardian
  ] = useState('')
  var [disabilityForms, setDisabilityForms] = useState('')
  var [recommendationLetters, setRecommendationLetters] = useState('')
  var [recommendationOrAssessmentReport, setRecommendationOrAssessmentReport] =
    useState('')

  var [
    recommendationByTheWardDevelopmentCommittee,
    setRecommendationByTheWardDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheWardDevelopmentCommitteeReason,
    setRecommendationByTheWardDevelopmentCommitteeReason
  ] = useState('')

  var [
    recommendationByTheConstituencyDevelopmentCommittee,
    setRecommendationByTheConstituencyDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheConstituencyDevelopmentCommitteeReason,
    setRecommendationByTheConstituencyDevelopmentCommitteeReason
  ] = useState('')

  const handleSubmitWards = async e => {
    //useEffect(() => {
    var formDataRecommendationByWards = new FormData(this)
    formDataRecommendationByWards.append('apiType', 'recommendationByWards')
    formDataRecommendationByWards.append(
      'applicantID',
      ApplicantSecondaryBoardingSchoolsBursariesViewID
    )
    formDataRecommendationByWards.append('userID', userID)
    formDataRecommendationByWards.append(
      'recommendation',
      recommendationByTheWardDevelopmentCommittee
    )
    formDataRecommendationByWards.append(
      'reason',
      recommendationByTheWardDevelopmentCommitteeReason
    )
    formDataRecommendationByWards.append(
      'table',
      'secondaryboardingschoolbursaryapplicationform'
    )
    formDataRecommendationByWards.append(
      'recommendationFromPreviousSponsor',
      recommendationFromPreviousSponsor
    )
    formDataRecommendationByWards.append(
      'birthCertificatesOfApplicant',
      birthCertificatesOfApplicant
    )
    formDataRecommendationByWards.append(
      'deathCertificatesOfParents',
      deathCertificatesOfParents
    )
    formDataRecommendationByWards.append('paySlipsOfParent', paySlipsOfParent)
    formDataRecommendationByWards.append(
      'medicalRecordOfParent',
      medicalRecordOfParent
    )
    formDataRecommendationByWards.append(
      'disabilityCardOfParent',
      disabilityCardOfParent
    )
    formDataRecommendationByWards.append(
      'recommendationFromTraditionalLeadership',
      recommendationFromTraditionalLeadership
    )
    formDataRecommendationByWards.append(
      'recommendationFromCommunityWelfareAssistanceCommittee',
      recommendationFromCommunityWelfareAssistanceCommittee
    )
    formDataRecommendationByWards.append('acceptanceLetter', acceptanceLetter)
    formDataRecommendationByWards.append(
      'copyOfApplicationForm',
      copyOfApplicationForm
    )
    formDataRecommendationByWards.append(
      'applicantToSignEachAndEveryPageOfThisApplicationDocument',
      applicantToSignEachAndEveryPageOfThisApplicationDocument
    )
    formDataRecommendationByWards.append(
      'learnerIsOfSchoolGoingAge',
      learnerIsOfSchoolGoingAge
    )
    formDataRecommendationByWards.append(
      'aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting',
      aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
    )
    formDataRecommendationByWards.append(
      'learnerMeetsMostOfTheFollowingCriteria',
      learnerMeetsMostOfTheFollowingCriteria
    )
    formDataRecommendationByWards.append(
      'deathCertificatesOfParentsOrGuardian',
      deathCertificatesOfParentsOrGuardian
    )
    formDataRecommendationByWards.append('disabilityForms', disabilityForms)
    formDataRecommendationByWards.append(
      'recommendationLetters',
      recommendationLetters
    )
    formDataRecommendationByWards.append(
      'recommendationOrAssessmentReport',
      recommendationOrAssessmentReport
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)

      setDBrecommendationFromPreviousSponsor(
        response.data.recommendationFromPreviousSponsor
      )
      setDBbirthCertificatesOfApplicant(
        response.data.birthCertificatesOfApplicant
      )
      setDBdeathCertificatesOfParents(response.data.deathCertificatesOfParents)
      setDBpaySlipsOfParent(response.data.paySlipsOfParent)
      setDBmedicalRecordOfParent(response.data.medicalRecordOfParent)
      setDBdisabilityCardOfParent(response.data.disabilityCardOfParent)
      setDBrecommendationFromTraditionalLeadership(
        response.data.recommendationFromTraditionalLeadership
      )
      setDBrecommendationFromCommunityWelfareAssistanceCommittee(
        response.data.recommendationFromCommunityWelfareAssistanceCommittee
      )
      setDBacceptanceLetter(response.data.acceptanceLetter)
      setDBcopyOfApplicationForm(response.data.copyOfApplicationForm)
      setDBapplicantToSignEachAndEveryPageOfThisApplicationDocument(
        response.data.applicantToSignEachAndEveryPageOfThisApplicationDocument
      )
      setDBlearnerIsOfSchoolGoingAge(response.data.learnerIsOfSchoolGoingAge)
      setDBaLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting(
        response.data
          .aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
      )
      setDBlearnerMeetsMostOfTheFollowingCriteria(
        response.data.learnerMeetsMostOfTheFollowingCriteria
      )
      setDBdeathCertificatesOfParentsOrGuardian(
        response.data.deathCertificatesOfParentsOrGuardian
      )
      setDBdisabilityForms(response.data.disabilityForms)
      setDBrecommendationLetters(response.data.recommendationLetters)
      setDBrecommendationOrAssessmentReport(
        response.data.recommendationOrAssessmentReport
      )

      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const handleSubmitConstituencies = async e => {
    //useEffect(() => {
    var formDataRecommendationByConstituencies = new FormData(this)
    formDataRecommendationByConstituencies.append(
      'apiType',
      'recommendationByConstituencies'
    )
    formDataRecommendationByConstituencies.append(
      'applicantID',
      ApplicantSecondaryBoardingSchoolsBursariesViewID
    )
    formDataRecommendationByConstituencies.append('userID', userID)
    formDataRecommendationByConstituencies.append(
      'recommendation',
      recommendationByTheConstituencyDevelopmentCommittee
    )
    formDataRecommendationByConstituencies.append(
      'reason',
      recommendationByTheConstituencyDevelopmentCommitteeReason
    )
    formDataRecommendationByConstituencies.append(
      'table',
      'secondaryboardingschoolbursaryapplicationform'
    )
    formDataRecommendationByConstituencies.append(
      'recommendationFromPreviousSponsor',
      recommendationFromPreviousSponsor
    )
    formDataRecommendationByConstituencies.append(
      'birthCertificatesOfApplicant',
      birthCertificatesOfApplicant
    )
    formDataRecommendationByConstituencies.append(
      'deathCertificatesOfParents',
      deathCertificatesOfParents
    )
    formDataRecommendationByConstituencies.append(
      'paySlipsOfParent',
      paySlipsOfParent
    )
    formDataRecommendationByConstituencies.append(
      'medicalRecordOfParent',
      medicalRecordOfParent
    )
    formDataRecommendationByConstituencies.append(
      'disabilityCardOfParent',
      disabilityCardOfParent
    )
    formDataRecommendationByConstituencies.append(
      'recommendationFromTraditionalLeadership',
      recommendationFromTraditionalLeadership
    )
    formDataRecommendationByConstituencies.append(
      'recommendationFromCommunityWelfareAssistanceCommittee',
      recommendationFromCommunityWelfareAssistanceCommittee
    )
    formDataRecommendationByConstituencies.append(
      'acceptanceLetter',
      acceptanceLetter
    )
    formDataRecommendationByConstituencies.append(
      'copyOfApplicationForm',
      copyOfApplicationForm
    )
    formDataRecommendationByConstituencies.append(
      'applicantToSignEachAndEveryPageOfThisApplicationDocument',
      applicantToSignEachAndEveryPageOfThisApplicationDocument
    )
    formDataRecommendationByConstituencies.append(
      'learnerIsOfSchoolGoingAge',
      learnerIsOfSchoolGoingAge
    )
    formDataRecommendationByConstituencies.append(
      'aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting',
      aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
    )
    formDataRecommendationByConstituencies.append(
      'learnerMeetsMostOfTheFollowingCriteria',
      learnerMeetsMostOfTheFollowingCriteria
    )
    formDataRecommendationByConstituencies.append(
      'deathCertificatesOfParentsOrGuardian',
      deathCertificatesOfParentsOrGuardian
    )
    formDataRecommendationByConstituencies.append(
      'disabilityForms',
      disabilityForms
    )
    formDataRecommendationByConstituencies.append(
      'recommendationLetters',
      recommendationLetters
    )
    formDataRecommendationByConstituencies.append(
      'recommendationOrAssessmentReport',
      recommendationOrAssessmentReport
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )

      setDBrecommendationFromPreviousSponsor(
        response.data.recommendationFromPreviousSponsor
      )
      setDBbirthCertificatesOfApplicant(
        response.data.birthCertificatesOfApplicant
      )
      setDBdeathCertificatesOfParents(response.data.deathCertificatesOfParents)
      setDBpaySlipsOfParent(response.data.paySlipsOfParent)
      setDBmedicalRecordOfParent(response.data.medicalRecordOfParent)
      setDBdisabilityCardOfParent(response.data.disabilityCardOfParent)
      setDBrecommendationFromTraditionalLeadership(
        response.data.recommendationFromTraditionalLeadership
      )
      setDBrecommendationFromCommunityWelfareAssistanceCommittee(
        response.data.recommendationFromCommunityWelfareAssistanceCommittee
      )
      setDBacceptanceLetter(response.data.acceptanceLetter)
      setDBcopyOfApplicationForm(response.data.copyOfApplicationForm)
      setDBapplicantToSignEachAndEveryPageOfThisApplicationDocument(
        response.data.applicantToSignEachAndEveryPageOfThisApplicationDocument
      )
      setDBlearnerIsOfSchoolGoingAge(response.data.learnerIsOfSchoolGoingAge)
      setDBaLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting(
        response.data
          .aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
      )
      setDBlearnerMeetsMostOfTheFollowingCriteria(
        response.data.learnerMeetsMostOfTheFollowingCriteria
      )
      setDBdeathCertificatesOfParentsOrGuardian(
        response.data.deathCertificatesOfParentsOrGuardian
      )
      setDBdisabilityForms(response.data.disabilityForms)
      setDBrecommendationLetters(response.data.recommendationLetters)
      setDBrecommendationOrAssessmentReport(
        response.data.recommendationOrAssessmentReport
      )
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const [ip, setIP] = useState('')
  //const [DBtableViewWards, setDBtableViewWards] = useState([])
  //const [DBtableViewWardName, setDBtableViewWardName] = useState([])
  const [surname, setsurname] = useState([])
  const [firstName, setfirstName] = useState([])
  const [otherNames, setotherNames] = useState([])
  const [gender, setgender] = useState([])
  const [dateOfBirth, setdateOfBirth] = useState([])
  const [birthCertificate, setbirthCertificate] = useState([])
  const [nationality, setnationality] = useState([])
  const [nrc, setnrc] = useState([])
  const [province, setprovince] = useState([])
  const [council, setcouncil] = useState([])
  const [constituency, setconstituency] = useState([])
  const [ward, setward] = useState([])
  const [zone, setzone] = useState([])
  const [villageOrTownship, setvillageOrTownship] = useState([])
  const [doYouHaveADisability, setdoYouHaveADisability] = useState([])
  const [doYouHaveADisabilityYesReason, setdoYouHaveADisabilityYesReason] =
    useState([])
  const [doYouHaveADisabilityYesFile, setdoYouHaveADisabilityYesFile] =
    useState([])
  const [
    nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted,
    setnameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
  ] = useState([])
  const [lastSchoolAttended, setlastSchoolAttended] = useState([])
  const [lastGradeAttended, setlastGradeAttended] = useState([])
  const [areYouABoarder, setareYouABoarder] = useState([])
  const [whoHasBeenPayingYourSchoolFees, setwhoHasBeenPayingYourSchoolFees] =
    useState([])
  const [
    haveYouBeenSupportedByAnyOrganization,
    sethaveYouBeenSupportedByAnyOrganization
  ] = useState([])
  const [
    haveYouBeenSupportedByAnyOrganizationYesExplanation,
    sethaveYouBeenSupportedByAnyOrganizationYesExplanation
  ] = useState([])
  const [fatherStatus, setfatherStatus] = useState([])
  const [fatherStatusFile, setfatherStatusFile] = useState([])
  const [fatherSurname, setfatherSurname] = useState([])
  const [fatherFirstName, setfatherFirstName] = useState([])
  const [fatherOtherNames, setfatherOtherNames] = useState([])
  const [fatherDateOfBirth, setfatherDateOfBirth] = useState([])
  const [fatherTelephoneNo, setfatherTelephoneNo] = useState([])
  const [fatherResidentialAddress, setfatherResidentialAddress] = useState([])
  const [fatherEmailAddress, setfatherEmailAddress] = useState([])
  const [fatherOccupation, setfatherOccupation] = useState([])
  const [fatherEmployer, setfatherEmployer] = useState([])
  const [fatherDisability, setfatherDisability] = useState([])
  const [pleaseSpecifyFatherDisability, setpleaseSpecifyFatherDisability] =
    useState([])
  const [fatherDisabilityDocumentation, setfatherDisabilityDocumentation] =
    useState([])
  const [fatherMedicalCondition, setfatherMedicalCondition] = useState([])
  const [
    pleaseSpecifyFatherMedicalCondition,
    setpleaseSpecifyFatherMedicalCondition
  ] = useState([])
  const [
    fatherMedicalConditionDocumentation,
    setfatherMedicalConditionDocumentation
  ] = useState([])
  const [motherStatus, setmotherStatus] = useState([])
  const [motherStatusFile, setmotherStatusFile] = useState([])
  const [motherSurname, setmotherSurname] = useState([])
  const [motherFirstName, setmotherFirstName] = useState([])
  const [motherOtherNames, setmotherOtherNames] = useState([])
  const [motherDateOfBirth, setmotherDateOfBirth] = useState([])
  const [motherTelephoneNo, setmotherTelephoneNo] = useState([])
  const [motherResidentialAddress, setmotherResidentialAddress] = useState([])
  const [motherEmailAddress, setmotherEmailAddress] = useState([])
  const [motherOccupation, setmotherOccupation] = useState([])
  const [motherEmployer, setmotherEmployer] = useState([])
  const [motherDisability, setmotherDisability] = useState([])
  const [pleaseSpecifyMotherDisability, setpleaseSpecifyMotherDisability] =
    useState([])
  const [motherDisabilityDocumentation, setmotherDisabilityDocumentation] =
    useState([])
  const [motherMedicalCondition, setmotherMedicalCondition] = useState([])
  const [
    pleaseSpecifyMotherMedicalCondition,
    setpleaseSpecifyMotherMedicalCondition
  ] = useState([])
  const [
    motherMedicalConditionDocumentation,
    setmotherMedicalConditionDocumentation
  ] = useState([])
  const [guardianStatus, setguardianStatus] = useState([])
  const [guardianStatusFile, setguardianStatusFile] = useState([])
  const [guardianSurname, setguardianSurname] = useState([])
  const [guardianFirstName, setguardianFirstName] = useState([])
  const [guardianOtherNames, setguardianOtherNames] = useState([])
  const [guardianDateOfBirth, setguardianDateOfBirth] = useState([])
  const [guardianTelephoneNo, setguardianTelephoneNo] = useState([])
  const [guardianResidentialAddress, setguardianResidentialAddress] = useState(
    []
  )
  const [guardianEmailAddress, setguardianEmailAddress] = useState([])
  const [guardianOccupation, setguardianOccupation] = useState([])
  const [guardianEmployer, setguardianEmployer] = useState([])
  const [guardianDisability, setguardianDisability] = useState([])
  const [pleaseSpecifyGuardianDisability, setpleaseSpecifyGuardianDisability] =
    useState([])
  const [guardianDisabilityDocumentation, setguardianDisabilityDocumentation] =
    useState([])
  const [guardianMedicalCondition, setguardianMedicalCondition] = useState([])
  const [
    pleaseSpecifyGuardianMedicalCondition,
    setpleaseSpecifyGuardianMedicalCondition
  ] = useState([])
  const [
    guardianMedicalConditionDocumentation,
    setguardianMedicalConditionDocumentation
  ] = useState([])

  const [house, sethouse] = useState([])
  const [houseOther, sethouseOther] = useState([])
  const [typeOfHouseMainMaterialOfRoof, settypeOfHouseMainMaterialOfRoof] =
    useState([])
  const [typeOfHouseMainMaterialOfFloor, settypeOfHouseMainMaterialOfFloor] =
    useState([])
  const [typeOfHouseMainMaterialOfWall, settypeOfHouseMainMaterialOfWall] =
    useState([])
  const [toilet, settoilet] = useState([])
  const [water, setwater] = useState([])
  const [waterOther, setwaterOther] = useState([])
  const [sourceOfWater, setsourceOfWater] = useState([])
  const [availabilityOfElectricity, setavailabilityOfElectricity] = useState([])
  const [mainSourceOfIncome, setmainSourceOfIncome] = useState([])
  const [numberOfMealsPerDay, setnumberOfMealsPerDay] = useState([])
  const [numberOfMealsPerDayOther, setnumberOfMealsPerDayOther] = useState([])
  const [
    doesYourHouseholdHaveAnyOfTheFollowingDurableItems,
    setdoesYourHouseholdHaveAnyOfTheFollowingDurableItems
  ] = useState([])
  const [
    doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther,
    setdoesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
  ] = useState([])
  const [
    doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal,
    setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
  ] = useState([])
  const [cattle, setcattle] = useState([])
  const [goats, setgoats] = useState([])
  const [sheep, setsheep] = useState([])
  const [pigs, setpigs] = useState([])
  const [poutry, setpoutry] = useState([])
  const [
    doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther,
    setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
  ] = useState([])
  const [learnerName, setlearnerName] = useState([])
  const [learnerAddress, setlearnerAddress] = useState([])
  const [learnerPhone, setlearnerPhone] = useState([])
  const [learnerNRC, setlearnerNRC] = useState([])
  const [contactName, setcontactName] = useState([])
  const [contactAddress, setcontactAddress] = useState([])
  const [contactPhone, setcontactPhone] = useState([])
  const [contactNRC, setcontactNRC] = useState([])
  const [siblingsList, setSiblingsList] = useState([])
  const [dependentsList, setDependentsList] = useState([])

  var [statusWardDB, setDBstatusWard] = useState([])
  var [statusProcessedWardDB, setDBstatusProcessedWard] = useState([])
  var [processedWardDateDB, setDBprocessedWardDate] = useState([])
  var [processedByWardDB, setDBprocessedByWard] = useState([])
  var [approvedByWardDB, setDBapprovedByWard] = useState([])
  var [approvedByWardDateDB, setDBapprovedByWardDate] = useState([])
  var [reasonWardDB, setDBreasonWard] = useState([])
  var [statusConstituencyDB, setDBstatusConstituency] = useState([])
  var [statusProcessedConstituencyDB, setDBstatusProcessedConstituency] =
    useState([])
  var [processedByConstituencyDB, setDBprocessedByConstituency] = useState([])
  var [processedConstituencyDateDB, setDBprocessedConstituencyDate] = useState(
    []
  )
  var [approvedByConstituencyDB, setDBapprovedByConstituency] = useState([])
  var [approvedConstituencyDateDB, setDBapprovedConstituencyDate] = useState([])
  var [reasonconstituencyDB, setDBreasonconstituency] = useState([])
  var [statusCouncilPrincipalDB, setDBstatusCouncilPrincipal] = useState([])
  var [processedByCouncilPrincipalDB, setDBprocessedByCouncilPrincipal] =
    useState([])
  var [processedCouncilPrincipalDateDB, setDBprocessedCouncilPrincipalDate] =
    useState([])
  var [approvedByCouncilPrincipalDB, setDBapprovedByCouncilPrincipal] =
    useState([])
  var [approvedCouncilPrincipalDateDB, setDBapprovedCouncilPrincipalDate] =
    useState([])
  var [reasonCouncilPrincipalDB, setDBreasonCouncilPrincipal] = useState([])
  var [tenureCouncilPrincipalDB, setDBtenureCouncilPrincipal] = useState([])
  var [statusPlanningDB, setDBstatusPlanning] = useState([])
  var [processedByPlanningDB, setDBprocessedByPlanning] = useState([])
  var [processedPlanningDateDB, setDBprocessedPlanningDate] = useState([])
  var [approvedByPlanningDB, setDBapprovedByPlanning] = useState([])
  var [approvedPlanningDateDB, setDBapprovedPlanningDate] = useState([])
  var [reasonPlanningDB, setDBreasonPlanning] = useState([])
  var [tenurePlanningDB, setDBtenurePlanning] = useState([])
  var [statusFinanceDB, setDBstatusFinance] = useState([])
  var [processedByFinanceDB, setDBprocessedByFinance] = useState([])
  var [processedFinanceDateDB, setDBprocessedFinanceDate] = useState([])
  var [approvedByFinanceDB, setDBapprovedByFinance] = useState([])
  var [approvedFinanceDateDB, setDBapprovedFinanceDate] = useState([])
  var [reasonFinanceDB, setDBreasonFinance] = useState([])
  var [tenureFinanceDB, setDBtenureFinance] = useState([])
  var [statusEngineeringDB, setDBstatusEngineering] = useState([])
  var [processedByEngineeringDB, setDBprocessedByEngineering] = useState([])
  var [processedEngineeringDateDB, setDBprocessedEngineeringDate] = useState([])
  var [approvedByEngineeringDB, setDBapprovedByEngineering] = useState([])
  var [approvedEngineeringDateDB, setDBapprovedEngineeringDate] = useState([])
  var [reasonEngineeringDB, setDBreasonEngineering] = useState([])
  var [tenureEngineeringDB, setDBtenureEngineering] = useState([])
  var [statusProcurementDB, setDBstatusProcurement] = useState([])
  var [processedByProcurementDB, setDBprocessedByProcurement] = useState([])
  var [processedProcurementDateDB, setDBprocessedProcurementDate] = useState([])
  var [approvedByProcurementDB, setDBapprovedByProcurement] = useState([])
  var [approvedProcurementDateDB, setDBapprovedProcurementDate] = useState([])
  var [reasonProcurementDB, setDBreasonProcurement] = useState([])
  var [tenureProcurementDB, setDBtenureProcurement] = useState([])
  var [statusMinistryDB, setDBstatusMinistry] = useState([])
  var [processedByMinistryDB, setDBprocessedByMinistry] = useState([])
  var [processedMinistryDateDB, setDBprocessedMinistryDate] = useState([])
  var [approvedByMinistryDB, setDBapprovedByMinistry] = useState([])
  var [approvedMinistryDateDB, setDBapprovedMinistryDate] = useState([])
  var [reasonMinistryDB, setDBreasonMinistry] = useState([])
  var [reasonProcessedWardDB, setDBreasonProcessedWard] = useState([])
  var [reasonProcessedConstituencyDB, setDBreasonProcessedConstituency] =
    useState([])
  var [reasonProcessedPlanningDB, setDBreasonProcessedPlanning] = useState([])
  var [reasonProcessedFinanceDB, setDBreasonProcessedFinance] = useState([])
  var [reasonProcessedEngineeringDB, setDBreasonProcessedEngineering] =
    useState([])
  var [reasonProcessedProcurementDB, setDBreasonProcessedProcurement] =
    useState([])
  var [
    reasonProcessedCouncilPrincipalDB,
    setDBreasonProcessedCouncilPrincipal
  ] = useState([])
  var [reasonProcessedMinistryDB, setDBreasonProcessedMinistry] = useState([])
  var [processedAmountWardDB, setDBprocessedAmountWard] = useState([])
  var [approvedAmountWardDB, setDBapprovedAmountWard] = useState([])
  var [processedAmountConstituencyDB, setDBprocessedAmountConstituency] =
    useState([])
  var [approvedAmountConstituencyDB, setDBapprovedAmountConstituency] =
    useState([])
  var [processedAmountMinistryDB, setDBprocessedAmountMinistry] = useState([])
  var [approvedAmountMinistryDB, setDBapprovedAmountMinistry] = useState([])

  var [
    recommendationFromPreviousSponsorDB,
    setDBrecommendationFromPreviousSponsor
  ] = useState([])
  var [birthCertificatesOfApplicantDB, setDBbirthCertificatesOfApplicant] =
    useState([])
  var [deathCertificatesOfParentsDB, setDBdeathCertificatesOfParents] =
    useState([])
  var [paySlipsOfParentDB, setDBpaySlipsOfParent] = useState([])
  var [medicalRecordOfParentDB, setDBmedicalRecordOfParent] = useState([])
  var [disabilityCardOfParentDB, setDBdisabilityCardOfParent] = useState([])
  var [
    recommendationFromTraditionalLeadershipDB,
    setDBrecommendationFromTraditionalLeadership
  ] = useState([])
  var [
    recommendationFromCommunityWelfareAssistanceCommitteeDB,
    setDBrecommendationFromCommunityWelfareAssistanceCommittee
  ] = useState([])
  var [acceptanceLetterDB, setDBacceptanceLetter] = useState([])
  var [copyOfApplicationFormDB, setDBcopyOfApplicationForm] = useState([])
  var [
    applicantToSignEachAndEveryPageOfThisApplicationDocumentDB,
    setDBapplicantToSignEachAndEveryPageOfThisApplicationDocument
  ] = useState([])
  var [learnerIsOfSchoolGoingAgeDB, setDBlearnerIsOfSchoolGoingAge] = useState(
    []
  )
  var [
    aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingDB,
    setDBaLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
  ] = useState([])
  var [
    learnerMeetsMostOfTheFollowingCriteriaDB,
    setDBlearnerMeetsMostOfTheFollowingCriteria
  ] = useState([])
  var [
    deathCertificatesOfParentsOrGuardianDB,
    setDBdeathCertificatesOfParentsOrGuardian
  ] = useState([])
  var [disabilityFormsDB, setDBdisabilityForms] = useState([])
  var [recommendationLettersDB, setDBrecommendationLetters] = useState([])
  var [
    recommendationOrAssessmentReportDB,
    setDBrecommendationOrAssessmentReport
  ] = useState([])
  var [statusProvinceDB, setDBstatusProvince] = useState([])
  var [processedByProvinceDB, setDBprocessedByProvince] = useState([])
  var [processedProvinceDateDB, setDBprocessedProvinceDate] = useState([])
  var [approvedByProvinceDB, setDBapprovedByProvince] = useState([])
  var [approvedProvinceDateDB, setDBapprovedProvinceDate] = useState([])
  var [reasonProvinceDB, setDBreasonProvince] = useState([])
  var [reasonProcessedProvinceDB, setDBreasonProcessedProvince] = useState([])
  var [processedAmountProvinceDB, setDBprocessedAmountProvince] = useState([])
  var [approvedAmountProvinceDB, setDBapprovedAmountProvince] = useState([])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantSecondaryBoardingSchoolsBursariesViewSiblings'
    )
    formData.append('id', ApplicantSecondaryBoardingSchoolsBursariesViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setSiblingsList(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantSecondaryBoardingSchoolsBursariesViewDependents'
    )
    formData.append('id', ApplicantSecondaryBoardingSchoolsBursariesViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDependentsList(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])
  //alert(ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID)
  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantSecondaryBoardingSchoolsBursariesView'
    )
    formData.append('id', ApplicantSecondaryBoardingSchoolsBursariesViewID)
    //formData.append('table', 'applyforcommunityproject')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setsurname(response.data.surname)
      setfirstName(response.data.firstName)
      setotherNames(response.data.otherNames)
      setgender(response.data.gender)
      setdateOfBirth(response.data.dateOfBirth)
      setbirthCertificate(response.data.birthCertificate)
      setnationality(response.data.nationality)
      setnrc(response.data.nrc)
      setprovince(response.data.province)
      setcouncil(response.data.council)
      setconstituency(response.data.constituency)
      setward(response.data.ward)
      setzone(response.data.zone)
      setvillageOrTownship(response.data.villageOrTownship)
      setdoYouHaveADisability(response.data.doYouHaveADisability)
      setdoYouHaveADisabilityYesReason(
        response.data.doYouHaveADisabilityYesReason
      )
      setdoYouHaveADisabilityYesFile(response.data.doYouHaveADisabilityYesFile)
      setnameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted(
        response.data.nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
      )
      setlastSchoolAttended(response.data.lastSchoolAttended)
      setlastGradeAttended(response.data.lastGradeAttended)
      setareYouABoarder(response.data.areYouABoarder)
      setwhoHasBeenPayingYourSchoolFees(
        response.data.whoHasBeenPayingYourSchoolFees
      )
      sethaveYouBeenSupportedByAnyOrganization(
        response.data.haveYouBeenSupportedByAnyOrganization
      )
      sethaveYouBeenSupportedByAnyOrganizationYesExplanation(
        response.data.haveYouBeenSupportedByAnyOrganizationYesExplanation
      )
      setfatherStatus(response.data.fatherStatus)
      setfatherStatusFile(response.data.fatherStatusFile)
      setfatherSurname(response.data.fatherSurname)
      setfatherFirstName(response.data.fatherFirstName)
      setfatherOtherNames(response.data.fatherOtherNames)
      setfatherDateOfBirth(response.data.fatherDateOfBirth)
      setfatherTelephoneNo(response.data.fatherTelephoneNo)
      setfatherResidentialAddress(response.data.fatherResidentialAddress)
      setfatherEmailAddress(response.data.fatherEmailAddress)
      setfatherOccupation(response.data.fatherOccupation)
      setfatherEmployer(response.data.fatherEmployer)
      setfatherDisability(response.data.fatherDisability)
      setpleaseSpecifyFatherDisability(
        response.data.pleaseSpecifyFatherDisability
      )
      setfatherDisabilityDocumentation(
        response.data.fatherDisabilityDocumentation
      )
      setfatherMedicalCondition(response.data.fatherMedicalCondition)
      setpleaseSpecifyFatherMedicalCondition(
        response.data.pleaseSpecifyFatherMedicalCondition
      )
      setfatherMedicalConditionDocumentation(
        response.data.fatherMedicalConditionDocumentation
      )
      setmotherStatus(response.data.motherStatus)
      setmotherStatusFile(response.data.motherStatusFile)
      setmotherSurname(response.data.motherSurname)
      setmotherFirstName(response.data.motherFirstName)
      setmotherOtherNames(response.data.motherOtherNames)
      setmotherDateOfBirth(response.data.motherDateOfBirth)
      setmotherTelephoneNo(response.data.motherTelephoneNo)
      setmotherResidentialAddress(response.data.motherResidentialAddress)
      setmotherEmailAddress(response.data.motherEmailAddress)
      setmotherOccupation(response.data.motherOccupation)
      setmotherEmployer(response.data.motherEmployer)
      setmotherDisability(response.data.motherDisability)
      setpleaseSpecifyMotherDisability(
        response.data.pleaseSpecifyMotherDisability
      )
      setmotherDisabilityDocumentation(
        response.data.motherDisabilityDocumentation
      )
      setmotherMedicalCondition(response.data.motherMedicalCondition)
      setpleaseSpecifyMotherMedicalCondition(
        response.data.pleaseSpecifyMotherMedicalCondition
      )
      setmotherMedicalConditionDocumentation(
        response.data.motherMedicalConditionDocumentation
      )
      setguardianStatus(response.data.guardianStatus)
      setguardianStatusFile(response.data.guardianStatusFile)
      setguardianSurname(response.data.guardianSurname)
      setguardianFirstName(response.data.guardianFirstName)
      setguardianOtherNames(response.data.guardianOtherNames)
      setguardianDateOfBirth(response.data.guardianDateOfBirth)
      setguardianTelephoneNo(response.data.guardianTelephoneNo)
      setguardianResidentialAddress(response.data.guardianResidentialAddress)
      setguardianEmailAddress(response.data.guardianEmailAddress)
      setguardianOccupation(response.data.guardianOccupation)
      setguardianEmployer(response.data.guardianEmployer)
      setguardianDisability(response.data.guardianDisability)
      setpleaseSpecifyGuardianDisability(
        response.data.pleaseSpecifyGuardianDisability
      )
      setguardianDisabilityDocumentation(
        response.data.guardianDisabilityDocumentation
      )
      setguardianMedicalCondition(response.data.guardianMedicalCondition)
      setpleaseSpecifyGuardianMedicalCondition(
        response.data.pleaseSpecifyGuardianMedicalCondition
      )
      setguardianMedicalConditionDocumentation(
        response.data.guardianMedicalConditionDocumentation
      )
      sethouse(response.data.house)
      sethouseOther(response.data.houseOther)
      settypeOfHouseMainMaterialOfRoof(
        response.data.typeOfHouseMainMaterialOfRoof
      )
      settypeOfHouseMainMaterialOfFloor(
        response.data.typeOfHouseMainMaterialOfFloor
      )
      settypeOfHouseMainMaterialOfWall(
        response.data.typeOfHouseMainMaterialOfWall
      )
      settoilet(response.data.toilet)
      setwater(response.data.water)
      setwaterOther(response.data.waterOther)
      setsourceOfWater(response.data.sourceOfWater)
      setavailabilityOfElectricity(response.data.availabilityOfElectricity)
      setmainSourceOfIncome(response.data.mainSourceOfIncome)
      setnumberOfMealsPerDay(response.data.numberOfMealsPerDay)
      setnumberOfMealsPerDayOther(response.data.numberOfMealsPerDayOther)
      setdoesYourHouseholdHaveAnyOfTheFollowingDurableItems(
        response.data.doesYourHouseholdHaveAnyOfTheFollowingDurableItems
      )
      setdoesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther(
        response.data.doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
      )
      setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal(
        response.data.doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
      )
      setcattle(response.data.cattle)
      setgoats(response.data.goats)
      setsheep(response.data.sheep)
      setpigs(response.data.pigs)
      setpoutry(response.data.poutry)
      setdoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther(
        response.data
          .doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
      )
      setlearnerName(response.data.learnerName)
      setlearnerAddress(response.data.learnerAddress)
      setlearnerPhone(response.data.learnerPhone)
      setlearnerNRC(response.data.learnerNRC)
      setcontactName(response.data.contactName)
      setcontactAddress(response.data.contactAddress)
      setcontactPhone(response.data.contactPhone)
      setcontactNRC(response.data.contactNRC)

      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBstatusCouncilPrincipal(response.data.statusCouncilPrincipal)
      setDBprocessedByCouncilPrincipal(
        response.data.processedByCouncilPrincipal
      )
      setDBprocessedCouncilPrincipalDate(
        response.data.processedCouncilPrincipalDate
      )
      setDBapprovedByCouncilPrincipal(response.data.approvedByCouncilPrincipal)
      setDBapprovedCouncilPrincipalDate(
        response.data.approvedCouncilPrincipalDate
      )
      setDBreasonCouncilPrincipal(response.data.reasonCouncilPrincipal)
      setDBtenureCouncilPrincipal(response.data.tenureCouncilPrincipal)
      setDBstatusPlanning(response.data.statusPlanning)
      setDBprocessedByPlanning(response.data.processedByPlanning)
      setDBprocessedPlanningDate(response.data.processedPlanningDate)
      setDBapprovedByPlanning(response.data.approvedByPlanning)
      setDBapprovedPlanningDate(response.data.approvedPlanningDate)
      setDBreasonPlanning(response.data.reasonPlanning)
      setDBtenurePlanning(response.data.tenurePlanning)
      setDBstatusFinance(response.data.statusFinance)
      setDBprocessedByFinance(response.data.processedByFinance)
      setDBprocessedFinanceDate(response.data.processedFinanceDate)
      setDBapprovedByFinance(response.data.approvedByFinance)
      setDBapprovedFinanceDate(response.data.approvedFinanceDate)
      setDBreasonFinance(response.data.reasonFinance)
      setDBtenureFinance(response.data.tenureFinance)
      setDBstatusEngineering(response.data.statusEngineering)
      setDBprocessedByEngineering(response.data.processedByEngineering)
      setDBprocessedEngineeringDate(response.data.processedEngineeringDate)
      setDBapprovedByEngineering(response.data.approvedByEngineering)
      setDBapprovedEngineeringDate(response.data.approvedEngineeringDate)
      setDBreasonEngineering(response.data.reasonEngineering)
      setDBtenureEngineering(response.data.tenureEngineering)
      setDBstatusProcurement(response.data.statusProcurement)
      setDBprocessedByProcurement(response.data.processedByProcurement)
      setDBprocessedProcurementDate(response.data.processedProcurementDate)
      setDBapprovedByProcurement(response.data.approvedByProcurement)
      setDBapprovedProcurementDate(response.data.approvedProcurementDate)
      setDBreasonProcurement(response.data.reasonProcurement)
      setDBtenureProcurement(response.data.tenureProcurement)
      setDBstatusMinistry(response.data.statusMinistry)
      setDBprocessedByMinistry(response.data.processedByMinistry)
      setDBprocessedMinistryDate(response.data.processedMinistryDate)
      setDBapprovedByMinistry(response.data.approvedByMinistry)
      setDBapprovedMinistryDate(response.data.approvedMinistryDate)
      setDBreasonMinistry(response.data.reasonMinistry)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )
      setDBreasonProcessedPlanning(response.data.reasonProcessedPlanning)
      setDBreasonProcessedFinance(response.data.reasonProcessedFinance)
      setDBreasonProcessedEngineering(response.data.reasonProcessedEngineering)
      setDBreasonProcessedProcurement(response.data.reasonProcessedProcurement)
      setDBreasonProcessedCouncilPrincipal(
        response.data.reasonProcessedCouncilPrincipal
      )
      setDBreasonProcessedMinistry(response.data.reasonProcessedMinistry)
      setDBprocessedAmountWard(response.data.processedAmountWard)
      setDBapprovedAmountWard(response.data.approvedAmountWard)
      setDBprocessedAmountConstituency(
        response.data.processedAmountConstituency
      )
      setDBapprovedAmountConstituency(response.data.approvedAmountConstituency)
      setDBprocessedAmountMinistry(response.data.processedAmountMinistry)
      setDBapprovedAmountMinistry(response.data.approvedAmountMinistry)

      setDBrecommendationFromPreviousSponsor(
        response.data.recommendationFromPreviousSponsor
      )
      setDBbirthCertificatesOfApplicant(
        response.data.birthCertificatesOfApplicant
      )
      setDBdeathCertificatesOfParents(response.data.deathCertificatesOfParents)
      setDBpaySlipsOfParent(response.data.paySlipsOfParent)
      setDBmedicalRecordOfParent(response.data.medicalRecordOfParent)
      setDBdisabilityCardOfParent(response.data.disabilityCardOfParent)
      setDBrecommendationFromTraditionalLeadership(
        response.data.recommendationFromTraditionalLeadership
      )
      setDBrecommendationFromCommunityWelfareAssistanceCommittee(
        response.data.recommendationFromCommunityWelfareAssistanceCommittee
      )
      setDBacceptanceLetter(response.data.acceptanceLetter)
      setDBcopyOfApplicationForm(response.data.copyOfApplicationForm)
      setDBapplicantToSignEachAndEveryPageOfThisApplicationDocument(
        response.data.applicantToSignEachAndEveryPageOfThisApplicationDocument
      )
      setDBlearnerIsOfSchoolGoingAge(response.data.learnerIsOfSchoolGoingAge)
      setDBaLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting(
        response.data
          .aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
      )
      setDBlearnerMeetsMostOfTheFollowingCriteria(
        response.data.learnerMeetsMostOfTheFollowingCriteria
      )
      setDBdeathCertificatesOfParentsOrGuardian(
        response.data.deathCertificatesOfParentsOrGuardian
      )
      setDBdisabilityForms(response.data.disabilityForms)
      setDBrecommendationLetters(response.data.recommendationLetters)
      setDBrecommendationOrAssessmentReport(
        response.data.recommendationOrAssessmentReport
      )

      setRecommendationFromPreviousSponsor(
        response.data.recommendationFromPreviousSponsor
      )
      setBirthCertificatesOfApplicant(
        response.data.birthCertificatesOfApplicant
      )

      setDeathCertificateOfParents(response.data.deathCertificatesOfParents)
      setPaySlipsOfParent(response.data.paySlipsOfParent)
      setMedicalRecordOfParent(response.data.medicalRecordOfParent)
      setDisabilityCardOfParent(response.data.disabilityCardOfParent)
      setRecommendationFromTraditionalLeadership(
        response.data.recommendationFromTraditionalLeadership
      )
      setRecommendationFromCommunityWelfareAssistanceCommittee(
        response.data.recommendationFromCommunityWelfareAssistanceCommittee
      )
      setAcceptanceLetter(response.data.acceptanceLetter)
      setCopyOfApplicationForm(response.data.copyOfApplicationForm)
      setApplicantToSignEachAndEveryPageOfThisApplicationDocument(
        response.data.applicantToSignEachAndEveryPageOfThisApplicationDocument
      )
      setLearnerIsOfSchoolGoingAge(response.data.learnerIsOfSchoolGoingAge)
      setALearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting(
        response.data
          .aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
      )
      setLearnerMeetsMostOfTheFollowingCriteria(
        response.data.learnerMeetsMostOfTheFollowingCriteria
      )
      setDeathCertificatesOfParentsOrGuardian(
        response.data.deathCertificatesOfParentsOrGuardian
      )
      setDisabilityForms(response.data.disabilityForms)
      setRecommendationLetters(response.data.recommendationLetters)
      setRecommendationOrAssessmentReport(
        response.data.recommendationOrAssessmentReport
      )

      setDBstatusProvince(response.data.statusProvince)
      setDBprocessedByProvince(response.data.processedByProvince)
      setDBprocessedProvinceDate(response.data.processedProvinceDate)
      setDBapprovedByProvince(response.data.approvedByProvince)
      setDBapprovedProvinceDate(response.data.approvedProvinceDate)
      setDBreasonProvince(response.data.reasonProvince)
      setDBreasonProcessedProvince(response.data.reasonProcessedProvince)
      setDBprocessedAmountProvince(response.data.processedAmountProvince)
      setDBapprovedAmountProvince(response.data.approvedAmountProvince)

      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  /*
  if (
    statusWardDB != 'Pending Approval By WDC' &&
    recommendationByTheWardDevelopmentCommittee === ''
  ) {
    recommendationByTheWardDevelopmentCommittee = statusWardDB
  }

  if (
    reasonWardDB != '' &&
    recommendationByTheWardDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheWardDevelopmentCommitteeReason = reasonWardDB
  }

  if (
    statusConstituencyDB != 'Pending Approval By CDFC' &&
    recommendationByTheConstituencyDevelopmentCommittee === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommittee = statusConstituencyDB
  }

  if (
    reasonconstituencyDB != '' &&
    recommendationByTheConstituencyDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommitteeReason =
      reasonconstituencyDB
  }

  let wardDevelopmentCommitteeChairpersonName = ''
  let wardDevelopmentCommitteeChairpersonDate = ''
  let constituencyDevelopmentCommitteeChairpersonName = ''
  let constituencyDevelopmentCommitteeChairpersonDate = ''
  let wardDevelopmentCommitteePosition = 'Name (WDC Chairperson):'
  let constituencyDevelopmentCommitteePosition = 'Name (CDFC Chairperson):'
  //alert(approvedByWardDB)
  if (
    processedByWardDB != '' &&
    processedByWardDB != null &&
    (approvedByWardDB === '' || approvedByWardDB === null)
  ) {
    wardDevelopmentCommitteeChairpersonName = processedByWardDB
    wardDevelopmentCommitteeChairpersonDate = processedWardDateDB
    wardDevelopmentCommitteePosition = 'Name (WDC Member):'
  }
  if (approvedByWardDB != '' && approvedByWardDB != null) {
    wardDevelopmentCommitteeChairpersonName = approvedByWardDB
    wardDevelopmentCommitteeChairpersonDate = approvedByWardDateDB
  }

  if (
    processedByConstituencyDB != '' &&
    processedByConstituencyDB != null &&
    (approvedByConstituencyDB === '' || approvedByConstituencyDB === null)
  ) {
    constituencyDevelopmentCommitteeChairpersonName = processedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate =
      processedConstituencyDateDB
    constituencyDevelopmentCommitteePosition = 'Name (CDFC Member):'
  }

  if (approvedByConstituencyDB != '' && approvedByConstituencyDB != null) {
    constituencyDevelopmentCommitteeChairpersonName = approvedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate = approvedConstituencyDateDB
  }
*/
  var [
    recommendationFromPreviousSponsorCheck,
    setCheckrecommendationFromPreviousSponsor
  ] = useState([])
  var [
    birthCertificatesOfApplicantCheck,
    setCheckbirthCertificatesOfApplicant
  ] = useState([])
  var [deathCertificatesOfParentsCheck, setCheckdeathCertificatesOfParents] =
    useState([])
  var [paySlipsOfParentCheck, setCheckpaySlipsOfParent] = useState([])
  var [medicalRecordOfParentCheck, setCheckmedicalRecordOfParent] = useState([])
  var [disabilityCardOfParentCheck, setCheckdisabilityCardOfParent] = useState(
    []
  )
  var [
    recommendationFromTraditionalLeadershipCheck,
    setCheckrecommendationFromTraditionalLeadership
  ] = useState([])
  var [
    recommendationFromCommunityWelfareAssistanceCommitteeCheck,
    setCheckrecommendationFromCommunityWelfareAssistanceCommittee
  ] = useState([])
  var [acceptanceLetterCheck, setCheckacceptanceLetter] = useState([])
  var [copyOfApplicationFormCheck, setCheckcopyOfApplicationForm] = useState([])
  var [
    applicantToSignEachAndEveryPageOfThisApplicationDocumentCheck,
    setCheckapplicantToSignEachAndEveryPageOfThisApplicationDocument
  ] = useState([])
  var [learnerIsOfSchoolGoingAgeCheck, setChecklearnerIsOfSchoolGoingAge] =
    useState([])
  var [
    aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingCheck,
    setCheckaLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
  ] = useState([])
  var [
    learnerMeetsMostOfTheFollowingCriteriaCheck,
    setChecklearnerMeetsMostOfTheFollowingCriteria
  ] = useState([])
  var [
    deathCertificatesOfParentsOrGuardianCheck,
    setCheckdeathCertificatesOfParentsOrGuardian
  ] = useState([])
  var [disabilityFormsCheck, setCheckdisabilityForms] = useState([])
  var [recommendationLettersCheck, setCheckrecommendationLetters] = useState([])
  var [
    recommendationOrAssessmentReportCheck,
    setCheckrecommendationOrAssessmentReport
  ] = useState([])

  const recommendationFromPreviousSponsorBtn = async e => {
    setRecommendationFromPreviousSponsor('false')
  }
  const birthCertificatesOfApplicantBtn = async e => {
    setBirthCertificatesOfApplicant('false')
  }
  const deathCertificatesOfParentsBtn = async e => {
    setDeathCertificateOfParents('false')
  }
  const paySlipsOfParentBtn = async e => {
    setPaySlipsOfParent('false')
  }
  const medicalRecordOfParentBtn = async e => {
    setMedicalRecordOfParent('false')
  }
  const disabilityCardOfParentBtn = async e => {
    setDisabilityCardOfParent('false')
  }
  const recommendationFromTraditionalLeadershipBtn = async e => {
    setRecommendationFromTraditionalLeadership('false')
  }
  const recommendationFromCommunityWelfareAssistanceCommitteeBtn = async e => {
    setRecommendationFromCommunityWelfareAssistanceCommittee('false')
  }
  const acceptanceLetterBtn = async e => {
    setAcceptanceLetter('false')
  }
  const copyOfApplicationFormBtn = async e => {
    setCopyOfApplicationForm('false')
  }
  const applicantToSignEachAndEveryPageOfThisApplicationDocumentBtn =
    async e => {
      setApplicantToSignEachAndEveryPageOfThisApplicationDocument('false')
    }
  const learnerIsOfSchoolGoingAgeBtn = async e => {
    setLearnerIsOfSchoolGoingAge('false')
  }
  const aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingBtn =
    async e => {
      setALearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting(
        'false'
      )
    }
  const learnerMeetsMostOfTheFollowingCriteriaBtn = async e => {
    setLearnerMeetsMostOfTheFollowingCriteria('false')
  }
  const deathCertificatesOfParentsOrGuardianBtn = async e => {
    setDeathCertificatesOfParentsOrGuardian('false')
  }
  const disabilityFormsBtn = async e => {
    setDisabilityForms('false')
  }
  const recommendationLettersBtn = async e => {
    setRecommendationLetters('false')
  }
  const recommendationOrAssessmentReportBtn = async e => {
    setRecommendationOrAssessmentReport('false')
  }

  if (recommendationFromPreviousSponsor === 'false') {
    recommendationFromPreviousSponsorDB = 'false'
  }
  if (birthCertificatesOfApplicant === 'false') {
    birthCertificatesOfApplicantDB = 'false'
  }
  if (deathCertificatesOfParents === 'false') {
    deathCertificatesOfParentsDB = 'false'
  }
  if (paySlipsOfParent === 'false') {
    paySlipsOfParentDB = 'false'
  }
  if (medicalRecordOfParent === 'false') {
    medicalRecordOfParentDB = 'false'
  }
  if (disabilityCardOfParent === 'false') {
    disabilityCardOfParentDB = 'false'
  }
  if (recommendationFromTraditionalLeadership === 'false') {
    recommendationFromTraditionalLeadershipDB = 'false'
  }
  if (recommendationFromCommunityWelfareAssistanceCommittee === 'false') {
    recommendationFromCommunityWelfareAssistanceCommitteeDB = 'false'
  }
  if (acceptanceLetter === 'false') {
    acceptanceLetterDB = 'false'
  }
  if (copyOfApplicationForm === 'false') {
    copyOfApplicationFormDB = 'false'
  }
  if (applicantToSignEachAndEveryPageOfThisApplicationDocument === 'false') {
    applicantToSignEachAndEveryPageOfThisApplicationDocumentDB = 'false'
  }
  if (learnerIsOfSchoolGoingAge === 'false') {
    learnerIsOfSchoolGoingAgeDB = 'false'
  }
  if (
    aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting === 'false'
  ) {
    aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingDB = 'false'
  }
  if (learnerMeetsMostOfTheFollowingCriteria === 'false') {
    learnerMeetsMostOfTheFollowingCriteriaDB = 'false'
  }
  if (deathCertificatesOfParentsOrGuardian === 'false') {
    deathCertificatesOfParentsOrGuardianDB = 'false'
  }
  if (disabilityForms === 'false') {
    disabilityFormsDB = 'false'
  }
  if (recommendationLetters === 'false') {
    recommendationLettersDB = 'false'
  }
  if (recommendationOrAssessmentReport === 'false') {
    recommendationOrAssessmentReportDB = 'false'
  }

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text'>
                    {' '}
                    <span class='text-uppercase'>
                      {surname} {otherNames} {firstName} APPLICATION FORM -
                      SECONDARY BOARDING SCHOOL BURSARY
                    </span>
                  </div>
                  {/*  <!-- Grid row -->*/}
                  <div class='col-12'>
                    <br /> <br />
                    <section>
                      <div class='text-left'>
                        <center>
                          {' '}
                          <img src={require('./img/coat of arms.jpg')} />
                          <strong>
                            <br />
                            CONSTITUENCY DEVELOPMENT FUND (CDF) <br />
                            SECONDARY BOARDING SCHOOL BURSARY APPLICATION FORM
                          </strong>
                        </center>
                        <br />
                        <strong>Instructions:</strong> This application form
                        should be completed by the applicant and sent together
                        with supporting documents to the Chairperson, Ward
                        Development Committee. <br />
                        <u>
                          <b>NOTE:</b>
                        </u>
                        This form is not for sale.
                        <br />
                        <i>
                          Disclaimer: Completion of the form does not guarantee
                          the award of Secondary Boarding School Bursary.
                        </i>
                      </div>

                      <br />
                    </section>
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION A: GENERAL DETAILS{' '}
                          <span class='yellow-text'>
                            (To be filled by the applicant)
                          </span>{' '}
                        </h6>
                      </center>
                    </div>
                    <br />
                    <div id='divShowMessageFromAjax'></div>
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Surname<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={surname}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            First Name<span class='red-text'>*</span>
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={firstName}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Other Names<span class='red-text'>*</span>
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={otherNames}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Gender<span class='red-text'>*</span>
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={gender}
                          />
                        </div>
                      </div>
                      {/*       <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->	*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-6 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date Of Birth <span class='red-text'>*</span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            placeholder=''
                            value={dateOfBirth}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->	*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-md-6 mb-0.001 text-left'>
                        {/*<div class="md-form">*/}
                        <label for='registerFullName'>
                          (Attach birth certificate where Possible){' '}
                          <span class='red-text'></span>
                        </label>
                        <br />
                        <br />
                        <input type='text' class='form-control' disabled />
                        {/*</div>*/}
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row-->	*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*   <!--Grid column-->	*/}
                      <div class='col-md-6 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Nationality <span class='red-text'>*</span>
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={nationality}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}

                      {/* <!--Grid column-->	*/}
                      <div class='col-md-6 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            NRC NO. (where applicable){' '}
                            <span class='red-text'></span>
                          </label>
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={nrc}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row-->	*/}
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/* <!--Grid column -->*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        <label for='registerFullName'>
                          Provinces<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={province}
                        />
                      </div>
                      {/* <!--\Grid column-->*/}

                      {/* <!--Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-0.01 text-left'
                        id='hideLocalAuthority'
                      >
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Council<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={council}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {/*<!--\Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-1 text-left'
                        id='hideConstituency'
                      >
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Constituency<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={constituency}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {/* <!--\Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-1 text-left'
                        id='hideWard'
                      >
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Ward<span class='red-text'>*</span>{' '}
                        </label>
                        <input type='text' class='form-control' value={ward} />
                        {/*<!--</div>-->*/}
                      </div>
                      {/* <!--Grid column -->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-0.01'
                        style={{ display: 'none' }}
                        id='divShowWard'
                      ></div>
                      {/*<!--\Grid column-->*/}

                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Zone<span class='red-text'>*</span>
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={zone}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-5 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Village/Township <span class='red-text'>*</span>
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={villageOrTownship}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/* <!-- <div class="md-form">->*/}
                        <label for='registerFullName'>
                          Do you have a disability/special need?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <br />
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          value={doYouHaveADisability}
                        />
                        {/* <!--</div>->*/}
                      </div>
                      {/* <!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div
                        class='col-md-12 mb-0.001 text-left'
                        id='hideDoYouHaveADisability'
                      ></div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row-->			*/}
                    {/*<!--Grid row-->*/}
                    <div class='row' id='showDoYouHaveADisability'>
                      {/*   <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1'>
                        <div class='md-form'>
                          {doYouHaveADisability === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Disability{' '}
                                <span class='red-text'>*</span>
                              </label>
                              <br />
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                value={doYouHaveADisabilityYesReason}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}

                      {/* <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*<div class="md-form">*/}
                        {doYouHaveADisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Attach Relevant Disability Documentation{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <br />
                            <br />
                            <input type='text' class='form-control' disabled />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*</div>*/}
                      </div>
                      {/*  <!--\Grid column-->			*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          B. SCHOOL DETAILS (where you are enrolled or have been
                          accepted) <br />
                          (Attach a copy of an acceptance letter or confirmation
                          letter from the school, this is a must)
                        </h6>
                      </center>
                    </div>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*  <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Name Of School Where You Are Enrolled Or Have Been
                          Accepted <span class='red-text'>*</span>
                        </label>
                        <input
                          class='form-control'
                          Placeholder=''
                          value={
                            nameOfSchoolWhereYouAreEnrolledOrHaveBeenAccepted
                          }
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*<!--     <div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Last School Attended <span class='red-text'>*</span>
                        </label>
                        <input
                          class='form-control'
                          Placeholder=''
                          value={lastSchoolAttended}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*<!--    <div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Last Grade Attended <span class='red-text'>*</span>
                        </label>
                        <input
                          class='form-control'
                          Placeholder=''
                          value={lastGradeAttended}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Are you/ where you a boarder?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={areYouABoarder}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*    <!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*    <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Who has been paying your school fees{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          class='form-control'
                          Placeholder=''
                          value={whoHasBeenPayingYourSchoolFees}
                        />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*   <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Have you been supported by any organization?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={haveYouBeenSupportedByAnyOrganization}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}

                      {/*	<!--Grid column-->	*/}
                      <div
                        class='col-md-12 mb-0.001 text-left'
                        id='hideHaveYouBeenSupportedByAnyOrganization'
                      ></div>
                      {/*     <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*  <!--Grid row-->*/}
                    <div
                      class='row'
                      id='showHaveYouBeenSupportedByAnyOrganization'
                    >
                      {/*   <!--Grid column-->	*/}
                      <div class='col-md-12 mb-1'>
                        <div class='md-form'>
                          {haveYouBeenSupportedByAnyOrganization === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                kindly give details
                                <span class='red-text'>*</span>
                              </label>
                              <br /> <br /> <br />
                              <textarea
                                class='form-control'
                                Placeholder=''
                                value={
                                  haveYouBeenSupportedByAnyOrganizationYesExplanation
                                }
                              ></textarea>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                    </div>
                    {/*<!--\Grid row-->   */}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          {' '}
                          C. DETAILS OF PARENTS /GUARDIANS{' '}
                        </h6>
                      </center>
                    </div>
                    <div class='row'>
                      <div class='col-md-12 mb-1'>
                        <br />
                        <center>
                          <h6>FATHER</h6>
                        </center>
                      </div>
                    </div>
                    {/*  <!--\Grid row--> */}
                    {/*  <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-6 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Is Your Father ? <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={fatherStatus}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}

                      {/*  <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*   <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Attach documentation where applicable
                          <span class='red-text'></span>
                        </label>
                        <input type='text' class='form-control' disabled />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->		*/}
                    </div>
                    {/*<!--\Grid row--> */}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Surname<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherSurname}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            First Name<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherFirstName}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Other Names<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherOtherNames}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date of Birth<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='date'
                            class='form-control'
                            Placeholder=''
                            value={fatherDateOfBirth}
                          />
                        </div>
                      </div>
                      {/*       <!--\Grid column-->		*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Telephone No<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherTelephoneNo}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                    </div>
                    {/*  <!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    <div class='row'>
                      {/*  <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Residential Address<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherResidentialAddress}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Email Address<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={fatherEmailAddress}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Occupation<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherOccupation}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Employer/Nature of Business
                            <span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={fatherEmployer}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->	*/}
                    </div>
                    {/*  <!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Does Your father have a disability/special need?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={fatherDisability}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}
                      {/*<!--Grid column-->*/}

                      {/* <!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    <div class='row' id='showFatherDisabilityYes'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md'>
                          {fatherDisability === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Disability{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <textarea
                                class='form-control'
                                placeholder=''
                                value={pleaseSpecifyFatherDisability}
                              >
                                {' '}
                              </textarea>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/*	 <!--\Grid column--> */}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*         <!--<div class="md-form">-->*/}
                        {fatherDisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Attach Relevant Disability Documentation{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <input type='text' class='form-control' disabled />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*  <!--Grid row--> */}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Does father have medical condition?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={fatherMedicalCondition}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}
                      {/*<!--Grid column-->*/}

                      {/* <!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*  <!--Grid row--> */}
                    <div class='row' id='showFatherMedicalConditionYes'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md'>
                          {fatherMedicalCondition === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Medical Condition Documentation{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <textarea
                                class='form-control'
                                placeholder=''
                                value={pleaseSpecifyFatherMedicalCondition}
                              >
                                {' '}
                              </textarea>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column--> */}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md-form'>
                          {fatherMedicalCondition === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Attach Relevant Medical Condition Documentation{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                disabled
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column--> */}
                    </div>
                    {/*<!--\Grid row--> */}
                    <div class='row'>
                      <div class='col-md-12 mb-1'>
                        <br />
                        <center>
                          <h6>MOTHER</h6>
                        </center>
                      </div>
                    </div>
                    {/*  <!--\Grid row--> */}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-6 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Is Your Mother ? <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={motherStatus}
                        />
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*    <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*    <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Attach documentation where applicable
                          <span class='red-text'></span>
                        </label>
                        <input type='text' class='form-control' disabled />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*   <!--\Grid column-->			*/}
                    </div>
                    {/*<!--\Grid row--> */}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Surname<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherSurname}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            First Name<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherFirstName}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Other Names<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherOtherNames}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date of Birth<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='date'
                            class='form-control'
                            Placeholder=''
                            value={motherDateOfBirth}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Telephone No<span class='red-text'></span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherTelephoneNo}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->		*/}
                    </div>
                    {/*<!--\Grid row--> */}
                    {/*<!--Grid row--> */}
                    <div class='row'>
                      {/*  <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Residential Address<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherResidentialAddress}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Email Address<span class='red-text'></span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherEmailAddress}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Occupation<span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherOccupation}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Employer/Nature of Business
                            <span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={motherEmployer}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Does Your Mother Have A Disability/Special Need?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={motherDisability}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    <div class='row' id='showMotherDisabilityYes'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md'>
                          {motherDisability === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Disability{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <textarea
                                class='form-control'
                                placeholder=''
                                value={pleaseSpecifyMotherDisability}
                              >
                                {' '}
                              </textarea>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*     <!--<div class="md-form">-->*/}
                        {motherDisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Attach Relevant Disability Documentation{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <input type='text' class='form-control' disabled />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*  <!--Grid row--> */}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Does Mother Have Medical Condition?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={motherMedicalCondition}
                        />
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/* <!--Grid column-->*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    <div class='row' id='showMotherMedicalConditionYes'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md'>
                          {motherMedicalCondition === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Medical Condition{' '}
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <textarea
                                class='form-control'
                                placeholder=''
                                value={pleaseSpecifyMotherMedicalCondition}
                              >
                                {' '}
                              </textarea>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        {motherMedicalCondition === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Attach Relevant Medical Documentation{' '}
                              <span class='red-text'>*</span>
                            </label>
                            <input type='text' class='form-control' disabled />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row-->     */}
                    <div class='row'>
                      <div class='col-md-12 mb-1'>
                        <br />
                        <center>
                          <h6>GUARDIAN</h6>
                        </center>
                      </div>
                    </div>
                    {/* <!--\Grid row--> */}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-6 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Is Your Guardian ? <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={guardianStatus}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}

                      {/*  <!--Grid column-->	*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*    <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Attach documentation where applicable
                          <span class='red-text'></span>
                        </label>
                        <input type='text' class='form-control' disabled />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*     <!--\Grid column-->			*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/* <-- Grid row --> */}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Surname<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={guardianSurname}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            First Name<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={guardianFirstName}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Other Names<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={guardianOtherNames}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->		*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-2 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date of Birth<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            value={guardianDateOfBirth}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->		*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Telephone No<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={guardianTelephoneNo}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*<!--Grid row--> */}
                    <div class='row'>
                      {/*  <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Residential Address<span class='red-text'></span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={guardianResidentialAddress}
                          />
                        </div>
                      </div>
                      {/*   <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Email Address<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={guardianEmailAddress}
                          />
                        </div>
                      </div>
                      {/*     <!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-2 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Occupation<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={guardianOccupation}
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Employer/Nature of Business
                            <span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={guardianEmployer}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*   <!--Grid row--> */}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Does Your Guardian Have A Disability/Special need?{' '}
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={guardianDisability}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*  <!--Grid row--> */}
                    <div class='row' id='showGuardianDisabilityYes'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md'>
                          {guardianDisability === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Disability{' '}
                                <span class='red-text'></span>
                              </label>
                              <textarea
                                class='form-control'
                                value={pleaseSpecifyGuardianDisability}
                              >
                                {' '}
                              </textarea>{' '}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column--> */}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*         <!--<div class="md-form">-->*/}
                        {guardianDisability === 'Yes' ? (
                          <>
                            <label for='registerFullName'>
                              Attach Relevant Disability Documentation{' '}
                              <span class='red-text'></span>
                            </label>
                            <input type='text' class='form-control' disabled />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/*<!--\Grid row--> */}
                    {/*  <!--Grid row--> */}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-2 text-left'>
                        <label for='registerFullName'>
                          Does Guardian have medical condition?{' '}
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={guardianMedicalCondition}
                        />
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/* <!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/*  <!--Grid row--> */}
                    <div class='row' id='showGuardianMedicalConditionYes'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md'>
                          {guardianMedicalCondition === 'Yes' ? (
                            <>
                              <label for='registerFullName'>
                                Please Specify Medical Condition{' '}
                                <span class='red-text'>*</span>
                              </label>
                              <textarea
                                class='form-control'
                                value={pleaseSpecifyGuardianMedicalCondition}
                              >
                                {' '}
                              </textarea>{' '}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        {guardianMedicalCondition === 'Yes' ? (
                          <>
                            {' '}
                            <label for='registerFullName'>
                              Attach Relevant Medical Condition Documentation{' '}
                              <span class='red-text'></span>
                            </label>
                            <input type='text' class='form-control' disabled />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          D. INFORMATION ON SIBLINGS/DEPENDANTS TO
                          PARENTS/GUARDIANS
                          <br />
                          (if siblings/dependents are in school, indicate who is
                          supporting them)
                        </h6>
                      </center>
                    </div>
                    {/*<!--Grid row--> */}
                    <div class='row'>
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-12 mb-1'>
                        <br />{' '}
                        <center>
                          <h6>
                            <u>DETAILS OF SIBLINGS</u>
                          </h6>
                        </center>
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row--> */}
                    {/* <!--Grid row--> */}
                    {siblingsList.map((x, i) => {
                      return (
                        <>
                          <div class='row'>
                            <div class='col-lg-1 col-md-2'>
                              <div class='md-form'>
                                <label for='registerFullName'>{i + 1}</label>
                                <br />
                                <br />
                              </div>
                            </div>
                            <div class='col-md-3 mb-1'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Name <span class='red-text'>*</span>
                                </label>{' '}
                                <br /> <br />
                                <input
                                  type='Text'
                                  class='form-control'
                                  value={
                                    x.SecondaryBoardingSchoolBursaryApplicationFormname
                                  }
                                />
                              </div>
                            </div>

                            <div class='col-lg-2 col-md-2 mb-2'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Sex <span class='red-text'>*</span>
                                </label>{' '}
                                <br /> <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  value={
                                    x.SecondaryBoardingSchoolBursaryApplicationFormsex
                                  }
                                />
                              </div>
                            </div>

                            <div class='col-md-1 mb-1'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Age<span class='red-text'>*</span>
                                </label>
                                <br /> <br />
                                <input
                                  type='Text'
                                  class='form-control'
                                  value={
                                    x.SecondaryBoardingSchoolBursaryApplicationFormage
                                  }
                                />
                              </div>
                            </div>

                            <div class='col-md-3 mb-1'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Occupation<span class='red-text'>*</span>
                                </label>
                                <br />
                                <br />
                                <input
                                  type='Text'
                                  class='form-control'
                                  value={
                                    x.SecondaryBoardingSchoolBursaryApplicationFormoccupation
                                  }
                                />
                              </div>
                            </div>

                            <div class='col-lg-2 col-md-2 mb-2 text-left'>
                              <div class='md-form'>
                                <label for='registerFullName'>
                                  Status <span class='red-text'>*</span>
                                </label>{' '}
                                <br /> <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  value={
                                    x.SecondaryBoardingSchoolBursaryApplicationFormsiblingStatus
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}
                    <div class='row'>
                      <div class='col-md-12 mb-1'>
                        <br />{' '}
                        <center>
                          <u>
                            <h6>DEPENDENTS TO PARENTS/GUARDIANS</h6>
                          </u>
                        </center>
                      </div>
                    </div>
                    {dependentsList.map(
                      (singleDependentsServiceList, dependentsIndex) => {
                        return (
                          <>
                            <div class='row'>
                              <div class='col-md-1 mb-1'>
                                <div class='md-form'>
                                  <br /> <br />
                                  <label for='registerFullName'>
                                    {dependentsIndex + 1}
                                  </label>
                                </div>
                              </div>

                              <div class='col-md-3 mb-1'>
                                <div class='md-form'>
                                  <label for='registerFullName'>
                                    Name
                                    <span class='red-text'>*</span>
                                  </label>
                                  <br /> <br />
                                  <input
                                    type='Text'
                                    class='form-control'
                                    value={
                                      singleDependentsServiceList.SecondaryBoardingSchoolBursaryApplicationFormdependentsName
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-2 col-md-2 mb-2 text-left'>
                                <div class='md-form'>
                                  <label for='registerFullName'>
                                    Sex <span class='red-text'>*</span>
                                  </label>
                                  <br /> <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      singleDependentsServiceList.SecondaryBoardingSchoolBursaryApplicationFormdependentsSex
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-md-1 mb-1'>
                                <div class='md-form'>
                                  <label for='registerFullName'>
                                    Age
                                    <span class='red-text'>*</span>
                                  </label>
                                  <br /> <br />
                                  <input
                                    type='Text'
                                    class='form-control'
                                    value={
                                      singleDependentsServiceList.SecondaryBoardingSchoolBursaryApplicationFormdependentsAge
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-md-5 mb-1'>
                                <div class='md-form'>
                                  <label for='registerFullName'>
                                    Occupation
                                    <span class='red-text'>*</span>
                                  </label>
                                  <br /> <br />
                                  <input
                                    type='Text'
                                    class='form-control'
                                    value={
                                      singleDependentsServiceList.SecondaryBoardingSchoolBursaryApplicationFormdependentscareer
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    )}
                    {/*<!--\Grid row--> */}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          E. FAMILY SOCIAL-ECONOMIC STATUS(Tick where
                          applicable) – to be confirmed by CWAC/CDA{' '}
                        </h6>
                      </center>
                    </div>
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          {' '}
                          House <span class='red-text'>*</span>
                        </label>
                        <input type='text' class='form-control' value={house} />
                      </div>
                      {/*<!--\Grid column-->	*/}

                      {/* <!--Grid column-->*/}
                      <div class='col-md-12 mb-1 text-left' id='showHouse'>
                        {/*   <!--<div class="md-form">-->*/}
                        {house === 'Other (Specify)' ? (
                          <>
                            <label for='registerFullName'>
                              {' '}
                              Specify Other<span class='red-text'></span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              value={houseOther}
                            />{' '}
                          </>
                        ) : (
                          <></>
                        )}
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*   <!--Grid column-->*/}
                      <div class='col-lg-4 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          {' '}
                          Type of House - Main Material of roof{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='custom-select d-block w-100'
                          value={typeOfHouseMainMaterialOfRoof}
                        />
                      </div>
                      {/*<!--\Grid column-->					*/}

                      {/*  <!--Grid column-->*/}
                      <div class='col-lg-4 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          {' '}
                          Type of House - Main Material of floor{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={typeOfHouseMainMaterialOfFloor}
                        />
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/* <!--Grid column-->*/}
                      <div class='col-lg-4 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          {' '}
                          Type of House - Main material of wall{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={typeOfHouseMainMaterialOfWall}
                        />
                      </div>
                      {/*	<!--\Grid column-->				*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*  <!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Toilet<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={toilet}
                        />
                      </div>
                      {/*	<!--\Grid column-->*/}
                    </div>
                    {/*  <!--\Grid row-->					*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Water<span class='red-text'>*</span>
                        </label>
                        <input type='text' class='form-control' value={water} />
                      </div>

                      <div class='col-md-12 mb-1 text-left'>
                        {/*    <!--<div class="md-form">-->*/}
                        {water === 'Other (specify)' ? (
                          <>
                            <label for='registerFullName'>
                              {' '}
                              Specify Other<span class='red-text'>*</span>
                            </label>{' '}
                            <br />
                            <input
                              type='Text'
                              class='form-control'
                              value={waterOther}
                            />{' '}
                          </>
                        ) : (
                          <></>
                        )}
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-lg-3 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Source Of Water<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={sourceOfWater}
                        />
                      </div>
                      {/*	<!--\Grid column-->	*/}
                      {/*	<!--Grid column-->*/}
                      <div class='col-lg-3 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Availability Of Electricity
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={availabilityOfElectricity}
                        />
                      </div>
                      {/*	<!--\Grid column-->	*/}
                      {/*	 <!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        {/*        <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Main Source Of Income<span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={mainSourceOfIncome}
                        />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Number Of Meals Per Day<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfMealsPerDay}
                        />
                      </div>

                      <div
                        class='col-md-12 mb-1 text-left'
                        id='showNumberOfMealsPerDay'
                      >
                        {/*       <!--<div class="md-form">-->*/}
                        {numberOfMealsPerDay === 'Other (specify)' ? (
                          <>
                            <label for='registerFullName'>
                              {' '}
                              Specify Other<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              value={numberOfMealsPerDayOther}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Does Your Household Have Any Of The Following Durable
                          Items?<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={
                            doesYourHouseholdHaveAnyOfTheFollowingDurableItems
                          }
                        />
                      </div>
                      {/*	<!--\Grid column-->	*/}
                      {/* <!--Grid column-->*/}
                      <div
                        class='col-md-12 mb-1 text-left'
                        id='showDoesYourHouseholdHaveAnyOfTheFollowingDurableItems'
                      >
                        {/*     <!--<div class="md-form">-->*/}
                        {doesYourHouseholdHaveAnyOfTheFollowingDurableItems ===
                        'Other (specify)' ? (
                          <>
                            <label for='registerFullName'>
                              {' '}
                              Specify Other<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={
                                doesYourHouseholdHaveAnyOfTheFollowingDurableItemsOther
                              }
                            />{' '}
                          </>
                        ) : (
                          <></>
                        )}
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> */}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-1 text-left'>
                        <label for='registerFullName'>
                          Does Your Household Own Poultry, Livestock Or Any
                          Other Farm Animal? If Yes, How Many?
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={
                            doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal
                          }
                        />
                      </div>
                      {/*	<!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    {doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal ===
                    'Yes' ? (
                      <>
                        {' '}
                        <div
                          class='row'
                          id='showDoesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimal'
                        >
                          <div class='col-md-12 mb-3 text-left'>
                            <br />
                            <center>
                              <h6>
                                <u>Specify How Many</u>
                              </h6>
                            </center>
                          </div>
                          {/* <!--Grid column-->*/}
                          <div class='col-md-2 mb-1 text-left'>
                            {/*        <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              {' '}
                              Cattle<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={cattle}
                            />
                            {/*	<!--</div>-->*/}
                          </div>
                          {/*<!--\Grid column--> */}
                          {/*<!--Grid column-->*/}
                          <div class='col-md-2 mb-1 text-left'>
                            {/*      <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              Goats<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={goats}
                            />
                            {/*	<!--</div>-->*/}
                          </div>
                          {/*<!--\Grid column--> */}
                          {/*<!--Grid column-->*/}
                          <div class='col-md-1 mb-1 text-left'>
                            {/*        <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              Sheep<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={sheep}
                            />
                            {/*	<!--</div>-->*/}
                          </div>
                          {/*<!--\Grid column--> */}
                          {/*<!--Grid column-->*/}
                          <div class='col-md-1 mb-1 text-left'>
                            {/*      <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              Pigs<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={pigs}
                            />
                            {/*	<!--</div>-->*/}
                          </div>
                          {/*<!--\Grid column--> */}
                          {/*<!--Grid column-->*/}
                          <div class='col-md-2 mb-1 text-left'>
                            {/*     <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              Poutry<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={poutry}
                            />
                            {/*<!--</div>-->*/}
                          </div>
                          {/* <!--\Grid column--> */}

                          {/*<!--Grid column-->*/}
                          <div class='col-md-4 mb-1 text-left'>
                            {/*        <!--<div class="md-form">-->*/}
                            <label for='registerFullName'>
                              {' '}
                              Specify Others<span class='red-text'>*</span>
                            </label>
                            <input
                              type='Text'
                              class='form-control'
                              placeholder=''
                              value={
                                doesYourHouseholdOwnPoultryLivestockOrAnyOtherFarmAnimalOther
                              }
                            />
                            {/*	<!--</div>-->*/}
                          </div>
                          {/* <!--\Grid column--> */}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid row-->*/}
                    <br />
                    <center>
                      <h4 class='red-text'>Applicant (Learner)</h4>
                    </center>
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*      <!--<div class="md-form">--->*/}
                        <label for='registerFullName'>
                          Name <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={learnerName}
                        />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*  <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Address <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={learnerAddress}
                        />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*        <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Phone (where applicable)
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={learnerPhone}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 				 */}
                      {/*   <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*        <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          NRC (where applicable)<span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={learnerNRC}
                        />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <br />
                    <center>
                      <h3 class='red-text'>Contact Person For Applicant</h3>
                    </center>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*        <!--<div class="md-form">--->*/}
                        <label for='registerFullName'>
                          Name <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={contactName}
                        />
                        {/*	<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Address <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={contactAddress}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*         <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Phone <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={contactPhone}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*   <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          NRC <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={contactNRC}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 	*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    <section class='section mt-5'>
                      {/*Grid row */}
                      <div class='row'>
                        {/* Grid column */}

                        <div class='col-lg-12 col-md-12'>
                          <div class='card mb-4'>
                            <div class='card-body'>
                              <div
                                class='form-header white-text'
                                style={{ backgroundColor: 'green' }}
                              >
                                <center>
                                  <span class='text-uppercase'>
                                    F. LIST OF ATTACHMENTS- (please tick what
                                    has been attached and /or indicate what is
                                    not provided)
                                  </span>
                                </center>
                              </div>
                              <table class='table large-header'>
                                <thead class='text-left'>
                                  <tr>
                                    <th class='font-weight-bold'>
                                      <strong>No</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Checklist</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Yes / No</strong>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody class='text-left'>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      Recommendation from previous sponsor
                                      (where applicable)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationFromPreviousSponsorDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            value={
                                              recommendationFromPreviousSponsor
                                            }
                                            onChange={e =>
                                              setRecommendationFromPreviousSponsor(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox1'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationFromPreviousSponsorDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            recommendationFromPreviousSponsorBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Birth Certificate/s of applicant </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {birthCertificatesOfApplicantDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            value={birthCertificatesOfApplicant}
                                            onChange={e =>
                                              setBirthCertificatesOfApplicant(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox2'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            birthCertificatesOfApplicantDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            birthCertificatesOfApplicantBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Death certificate/s of parents</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {deathCertificatesOfParentsDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            value={deathCertificatesOfParents}
                                            onChange={e =>
                                              setDeathCertificateOfParents(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox3'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            deathCertificatesOfParentsDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            deathCertificatesOfParentsBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>
                                      Pay slips/ proof of income of
                                      parents/guardian
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {paySlipsOfParentDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            value={paySlipsOfParent}
                                            onChange={e =>
                                              setPaySlipsOfParent(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox4'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={paySlipsOfParentDB != 'true'}
                                          title='Cancel Check'
                                          type='button'
                                          onClick={paySlipsOfParentBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>
                                      Medical record(s) of parent/guardian
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {medicalRecordOfParentDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            value={medicalRecordOfParent}
                                            onChange={e =>
                                              setMedicalRecordOfParent(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox5'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            medicalRecordOfParentDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={medicalRecordOfParentBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>
                                      Disability card/ Confirmation of
                                      disability of applicant/parent/guardian
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {disabilityCardOfParentDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            value={disabilityCardOfParent}
                                            onChange={e =>
                                              setDisabilityCardOfParent(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox6'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            disabilityCardOfParentDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={disabilityCardOfParentBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>
                                      Recommendation from traditional leadership
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationFromTraditionalLeadershipDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            value={
                                              recommendationFromTraditionalLeadership
                                            }
                                            onChange={e =>
                                              setRecommendationFromTraditionalLeadership(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox7'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationFromTraditionalLeadershipDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            recommendationFromTraditionalLeadershipBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>
                                      Recommendation from Community Welfare
                                      Assistance Committee
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationFromCommunityWelfareAssistanceCommitteeDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            value={
                                              recommendationFromCommunityWelfareAssistanceCommittee
                                            }
                                            onChange={e =>
                                              setRecommendationFromCommunityWelfareAssistanceCommittee(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox8'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationFromCommunityWelfareAssistanceCommitteeDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            recommendationFromCommunityWelfareAssistanceCommitteeBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>9</td>
                                    <td>
                                      Acceptance letter /confirmation of
                                      enrollment
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {acceptanceLetterDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            value={acceptanceLetter}
                                            onChange={e =>
                                              setAcceptanceLetter(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox9'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={acceptanceLetterDB != 'true'}
                                          title='Cancel Check'
                                          type='button'
                                          onClick={acceptanceLetterBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>10</td>
                                    <td>Copy of application form</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {copyOfApplicationFormDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            value={copyOfApplicationForm}
                                            onChange={e =>
                                              setCopyOfApplicationForm(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox10'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            copyOfApplicationFormDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={copyOfApplicationFormBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>11</td>
                                    <td>
                                      Applicant to sign each and every page of
                                      this application document
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {applicantToSignEachAndEveryPageOfThisApplicationDocumentDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox11'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox11'
                                            value={
                                              applicantToSignEachAndEveryPageOfThisApplicationDocument
                                            }
                                            onChange={e =>
                                              setApplicantToSignEachAndEveryPageOfThisApplicationDocument(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox11'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            applicantToSignEachAndEveryPageOfThisApplicationDocumentDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            applicantToSignEachAndEveryPageOfThisApplicationDocumentBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Grid column */}
                      </div>
                    </section>
                    <section class='section mt-5'>
                      {/*Grid row */}
                      <div class='row'>
                        {/* Grid column */}

                        <div class='col-lg-12 col-md-12'>
                          <div class='card mb-4'>
                            <div class='card-body'>
                              <div
                                class='form-header white-text'
                                style={{ backgroundColor: 'green' }}
                              >
                                <center>
                                  <span class='text-uppercase'>
                                    Application Checklist
                                  </span>
                                </center>
                              </div>
                              <table class='table large-header'>
                                <thead class='text-left'>
                                  <tr>
                                    <th class='font-weight-bold'>
                                      <strong>No</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Checklist</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Yes / No</strong>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody class='text-left'>
                                  <tr>
                                    <td>1</td>
                                    <td>Learner is of school going age</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {learnerIsOfSchoolGoingAgeDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox12'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox12'
                                            value={learnerIsOfSchoolGoingAge}
                                            onChange={e =>
                                              setLearnerIsOfSchoolGoingAge(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox12'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            learnerIsOfSchoolGoingAgeDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={learnerIsOfSchoolGoingAgeBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>
                                      A learner must be a resident of the
                                      Constituency in which the fund is sitting
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox13'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox13'
                                            value={
                                              aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting
                                            }
                                            onChange={e =>
                                              setALearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSitting(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox13'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            aLearnerMustBeAResidentOfTheConstituencyInWhichTheFundIsSittingBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>3</td>
                                    <td>
                                      Learner meets most of the following
                                      criteria:
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {learnerMeetsMostOfTheFollowingCriteriaDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox14'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox14'
                                            value={
                                              learnerMeetsMostOfTheFollowingCriteria
                                            }
                                            onChange={e =>
                                              setLearnerMeetsMostOfTheFollowingCriteria(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox14'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            learnerMeetsMostOfTheFollowingCriteriaDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            learnerMeetsMostOfTheFollowingCriteriaBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>i</td>
                                    <td colspan='2'>
                                      A learner must be enrolled at a particular
                                      public boarding school;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>ii</td>
                                    <td colspan='2'>
                                      A learner must have qualified to grade
                                      eight or already enrolled in grade 9 to
                                      12;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>iii</td>
                                    <td colspan='2'>
                                      School drop-outs who failed to pay school
                                      fees shall be eligible for a bursary;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>iv</td>
                                    <td colspan='2'>
                                      Learners that are enrolled in the boarding
                                      schools but are accommodated in the nearby
                                      villages due to failure to pay boarding
                                      fees;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>v</td>
                                    <td colspan='2'>
                                      Double Orphaned where the guardian has no
                                      reliable source of income;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>vi</td>
                                    <td colspan='2'>
                                      Single Orphaned where the surviving parent
                                      has no reliable source of income;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>vii</td>
                                    <td colspan='2'>
                                      A learner with disability;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>viii</td>
                                    <td colspan='2'>
                                      Learners whose parents are disabled and
                                      have no reliable source of income;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>ix</td>
                                    <td colspan='2'>
                                      Learners whose parents or guardians have
                                      no reliable source of income;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>x</td>
                                    <td colspan='2'>
                                      Learners on the school re-entry project
                                      without family support; and
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>xi</td>
                                    <td colspan='2'>
                                      A learner from a poor, vulnerable and
                                      incapacitated household where a vulnerable
                                      household has the following
                                      characteristics:
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>xii</td>
                                    <td colspan='2'>
                                      Presence of stunted or underweight
                                      children;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>xiii</td>
                                    <td colspan='2'>Children out of school;</td>
                                  </tr>

                                  <tr>
                                    <td>xiv</td>
                                    <td colspan='2'>
                                      Female headed household;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>xv</td>
                                    <td colspan='2'>
                                      Households headed by chronically ill and
                                      on palliative care;
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>xvi</td>
                                    <td colspan='2'>
                                      Households headed by elderly persons aged
                                      65 and above; and
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>xvii</td>
                                    <td colspan='2'>
                                      Child headed households.
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>4</td>
                                    <td>
                                      Death certificates of Parents/Guardian
                                      (where applicable)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {deathCertificatesOfParentsOrGuardianDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox15'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox15'
                                            value={
                                              deathCertificatesOfParentsOrGuardian
                                            }
                                            onChange={e =>
                                              setDeathCertificatesOfParentsOrGuardian(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox15'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            deathCertificatesOfParentsOrGuardianDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            deathCertificatesOfParentsOrGuardianBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>
                                      Disability forms/membership cards or
                                      letters from the hospital (where
                                      applicable)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {disabilityFormsDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox16'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox16'
                                            value={disabilityForms}
                                            onChange={e =>
                                              setDisabilityForms(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox16'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={disabilityFormsDB != 'true'}
                                          title='Cancel Check'
                                          type='button'
                                          onClick={disabilityFormsBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>
                                      Recommendation letters from the
                                      Church/Chief/Headman (whichever is
                                      applicable)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationLettersDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox17'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox17'
                                            value={recommendationLetters}
                                            onChange={e =>
                                              setRecommendationLetters(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox17'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationLettersDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={recommendationLettersBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>
                                      Recommendation or assessment report from
                                      the School Guidance Teacher/Head teacher
                                      (where applicable)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationOrAssessmentReportDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox18'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox18'
                                            value={
                                              recommendationOrAssessmentReport
                                            }
                                            onChange={e =>
                                              setRecommendationOrAssessmentReport(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox18'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationOrAssessmentReportDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            recommendationOrAssessmentReportBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Grid column */}
                      </div>
                    </section>
                    <section>
                      <div
                        class='card-header mt-5 mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION D: RECOMMENDATION BY THE WARD DEVELOPMENT
                            COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/*  <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Ward Development Committee{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            //name='recommendationByTheWardDevelopmentCommittee'
                            value={recommendationByTheWardDevelopmentCommittee}
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Recommended</option>
                            <option>Not Recommended</option>
                            <option>Defer</option>
                          </select>
                        </div>
                        {/*   <!--\Grid column-->				 */}
                        {/*     <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*       <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            name='recommendationByTheWardDevelopmentCommitteeReason'
                            value={
                              recommendationByTheWardDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                            required
                          >
                            {' '}
                          </textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->				*/}
                      </div>
                      {/* <!--\Grid row--> */}
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (WDC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonSign'
                              id='wardDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row--> */}

                      <section
                        class='section mt-5'
                        hidden={
                          processedByWardDB === '' || processedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Proccessed By
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={processedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={processedWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByWardDB === '' || approvedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (WDC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={approvedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={approvedByWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        onClick={handleSubmitWards}
                        disabled={
                          recommendationByTheWardDevelopmentCommittee === '' ||
                          recommendationByTheWardDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        Submit
                      </button>
                    </section>
                    <section
                      hidden={
                        userPosition === 'Councilor' ||
                        userPosition === 'WDC Chairperson' ||
                        userPosition === 'WDC Member'
                      }
                    >
                      <div
                        class='card-header mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION E: DECISION BY THE CONSTITUENCY DEVELOPMENT
                            FUND COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Constituency Development
                            Committee <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            value={
                              recommendationByTheConstituencyDevelopmentCommittee
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Approved</option>
                            <option>Not Approved</option>
                          </select>
                        </div>
                        {/*  <!--\Grid column-->		*/}
                        {/*      <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*      <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons For Recommendation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            value={
                              recommendationByTheConstituencyDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                          ></textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row-->*/}
                      {/* <!--Grid row-->*/}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/*<!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (CDFC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonSign'
                              id='constituencyDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                        {/* <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                      </div>

                      <section
                        class='section mt-5'
                        hidden={
                          processedByConstituencyDB === '' ||
                          processedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Processed By :
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={processedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={processedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByConstituencyDB === '' ||
                          approvedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonconstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (CDFC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={approvedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={approvedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        disabled={
                          recommendationByTheConstituencyDevelopmentCommittee ===
                            '' ||
                          recommendationByTheConstituencyDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                        onClick={handleSubmitConstituencies}
                      >
                        Submit
                      </button>
                      {/* <!-- Grid row -->*/}
                    </section>
                  </div>
                  {/* <!-- Grid row -->*/}
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default ApplicantSecondaryBoardingSchoolsBursariesView
