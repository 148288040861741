import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  let navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem('userID')
    localStorage.removeItem('userInstitution')
    localStorage.removeItem('userOrganisation')
    localStorage.removeItem('loggedIn')
    sessionStorage.removeItem('loggedIn')
    localStorage.removeItem('login')
    localStorage.removeItem('userPosition')
    localStorage.removeItem('userDepartment')
    localStorage.removeItem('userSystemRole')
    localStorage.removeItem('userProvincesID')
    localStorage.removeItem('userCouncilsID')
    localStorage.removeItem('userConstituenciesID')
    localStorage.removeItem('userWardsID')
    localStorage.removeItem('userName')
    localStorage.removeItem('userPhone')
    localStorage.removeItem('userNRC')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userGender')    
    localStorage.removeItem('clientType')

    localStorage.removeItem('ApplicantCommunityProjectsView')
    localStorage.removeItem('userEducationSkillsID')
    localStorage.removeItem('ApplicantsDetailedGrantsForYouthWomenAndCommunityEmpowermentReport')
    localStorage.removeItem('userPositionProvisional')
    localStorage.removeItem('userEducationBoardingID')
    localStorage.removeItem('CouncilsSummaryGrantsForYouthWomenAndCommunityEmpowerment')
    localStorage.removeItem('ConstituenciesSummaryGrantsForYouthWomenAndCommunityEmpowerment')
    localStorage.removeItem('CouncilsSummarySkillsDevelopmentBursaries')
    localStorage.removeItem('names')
    localStorage.removeItem('WardsSummarySkillsDevelopmentBursaries')
    localStorage.removeItem('userNrc')
    localStorage.removeItem('ApplicantSkillsDevelopmentBursariesView')
    localStorage.removeItem('userSchoolName')
    localStorage.removeItem('ApplicantGrantsForYouthWomenAndCommunityEmpowermentView')
    localStorage.removeItem('ConstituenciesSummarySkillsDevelopmentBursaries')
    localStorage.removeItem('ApplicantSecondaryBoardingSchoolsBursariesView')
    localStorage.removeItem('WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment')
    localStorage.removeItem('ApplicantsDetailedSkillsDevelopmentBursariesReport')
    localStorage.removeItem('userOrganisationcategory')

    sessionStorage.removeItem('reloadCount')
    sessionStorage.removeItem('reloadCountHome')

    //transactionID:""1939491010202210""
    //transactionIDPrintReceipt:""193921010202210""
    const firstLogout = localStorage.getItem('login')
    const secondLogout = JSON.parse(firstLogout)
    if (secondLogout === 'Business') {
      navigate('../LoginAdmin')
    } else {
      navigate('/')
    }
  }, [])
}

export default Logout
