import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Header from './header'
const ApplyForSkills = () => {
  sessionStorage.removeItem('reloadCount')
  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;
  const OTP_4_DDIGITS_REGEX = /^\d{4}$/
  const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/
  const NRC_REGEX1 =/^\d{6}[/]\d{2}[/]\d{1}$/
  const NRC_REGEX2 = /ZMB[0-9]{10}$/
  //const TESTING_NRC_REGEX = /^(?=\d{6}[/]\d{2}[/]\d{1}) | (ZMB[0-9]{10})$/
  const server_app_api_php = window.SERVER_APP_API_PHP
  const [validPhone, setValidPhone] = useState(false)
  const [NRCNumber, setNRCNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [surname, setSurname] = useState('')
  const [otherNames, setOtherNames] = useState('')
  const [OTP, setOTP] = useState('')
  const [email, setEmail] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [validOTP, setValidOTP] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [validNrc, setValidNrc] = useState(false)
  const [nrcFocus, setNrcFocus] = useState(false)

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    //var result = false
    let result1 = NRC_REGEX1.test(NRCNumber)
    let result2 = NRC_REGEX2.test(NRCNumber)
    if(result1 === true){
      setValidNrc(true)
    }
    else if(result2 === true){
      setValidNrc(true)
    }
    else{
      setValidNrc(false)
    }
    //alert(result2)
  }, [NRCNumber])

  useEffect(() => {
    const result = OTP_4_DDIGITS_REGEX.test(OTP)
    if(result === true){
    var formDataComfirmOTP = new FormData(this)
    formDataComfirmOTP.append('apiType', 'skillsComfirmOTP')
    formDataComfirmOTP.append('learnerNRCNumber', NRCNumber)
    formDataComfirmOTP.append('phoneNumber', phoneNumber)
    formDataComfirmOTP.append('OTP', OTP)
    formDataComfirmOTP.append('email', email)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formDataComfirmOTP,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if(response.data === 'OTP Is Correct'){
        alert(response.data)
        setValidOTP(true)
      }
      else if(response.data === 'OTP Exists But Other Details Are Not Matching'){
        setValidOTP(false)
      }
      else{
      alert(response.data)
      setValidOTP(false)
      }
      })
    }
  }, [OTP, NRCNumber, phoneNumber, email])

  useEffect(() => {
    const result = PHONE_REGEX.test(phoneNumber)
    setValidPhone(result)
    if(result === true){
      setOTP('');
      setValidOTP(false)

      var formDataCreateOTP = new FormData(this)
      formDataCreateOTP.append('apiType', 'skillsCreateOTP')
      formDataCreateOTP.append('learnerNRCNumber', NRCNumber)
      formDataCreateOTP.append('phoneNumber', phoneNumber)      
      formDataCreateOTP.append('surname', surname)
      formDataCreateOTP.append('otherNames', otherNames)
      formDataCreateOTP.append('email', email)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
        data: formDataCreateOTP,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        alert(response.data)
          //setBusinessNameDropDown(response.data)
        })
          }
  }, [phoneNumber])

  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])

  let navigate = useNavigate()
  const handleSubmit = async e =>  {
    //navigate('/SkillsDevelopmentBursaryApplicationForm')
        //useEffect(() => {
          var formData = new FormData(this)
          formData.append('apiType', 'uploadSkillsDevelopmentBursaryApplicationFormProgress')
          formData.append('phoneNumber', phoneNumber)
          formData.append('surname', surname)
          formData.append('otherNames', otherNames)
          formData.append('NRCNumber', NRCNumber)
          formData.append('email', email)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            setPhoneNumber('')
            setSurname('')
            setOtherNames('')
            setNRCNumber('')
            setEmail('')
            setOTP('')
            if(response.data === 'Successfully Registered'){
              alert("Successfully Registered, Login Credentials Have Been Sent To Phone Number "+phoneNumber +" ... Redirecting To Login Page");
              //navigate('/SecondaryBoardingSchoolBursaryApplicationForm')
              //localStorage.setItem('clientType', JSON.stringify('First Timer'))
              navigate('/Login')
            }
            else if(response.data === 'Duplicate Entry, Failed To Register'){
              alert("You Already Have An Account With Us, Redirecting To Login Page");
              //navigate('/ApplyForSecondaryProgress');
              //localStorage.setItem('clientType', JSON.stringify('Returning'))
              navigate('/Login')
            }
            else{
              alert(response.data)
            }
            }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
        //}, [])
  }

  return (
    <>
     {/*} <>
        <Header />
      </> */}


  <main>
    <div class="container-fluid">

      
      <section class="mb-2 mt-5">

        
        <div class="card card-cascade narrower">

          
          <div class="view view-cascade gradient-card-header blue-gradient">
            <h5 class="mb-0">SKILLS DEVELOPMENT APPLICATION PROGRESS</h5>
          </div>
          
          <div class="card-body card-body-cascade text-center table-responsive mb-5">

          <div class="row">
            <a class="text-left" href='Main' style={{ color: 'red' }} title='Back To The Home Page'>
            <i class='fas fa-home' style={{ fontSize: '100%' }} >Back</i> 
            </a>
            </div>

            
            <div class="row">
        
<div class="col-md-4">
<div class="md-outline form-sm text-left">    
<label>Surname</label>           
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={surname}
                onChange={e => setSurname(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4">
            <div class="md-outline form-sm text-left">    
            <label>Other Names</label>           
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                value={otherNames}
                onChange={e => setOtherNames(e.target.value)}
                />
              </div>
</div>

<div class="col-md-4">
            <div class="md-outline form-sm text-left">
              <label>NRC Number
              <span className={validNrc ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={validNrc || !NRCNumber ? 'hide' : 'invalid'}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                </label>               
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={NRCNumber}
                onChange={e => setNRCNumber(e.target.value)}
                aria-invalid={validNrc ? 'false' : 'true'}
                          aria-describedby='nrcnote'
                          onFocus={() => setNrcFocus(true)}
                          onBlur={() => setNrcFocus(false)}
                />
                 <p
                          id='nrcnote'
                          className={
                            nrcFocus && NRCNumber && !validNrc
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          It Should Be A Valid NRC Number
                          <br />
                          If You Are Using The NRC number which starts with ZMB, The letters (ZMB) should be capitalised. 
                        </p>
              </div>
</div>

<div class="col-md-6">
            <div class="md-outline form-sm text-left"> 
            <label>Email
            <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validEmail || !email ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>              
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={email}
                onChange={e => setEmail(e.target.value)}
                aria-invalid={validEmail ? 'false' : 'true'}
                          aria-describedby='emailnote'
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                />
                <p
                          id='emailnote'
                          className={
                            emailFocus && email && !validEmail
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          4 to 24 characters. <br />
                          Let It Be A Valid Email Address.
                        </p>
              </div>
</div>

<div class="col-md-6">
            <div class="md-outline form-sm text-left">    
            <label>Phone Number
            <span className={validPhone ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validPhone || !phoneNumber ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
              </label>           
                <input placeholder="" type="text" id="form31" 
                class="form-control form-control-sm" 
                disabled={email === '' || NRCNumber === '' || otherNames === '' || surname === ''}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}

                aria-invalid={validPhone ? 'false' : 'true'}
                            aria-describedby='phonenote'
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                />
                <p
                            id='phonenote'
                            className={
                              phoneFocus && phoneNumber && !validPhone
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            There Should Be 10 Numerical Values.
                          </p>
              </div>
</div>

<div class="col-md-12" hidden = {!validPhone}>
            <div class="md-outline form-sm text-left"> 
            <label>OTP Received Via Email Or SMS</label>              
                <input placeholder="" type="text" id="form31" class="form-control form-control-sm" 
                value={OTP}
                onChange={e => setOTP(e.target.value)}
                />
              </div>
</div>



<div class='col-md-12 col-12'>
                          <button
                            class='form-control blue-gradient white-text'
                            onClick={() => {
                                handleSubmit()
                              }}
                              disabled = {email === '' || !validOTP || !validPhone || otherNames === '' || NRCNumber ==='' ||
                              surname === '' || phoneNumber === '' || OTP === ''}                               
                          >Next</button>
                        </div>

            </div>
            
          </div>
          

        </div>
        
        

      </section>
   
    </div>
  </main>
    </>
  )
}

export default ApplyForSkills
