import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ApplicantLoansView = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let ApplicantLoansViewID = localStorage.getItem(
    //'WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
    'ApplicantLoansView'
  )
  ApplicantLoansViewID = JSON.parse(ApplicantLoansViewID)

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)
  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var [
    recommendationByTheWardDevelopmentCommittee,
    setRecommendationByTheWardDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheWardDevelopmentCommitteeReason,
    setRecommendationByTheWardDevelopmentCommitteeReason
  ] = useState('')

  var [
    recommendationByTheConstituencyDevelopmentCommittee,
    setRecommendationByTheConstituencyDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheConstituencyDevelopmentCommitteeReason,
    setRecommendationByTheConstituencyDevelopmentCommitteeReason
  ] = useState('')

  const handleSubmitWards = async e => {
    //useEffect(() => {
    var formDataRecommendationByWards = new FormData(this)
    formDataRecommendationByWards.append('apiType', 'recommendationByWards')
    formDataRecommendationByWards.append('applicantID', ApplicantLoansViewID)
    formDataRecommendationByWards.append('userID', userID)
    formDataRecommendationByWards.append(
      'recommendation',
      recommendationByTheWardDevelopmentCommittee
    )
    formDataRecommendationByWards.append(
      'reason',
      recommendationByTheWardDevelopmentCommitteeReason
    )
    formDataRecommendationByWards.append('table', 'loans')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const handleSubmitConstituencies = async e => {
    //useEffect(() => {
    var formDataRecommendationByConstituencies = new FormData(this)
    formDataRecommendationByConstituencies.append(
      'apiType',
      'recommendationByConstituencies'
    )
    formDataRecommendationByConstituencies.append(
      'applicantID',
      ApplicantLoansViewID
    )
    formDataRecommendationByConstituencies.append('userID', userID)
    formDataRecommendationByConstituencies.append(
      'recommendation',
      recommendationByTheConstituencyDevelopmentCommittee
    )
    formDataRecommendationByConstituencies.append(
      'reason',
      recommendationByTheConstituencyDevelopmentCommitteeReason
    )
    formDataRecommendationByConstituencies.append('table', 'loans')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const [ip, setIP] = useState('')
  //const [DBtableViewWards, setDBtableViewWards] = useState([])
  //const [DBtableViewWardName, setDBtableViewWardName] = useState([])
  const [loanTenure, setloanTenure] = useState([])
  const [loanAmount, setloanAmount] = useState([])
  const [typeOfBusiness, settypeOfBusiness] = useState([])
  const [nameOfBusinessMakingApplication, setnameOfBusinessMakingApplication] =
    useState([])
  const [province, setprovince] = useState([])
  const [council, setcouncil] = useState([])
  const [constituency, setconstituency] = useState([])
  const [ward, setward] = useState([])
  const [zone, setzone] = useState([])
  const [physicalAddress, setphysicalAddress] = useState([])
  const [
    descriptionOfCurrentBusinessActivities,
    setdescriptionOfCurrentBusinessActivities
  ] = useState([])
  const [legalFormOfApplicant, setlegalFormOfApplicant] = useState([])
  const [certificateOfRegistration, setcertificateOfRegistration] = useState([])
  const [isYourBusinessRegistered, setisYourBusinessRegistered] = useState([])
  const [contactNumbersForApplicant, setcontactNumbersForApplicant] = useState(
    []
  )
  const [
    doYouHaveCopyOfBusinessRegistrationCertificate,
    setdoYouHaveCopyOfBusinessRegistrationCertificate
  ] = useState([])
  const [dateWhenBusinessWasRegistered, setdateWhenBusinessWasRegistered] =
    useState([])
  const [nextOfKinName, setnextOfKinName] = useState([])
  const [nextOfKinPhysicalAddress, setnextOfKinPhysicalAddress] = useState([])
  const [nextOfKinNrc, setnextOfKinNrc] = useState([])
  const [nextOfKinPhoneNumber, setnextOfKinPhoneNumber] = useState([])
  const [
    authorizedRepresentativeNameOfContactPerson,
    setauthorizedRepresentativeNameOfContactPerson
  ] = useState([])
  const [
    authorizedRepresentativePositionInTheBusiness,
    setauthorizedRepresentativePositionInTheBusiness
  ] = useState([])
  const [authorizedRepresentativeCell, setauthorizedRepresentativeCell] =
    useState([])
  const [
    authorizedRepresentativeTelephone,
    setauthorizedRepresentativeTelephone
  ] = useState([])
  const [authorizedRepresentativeEmail, setauthorizedRepresentativeEmail] =
    useState([])
  const [previousCDFFunding, setpreviousCDFFunding] = useState([])
  const [previousCDFFundingStatusOfLoan, setpreviousCDFFundingStatusOfLoan] =
    useState([])
  const [
    previousCDFFundingNameOfBusiness,
    setpreviousCDFFundingNameOfBusiness
  ] = useState([])
  const [
    previousCDFFundingTypeOfRegistrationOfBusiness,
    setpreviousCDFFundingTypeOfRegistrationOfBusiness
  ] = useState([])
  const [bankDetailsBankName, setbankDetailsBankName] = useState([])
  const [bankDetailsBranch, setbankDetailsBranch] = useState([])
  const [bankDetailsBranchCode, setbankDetailsBranchCode] = useState([])
  const [bankDetailsSwiftCode, setbankDetailsSwiftCode] = useState([])
  const [bankDetailsAccountNumber, setbankDetailsAccountNumber] = useState([])
  const [bankDetailsTpin, setbankDetailsTpin] = useState([])
  const [
    bankDetailsMobileMoneyNameAndNumber,
    setbankDetailsMobileMoneyNameAndNumber
  ] = useState([])
  const [
    financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness,
    setfinancialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness
  ] = useState([])
  const [
    financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness,
    setfinancialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness
  ] = useState([])
  const [
    financialDataHowMuchHaveYouInvestedInTheCurrentBusiness,
    setfinancialDataHowMuchHaveYouInvestedInTheCurrentBusiness
  ] = useState([])
  const [marketOverviewTrendsInDemand, setmarketOverviewTrendsInDemand] =
    useState([])
  const [marketOverviewTrendsInSupply, setmarketOverviewTrendsInSupply] =
    useState([])
  const [
    businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers,
    setbusinessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
  ] = useState([])
  const [businessOperationsLocation, setbusinessOperationsLocation] = useState(
    []
  )
  const [
    businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations,
    setbusinessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations
  ] = useState([])
  const [
    businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations,
    setbusinessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations
  ] = useState([])
  const [
    businessOperationsWhereWillYouSecureRawMaterialsFromAndHow,
    setbusinessOperationsWhereWillYouSecureRawMaterialsFromAndHow
  ] = useState([])
  const [
    businessOperationsWhatWillBeYourCostOfProductionPerMonth,
    setbusinessOperationsWhatWillBeYourCostOfProductionPerMonth
  ] = useState([])
  const [
    businessOperationsWhatWillBeThePriceOfYourProductsOrServices,
    setbusinessOperationsWhatWillBeThePriceOfYourProductsOrServices
  ] = useState([])
  const [
    businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations,
    setbusinessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations
  ] = useState([])
  const [
    businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear,
    setbusinessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear
  ] = useState([])
  const [
    businessOperationsWhoWillBeYourMajorCompetitors,
    setbusinessOperationsWhoWillBeYourMajorCompetitors
  ] = useState([])
  const [
    howWillYourCustomersBenefitFromYourBusiness,
    sethowWillYourCustomersBenefitFromYourBusiness
  ] = useState([])
  const [
    howWillYourSuppliersBenefitFromYourBusiness,
    sethowWillYourSuppliersBenefitFromYourBusiness
  ] = useState([])
  const [
    numberOfWorkersBeforeCDFFundingWomen,
    setnumberOfWorkersBeforeCDFFundingWomen
  ] = useState([])
  const [
    numberOfWorkersBeforeCDFFundingMen,
    setnumberOfWorkersBeforeCDFFundingMen
  ] = useState([])
  const [
    numberOfWorkersBeforeCDFFundingYouth,
    setnumberOfWorkersBeforeCDFFundingYouth
  ] = useState([])
  const [
    numberOfWorkersBeforeCDFFundingDisabled,
    setnumberOfWorkersBeforeCDFFundingDisabled
  ] = useState([])
  const [
    numberOfWorkersAfterCDFFundingWomen,
    setnumberOfWorkersAfterCDFFundingWomen
  ] = useState([])
  const [
    numberOfWorkersAfterCDFFundingMen,
    setnumberOfWorkersAfterCDFFundingMen
  ] = useState([])
  const [
    numberOfWorkersAfterCDFFundingYouth,
    setnumberOfWorkersAfterCDFFundingYouth
  ] = useState([])
  const [
    numberOfWorkersAfterCDFFundingDisabled,
    setnumberOfWorkersAfterCDFFundingDisabled
  ] = useState([])
  const [capitalThresholdRepaymentPeriod, setcapitalThresholdRepaymentPeriod] =
    useState([])
  const [
    ifYouAreFundedWhenWouldOperationsStart,
    setifYouAreFundedWhenWouldOperationsStart
  ] = useState([])
  const [declarationApplicantDate, setdeclarationApplicantDate] = useState([])
  const [declarationApplicantDay, setdeclarationApplicantDay] = useState([])
  const [applicationDate, setapplicationDate] = useState([])
  const [shareHoldersOfTheBusinessList, setShareHoldersOfTheBusinessList] =
    useState([])
  const [proposedManagementTeamList, setProposedManagementTeamList] = useState(
    []
  )
  const [projectBudgetList, setProjectBudgetList] = useState([])
  const [clientContributionList, setClientContributionsList] = useState([])

  var [statusWardDB, setDBstatusWard] = useState([])
  var [statusProcessedWardDB, setDBstatusProcessedWard] = useState([])
  var [processedWardDateDB, setDBprocessedWardDate] = useState([])
  var [processedByWardDB, setDBprocessedByWard] = useState([])
  var [approvedByWardDB, setDBapprovedByWard] = useState([])
  var [approvedByWardDateDB, setDBapprovedByWardDate] = useState([])
  var [reasonWardDB, setDBreasonWard] = useState([])
  var [statusConstituencyDB, setDBstatusConstituency] = useState([])
  var [statusProcessedConstituencyDB, setDBstatusProcessedConstituency] =
    useState([])
  var [processedByConstituencyDB, setDBprocessedByConstituency] = useState([])
  var [processedConstituencyDateDB, setDBprocessedConstituencyDate] = useState(
    []
  )
  var [approvedByConstituencyDB, setDBapprovedByConstituency] = useState([])
  var [approvedConstituencyDateDB, setDBapprovedConstituencyDate] = useState([])
  var [reasonconstituencyDB, setDBreasonconstituency] = useState([])
  var [statusCouncilPrincipalDB, setDBstatusCouncilPrincipal] = useState([])
  var [processedByCouncilPrincipalDB, setDBprocessedByCouncilPrincipal] =
    useState([])
  var [processedCouncilPrincipalDateDB, setDBprocessedCouncilPrincipalDate] =
    useState([])
  var [approvedByCouncilPrincipalDB, setDBapprovedByCouncilPrincipal] =
    useState([])
  var [approvedCouncilPrincipalDateDB, setDBapprovedCouncilPrincipalDate] =
    useState([])
  var [reasonCouncilPrincipalDB, setDBreasonCouncilPrincipal] = useState([])
  var [tenureCouncilPrincipalDB, setDBtenureCouncilPrincipal] = useState([])
  var [statusPlanningDB, setDBstatusPlanning] = useState([])
  var [processedByPlanningDB, setDBprocessedByPlanning] = useState([])
  var [processedPlanningDateDB, setDBprocessedPlanningDate] = useState([])
  var [approvedByPlanningDB, setDBapprovedByPlanning] = useState([])
  var [approvedPlanningDateDB, setDBapprovedPlanningDate] = useState([])
  var [reasonPlanningDB, setDBreasonPlanning] = useState([])
  var [tenurePlanningDB, setDBtenurePlanning] = useState([])
  var [statusFinanceDB, setDBstatusFinance] = useState([])
  var [processedByFinanceDB, setDBprocessedByFinance] = useState([])
  var [processedFinanceDateDB, setDBprocessedFinanceDate] = useState([])
  var [approvedByFinanceDB, setDBapprovedByFinance] = useState([])
  var [approvedFinanceDateDB, setDBapprovedFinanceDate] = useState([])
  var [reasonFinanceDB, setDBreasonFinance] = useState([])
  var [tenureFinanceDB, setDBtenureFinance] = useState([])
  var [statusEngineeringDB, setDBstatusEngineering] = useState([])
  var [processedByEngineeringDB, setDBprocessedByEngineering] = useState([])
  var [processedEngineeringDateDB, setDBprocessedEngineeringDate] = useState([])
  var [approvedByEngineeringDB, setDBapprovedByEngineering] = useState([])
  var [approvedEngineeringDateDB, setDBapprovedEngineeringDate] = useState([])
  var [reasonEngineeringDB, setDBreasonEngineering] = useState([])
  var [tenureEngineeringDB, setDBtenureEngineering] = useState([])
  var [statusProcurementDB, setDBstatusProcurement] = useState([])
  var [processedByProcurementDB, setDBprocessedByProcurement] = useState([])
  var [processedProcurementDateDB, setDBprocessedProcurementDate] = useState([])
  var [approvedByProcurementDB, setDBapprovedByProcurement] = useState([])
  var [approvedProcurementDateDB, setDBapprovedProcurementDate] = useState([])
  var [reasonProcurementDB, setDBreasonProcurement] = useState([])
  var [tenureProcurementDB, setDBtenureProcurement] = useState([])
  var [statusMinistryDB, setDBstatusMinistry] = useState([])
  var [processedByMinistryDB, setDBprocessedByMinistry] = useState([])
  var [processedMinistryDateDB, setDBprocessedMinistryDate] = useState([])
  var [approvedByMinistryDB, setDBapprovedByMinistry] = useState([])
  var [approvedMinistryDateDB, setDBapprovedMinistryDate] = useState([])
  var [reasonMinistryDB, setDBreasonMinistry] = useState([])
  var [reasonProcessedWardDB, setDBreasonProcessedWard] = useState([])
  var [reasonProcessedConstituencyDB, setDBreasonProcessedConstituency] =
    useState([])
  var [reasonProcessedPlanningDB, setDBreasonProcessedPlanning] = useState([])
  var [reasonProcessedFinanceDB, setDBreasonProcessedFinance] = useState([])
  var [reasonProcessedEngineeringDB, setDBreasonProcessedEngineering] =
    useState([])
  var [reasonProcessedProcurementDB, setDBreasonProcessedProcurement] =
    useState([])
  var [
    reasonProcessedCouncilPrincipalDB,
    setDBreasonProcessedCouncilPrincipal
  ] = useState([])
  var [reasonProcessedMinistryDB, setDBreasonProcessedMinistry] = useState([])
  var [processedAmountWardDB, setDBprocessedAmountWard] = useState([])
  var [approvedAmountWardDB, setDBapprovedAmountWard] = useState([])
  var [processedAmountConstituencyDB, setDBprocessedAmountConstituency] =
    useState([])
  var [approvedAmountConstituencyDB, setDBapprovedAmountConstituency] =
    useState([])
  var [processedAmountMinistryDB, setDBprocessedAmountMinistry] = useState([])
  var [approvedAmountMinistryDB, setDBapprovedAmountMinistry] = useState([])
  var [statusProvinceDB, setDBstatusProvince] = useState([])
  var [processedByProvinceDB, setDBprocessedByProvince] = useState([])
  var [processedProvinceDateDB, setDBprocessedProvinceDate] = useState([])
  var [approvedByProvinceDB, setDBapprovedByProvince] = useState([])
  var [approvedProvinceDateDB, setDBapprovedProvinceDate] = useState([])
  var [reasonProvinceDB, setDBreasonProvince] = useState([])
  var [reasonProcessedProvinceDB, setDBreasonProcessedProvince] = useState([])
  var [processedAmountProvinceDB, setDBprocessedAmountProvince] = useState([])
  var [approvedAmountProvinceDB, setDBapprovedAmountProvince] = useState([])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  //alert(ApplicantLoansViewID)
  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getApplicantLoansViewShareHoldersOfTheBusiness')
    formData.append('id', ApplicantLoansViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setShareHoldersOfTheBusinessList(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getApplicantLoansViewProposedManagement')
    formData.append('id', ApplicantLoansViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setProposedManagementTeamList(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getApplicantLoansViewProjectBudget')
    formData.append('id', ApplicantLoansViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setProjectBudgetList(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getApplicantLoansViewClientContributions')
    formData.append('id', ApplicantLoansViewID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setClientContributionsList(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])
  //alert(ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID)
  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'getApplicantLoansView')
    formData.append('id', ApplicantLoansViewID)
    //formData.append('table', 'applyforcommunityproject')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setDBtableViewWardName(response.data)
      setloanTenure(response.data.loanTenure)
      setloanAmount(response.data.loanAmount)
      settypeOfBusiness(response.data.typeOfBusiness)
      setnameOfBusinessMakingApplication(
        response.data.nameOfBusinessMakingApplication
      )
      setprovince(response.data.province)
      setcouncil(response.data.council)
      setconstituency(response.data.constituency)
      setward(response.data.ward)
      setzone(response.data.zone)
      setphysicalAddress(response.data.physicalAddress)
      setdescriptionOfCurrentBusinessActivities(
        response.data.descriptionOfCurrentBusinessActivities
      )
      setlegalFormOfApplicant(response.data.legalFormOfApplicant)
      setcertificateOfRegistration(response.data.certificateOfRegistration)
      setisYourBusinessRegistered(response.data.isYourBusinessRegistered)
      setcontactNumbersForApplicant(response.data.contactNumbersForApplicant)
      setdoYouHaveCopyOfBusinessRegistrationCertificate(
        response.data.doYouHaveCopyOfBusinessRegistrationCertificate
      )
      setdateWhenBusinessWasRegistered(
        response.data.dateWhenBusinessWasRegistered
      )
      setnextOfKinName(response.data.nextOfKinName)
      setnextOfKinPhysicalAddress(response.data.nextOfKinPhysicalAddress)
      setnextOfKinNrc(response.data.nextOfKinNrc)
      setnextOfKinPhoneNumber(response.data.nextOfKinPhoneNumber)
      setauthorizedRepresentativeNameOfContactPerson(
        response.data.authorizedRepresentativeNameOfContactPerson
      )
      setauthorizedRepresentativePositionInTheBusiness(
        response.data.authorizedRepresentativePositionInTheBusiness
      )
      setauthorizedRepresentativeCell(
        response.data.authorizedRepresentativeCell
      )
      setauthorizedRepresentativeTelephone(
        response.data.authorizedRepresentativeTelephone
      )
      setauthorizedRepresentativeEmail(
        response.data.authorizedRepresentativeEmail
      )
      setpreviousCDFFunding(response.data.previousCDFFunding)
      setpreviousCDFFundingStatusOfLoan(
        response.data.previousCDFFundingStatusOfLoan
      )
      setpreviousCDFFundingNameOfBusiness(
        response.data.previousCDFFundingNameOfBusiness
      )
      setpreviousCDFFundingTypeOfRegistrationOfBusiness(
        response.data.previousCDFFundingTypeOfRegistrationOfBusiness
      )
      setbankDetailsBankName(response.data.bankDetailsBankName)
      setbankDetailsBranch(response.data.bankDetailsBranch)
      setbankDetailsBranchCode(response.data.bankDetailsBranchCode)
      setbankDetailsSwiftCode(response.data.bankDetailsSwiftCode)
      setbankDetailsAccountNumber(response.data.bankDetailsAccountNumber)
      setbankDetailsTpin(response.data.bankDetailsTpin)
      setbankDetailsMobileMoneyNameAndNumber(
        response.data.bankDetailsMobileMoneyNameAndNumber
      )
      setfinancialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness(
        response.data.financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness
      )
      setfinancialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness(
        response.data.financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness
      )
      setfinancialDataHowMuchHaveYouInvestedInTheCurrentBusiness(
        response.data.financialDataHowMuchHaveYouInvestedInTheCurrentBusiness
      )
      setmarketOverviewTrendsInDemand(
        response.data.marketOverviewTrendsInDemand
      )
      setmarketOverviewTrendsInSupply(
        response.data.marketOverviewTrendsInSupply
      )
      setbusinessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers(
        response.data
          .businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
      )
      setbusinessOperationsLocation(response.data.businessOperationsLocation)
      setbusinessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations(
        response.data
          .businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations
      )
      setbusinessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations(
        response.data
          .businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations
      )
      setbusinessOperationsWhereWillYouSecureRawMaterialsFromAndHow(
        response.data.businessOperationsWhereWillYouSecureRawMaterialsFromAndHow
      )
      setbusinessOperationsWhatWillBeYourCostOfProductionPerMonth(
        response.data.businessOperationsWhatWillBeYourCostOfProductionPerMonth
      )
      setbusinessOperationsWhatWillBeThePriceOfYourProductsOrServices(
        response.data
          .businessOperationsWhatWillBeThePriceOfYourProductsOrServices
      )
      setbusinessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations(
        response.data
          .businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations
      )
      setbusinessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear(
        response.data
          .businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear
      )
      setbusinessOperationsWhoWillBeYourMajorCompetitors(
        response.data.businessOperationsWhoWillBeYourMajorCompetitors
      )
      sethowWillYourCustomersBenefitFromYourBusiness(
        response.data.howWillYourCustomersBenefitFromYourBusiness
      )
      sethowWillYourSuppliersBenefitFromYourBusiness(
        response.data.howWillYourSuppliersBenefitFromYourBusiness
      )
      setnumberOfWorkersBeforeCDFFundingWomen(
        response.data.numberOfWorkersBeforeCDFFundingWomen
      )
      setnumberOfWorkersBeforeCDFFundingMen(
        response.data.numberOfWorkersBeforeCDFFundingMen
      )
      setnumberOfWorkersBeforeCDFFundingYouth(
        response.data.numberOfWorkersBeforeCDFFundingYouth
      )
      setnumberOfWorkersBeforeCDFFundingDisabled(
        response.data.numberOfWorkersBeforeCDFFundingDisabled
      )
      setnumberOfWorkersAfterCDFFundingWomen(
        response.data.numberOfWorkersAfterCDFFundingWomen
      )
      setnumberOfWorkersAfterCDFFundingMen(
        response.data.numberOfWorkersAfterCDFFundingMen
      )
      setnumberOfWorkersAfterCDFFundingYouth(
        response.data.numberOfWorkersAfterCDFFundingYouth
      )
      setnumberOfWorkersAfterCDFFundingDisabled(
        response.data.numberOfWorkersAfterCDFFundingDisabled
      )
      setcapitalThresholdRepaymentPeriod(
        response.data.capitalThresholdRepaymentPeriod
      )
      setifYouAreFundedWhenWouldOperationsStart(
        response.data.ifYouAreFundedWhenWouldOperationsStart
      )
      setdeclarationApplicantDate(response.data.declarationApplicantDate)
      setdeclarationApplicantDay(response.data.declarationApplicantDay)
      setapplicationDate(response.data.applicationDate)

      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBstatusCouncilPrincipal(response.data.statusCouncilPrincipal)
      setDBprocessedByCouncilPrincipal(
        response.data.processedByCouncilPrincipal
      )
      setDBprocessedCouncilPrincipalDate(
        response.data.processedCouncilPrincipalDate
      )
      setDBapprovedByCouncilPrincipal(response.data.approvedByCouncilPrincipal)
      setDBapprovedCouncilPrincipalDate(
        response.data.approvedCouncilPrincipalDate
      )
      setDBreasonCouncilPrincipal(response.data.reasonCouncilPrincipal)
      setDBtenureCouncilPrincipal(response.data.tenureCouncilPrincipal)
      setDBstatusPlanning(response.data.statusPlanning)
      setDBprocessedByPlanning(response.data.processedByPlanning)
      setDBprocessedPlanningDate(response.data.processedPlanningDate)
      setDBapprovedByPlanning(response.data.approvedByPlanning)
      setDBapprovedPlanningDate(response.data.approvedPlanningDate)
      setDBreasonPlanning(response.data.reasonPlanning)
      setDBtenurePlanning(response.data.tenurePlanning)
      setDBstatusFinance(response.data.statusFinance)
      setDBprocessedByFinance(response.data.processedByFinance)
      setDBprocessedFinanceDate(response.data.processedFinanceDate)
      setDBapprovedByFinance(response.data.approvedByFinance)
      setDBapprovedFinanceDate(response.data.approvedFinanceDate)
      setDBreasonFinance(response.data.reasonFinance)
      setDBtenureFinance(response.data.tenureFinance)
      setDBstatusEngineering(response.data.statusEngineering)
      setDBprocessedByEngineering(response.data.processedByEngineering)
      setDBprocessedEngineeringDate(response.data.processedEngineeringDate)
      setDBapprovedByEngineering(response.data.approvedByEngineering)
      setDBapprovedEngineeringDate(response.data.approvedEngineeringDate)
      setDBreasonEngineering(response.data.reasonEngineering)
      setDBtenureEngineering(response.data.tenureEngineering)
      setDBstatusProcurement(response.data.statusProcurement)
      setDBprocessedByProcurement(response.data.processedByProcurement)
      setDBprocessedProcurementDate(response.data.processedProcurementDate)
      setDBapprovedByProcurement(response.data.approvedByProcurement)
      setDBapprovedProcurementDate(response.data.approvedProcurementDate)
      setDBreasonProcurement(response.data.reasonProcurement)
      setDBtenureProcurement(response.data.tenureProcurement)
      setDBstatusMinistry(response.data.statusMinistry)
      setDBprocessedByMinistry(response.data.processedByMinistry)
      setDBprocessedMinistryDate(response.data.processedMinistryDate)
      setDBapprovedByMinistry(response.data.approvedByMinistry)
      setDBapprovedMinistryDate(response.data.approvedMinistryDate)
      setDBreasonMinistry(response.data.reasonMinistry)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )
      setDBreasonProcessedPlanning(response.data.reasonProcessedPlanning)
      setDBreasonProcessedFinance(response.data.reasonProcessedFinance)
      setDBreasonProcessedEngineering(response.data.reasonProcessedEngineering)
      setDBreasonProcessedProcurement(response.data.reasonProcessedProcurement)
      setDBreasonProcessedCouncilPrincipal(
        response.data.reasonProcessedCouncilPrincipal
      )
      setDBreasonProcessedMinistry(response.data.reasonProcessedMinistry)
      setDBprocessedAmountWard(response.data.processedAmountWard)
      setDBapprovedAmountWard(response.data.approvedAmountWard)
      setDBprocessedAmountConstituency(
        response.data.processedAmountConstituency
      )
      setDBapprovedAmountConstituency(response.data.approvedAmountConstituency)
      setDBprocessedAmountMinistry(response.data.processedAmountMinistry)
      setDBapprovedAmountMinistry(response.data.approvedAmountMinistry)
      setDBstatusProvince(response.data.statusProvince)
      setDBprocessedByProvince(response.data.processedByProvince)
      setDBprocessedProvinceDate(response.data.processedProvinceDate)
      setDBapprovedByProvince(response.data.approvedByProvince)
      setDBapprovedProvinceDate(response.data.approvedProvinceDate)
      setDBreasonProvince(response.data.reasonProvince)
      setDBreasonProcessedProvince(response.data.reasonProcessedProvince)
      setDBprocessedAmountProvince(response.data.processedAmountProvince)
      setDBapprovedAmountProvince(response.data.approvedAmountProvince)

      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  /*
  if (
    statusWardDB != 'Pending Approval By WDC' &&
    recommendationByTheWardDevelopmentCommittee === ''
  ) {
    recommendationByTheWardDevelopmentCommittee = statusWardDB
  }

  if (
    reasonWardDB != '' &&
    recommendationByTheWardDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheWardDevelopmentCommitteeReason = reasonWardDB
  }

  if (
    statusConstituencyDB != 'Pending Approval By CDFC' &&
    recommendationByTheConstituencyDevelopmentCommittee === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommittee = statusConstituencyDB
  }

  if (
    reasonconstituencyDB != '' &&
    recommendationByTheConstituencyDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommitteeReason =
      reasonconstituencyDB
  }

  let wardDevelopmentCommitteeChairpersonName = ''
  let wardDevelopmentCommitteeChairpersonDate = ''
  let constituencyDevelopmentCommitteeChairpersonName = ''
  let constituencyDevelopmentCommitteeChairpersonDate = ''
  let wardDevelopmentCommitteePosition = 'Name (WDC Chairperson):'
  let constituencyDevelopmentCommitteePosition = 'Name (CDFC Chairperson):'
  //alert(approvedByWardDB)
  if (
    processedByWardDB != '' &&
    processedByWardDB != null &&
    (approvedByWardDB === '' || approvedByWardDB === null)
  ) {
    wardDevelopmentCommitteeChairpersonName = processedByWardDB
    wardDevelopmentCommitteeChairpersonDate = processedWardDateDB
    wardDevelopmentCommitteePosition = 'Name (WDC Member):'
  }
  if (approvedByWardDB != '' && approvedByWardDB != null) {
    wardDevelopmentCommitteeChairpersonName = approvedByWardDB
    wardDevelopmentCommitteeChairpersonDate = approvedByWardDateDB
  }

  if (
    processedByConstituencyDB != '' &&
    processedByConstituencyDB != null &&
    (approvedByConstituencyDB === '' || approvedByConstituencyDB === null)
  ) {
    constituencyDevelopmentCommitteeChairpersonName = processedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate =
      processedConstituencyDateDB
    constituencyDevelopmentCommitteePosition = 'Name (CDFC Member):'
  }

  if (approvedByConstituencyDB != '' && approvedByConstituencyDB != null) {
    constituencyDevelopmentCommitteeChairpersonName = approvedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate = approvedConstituencyDateDB
  }
*/

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text'>
                    {' '}
                    <span class='text-uppercase'>
                      {nameOfBusinessMakingApplication} APPLICATION FORM - LOANS
                    </span>
                  </div>
                  {/*  <!-- Grid row -->*/}
                  <div class='col-12'>
                    <br /> <br />
                    <section>
                      <div class='text-left'>
                        <center>
                          {' '}
                          <img src={require('./img/coat of arms.jpg')} />
                          <strong>
                            <br />
                            CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> LOAN
                            APPLICATION FORM
                          </strong>
                        </center>
                        <br />
                        <strong>Instructions:</strong> This application form
                        should be completed by the applicant and sent together
                        with supporting documents to the Chairperson, Ward
                        Development Committee. <br />
                        <u>
                          <b>NOTE:</b>
                        </u>{' '}
                        This form is not for sale.
                        <br />
                        <i>
                          Disclaimer: Completion of the form does not guarantee
                          the award of the Loan.
                        </i>
                      </div>

                      <br />
                      <div class='row' style={{ border: '1px solid black' }}>
                        <div class='col-lg-3 col-md-12 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Loan Amount (ZMW) <span class='red-text'>*</span>{' '}
                            </label>
                            <br />
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={loanAmount}
                            />
                          </div>
                        </div>
                        {/*</div>
                    <br />
                    <div style={{ border: '1px solid black' }}>
                      <br />*/}
                        <div class='col-lg-2 col-md-12 mb-1'>
                          <div class='md-form text-left'>
                            <label for='registerFullName'>
                              Loan Tenure <span class='red-text '>*</span>{' '}
                            </label>
                            <br />
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              value={loanTenure}
                            />
                          </div>
                        </div>
                        <div class='col-lg-2 col-md-12 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Application Date<span class='red-text'>*</span>{' '}
                            </label>
                            <br />
                            <br />
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              value={applicationDate}
                            />
                          </div>
                        </div>
                        <div class='col-lg-5 col-md-12 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Type Of Business{' '}
                              <span class='red-text'>
                                e.g Poultry, Piggery, Carpentry, Tailoring etc
                              </span>{' '}
                            </label>{' '}
                            <br />
                            <br />
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              value={typeOfBusiness}
                            />
                          </div>
                        </div>
                      </div>

                      <br />
                    </section>
                    {/*<!--\form heading -->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION A: GENERAL DETAILS{' '}
                          <span class='yellow-text'>
                            (To be filled by the applicant)
                          </span>{' '}
                        </h6>
                      </center>
                    </div>
                    <br />
                    <div id='divShowMessageFromAjax'></div>
                    {/*<!--Grid row-->			*/}
                    <div class='row text-left'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-12 mb-1'>
                        {/*<!--<div class="md-form">--> */}{' '}
                        <label for='registerFullName'>
                          1. Name of Business/Enterprise/Company/Cooperative
                          making application. <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={nameOfBusinessMakingApplication}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->				*/}
                    <div class='text-left mt-5'>
                      2. <u>PROJECT LOCATION </u>
                    </div>
                    {/*<!--Grid row-->				*/}
                    <div class='row text-left'>
                      {/* <!--Grid column -->*/}
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <label for='registerFullName'>
                          Provinces<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={province}
                        />
                      </div>
                      {/* <!--\Grid column-->*/}

                      {/* <!--Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-0.01'
                        id='hideLocalAuthority'
                      >
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Council<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={council}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {/*<!--\Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-1'
                        id='hideConstituency'
                      >
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Constituency<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={constituency}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {/* <!--\Grid column-->*/}
                      <div class='col-lg-4 col-md-12 mb-1' id='hideWard'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Ward<span class='red-text'>*</span>{' '}
                        </label>
                        <input type='text' class='form-control' value={ward} />
                        {/*<!--</div>-->*/}
                      </div>

                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Zone<span class='red-text'>*</span>
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={zone}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-5 col-md-12 mb-1'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Business Physical Address
                            <span class='red-text'>*</span>
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={physicalAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            3. a. Description of Current Business Activities, if
                            any <span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <br />
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={descriptionOfCurrentBusinessActivities}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-7 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            b. Legal Form of Applicant (e.g. Limited Company,
                            Cooperative, etc.) Attach copy of certificate if
                            registered. <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={legalFormOfApplicant}
                          />
                        </div>
                      </div>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form text-left'>
                          <label for='registerFullName'>
                            Is Your Business Registered ?{' '}
                            <span class='red-text '>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={isYourBusinessRegistered}
                          />
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Certificate Of Registration{' '}
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input disabled type='text' class='form-control' />
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md text-left'>
                          {' '}
                          <label for='registerFullName'>
                            c. Date when Business was registered{' '}
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='date'
                            class='form-control'
                            placeholder=''
                            value={dateWhenBusinessWasRegistered}
                          />
                        </div>
                      </div>
                      <div class='col-lg-5 col-md-12 mb-1'>
                        <div class='md text-left'>
                          <label for='registerFullName'>
                            d. Do You Have Copy of business registration
                            certificate ? <span class='red-text '>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={
                              doYouHaveCopyOfBusinessRegistrationCertificate
                            }
                          />
                        </div>
                      </div>
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md text-left'>
                          {' '}
                          <label for='registerFullName'>
                            e. Contact Number(s) for Applicant{' '}
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={contactNumbersForApplicant}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-5 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            f. Next of Kin and their physical address and Phone
                          </label>
                          <br />
                        </div>
                      </div>
                    </div>
                    <br /> <br />
                    <div class='row' style={{ border: '1px solid black' }}>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Name
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={nextOfKinName}
                          />
                        </div>
                      </div>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Physical Address
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={nextOfKinPhysicalAddress}
                          />
                        </div>
                      </div>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            NRC
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={nextOfKinNrc}
                          />
                        </div>
                      </div>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Phone Number
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={nextOfKinPhoneNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='mt-5 text-left'>
                      4. Shareholders of the Business
                    </div>
                    {shareHoldersOfTheBusinessList.map(
                      (
                        shareholdersOfTheBusinessSingleInputList,
                        shareholdersOfTheBusinessIndex
                      ) => {
                        return (
                          <>
                            <div class='row text-left'>
                              <div class='col-lg-4 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    {shareholdersOfTheBusinessIndex + 1} . Full
                                    Names
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessFullNames
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Nationality
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessNationality
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-2 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    NRC/ Passport No
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessNrc
                                    }
                                  />
                                </div>
                              </div>
                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Attach Copy of ID(NRC/Passport)
                                    <span class='red-text'>*</span>{' '}
                                  </label>
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    disabled
                                  />
                                </div>
                              </div>

                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    % share holding
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessShares
                                    }
                                  />
                                </div>
                              </div>
                              <div class='col-lg-4 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Position in the Business
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessPositionInTheBusiness
                                    }
                                  />
                                </div>
                              </div>
                              <div class='col-lg-5 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Disabled ?<span class='red-text'>*</span>{' '}
                                  </label>
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessDisabled
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    )}
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12'>
                        <div class='md'>
                          <label for='registerFullName'>
                            5. Authorized Representative (to act on behalf of
                            the Applicant and respond to any questions regarding
                            the Concept Note)
                          </label>{' '}
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Name of Contact Person
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={authorizedRepresentativeNameOfContactPerson}
                          />
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Position in the Business
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={
                              authorizedRepresentativePositionInTheBusiness
                            }
                          />
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Cell
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={authorizedRepresentativeCell}
                          />
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Telephone
                            <span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={authorizedRepresentativeTelephone}
                          />
                        </div>
                      </div>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Email
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={authorizedRepresentativeEmail}
                          />
                        </div>
                      </div>
                    </div>
                    <br /> <br />
                    <div
                      class='row text-left'
                      style={{ border: '1px solid black' }}
                    >
                      <div class='col-lg-12 col-md-12 mb-2'>
                        <div class='md'>
                          <label for='registerFullName'>
                            6. Previous CDF Funding <br />
                            <i>
                              Is Applicant or any of its shareholders already a
                              beneficiary of Loan or Grant Empowerment Fund?{' '}
                            </i>
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={previousCDFFunding}
                          />
                        </div>
                      </div>

                      <div
                        class='col-lg-4 col-md-12 mb-1'
                        hidden={previousCDFFunding != 'Yes'}
                      >
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Status of Loan (outstanding/Repaid)
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={previousCDFFundingStatusOfLoan}
                          />
                        </div>
                      </div>
                      <div
                        class='col-lg-4 col-md-12 mb-1'
                        hidden={previousCDFFunding != 'Yes'}
                      >
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Name of Business
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={previousCDFFundingNameOfBusiness}
                          />
                        </div>
                      </div>
                      <div
                        class='col-lg-4 col-md-12 mb-1'
                        hidden={previousCDFFunding != 'Yes'}
                      >
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Type of Registration of Business
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={
                              previousCDFFundingTypeOfRegistrationOfBusiness
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12'>
                        <div class='md'>
                          <label for='registerFullName'>
                            7.0 BANK DETAILS
                            <br />
                            Provide Bank account or mobile money wallet
                            registered for your Business/
                            Club/Group/Enterprise/Cooperative
                          </label>{' '}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            BANK NAME
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsBankName}
                          />
                        </div>
                      </div>
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            BRANCH
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsBranch}
                          />
                        </div>
                      </div>
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            SORT/BRANCH CODE
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsBranchCode}
                          />
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            SWIFT CODE
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsSwiftCode}
                          />
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            ACCOUNT NUMBER
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsAccountNumber}
                          />
                        </div>
                      </div>
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            TPIN
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsTpin}
                          />
                        </div>
                      </div>
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            MOBILE MONEY WALLET NAME AND NUMBER
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={bankDetailsMobileMoneyNameAndNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12'>
                        <div class='md'>
                          <label for='registerFullName'>
                            8.0 Financial Data (
                            <i>
                              Please provide Financial data for your business
                            </i>
                            ).
                          </label>{' '}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            What is the Monthly Income of the current business?
                            If any
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={
                              financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness
                            }
                          />
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            What is the Monthly Expenses of the current
                            business?
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={
                              financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness
                            }
                          />
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            How much have you invested in the current business?
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={
                              financialDataHowMuchHaveYouInvestedInTheCurrentBusiness
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12'>
                        <div class='md'>
                          <label for='registerFullName'>
                            9.0 MARKET OVERVIEW
                          </label>{' '}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            9.1 Trends in Demand (Explain with regards to the
                            Proposed Product)
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <br /> <br />
                          <textarea
                            class='form-control'
                            value={marketOverviewTrendsInDemand}
                          ></textarea>
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            9.2 Trends in Supply (Explain with regards to the
                            Proposed Product)
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={marketOverviewTrendsInSupply}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <br /> <br />
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12'>
                        <div class='md'>
                          <label for='registerFullName'>
                            10.0 DESCRIPTION OF PROPOSED BUSINESS PROJECT
                            <br />
                            10.1 Business Operations
                            <br />
                            How is your business going to compete in terms of
                            the following:
                          </label>{' '}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (a) What products and/or services will you be
                            selling to your customers?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (b) (i) Location (State the advantage of the
                            location of the project)
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <br /> <br />
                          <textarea
                            class='form-control'
                            value={businessOperationsLocation}
                          ></textarea>
                        </div>
                      </div>

                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (ii) What kind of equipment will be needed for the
                            operations?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (iii) What will such types of equipment be used for
                            in the operations?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations
                            }
                          ></textarea>
                        </div>
                      </div>

                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (iv) Where will you secure raw materials from and
                            how?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhereWillYouSecureRawMaterialsFromAndHow
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (v) What will be your cost of production per month
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatWillBeYourCostOfProductionPerMonth
                            }
                          ></textarea>
                        </div>
                      </div>

                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (a) What products and/or services will you be
                            selling to your customers?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-1 text-left'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (c) Price of products and income:
                            <br /> (i) What will be the price of your
                            products/services?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatWillBeThePriceOfYourProductsOrServices
                            }
                          ></textarea>
                        </div>
                      </div>

                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (ii) What will be your income per month from the
                            business operations
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations
                            }
                          ></textarea>
                        </div>
                      </div>

                      <div class='col-lg-12 col-md-12 mb-1 text-left'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (d) Sales Volumes
                            <br /> (i) What will be your level of production per
                            month or year
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear
                            }
                          ></textarea>
                        </div>
                      </div>

                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (ii) Who will be your major competitors in terms of
                            supplying the same products/services and why do you
                            think you will survive the competition
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={
                              businessOperationsWhoWillBeYourMajorCompetitors
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            11.0 Proposed Management Team
                            <br /> [Please indicate names, positions,
                            qualification and relevant experience of key members
                            of the management team that will implement and run
                            the proposed business. Startup businesses may only
                            include positions]
                          </label>
                        </div>
                      </div>
                    </div>
                    {proposedManagementTeamList.map(
                      (
                        proposedManagementTeamSingleInputList,
                        proposedManagementTeamIndex
                      ) => {
                        return (
                          <>
                            <div class='row text-left'>
                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    {proposedManagementTeamIndex + 1} . Full
                                    Name
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      proposedManagementTeamSingleInputList.proposedManagementTeamFullName
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-4 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Management Position
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      proposedManagementTeamSingleInputList.proposedManagementTeamManagementPosition
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-4 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Management Qualification and Experience
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      proposedManagementTeamSingleInputList.proposedManagementTeamManagementQualificationAndExperience
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    )}
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            <b>12.0 What will be the Business Impact?</b> In not
                            more than a sentence on each, please indicate the
                            positive impact this business will have on the
                            following:
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (a) How will your <b>customers</b> benefit from your
                            business?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={howWillYourCustomersBenefitFromYourBusiness}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            (b) How will your <b>suppliers</b> benefit from your
                            business?
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br /> <br /> <br />
                          <textarea
                            class='form-control'
                            value={howWillYourSuppliersBenefitFromYourBusiness}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            (c) Number of Workers:
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class='row text-center '>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            <center>BEFORE CDF FUNDING </center>
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Women
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersBeforeCDFFundingWomen}
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Men
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersBeforeCDFFundingMen}
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Youth
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersBeforeCDFFundingYouth}
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Physically Challenged (Disabled)
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersBeforeCDFFundingDisabled}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row text-center '>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            <center>AFTER CDF FUNDING </center>
                            <span class='red-text'></span>{' '}
                          </label>{' '}
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Women
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersAfterCDFFundingWomen}
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Men
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersAfterCDFFundingMen}
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Youth
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersAfterCDFFundingYouth}
                          />
                        </div>
                      </div>

                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          {' '}
                          <label for='registerFullName'>
                            Physically Challenged (Disabled)
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={numberOfWorkersAfterCDFFundingDisabled}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            13.0 PROJECT BUDGET
                          </label>
                        </div>
                      </div>
                    </div>
                    {projectBudgetList.map(
                      (projectBudgetSingleInputList, projectBudgetIndex) => {
                        return (
                          <>
                            <div class='row text-left'>
                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    {projectBudgetIndex + 1} . Item
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      projectBudgetSingleInputList.projectBudgetItem
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Quantity
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      projectBudgetSingleInputList.projectBudgetQuantity
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-2 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Cost (ZMW)
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      projectBudgetSingleInputList.projectBudgetCost
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-4 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Total (ZMW)
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      projectBudgetSingleInputList.projectBudgetTotal
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    )}
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            14.0 CLIENT CONTRIBUTION <br />
                            <i>
                              Will any of the above items be financed by the
                              Business? If so, list below.
                            </i>
                          </label>
                        </div>
                      </div>
                    </div>
                    {clientContributionList.map(
                      (
                        clientContributionSingleInputList,
                        clientContributionIndex
                      ) => {
                        return (
                          <>
                            <div class='row text-left'>
                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    {clientContributionIndex + 1} . Item
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      clientContributionSingleInputList.clientContributionItem
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-3 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Quantity
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      clientContributionSingleInputList.clientContributionQuantity
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-2 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Cost (ZMW)
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      clientContributionSingleInputList.clientContributionCost
                                    }
                                  />
                                </div>
                              </div>

                              <div class='col-lg-4 col-md-12 mb-1'>
                                <div class='md-form'>
                                  {' '}
                                  <label for='registerFullName'>
                                    Total (ZMW)
                                    <span class='red-text'>*</span>{' '}
                                  </label>{' '}
                                  <br />
                                  <br />
                                  <input
                                    type='text'
                                    class='form-control'
                                    value={
                                      clientContributionSingleInputList.clientContributionTotal
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    )}
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            15.0 Loan Information
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            CAPITAL THRESHOLD REPAYMENT PERIOD (select preferred
                            capital threshold)
                            <br />
                            <i>Note: The interest rate for all loans is 5%</i>
                            <span class='red-text'>*</span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={capitalThresholdRepaymentPeriod}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            16.0 Project Implementation
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>
                            If you are funded, when would operations start?
                            <span class='red-text'>*</span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={ifYouAreFundedWhenWouldOperationsStart}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row text-left'>
                      <div class='col-lg-12 col-md-12 mb-1'>
                        <div class='md'>
                          {' '}
                          <label for='registerFullName'>17.0 DECLARATION</label>
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-12 col-md-12 mb-1 text-left'>
                        <br />
                        We the undersigned, on{' '}
                        <input
                          type='text'
                          placeholder='Indicate Day Here'
                          value={declarationApplicantDay}
                        />{' '}
                        This{' '}
                        <input
                          type='date'
                          placeholder='Indicate Date Here'
                          value={declarationApplicantDate}
                        />{' '}
                        20... declare that the information given herein is the
                        correct state of affairs to the best of my knowledge. We
                        will take full responsibility in the event of abuse,
                        mismanagement, defrauding of the funds provided under
                        this empowerment fund:
                      </div>
                      {/* <!--\Grid column-->		*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    <section>
                      <div
                        class='card-header mt-5 mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION D: RECOMMENDATION BY THE WARD DEVELOPMENT
                            COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/*  <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Ward Development Committee{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            //name='recommendationByTheWardDevelopmentCommittee'
                            value={recommendationByTheWardDevelopmentCommittee}
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Recommended</option>
                            <option>Not Recommended</option>
                            <option>Defer</option>
                          </select>
                        </div>
                        {/*   <!--\Grid column-->				 */}
                        {/*     <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*       <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            name='recommendationByTheWardDevelopmentCommitteeReason'
                            value={
                              recommendationByTheWardDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                            required
                          >
                            {' '}
                          </textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->				*/}
                      </div>
                      {/* <!--\Grid row--> */}
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (WDC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonSign'
                              id='wardDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row--> */}

                      <section
                        class='section mt-5'
                        hidden={
                          processedByWardDB === '' || processedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Proccessed By
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={processedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={processedWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByWardDB === '' || approvedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (WDC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={approvedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={approvedByWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        onClick={handleSubmitWards}
                        disabled={
                          recommendationByTheWardDevelopmentCommittee === '' ||
                          recommendationByTheWardDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        Submit
                      </button>
                    </section>
                    <section
                      hidden={
                        userPosition === 'Councilor' ||
                        userPosition === 'WDC Chairperson' ||
                        userPosition === 'WDC Member'
                      }
                    >
                      <div
                        class='card-header mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION E: DECISION BY THE CONSTITUENCY DEVELOPMENT
                            FUND COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Constituency Development
                            Committee <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            value={
                              recommendationByTheConstituencyDevelopmentCommittee
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Approved</option>
                            <option>Not Approved</option>
                          </select>
                        </div>
                        {/*  <!--\Grid column-->		*/}
                        {/*      <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*      <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons For Recommendation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            value={
                              recommendationByTheConstituencyDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                          ></textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row-->*/}
                      {/* <!--Grid row-->*/}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/*<!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (CDFC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonSign'
                              id='constituencyDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                        {/* <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                      </div>

                      <section
                        class='section mt-5'
                        hidden={
                          processedByConstituencyDB === '' ||
                          processedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Processed By :
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={processedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={processedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByConstituencyDB === '' ||
                          approvedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonconstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (CDFC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={approvedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={approvedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        disabled={
                          recommendationByTheConstituencyDevelopmentCommittee ===
                            '' ||
                          recommendationByTheConstituencyDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                        onClick={handleSubmitConstituencies}
                      >
                        Submit
                      </button>
                      {/* <!-- Grid row -->*/}
                    </section>
                  </div>
                  {/* <!-- Grid row -->*/}
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default ApplicantLoansView
