import {useRef, useState, useEffect} from 'react';
import './App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from './api/axios';
//import Session from './Session';

const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
//const REGISTER_URL = '/register';
//const PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ; 
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;


 
const Register = () => {
	const items ="";
	const [data, setData] = useState("Zambia My Country");

useEffect(() => {
   let items = JSON.parse(localStorage.getData('data'));
  if (items) {
   setData(items);
  }
}, []);
/*const [name, getData] = useState(() => {
  // getting stored value
  const saved = localStorage.getItem("name");
  const initialValue = JSON.parse(saved);
  return initialValue;
});*/

console.log("ITEMS = ");

const userRef = useRef();
const errRef = useRef();

const [user, setUser] = useState('');
const [validName, setValidName ] = useState(false);
const [userFocus, setUserFocus ] = useState(false);

const [pwd, setPwd] = useState('');
const [validPwd, setValidPwd] = useState(false);
const [pwdFocus, setPwdFocus] = useState(false);

const [matchPwd, setMatchPwd] = useState('');
const [validMatch, setValidMatch] = useState(false);
const [matchFocus, setMatchFocus] = useState(false);

const [errMsg, setErrMsg ] = useState('');
const [success, setSuccess ] = useState(false);

const [DBtable, setDBtable] = useState([]);

const [update, setUpdate] = useState('');

const [photo, setPhoto] = useState('');

useEffect( () => {
	userRef.current.focus();
},
[])

useEffect( () => {
    const result = USER_REGEX.test(user);
    //console.log(result);
    //console.log(user);
	setValidName(result);
},
[user])

useEffect( () => {
    const result = PWD_REGEX.test(pwd);
    //console.log(result);
    //console.log(pwd);
	setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
},
[pwd, matchPwd])

useEffect( () => {
	setErrMsg('');
},
[user, pwd, matchPwd])

useEffect( () => {
Axios.get("http://localhost:3001/api/get").then((response) => {
    setDBtable(response.data);
    //console.log(response);
    });
},
[])

const handleSubmit = async (e) => 
{
    setSuccess(true);
    
    Axios.post("http://localhost:3001/api/insert", {
        lname : user, 
        fname : pwd,
    });

/*setDBtable([
    ...DBtable, {lname: user, fname:pwd},
]);*/
    
	
    
    alert ("Successfully Uploaded");
    //console.log(user);
    //console.log(pwd);
}

const UpdateRecord = (id) => {
    Axios.put("http://localhost:3001/api/update",{
        id : id, 
        lname : update,
    });
    setUpdate('');
}

const deleteRecord = (id) => {
    Axios.delete(`http://localhost:3001/api/delete/${id}`);
    alert ("Successfully Deleted Record");
}

//alert(Session.getName());
    return(
	 <header>
	<>

	
	{success ? (
	<section>
	{/*<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">&times;</button>
Successfully Uploaded</div>*/}
{/*<p> 
	<a href="#">Login</a>
	</p>*/}
	</section>
	) : (
        <section>
          
{/*<p> 
	<a href="#">Login</a>
	</p>*/}
	</section>
        )}

       <section class="view intro-2">
<div class=" d-flex justify-content-center align-items-center">
  <div class="container">
<div class="row"> 
<div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
 
   <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
	aria-live = "assertive"> {errMsg} </p>
	
	<div class="card wow fadeIn" data-wow-delay="0.3s">
                <div class="card-body">
	<div class="form-header purple-gradient">
                    <h3 class="font-weight-500 my-2 py-1"><i class="fas fa-user"></i> Sign Up:</h3>
                  </div>
    <form >
        
        
		<div class="md-form">
                    <input 
                    type="file" 
                    id="photoName" 
                    class="form-control"
            autoComplete = "off"
        onChange={(e) => setPhoto(e.target.value)}
        value={photo}
       		/>   
                    <label class="red-text"><br />Your Image</label>
                  </div>

                  <br />
		<div class="md-form">
                    <i class="fas fa-user prefix white-text"></i>
                    <input 
                    type="text" 
                    id="username" 
                    class="form-control"
        ref= {userRef} 
        autoComplete = "off"
        onChange={(e) => setUser(e.target.value)}
        value={user}
        required
        aria-invalid={validName ? "false" : "true"}
        aria-describedby = "uidnote"
        onFocus={() => setUserFocus(true)}
        onBlur={() => setUserFocus(false)}
					/>
                    <p
                        id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            4 to 24 characters. <br />
                            Letters, numbers, undercores, hyphens allowed.
                    </p>
                    <label for="orangeForm-email">Your Username
                    <span className={validName ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validName || !user ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
                    </label>
                  </div>
		
		
		<div class="md-form">
                    <i class="fas fa-lock prefix white-text"></i>
                    <input 
					type="password" 
					id="password"  
					class="form-control"
       onChange={(e) => setPwd(e.target.value)}
       required
aria-invalid={validPwd ? "false" : "true"}
        aria-describedby = "pwdnote"
        onFocus={() => setPwdFocus(true)}
        onBlur={() => setPwdFocus(false)}
					/>
                    <p
                        id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            8 to 24 characters. <br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters : <span aria-label = "exclamation mark">!</span>
                            <span aria-label = "at symbol">@</span>
                            <span aria-label = "hashtag">#</span>
                            <span aria-label = "dollar sign">$</span>
                            <span aria-label = "percent">%</span>
                    </p>	
		
					
                    <label for="orangeForm-pass">Your password
					<span className={validPwd ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validPwd || !pwd ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
					</label>
                  </div>
				  
		<div class="md-form">
                    <i class="fas fa-lock prefix white-text"></i>
                    <input 
					type="password" 
					id="confirm_pwd"  
					class="form-control"
       onChange={(e) => setMatchPwd(e.target.value)}
       required
aria-invalid={validMatch ? "false" : "true"}
        aria-describedby = "confirmnote"
        onFocus={() => setMatchFocus(true)}
        onBlur={() => setMatchFocus(false)}
					/>
                    <p
                        id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            Must match the first password input field.
                    </p>	
		
					
                    <label htmlFo="password" >Confirm Password
					<span className={validMatch && matchPwd ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validMatch || !matchPwd ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
					</label>
                  </div>
         <div class="text-center">
                   
					<button onClick= {handleSubmit} class="btn purple-gradient btn-lg" 
                    disabled={!validName || !validPwd || !validMatch ? true : false}
                    >Sign up</button>
                    <hr class="mt-4" />
                    <p >
            Already Have An Account ? <br />
            {/* put Router link Here*/}
            <a href="login" class="white-text"> Sign In</a>
        </p>
                  </div>
				  
				  <div class="text-center"> <hr class="mt-4" /> <p > <a href="/" class="blue-text"> Home</a> </p> </div>
				  
        
    </form>
	 </div>
              </div>
</div>
</div>

{/* Data Table View Starts Here*/}
<div class="row"> 
<br />
<table class="table table-striped table-bordered table-hover table-sm">

<thead class="form-header">
<tr>
<td colspan="5"><h1 class="card-header white-text">DATA FROM THE DATABASE</h1></td>
</tr>
    <tr>

    <th>User Name</th>
    <th>Password</th>
    <th>Update</th>
    <th>Delete</th>
    </tr>
</thead>
<tbody>
{
    DBtable.map((val)=>{
return (
  
<tr>
    
    <td>{val.lname}</td>
    <td>{val.fname}</td>
    <td><input type="text"  onChange={(e) => setUpdate(e.target.value)} class="form-control" required/> <button onClick={()=> {UpdateRecord(val.id)}}  class="btn btn-info" title="Update This Record"><span class="fa fa-edit"></span></button></td>
    <td><button onClick={()=> {deleteRecord(val.id)}} class="btn btn-danger" title="Delete This Record From The Database"><span class="fa fa-trash"></span></button></td>
</tr>

    );
    })
    }
    </tbody>
    </table>
    
</div>
{/* Data Table View Ends Here*/}

</div>
</div>
</section>

</>

 </header>
    )
}

export default Register;