import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ApplicantSkillsDevelopmentBursariesView = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let ApplicantSkillsDevelopmentBursariesViewID = localStorage.getItem(
    'ApplicantSkillsDevelopmentBursariesView'
  )
  ApplicantSkillsDevelopmentBursariesViewID = JSON.parse(
    ApplicantSkillsDevelopmentBursariesViewID
  )

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)
  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var [
    theApplicantMustBeZambianAndInPossessionNRC,
    setTheApplicantMustBeZambianAndInPossessionNRC
  ] = useState('')
  var [grade12SchoolCertificate, setGrade12SchoolCertificate] = useState('')
  var [
    grade9CertificateOrGrade7Certificate,
    setGrade9CertificateOrGrade7Certificate
  ] = useState('')
  var [
    alsoEncouragedAreThoseWhoHaveNeverBeenToSchool,
    setAlsoEncouragedAreThoseWhoHaveNeverBeenToSchool
  ] = useState('')
  var [
    theApplicantMustPossessAcceptanceLetter,
    setTheApplicantMustPossessAcceptanceLetter
  ] = useState('')
  var [recommendationLetter, setRecommendationLetter] = useState('')
  var [
    theApplicantIsNotOnAnyOtherBursary,
    setTheApplicantIsNotOnAnyOtherBursary
  ] = useState('')
  var [
    theApplicantMustBeAbove16YearsOfAge,
    setTheApplicantMustBeAbove16YearsOfAge
  ] = useState('')
  var [whereApplicableADisabilityLetter, setWhereApplicableADisabilityLetter] =
    useState('')
  var [
    theApplicantHasBeenAResidentOfTheConstituency,
    setTheApplicantHasBeenAResidentOfTheConstituency
  ] = useState('')
  var [consentOfParent, setConsentOfParent] = useState('')

  var [
    recommendationByTheWardDevelopmentCommittee,
    setRecommendationByTheWardDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheWardDevelopmentCommitteeReason,
    setRecommendationByTheWardDevelopmentCommitteeReason
  ] = useState('')

  var [
    recommendationByTheConstituencyDevelopmentCommittee,
    setRecommendationByTheConstituencyDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheConstituencyDevelopmentCommitteeReason,
    setRecommendationByTheConstituencyDevelopmentCommitteeReason
  ] = useState('')

  const handleSubmitWards = async e => {
    //useEffect(() => {
    var formDataRecommendationByWards = new FormData(this)
    formDataRecommendationByWards.append('apiType', 'recommendationByWards')
    formDataRecommendationByWards.append(
      'applicantID',
      ApplicantSkillsDevelopmentBursariesViewID
    )
    formDataRecommendationByWards.append('userID', userID)
    formDataRecommendationByWards.append(
      'recommendation',
      recommendationByTheWardDevelopmentCommittee
    )
    formDataRecommendationByWards.append(
      'reason',
      recommendationByTheWardDevelopmentCommitteeReason
    )
    formDataRecommendationByWards.append(
      'table',
      'skillsdevelopmentbursaryapplicationform'
    )
    formDataRecommendationByWards.append(
      'theApplicantMustBeZambianAndInPossessionNRC',
      theApplicantMustBeZambianAndInPossessionNRC
    )
    formDataRecommendationByWards.append(
      'grade12SchoolCertificate',
      grade12SchoolCertificate
    )
    formDataRecommendationByWards.append(
      'grade9CertificateOrGrade7Certificate',
      grade9CertificateOrGrade7Certificate
    )
    formDataRecommendationByWards.append(
      'alsoEncouragedAreThoseWhoHaveNeverBeenToSchool',
      alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
    )
    formDataRecommendationByWards.append(
      'theApplicantMustPossessAcceptanceLetter',
      theApplicantMustPossessAcceptanceLetter
    )
    formDataRecommendationByWards.append(
      'recommendationLetter',
      recommendationLetter
    )
    formDataRecommendationByWards.append(
      'theApplicantIsNotOnAnyOtherBursary',
      theApplicantIsNotOnAnyOtherBursary
    )
    formDataRecommendationByWards.append(
      'theApplicantMustBeAbove16YearsOfAge',
      theApplicantMustBeAbove16YearsOfAge
    )
    formDataRecommendationByWards.append(
      'whereApplicableADisabilityLetter',
      whereApplicableADisabilityLetter
    )
    formDataRecommendationByWards.append(
      'theApplicantHasBeenAResidentOfTheConstituency',
      theApplicantHasBeenAResidentOfTheConstituency
    )
    formDataRecommendationByWards.append('consentOfParent', consentOfParent)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)

      setDBtheApplicantMustBeZambianAndInPossessionNRC(
        response.data.theApplicantMustBeZambianAndInPossessionNRC
      )
      setDBgrade12SchoolCertificate(response.data.grade12SchoolCertificate)
      setDBgrade9CertificateOrGrade7Certificate(
        response.data.grade9CertificateOrGrade7Certificate
      )
      setDBalsoEncouragedAreThoseWhoHaveNeverBeenToSchool(
        response.data.alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
      )
      setDBtheApplicantMustPossessAcceptanceLetter(
        response.data.theApplicantMustPossessAcceptanceLetter
      )
      setDBrecommendationLetter(response.data.recommendationLetter)
      setDBtheApplicantIsNotOnAnyOtherBursary(
        response.data.theApplicantIsNotOnAnyOtherBursary
      )
      setDBtheApplicantMustBeAbove16YearsOfAge(
        response.data.theApplicantMustBeAbove16YearsOfAge
      )
      setDBwhereApplicableADisabilityLetter(
        response.data.whereApplicableADisabilityLetter
      )
      setDBtheApplicantHasBeenAResidentOfTheConstituency(
        response.data.theApplicantHasBeenAResidentOfTheConstituency
      )
      setDBconsentOfParent(response.data.consentOfParent)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const handleSubmitConstituencies = async e => {
    //useEffect(() => {
    var formDataRecommendationByConstituencies = new FormData(this)
    formDataRecommendationByConstituencies.append(
      'apiType',
      'recommendationByConstituencies'
    )
    formDataRecommendationByConstituencies.append(
      'applicantID',
      ApplicantSkillsDevelopmentBursariesViewID
    )
    formDataRecommendationByConstituencies.append('userID', userID)
    formDataRecommendationByConstituencies.append(
      'recommendation',
      recommendationByTheConstituencyDevelopmentCommittee
    )
    formDataRecommendationByConstituencies.append(
      'reason',
      recommendationByTheConstituencyDevelopmentCommitteeReason
    )
    formDataRecommendationByConstituencies.append(
      'table',
      'skillsdevelopmentbursaryapplicationform'
    )
    formDataRecommendationByConstituencies.append(
      'theApplicantMustBeZambianAndInPossessionNRC',
      theApplicantMustBeZambianAndInPossessionNRC
    )
    formDataRecommendationByConstituencies.append(
      'grade12SchoolCertificate',
      grade12SchoolCertificate
    )
    formDataRecommendationByConstituencies.append(
      'grade9CertificateOrGrade7Certificate',
      grade9CertificateOrGrade7Certificate
    )
    formDataRecommendationByConstituencies.append(
      'alsoEncouragedAreThoseWhoHaveNeverBeenToSchool',
      alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
    )
    formDataRecommendationByConstituencies.append(
      'theApplicantMustPossessAcceptanceLetter',
      theApplicantMustPossessAcceptanceLetter
    )
    formDataRecommendationByConstituencies.append(
      'recommendationLetter',
      recommendationLetter
    )
    formDataRecommendationByConstituencies.append(
      'theApplicantIsNotOnAnyOtherBursary',
      theApplicantIsNotOnAnyOtherBursary
    )
    formDataRecommendationByConstituencies.append(
      'theApplicantMustBeAbove16YearsOfAge',
      theApplicantMustBeAbove16YearsOfAge
    )
    formDataRecommendationByConstituencies.append(
      'whereApplicableADisabilityLetter',
      whereApplicableADisabilityLetter
    )
    formDataRecommendationByConstituencies.append(
      'theApplicantHasBeenAResidentOfTheConstituency',
      theApplicantHasBeenAResidentOfTheConstituency
    )
    formDataRecommendationByConstituencies.append(
      'consentOfParent',
      consentOfParent
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )

      setDBtheApplicantMustBeZambianAndInPossessionNRC(
        response.data.theApplicantMustBeZambianAndInPossessionNRC
      )
      setDBgrade12SchoolCertificate(response.data.grade12SchoolCertificate)
      setDBgrade9CertificateOrGrade7Certificate(
        response.data.grade9CertificateOrGrade7Certificate
      )
      setDBalsoEncouragedAreThoseWhoHaveNeverBeenToSchool(
        response.data.alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
      )
      setDBtheApplicantMustPossessAcceptanceLetter(
        response.data.theApplicantMustPossessAcceptanceLetter
      )
      setDBrecommendationLetter(response.data.recommendationLetter)
      setDBtheApplicantIsNotOnAnyOtherBursary(
        response.data.theApplicantIsNotOnAnyOtherBursary
      )
      setDBtheApplicantMustBeAbove16YearsOfAge(
        response.data.theApplicantMustBeAbove16YearsOfAge
      )
      setDBwhereApplicableADisabilityLetter(
        response.data.whereApplicableADisabilityLetter
      )
      setDBtheApplicantHasBeenAResidentOfTheConstituency(
        response.data.theApplicantHasBeenAResidentOfTheConstituency
      )
      setDBconsentOfParent(response.data.consentOfParent)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
        //alert(response.data)
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const [ip, setIP] = useState('')
  const [surname, setsurname] = useState([])
  const [otherNames, setotherNames] = useState([])
  const [sex, setsex] = useState([])
  const [nationality, setnationality] = useState([])
  const [NRCNumber, setNRCNumber] = useState([])
  const [NRCFile, setNRCFile] = useState([])
  const [dob, setdob] = useState([])
  const [pob, setpob] = useState([])
  const [province, setprovince] = useState([])
  const [council, setcouncil] = useState([])
  const [constituency, setconstituency] = useState([])
  const [ward, setward] = useState([])
  const [zone, setzone] = useState([])
  const [postalAddress, setpostalAddress] = useState([])
  const [vulnerabilityStatus, setvulnerabilityStatus] = useState([])
  const [pleaseSpecifyVulnerability, setpleaseSpecifyVulnerability] = useState(
    []
  )
  const [areYouDisabled, setareYouDisabled] = useState([])
  const [
    pleaseSpecifyNatureOfVulnerability,
    setpleaseSpecifyNatureOfVulnerability
  ] = useState([])
  const [doYouFinancialChallenge, setdoYouFinancialChallenge] = useState([])
  const [pleaseSpecifyFinancialChallenge, setpleaseSpecifyFinancialChallenge] =
    useState([])
  const [SchoolLeaverOrNot, setSchoolLeaverOrNot] = useState([])
  const [lastGradeAttended, setlastGradeAttended] = useState([])
  const [lastSchoolAttended, setlastSchoolAttended] = useState([])
  const [district, setdistrict] = useState([])
  const [fromDate, setfromDate] = useState([])
  const [toDate, settoDate] = useState([])
  const [highestCertificateAttained, sethighestCertificateAttained] = useState(
    []
  )
  const [
    haveYouReceivedAnAcceptanceLetter,
    sethaveYouReceivedAnAcceptanceLetter
  ] = useState([])
  const [
    nameTheInstitutionWhereYouHaveBeenAccepted,
    setnameTheInstitutionWhereYouHaveBeenAccepted
  ] = useState([])
  const [
    whatProgrammeOfStudyDoYouWishToPursue,
    setwhatProgrammeOfStudyDoYouWishToPursue
  ] = useState([])
  const [durationOfProgramme, setdurationOfProgramme] = useState([])
  const [
    haveYouAppliedForAnyScholarshipBefore,
    sethaveYouAppliedForAnyScholarshipBefore
  ] = useState([])
  const [AnyScholarshipBeforeDetails, setAnyScholarshipBeforeDetails] =
    useState([])
  const [
    haveYouEverBenefitedFromTheCDFBursaryBefore,
    sethaveYouEverBenefitedFromTheCDFBursaryBefore
  ] = useState([])
  const [
    detailsOfHowYouBenefitedFromTheCDFBursaryBefore,
    setdetailsOfHowYouBenefitedFromTheCDFBursaryBefore
  ] = useState([])
  const [parentSurname, setparentSurname] = useState([])
  const [parentOtherNames, setparentOtherNames] = useState([])
  const [parentGender, setparentGender] = useState([])
  const [parentDob, setparentDob] = useState([])
  const [parentNationality, setparentNationality] = useState([])
  const [parentNRC, setparentNRC] = useState([])
  const [parentRelationshipToApplicant, setparentRelationshipToApplicant] =
    useState([])
  const [parentVillage, setparentVillage] = useState([])
  const [parentChief, setparentChief] = useState([])
  const [parentDistrict, setparentDistrict] = useState([])
  const [parentResidentialAddress, setparentResidentialAddress] = useState([])
  const [parentConstituency, setparentConstituency] = useState([])
  const [parentProvince, setparentProvince] = useState([])
  const [parentPostalAddress, setparentPostalAddress] = useState([])
  const [parentMobilePhoneNumber, setparentMobilePhoneNumber] = useState([])
  const [parentEmailAddress, setparentEmailAddress] = useState([])
  const [parentFatherOccupationOf, setparentFatherOccupationOf] = useState([])
  const [parentMotherOccupationOf, setparentMotherOccupationOf] = useState([])
  const [parentGuardianOccupationOf, setparentGuardianOccupationOf] = useState(
    []
  )
  const [parentFatherNameOfEmployerOf, setparentFatherNameOfEmployerOf] =
    useState([])
  const [parentMotherNameOfEmployerOf, setparentMotherNameOfEmployerOf] =
    useState([])
  const [parentGuardianNameOfEmployerOf, setparentGuardianNameOfEmployerOf] =
    useState([])
  const [parentFatherPosition, setparentFatherPosition] = useState([])
  const [parentMotherPosition, setparentMotherPosition] = useState([])
  const [parentGuardianPosition, setparentGuardianPosition] = useState([])
  const [parentFatherAddressOfEmployer, setparentFatherAddressOfEmployer] =
    useState([])
  const [parentMotherAddressOfEmployer, setparentMotherAddressOfEmployer] =
    useState([])
  const [parentGuardianAddressOfEmployer, setparentGuardianAddressOfEmployer] =
    useState([])
  const [declarationApplicantName, setdeclarationApplicantName] = useState([])
  const [declarationApplicantNRC, setdeclarationApplicantNRC] = useState([])
  const [declarationSignatureOfApplicant, setdeclarationSignatureOfApplicant] =
    useState([])
  const [declarationDateOfApplicant, setdeclarationDateOfApplicant] = useState(
    []
  )
  const [declarationSignatureOfParent, setdeclarationSignatureOfParent] =
    useState([])
  const [declarationDateOfParent, setdeclarationDateOfParent] = useState([])

  var [statusWardDB, setDBstatusWard] = useState([])
  var [statusProcessedWardDB, setDBstatusProcessedWard] = useState([])
  var [processedWardDateDB, setDBprocessedWardDate] = useState([])
  var [processedByWardDB, setDBprocessedByWard] = useState([])
  var [approvedByWardDB, setDBapprovedByWard] = useState([])
  var [approvedByWardDateDB, setDBapprovedByWardDate] = useState([])
  var [reasonWardDB, setDBreasonWard] = useState([])
  var [statusConstituencyDB, setDBstatusConstituency] = useState([])
  var [statusProcessedConstituencyDB, setDBstatusProcessedConstituency] =
    useState([])
  var [processedByConstituencyDB, setDBprocessedByConstituency] = useState([])
  var [processedConstituencyDateDB, setDBprocessedConstituencyDate] = useState(
    []
  )
  var [approvedByConstituencyDB, setDBapprovedByConstituency] = useState([])
  var [approvedConstituencyDateDB, setDBapprovedConstituencyDate] = useState([])
  var [reasonconstituencyDB, setDBreasonconstituency] = useState([])
  var [statusCouncilPrincipalDB, setDBstatusCouncilPrincipal] = useState([])
  var [processedByCouncilPrincipalDB, setDBprocessedByCouncilPrincipal] =
    useState([])
  var [processedCouncilPrincipalDateDB, setDBprocessedCouncilPrincipalDate] =
    useState([])
  var [approvedByCouncilPrincipalDB, setDBapprovedByCouncilPrincipal] =
    useState([])
  var [approvedCouncilPrincipalDateDB, setDBapprovedCouncilPrincipalDate] =
    useState([])
  var [reasonCouncilPrincipalDB, setDBreasonCouncilPrincipal] = useState([])
  var [tenureCouncilPrincipalDB, setDBtenureCouncilPrincipal] = useState([])
  var [statusPlanningDB, setDBstatusPlanning] = useState([])
  var [processedByPlanningDB, setDBprocessedByPlanning] = useState([])
  var [processedPlanningDateDB, setDBprocessedPlanningDate] = useState([])
  var [approvedByPlanningDB, setDBapprovedByPlanning] = useState([])
  var [approvedPlanningDateDB, setDBapprovedPlanningDate] = useState([])
  var [reasonPlanningDB, setDBreasonPlanning] = useState([])
  var [tenurePlanningDB, setDBtenurePlanning] = useState([])
  var [statusFinanceDB, setDBstatusFinance] = useState([])
  var [processedByFinanceDB, setDBprocessedByFinance] = useState([])
  var [processedFinanceDateDB, setDBprocessedFinanceDate] = useState([])
  var [approvedByFinanceDB, setDBapprovedByFinance] = useState([])
  var [approvedFinanceDateDB, setDBapprovedFinanceDate] = useState([])
  var [reasonFinanceDB, setDBreasonFinance] = useState([])
  var [tenureFinanceDB, setDBtenureFinance] = useState([])
  var [statusEngineeringDB, setDBstatusEngineering] = useState([])
  var [processedByEngineeringDB, setDBprocessedByEngineering] = useState([])
  var [processedEngineeringDateDB, setDBprocessedEngineeringDate] = useState([])
  var [approvedByEngineeringDB, setDBapprovedByEngineering] = useState([])
  var [approvedEngineeringDateDB, setDBapprovedEngineeringDate] = useState([])
  var [reasonEngineeringDB, setDBreasonEngineering] = useState([])
  var [tenureEngineeringDB, setDBtenureEngineering] = useState([])
  var [statusProcurementDB, setDBstatusProcurement] = useState([])
  var [processedByProcurementDB, setDBprocessedByProcurement] = useState([])
  var [processedProcurementDateDB, setDBprocessedProcurementDate] = useState([])
  var [approvedByProcurementDB, setDBapprovedByProcurement] = useState([])
  var [approvedProcurementDateDB, setDBapprovedProcurementDate] = useState([])
  var [reasonProcurementDB, setDBreasonProcurement] = useState([])
  var [tenureProcurementDB, setDBtenureProcurement] = useState([])
  var [statusMinistryDB, setDBstatusMinistry] = useState([])
  var [processedByMinistryDB, setDBprocessedByMinistry] = useState([])
  var [processedMinistryDateDB, setDBprocessedMinistryDate] = useState([])
  var [approvedByMinistryDB, setDBapprovedByMinistry] = useState([])
  var [approvedMinistryDateDB, setDBapprovedMinistryDate] = useState([])
  var [reasonMinistryDB, setDBreasonMinistry] = useState([])
  var [reasonProcessedWardDB, setDBreasonProcessedWard] = useState([])
  var [reasonProcessedConstituencyDB, setDBreasonProcessedConstituency] =
    useState([])
  var [reasonProcessedPlanningDB, setDBreasonProcessedPlanning] = useState([])
  var [reasonProcessedFinanceDB, setDBreasonProcessedFinance] = useState([])
  var [reasonProcessedEngineeringDB, setDBreasonProcessedEngineering] =
    useState([])
  var [reasonProcessedProcurementDB, setDBreasonProcessedProcurement] =
    useState([])
  var [
    reasonProcessedCouncilPrincipalDB,
    setDBreasonProcessedCouncilPrincipal
  ] = useState([])
  var [reasonProcessedMinistryDB, setDBreasonProcessedMinistry] = useState([])
  var [processedAmountWardDB, setDBprocessedAmountWard] = useState([])
  var [approvedAmountWardDB, setDBapprovedAmountWard] = useState([])
  var [processedAmountConstituencyDB, setDBprocessedAmountConstituency] =
    useState([])
  var [approvedAmountConstituencyDB, setDBapprovedAmountConstituency] =
    useState([])
  var [processedAmountMinistryDB, setDBprocessedAmountMinistry] = useState([])
  var [approvedAmountMinistryDB, setDBapprovedAmountMinistry] = useState([])

  var [
    theApplicantMustBeZambianAndInPossessionNRCDB,
    setDBtheApplicantMustBeZambianAndInPossessionNRC
  ] = useState([])
  var [grade12SchoolCertificateDB, setDBgrade12SchoolCertificate] = useState([])
  var [
    grade9CertificateOrGrade7CertificateDB,
    setDBgrade9CertificateOrGrade7Certificate
  ] = useState([])
  var [
    alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolDB,
    setDBalsoEncouragedAreThoseWhoHaveNeverBeenToSchool
  ] = useState([])
  var [
    theApplicantMustPossessAcceptanceLetterDB,
    setDBtheApplicantMustPossessAcceptanceLetter
  ] = useState([])
  var [recommendationLetterDB, setDBrecommendationLetter] = useState([])
  var [
    theApplicantIsNotOnAnyOtherBursaryDB,
    setDBtheApplicantIsNotOnAnyOtherBursary
  ] = useState([])
  var [
    theApplicantMustBeAbove16YearsOfAgeDB,
    setDBtheApplicantMustBeAbove16YearsOfAge
  ] = useState([])
  var [
    whereApplicableADisabilityLetterDB,
    setDBwhereApplicableADisabilityLetter
  ] = useState([])
  var [
    theApplicantHasBeenAResidentOfTheConstituencyDB,
    setDBtheApplicantHasBeenAResidentOfTheConstituency
  ] = useState([])
  var [consentOfParentDB, setDBconsentOfParent] = useState([])
  var [statusProvinceDB, setDBstatusProvince] = useState([])
  var [processedByProvinceDB, setDBprocessedByProvince] = useState([])
  var [processedProvinceDateDB, setDBprocessedProvinceDate] = useState([])
  var [approvedByProvinceDB, setDBapprovedByProvince] = useState([])
  var [approvedProvinceDateDB, setDBapprovedProvinceDate] = useState([])
  var [reasonProvinceDB, setDBreasonProvince] = useState([])
  var [reasonProcessedProvinceDB, setDBreasonProcessedProvince] = useState([])
  var [processedAmountProvinceDB, setDBprocessedAmountProvince] = useState([])
  var [approvedAmountProvinceDB, setDBapprovedAmountProvince] = useState([])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  //alert(ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID)
  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantApplicantSkillsDevelopmentBursariesView'
    )
    formData.append('id', ApplicantSkillsDevelopmentBursariesViewID)
    //formData.append('table', 'applyforcommunityproject')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setDBtableViewWardName(response.data)
      setsurname(response.data.surname)
      setotherNames(response.data.otherNames)
      setsex(response.data.sex)
      setnationality(response.data.nationality)
      setNRCNumber(response.data.NRCNumber)
      setNRCFile(response.data.NRCFile)
      setdob(response.data.dob)
      setpob(response.data.pob)
      setprovince(response.data.province)
      setcouncil(response.data.council)
      setconstituency(response.data.constituency)
      setward(response.data.ward)
      setzone(response.data.zone)
      setpostalAddress(response.data.postalAddress)
      setvulnerabilityStatus(response.data.vulnerabilityStatus)
      setpleaseSpecifyVulnerability(response.data.pleaseSpecifyVulnerability)
      setareYouDisabled(response.data.areYouDisabled)
      setpleaseSpecifyNatureOfVulnerability(
        response.data.pleaseSpecifyNatureOfVulnerability
      )
      setdoYouFinancialChallenge(response.data.doYouFinancialChallenge)
      setpleaseSpecifyFinancialChallenge(
        response.data.pleaseSpecifyFinancialChallenge
      )
      setSchoolLeaverOrNot(response.data.SchoolLeaverOrNot)
      setlastGradeAttended(response.data.lastGradeAttended)
      setlastSchoolAttended(response.data.lastSchoolAttended)
      setdistrict(response.data.district)
      setfromDate(response.data.fromDate)
      settoDate(response.data.toDate)
      sethighestCertificateAttained(response.data.highestCertificateAttained)
      sethaveYouReceivedAnAcceptanceLetter(
        response.data.haveYouReceivedAnAcceptanceLetter
      )
      setnameTheInstitutionWhereYouHaveBeenAccepted(
        response.data.nameTheInstitutionWhereYouHaveBeenAccepted
      )
      setwhatProgrammeOfStudyDoYouWishToPursue(
        response.data.whatProgrammeOfStudyDoYouWishToPursue
      )
      setdurationOfProgramme(response.data.durationOfProgramme)
      sethaveYouAppliedForAnyScholarshipBefore(
        response.data.haveYouAppliedForAnyScholarshipBefore
      )
      setAnyScholarshipBeforeDetails(response.data.AnyScholarshipBeforeDetails)
      sethaveYouEverBenefitedFromTheCDFBursaryBefore(
        response.data.haveYouEverBenefitedFromTheCDFBursaryBefore
      )
      setdetailsOfHowYouBenefitedFromTheCDFBursaryBefore(
        response.data.detailsOfHowYouBenefitedFromTheCDFBursaryBefore
      )
      setparentSurname(response.data.parentSurname)
      setparentOtherNames(response.data.parentOtherNames)
      setparentGender(response.data.parentGender)
      setparentDob(response.data.parentDob)
      setparentNationality(response.data.parentNationality)
      setparentNRC(response.data.parentNRC)
      setparentRelationshipToApplicant(
        response.data.parentRelationshipToApplicant
      )
      setparentVillage(response.data.parentVillage)
      setparentChief(response.data.parentChief)
      setparentDistrict(response.data.parentDistrict)
      setparentResidentialAddress(response.data.parentResidentialAddress)
      setparentConstituency(response.data.parentConstituency)
      setparentProvince(response.data.parentProvince)
      setparentPostalAddress(response.data.parentPostalAddress)
      setparentMobilePhoneNumber(response.data.parentMobilePhoneNumber)
      setparentEmailAddress(response.data.parentEmailAddress)
      setparentFatherOccupationOf(response.data.parentFatherOccupationOf)
      setparentMotherOccupationOf(response.data.parentMotherOccupationOf)
      setparentGuardianOccupationOf(response.data.parentGuardianOccupationOf)
      setparentFatherNameOfEmployerOf(
        response.data.parentFatherNameOfEmployerOf
      )
      setparentMotherNameOfEmployerOf(
        response.data.parentMotherNameOfEmployerOf
      )
      setparentGuardianNameOfEmployerOf(
        response.data.parentGuardianNameOfEmployerOf
      )
      setparentFatherPosition(response.data.parentFatherPosition)
      setparentMotherPosition(response.data.parentMotherPosition)
      setparentGuardianPosition(response.data.parentGuardianPosition)
      setparentFatherAddressOfEmployer(
        response.data.parentFatherAddressOfEmployer
      )
      setparentMotherAddressOfEmployer(
        response.data.parentMotherAddressOfEmployer
      )
      setparentGuardianAddressOfEmployer(
        response.data.parentGuardianAddressOfEmployer
      )
      setdeclarationApplicantName(response.data.declarationApplicantName)
      setdeclarationApplicantNRC(response.data.declarationApplicantNRC)
      setdeclarationSignatureOfApplicant(
        response.data.declarationSignatureOfApplicant
      )
      setdeclarationDateOfApplicant(response.data.declarationDateOfApplicant)
      setdeclarationSignatureOfParent(
        response.data.declarationSignatureOfParent
      )
      setdeclarationDateOfParent(response.data.declarationDateOfParent)

      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBstatusCouncilPrincipal(response.data.statusCouncilPrincipal)
      setDBprocessedByCouncilPrincipal(
        response.data.processedByCouncilPrincipal
      )
      setDBprocessedCouncilPrincipalDate(
        response.data.processedCouncilPrincipalDate
      )
      setDBapprovedByCouncilPrincipal(response.data.approvedByCouncilPrincipal)
      setDBapprovedCouncilPrincipalDate(
        response.data.approvedCouncilPrincipalDate
      )
      setDBreasonCouncilPrincipal(response.data.reasonCouncilPrincipal)
      setDBtenureCouncilPrincipal(response.data.tenureCouncilPrincipal)
      setDBstatusPlanning(response.data.statusPlanning)
      setDBprocessedByPlanning(response.data.processedByPlanning)
      setDBprocessedPlanningDate(response.data.processedPlanningDate)
      setDBapprovedByPlanning(response.data.approvedByPlanning)
      setDBapprovedPlanningDate(response.data.approvedPlanningDate)
      setDBreasonPlanning(response.data.reasonPlanning)
      setDBtenurePlanning(response.data.tenurePlanning)
      setDBstatusFinance(response.data.statusFinance)
      setDBprocessedByFinance(response.data.processedByFinance)
      setDBprocessedFinanceDate(response.data.processedFinanceDate)
      setDBapprovedByFinance(response.data.approvedByFinance)
      setDBapprovedFinanceDate(response.data.approvedFinanceDate)
      setDBreasonFinance(response.data.reasonFinance)
      setDBtenureFinance(response.data.tenureFinance)
      setDBstatusEngineering(response.data.statusEngineering)
      setDBprocessedByEngineering(response.data.processedByEngineering)
      setDBprocessedEngineeringDate(response.data.processedEngineeringDate)
      setDBapprovedByEngineering(response.data.approvedByEngineering)
      setDBapprovedEngineeringDate(response.data.approvedEngineeringDate)
      setDBreasonEngineering(response.data.reasonEngineering)
      setDBtenureEngineering(response.data.tenureEngineering)
      setDBstatusProcurement(response.data.statusProcurement)
      setDBprocessedByProcurement(response.data.processedByProcurement)
      setDBprocessedProcurementDate(response.data.processedProcurementDate)
      setDBapprovedByProcurement(response.data.approvedByProcurement)
      setDBapprovedProcurementDate(response.data.approvedProcurementDate)
      setDBreasonProcurement(response.data.reasonProcurement)
      setDBtenureProcurement(response.data.tenureProcurement)
      setDBstatusMinistry(response.data.statusMinistry)
      setDBprocessedByMinistry(response.data.processedByMinistry)
      setDBprocessedMinistryDate(response.data.processedMinistryDate)
      setDBapprovedByMinistry(response.data.approvedByMinistry)
      setDBapprovedMinistryDate(response.data.approvedMinistryDate)
      setDBreasonMinistry(response.data.reasonMinistry)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )
      setDBreasonProcessedPlanning(response.data.reasonProcessedPlanning)
      setDBreasonProcessedFinance(response.data.reasonProcessedFinance)
      setDBreasonProcessedEngineering(response.data.reasonProcessedEngineering)
      setDBreasonProcessedProcurement(response.data.reasonProcessedProcurement)
      setDBreasonProcessedCouncilPrincipal(
        response.data.reasonProcessedCouncilPrincipal
      )
      setDBreasonProcessedMinistry(response.data.reasonProcessedMinistry)
      setDBprocessedAmountWard(response.data.processedAmountWard)
      setDBapprovedAmountWard(response.data.approvedAmountWard)
      setDBprocessedAmountConstituency(
        response.data.processedAmountConstituency
      )
      setDBapprovedAmountConstituency(response.data.approvedAmountConstituency)
      setDBprocessedAmountMinistry(response.data.processedAmountMinistry)
      setDBapprovedAmountMinistry(response.data.approvedAmountMinistry)

      setDBtheApplicantMustBeZambianAndInPossessionNRC(
        response.data.theApplicantMustBeZambianAndInPossessionNRC
      )
      setDBgrade12SchoolCertificate(response.data.grade12SchoolCertificate)
      setDBgrade9CertificateOrGrade7Certificate(
        response.data.grade9CertificateOrGrade7Certificate
      )
      setDBalsoEncouragedAreThoseWhoHaveNeverBeenToSchool(
        response.data.alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
      )
      setDBtheApplicantMustPossessAcceptanceLetter(
        response.data.theApplicantMustPossessAcceptanceLetter
      )
      setDBrecommendationLetter(response.data.recommendationLetter)
      setDBtheApplicantIsNotOnAnyOtherBursary(
        response.data.theApplicantIsNotOnAnyOtherBursary
      )
      setDBtheApplicantMustBeAbove16YearsOfAge(
        response.data.theApplicantMustBeAbove16YearsOfAge
      )
      setDBwhereApplicableADisabilityLetter(
        response.data.whereApplicableADisabilityLetter
      )
      setDBtheApplicantHasBeenAResidentOfTheConstituency(
        response.data.theApplicantHasBeenAResidentOfTheConstituency
      )
      setDBconsentOfParent(response.data.consentOfParent)

      setTheApplicantMustBeZambianAndInPossessionNRC(
        response.data.theApplicantMustBeZambianAndInPossessionNRC
      )
      setGrade12SchoolCertificate(response.data.grade12SchoolCertificate)
      setGrade9CertificateOrGrade7Certificate(
        response.data.grade9CertificateOrGrade7Certificate
      )
      setAlsoEncouragedAreThoseWhoHaveNeverBeenToSchool(
        response.data.alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
      )
      setTheApplicantMustPossessAcceptanceLetter(
        response.data.theApplicantMustPossessAcceptanceLetter
      )
      setRecommendationLetter(response.data.recommendationLetter)
      setTheApplicantIsNotOnAnyOtherBursary(
        response.data.theApplicantIsNotOnAnyOtherBursary
      )
      setTheApplicantMustBeAbove16YearsOfAge(
        response.data.theApplicantMustBeAbove16YearsOfAge
      )
      setWhereApplicableADisabilityLetter(
        response.data.whereApplicableADisabilityLetter
      )
      setTheApplicantHasBeenAResidentOfTheConstituency(
        response.data.theApplicantHasBeenAResidentOfTheConstituency
      )
      setConsentOfParent(response.data.consentOfParent)

      setDBstatusProvince(response.data.statusProvince)
      setDBprocessedByProvince(response.data.processedByProvince)
      setDBprocessedProvinceDate(response.data.processedProvinceDate)
      setDBapprovedByProvince(response.data.approvedByProvince)
      setDBapprovedProvinceDate(response.data.approvedProvinceDate)
      setDBreasonProvince(response.data.reasonProvince)
      setDBreasonProcessedProvince(response.data.reasonProcessedProvince)
      setDBprocessedAmountProvince(response.data.processedAmountProvince)
      setDBapprovedAmountProvince(response.data.approvedAmountProvince)

      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  /*
  if (
    statusWardDB != 'Pending Approval By WDC' &&
    recommendationByTheWardDevelopmentCommittee === ''
  ) {
    recommendationByTheWardDevelopmentCommittee = statusWardDB
  }

  if (
    reasonWardDB != '' &&
    recommendationByTheWardDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheWardDevelopmentCommitteeReason = reasonWardDB
  }

  if (
    statusConstituencyDB != 'Pending Approval By CDFC' &&
    recommendationByTheConstituencyDevelopmentCommittee === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommittee = statusConstituencyDB
  }

  if (
    reasonconstituencyDB != '' &&
    recommendationByTheConstituencyDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommitteeReason =
      reasonconstituencyDB
  }

  let wardDevelopmentCommitteeChairpersonName = ''
  let wardDevelopmentCommitteeChairpersonDate = ''
  let constituencyDevelopmentCommitteeChairpersonName = ''
  let constituencyDevelopmentCommitteeChairpersonDate = ''
  let wardDevelopmentCommitteePosition = 'Name (WDC Chairperson):'
  let constituencyDevelopmentCommitteePosition = 'Name (CDFC Chairperson):'
  //alert(approvedByWardDB)
  if (
    processedByWardDB != '' &&
    processedByWardDB != null &&
    (approvedByWardDB === '' || approvedByWardDB === null)
  ) {
    wardDevelopmentCommitteeChairpersonName = processedByWardDB
    wardDevelopmentCommitteeChairpersonDate = processedWardDateDB
    wardDevelopmentCommitteePosition = 'Name (WDC Member):'
  }
  if (approvedByWardDB != '' && approvedByWardDB != null) {
    wardDevelopmentCommitteeChairpersonName = approvedByWardDB
    wardDevelopmentCommitteeChairpersonDate = approvedByWardDateDB
  }

  if (
    processedByConstituencyDB != '' &&
    processedByConstituencyDB != null &&
    (approvedByConstituencyDB === '' || approvedByConstituencyDB === null)
  ) {
    constituencyDevelopmentCommitteeChairpersonName = processedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate =
      processedConstituencyDateDB
    constituencyDevelopmentCommitteePosition = 'Name (CDFC Member):'
  }

  if (approvedByConstituencyDB != '' && approvedByConstituencyDB != null) {
    constituencyDevelopmentCommitteeChairpersonName = approvedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate = approvedConstituencyDateDB
  }
*/

  var [
    theApplicantMustBeZambianAndInPossessionNRCCheck,
    setChecktheApplicantMustBeZambianAndInPossessionNRC
  ] = useState([])
  var [grade12SchoolCertificateCheck, setCheckgrade12SchoolCertificate] =
    useState([])
  var [
    grade9CertificateOrGrade7CertificateCheck,
    setCheckgrade9CertificateOrGrade7Certificate
  ] = useState([])
  var [
    alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolCheck,
    setCheckalsoEncouragedAreThoseWhoHaveNeverBeenToSchool
  ] = useState([])
  var [
    theApplicantMustPossessAcceptanceLetterCheck,
    setChecktheApplicantMustPossessAcceptanceLetter
  ] = useState([])
  var [recommendationLetterCheck, setCheckrecommendationLetter] = useState([])
  var [
    theApplicantIsNotOnAnyOtherBursaryCheck,
    setChecktheApplicantIsNotOnAnyOtherBursary
  ] = useState([])
  var [
    theApplicantMustBeAbove16YearsOfAgeCheck,
    setChecktheApplicantMustBeAbove16YearsOfAge
  ] = useState([])
  var [
    whereApplicableADisabilityLetterCheck,
    setCheckwhereApplicableADisabilityLetter
  ] = useState([])
  var [
    theApplicantHasBeenAResidentOfTheConstituencyCheck,
    setChecktheApplicantHasBeenAResidentOfTheConstituency
  ] = useState([])
  var [consentOfParentCheck, setCheckconsentOfParent] = useState([])

  const theApplicantMustBeZambianAndInPossessionNRCBtn = async e => {
    setTheApplicantMustBeZambianAndInPossessionNRC('false')
  }
  const grade12SchoolCertificateBtn = async e => {
    setGrade12SchoolCertificate('false')
  }
  const grade9CertificateOrGrade7CertificateBtn = async e => {
    setGrade9CertificateOrGrade7Certificate('false')
  }
  const alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolBtn = async e => {
    setAlsoEncouragedAreThoseWhoHaveNeverBeenToSchool('false')
  }
  const theApplicantMustPossessAcceptanceLetterBtn = async e => {
    setTheApplicantMustPossessAcceptanceLetter('false')
  }
  const recommendationLetterBtn = async e => {
    setRecommendationLetter('false')
  }
  const theApplicantIsNotOnAnyOtherBursaryBtn = async e => {
    setTheApplicantIsNotOnAnyOtherBursary('false')
  }
  const theApplicantMustBeAbove16YearsOfAgeBtn = async e => {
    setTheApplicantMustBeAbove16YearsOfAge('false')
  }
  const whereApplicableADisabilityLetterBtn = async e => {
    setWhereApplicableADisabilityLetter('false')
  }
  const theApplicantHasBeenAResidentOfTheConstituencyBtn = async e => {
    setTheApplicantHasBeenAResidentOfTheConstituency('false')
  }
  const consentOfParentBtn = async e => {
    setConsentOfParent('false')
  }

  if (theApplicantMustBeZambianAndInPossessionNRC === 'false') {
    theApplicantMustBeZambianAndInPossessionNRCDB = 'false'
  }
  if (grade12SchoolCertificate === 'false') {
    grade12SchoolCertificateDB = 'false'
  }
  if (grade9CertificateOrGrade7Certificate === 'false') {
    grade9CertificateOrGrade7CertificateDB = 'false'
  }
  if (alsoEncouragedAreThoseWhoHaveNeverBeenToSchool === 'false') {
    alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolDB = 'false'
  }
  if (theApplicantMustPossessAcceptanceLetter === 'false') {
    theApplicantMustPossessAcceptanceLetterDB = 'false'
  }
  if (recommendationLetter === 'false') {
    recommendationLetterDB = 'false'
  }
  if (theApplicantIsNotOnAnyOtherBursary === 'false') {
    theApplicantIsNotOnAnyOtherBursaryDB = 'false'
  }
  if (theApplicantMustBeAbove16YearsOfAge === 'false') {
    theApplicantMustBeAbove16YearsOfAgeDB = 'false'
  }
  if (whereApplicableADisabilityLetter === 'false') {
    whereApplicableADisabilityLetterDB = 'false'
  }
  if (theApplicantHasBeenAResidentOfTheConstituency === 'false') {
    theApplicantHasBeenAResidentOfTheConstituencyDB = 'false'
  }
  if (consentOfParent === 'false') {
    consentOfParentDB = 'false'
  }

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text'>
                    {' '}
                    <span class='text-uppercase'>
                      {surname} {otherNames} APPLICATION FORM - SKILLS
                      DEVELOPMENT BURSARIES
                    </span>
                  </div>
                  {/*  <!-- Grid row -->*/}
                  <div class='col-12'>
                    <br /> <br />
                    <section>
                      <div class='text-left'>
                        <center>
                          {' '}
                          <img src={require('./img/coat of arms.jpg')} />
                          <strong>
                            <br />
                            CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> SKILLS
                            DEVELOPMENT BURSARY APPLICATION FORM
                          </strong>
                        </center>
                        <br />
                        <strong>Instructions:</strong> This application form
                        should be completed by the applicant and sent together
                        with documents outlined in Annex 1 to the Chairperson,
                        Ward Development Committee. <br />
                        <u>
                          <b>NOTE:</b>
                        </u>{' '}
                        This form is not for sale.
                        <br />
                        <i>
                          Disclaimer: Completion of the form does not guarantee
                          the award of CDF Skills Development Bursary.
                        </i>
                      </div>

                      <br />

                      <br />
                    </section>
                    {/*<!--\form heading -->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          PART A: GENERAL DETAILS{' '}
                          <span class='yellow-text'>
                            (To be filled by the applicant)
                          </span>{' '}
                        </h6>
                      </center>
                    </div>
                    <br />
                    <div id='divShowMessageFromAjax'></div>
                    {/*<!--Grid row-->	*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            {' '}
                            Surname<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={surname}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            {' '}
                            Other Names<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={otherNames}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-4 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            {' '}
                            Sex<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input type='text' class='form-control' value={sex} />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .--> */}
                    {/*<!--Grid row-->	*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Nationality <span class='red-text'>*</span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={nationality}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-4 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            N.R.C. Number <span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={NRCNumber}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-4 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            NRC (Photocopy to be attached)
                            <span class='red-text'>*</span>
                          </label>
                          <br />
                          <br />
                          <input type='text' class='form-control' disabled />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    {/*<!--Grid row-->		*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-6 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date of Birth <span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input type='date' class='form-control' value={dob} />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-md-6 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Place of Birth <span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={pob}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->	*/}
                    <center>
                      <h4>
                        <u>RESIDENCE OF APPLICANT </u>
                      </h4>
                    </center>
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/* <!--Grid column -->*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        <label for='registerFullName'>
                          Provinces<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={province}
                        />
                      </div>
                      {/* <!--\Grid column-->*/}

                      {/* <!--Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-0.01 text-left'
                        id='hideLocalAuthority'
                      >
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Council<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={council}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {/*<!--\Grid column-->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-1 text-left'
                        id='hideConstituency'
                      >
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Constituency<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={constituency}
                        />
                      </div>

                      <div
                        class='col-lg-4 col-md-12 mb-1 text-left'
                        id='hideWard'
                      >
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Ward<span class='red-text'>*</span>{' '}
                        </label>
                        <input type='text' class='form-control' value={ward} />
                        {/*<!--</div>-->*/}
                      </div>
                      {/* <!--Grid column -->*/}
                      <div
                        class='col-lg-4 col-md-12 mb-0.01'
                        style={{ display: 'none' }}
                        id='divShowWard'
                      ></div>
                      {/*<!--\Grid column-->*/}

                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Zone<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={zone}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Postal Address<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={postalAddress}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    <center>
                      <h4 class='mt-5'>
                        <u> VULNERABILITY STATUS </u>
                      </h4>
                    </center>
                    {/*<!--Grid row-->		*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          {' '}
                          Status<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={vulnerabilityStatus}
                        />
                        {/*<!--</div>--> */}
                      </div>
                      {/* <!--\Grid column-->	*/}
                      {/*<!--Grid column--> */}
                      <div
                        class='col-md-12 mb-0.001'
                        id='hideVulnerabilityStatus'
                      ></div>
                      {/*<!--\Grid column-->	*/}
                      {vulnerabilityStatus === 'Other' ? (
                        <>
                          {/*<!--Grid column--> */}
                          <div
                            class='col-md-12 mb-0.001 text-left'
                            id='showVulnerabilityStatus'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Please Specify Vulnerability
                                <span class='red-text'>*</span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                value={pleaseSpecifyVulnerability}
                              />
                            </div>
                          </div>
                          {/*<!--\Grid column-->	*/}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/*<!--Grid row .--> */}
                    {/* <!--Grid row-->	*/}
                    <div class='row'>
                      {/* 	<!--Grid column--> */}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/*  <!--<div class="md-form">--> */}
                        <label for='registerFullName'>
                          Are you disabled? <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={areYouDisabled}
                        />
                        {/* 	<!--</div>--> */}
                      </div>

                      {/* <!--Grid column-->*/}
                      {areYouDisabled === 'Yes' ? (
                        <>
                          <div
                            class='col-md-12 mb-0.001'
                            id='showAreYouDisabled'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Please Specify Vulnerability
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br />
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                value={pleaseSpecifyNatureOfVulnerability}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/* <!--Grid row .-->*/}
                    {/*<!--Grid row-->			*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Do You Have Financial Challenge?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={doYouFinancialChallenge}
                        />
                      </div>

                      {doYouFinancialChallenge === 'Yes' ? (
                        <>
                          <div
                            class='col-md-12 mb-0.001'
                            id='showDoYouFinancialChallenge'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Please Specify Financial Challenge
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br />
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                value={pleaseSpecifyFinancialChallenge}
                              />
                            </div>
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          PART B: SCHOOL AND COURSE DETAILS{' '}
                        </h6>
                      </center>
                    </div>
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Education Status <span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={SchoolLeaverOrNot}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}

                      {/* <!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Last Grade Attended <span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={lastGradeAttended}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Last School Attended <span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={lastSchoolAttended}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            District<span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={district}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->		*/}
                    </div>
                    {/*<!--\Grid row-->   */}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            From Date<span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            Placeholder=''
                            value={fromDate}
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-3 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            To Date<span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            value={toDate}
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-6 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Highest Certificate Attained
                            <span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={highestCertificateAttained}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->		*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/*<!--Grid row-->	*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Have You Received An Acceptance Letter?
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={haveYouReceivedAnAcceptanceLetter}
                        />
                        {/*	<!--</div>-->*/}
                      </div>

                      {haveYouReceivedAnAcceptanceLetter === 'Yes' ? (
                        <>
                          <div
                            class='col-md-12 mb-0.001'
                            id='showHaveYouReceivedAnAcceptanceLetter'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Name The Institution Where You Have Been
                                Accepted
                                <span class='red-text'>*</span>
                              </label>{' '}
                              <br />
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                Placeholder=''
                                value={
                                  nameTheInstitutionWhereYouHaveBeenAccepted
                                }
                              />
                            </div>
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    {/*<!--Grid row-->			*/}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-8 col-md-12 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            What Programme Of Study Do You Wish To Pursue?
                            <span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={whatProgrammeOfStudyDoYouWishToPursue}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Duration Of Programme<span class='red-text'></span>
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={durationOfProgramme}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*<!--Grid row-->		*/}
                    <div class='row'>
                      {/*	<!--Grid column-->*/}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Have You Applied For Or Received Any Scholarship,
                          Bursary From Any Other Organizations Or Authority
                          Before?<span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={haveYouAppliedForAnyScholarshipBefore}
                        />
                      </div>

                      {haveYouAppliedForAnyScholarshipBefore === 'Yes' ? (
                        <>
                          <div
                            class='col-md-12 mb-0.001 text-left'
                            id='showHaveYouAppliedForAnyScholarshipBefore'
                          >
                            {/*<div class="md-form">*/}
                            <label for='registerFullName'>
                              Name The Institution Where You Have Been Accepted
                              <span class='red-text'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              value={AnyScholarshipBeforeDetails}
                            />
                            {/*</div>*/}
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    {/*<!--Grid row-->		*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-12 mb-0.001 text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Have You Ever Benefited From The CDF Skills
                          Development Bursary Sponsorship Before?
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={haveYouEverBenefitedFromTheCDFBursaryBefore}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {haveYouEverBenefitedFromTheCDFBursaryBefore === 'Yes' ? (
                        <>
                          <div
                            class='col-md-12 mb-0.001 text-left'
                            id='showHaveYouEverBenefitedFromTheCDFBursaryBefore'
                          >
                            {/*<div class="md-form">*/}
                            <label for='registerFullName'>
                              Give Details Of When And How You Benefited
                              <span class='red-text'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              value={
                                detailsOfHowYouBenefitedFromTheCDFBursaryBefore
                              }
                            />
                            {/*</div>*/}
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    <br />{' '}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          PART C: PERSONAL DETAILS OF PARENT/GUARDIAN{' '}
                        </h6>
                      </center>
                    </div>
                    {/* <!--Grid row-->	*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Surname<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentSurname}
                          />
                        </div>
                      </div>

                      {/*<!--\Grid column-->		*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Other Names<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentOtherNames}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->		*/}
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-2 col-md-2 mb-0.001 text-file'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Gender<span class='red-text'></span>
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentGender}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->		*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-0.001 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date Of Birth<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            value={parentDob}
                            disabled
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row-->	*/}
                    {/*<!--Grid row-->		*/}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Nationality<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentNationality}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            NRC Number<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentNRC}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Relationship To Applicant
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentRelationshipToApplicant}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Village<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentVillage}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--Grid row-->*/}
                    {/*<!--Grid row-->		*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Chief<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentChief}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            District<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentDistrict}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Residential Address<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentResidentialAddress}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Constituency<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentConstituency}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Province<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentProvince}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Postal Address<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentPostalAddress}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->		*/}

                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Mobile Phone Number<span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentMobilePhoneNumber}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}

                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            E-mail Address<span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentEmailAddress}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          {' '}
                          PART D: EMPLOYMENT DETAILS OF PARENT/GUARDIAN (WHERE
                          APPLICABLE){' '}
                        </h6>
                      </center>
                    </div>
                    <br />
                    <h6>
                      <center>OCCUPATION OF :</center>
                    </h6>
                    {/*  <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Father<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentFatherOccupationOf}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Mother<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentMotherOccupationOf}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Guardian<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentGuardianOccupationOf}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <br />
                    <h6>
                      <center>NAME OF EMPLOYER OF :</center>
                    </h6>
                    {/*  <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Father<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentFatherNameOfEmployerOf}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Mother<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentMotherNameOfEmployerOf}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Guardian<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentGuardianNameOfEmployerOf}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <br />
                    <h6>
                      <center>POSITION/ RANK OF :</center>
                    </h6>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Father<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentFatherPosition}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Mother<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={parentMotherPosition}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Guardian<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={parentGuardianPosition}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*	<!--\Grid row-->*/}
                    <br />
                    <h6>
                      <center>ADDRESS OF EMPLOYER :</center>
                    </h6>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Father<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={parentFatherAddressOfEmployer}
                          >
                            {' '}
                          </textarea>
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Mother<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={parentMotherAddressOfEmployer}
                          >
                            {' '}
                          </textarea>
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Guardian<span class='red-text'></span>{' '}
                          </label>
                          <br />
                          <br />
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={parentGuardianAddressOfEmployer}
                          >
                            {' '}
                          </textarea>
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundgColor: 'green !important' }}
                    >
                      <center>
                        <h6 class='white-text'> PART E: DECLARATION </h6>
                      </center>
                    </div>
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-12 col-md-12 mb-10 text-left'>
                        <br />I{' '}
                        <input
                          type='text'
                          placeholder='Indicate Name Here'
                          value={declarationApplicantName}
                        />{' '}
                        of NRC number{' '}
                        <input
                          type='text'
                          placeholder='Indicate NRC Here'
                          value={declarationApplicantNRC}
                        />{' '}
                        do declare that to the best of my knowledge, the
                        information I have given is the absolute truth. I also
                        understand that any false information on this form will
                        lead to immediate forfeiture of this assistance and
                        possible prosecution or both.
                      </div>
                      {/* <!--\Grid column-->		*/}
                    </div>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Signature Of Applicant
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={declarationSignatureOfApplicant}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-3 col-md-12 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            value={declarationDateOfApplicant}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Signature Of Parent/Guardian
                            <span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={declarationSignatureOfParent}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Date<span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <br />
                          <input
                            type='date'
                            class='form-control'
                            value={declarationDateOfParent}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    <section class='section mt-5'>
                      {/*Grid row */}
                      <div class='row'>
                        {/* Grid column */}

                        <div class='col-lg-12 col-md-12'>
                          <div class='card mb-4'>
                            <div class='card-body'>
                              <div
                                class='form-header white-text'
                                style={{ backgroundColor: 'green' }}
                              >
                                <center>
                                  <span class='text-uppercase'>
                                    Application Checklist
                                  </span>
                                </center>
                              </div>
                              <table class='table large-header'>
                                <thead class='text-left'>
                                  <tr>
                                    <th class='font-weight-bold'>
                                      <strong>No</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Checklist</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Yes / No</strong>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody class='text-left'>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      The applicant must be Zambian and in
                                      possession of a Green National
                                      Registration Card
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {theApplicantMustBeZambianAndInPossessionNRCDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            value={
                                              theApplicantMustBeZambianAndInPossessionNRC
                                            }
                                            onChange={e =>
                                              setTheApplicantMustBeZambianAndInPossessionNRC(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox1'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            theApplicantMustBeZambianAndInPossessionNRCDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            theApplicantMustBeZambianAndInPossessionNRCBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>2</td>
                                    <td colspan='2'>
                                      Applicant must possess any of the
                                      following qualifications: (Tick where
                                      applicable)
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>a. </td>
                                    <td>
                                      Grade 12 School Certificate or its
                                      equivalent for those applying for
                                      Craft/Certificate, Technician/ Advanced
                                      Certificate and Technologist/ Diploma
                                      Programmes.
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {grade12SchoolCertificateDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            value={grade12SchoolCertificate}
                                            onChange={e =>
                                              setGrade12SchoolCertificate(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox2'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            grade12SchoolCertificateDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={grade12SchoolCertificateBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>b. </td>
                                    <td>
                                      Grade 9 Certificate or Grade 7 Certificate
                                      for those applying for Trade Test
                                      Programmes.
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {grade9CertificateOrGrade7CertificateDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            value={
                                              grade9CertificateOrGrade7Certificate
                                            }
                                            onChange={e =>
                                              setGrade9CertificateOrGrade7Certificate(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox3'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            grade9CertificateOrGrade7CertificateDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            grade9CertificateOrGrade7CertificateBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>c. </td>
                                    <td>
                                      Also encouraged are those who have never
                                      been to school to apply for trade test
                                      programmes.
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            value={
                                              alsoEncouragedAreThoseWhoHaveNeverBeenToSchool
                                            }
                                            onChange={e =>
                                              setAlsoEncouragedAreThoseWhoHaveNeverBeenToSchool(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox4'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            alsoEncouragedAreThoseWhoHaveNeverBeenToSchoolBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>
                                      The applicant must possess acceptance
                                      letter from a public TEVET Institutions
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {theApplicantMustPossessAcceptanceLetterDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            value={
                                              theApplicantMustPossessAcceptanceLetter
                                            }
                                            onChange={e =>
                                              setTheApplicantMustPossessAcceptanceLetter(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox5'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            theApplicantMustPossessAcceptanceLetterDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            theApplicantMustPossessAcceptanceLetterBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>
                                      Recommendation letter from the Church /
                                      Chief / Headman / Head Teacher of previous
                                      school
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {recommendationLetterDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            value={recommendationLetter}
                                            onChange={e =>
                                              setRecommendationLetter(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox6'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            recommendationLetterDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={recommendationLetterBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>
                                      The applicant is not on any other Bursary
                                      or Scholarship scheme at the moment.
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {theApplicantIsNotOnAnyOtherBursaryDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            value={
                                              theApplicantIsNotOnAnyOtherBursary
                                            }
                                            onChange={e =>
                                              setTheApplicantIsNotOnAnyOtherBursary(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox7'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            theApplicantIsNotOnAnyOtherBursaryDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            theApplicantIsNotOnAnyOtherBursaryBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>
                                      The applicant must be above 16 years of
                                      age.
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {theApplicantMustBeAbove16YearsOfAgeDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            value={
                                              theApplicantMustBeAbove16YearsOfAge
                                            }
                                            onChange={e =>
                                              setTheApplicantMustBeAbove16YearsOfAge(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox8'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            theApplicantMustBeAbove16YearsOfAgeDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            theApplicantMustBeAbove16YearsOfAgeBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>
                                      Where applicable, a disability letter
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {whereApplicableADisabilityLetterDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            value={
                                              whereApplicableADisabilityLetter
                                            }
                                            onChange={e =>
                                              setWhereApplicableADisabilityLetter(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox9'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            whereApplicableADisabilityLetterDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            whereApplicableADisabilityLetterBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>
                                      The applicant has been a resident of the
                                      Constituency for not less than six (6)
                                      months
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {theApplicantHasBeenAResidentOfTheConstituencyDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            value={
                                              theApplicantHasBeenAResidentOfTheConstituency
                                            }
                                            onChange={e =>
                                              setTheApplicantHasBeenAResidentOfTheConstituency(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox10'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            theApplicantHasBeenAResidentOfTheConstituencyDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            theApplicantHasBeenAResidentOfTheConstituencyBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>9</td>
                                    <td>
                                      Consent of Parent / Guardian for an
                                      applicant below 18 years
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {consentOfParentDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox11'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox11'
                                            value={consentOfParent}
                                            onChange={e =>
                                              setConsentOfParent(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox11'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={consentOfParentDB != 'true'}
                                          title='Cancel Check'
                                          type='button'
                                          onClick={consentOfParentBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Grid column */}
                      </div>
                    </section>
                    <section>
                      <div
                        class='card-header mt-5 mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION D: RECOMMENDATION BY THE WARD DEVELOPMENT
                            COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/*  <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Ward Development Committee{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            //name='recommendationByTheWardDevelopmentCommittee'
                            value={recommendationByTheWardDevelopmentCommittee}
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Recommended</option>
                            <option>Not Recommended</option>
                            <option>Defer</option>
                          </select>
                        </div>
                        {/*   <!--\Grid column-->				 */}
                        {/*     <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*       <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            name='recommendationByTheWardDevelopmentCommitteeReason'
                            value={
                              recommendationByTheWardDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                            required
                          >
                            {' '}
                          </textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->				*/}
                      </div>
                      {/* <!--\Grid row--> */}
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (WDC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonSign'
                              id='wardDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row--> */}

                      <section
                        class='section mt-5'
                        hidden={
                          processedByWardDB === '' || processedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Proccessed By
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={processedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={processedWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByWardDB === '' || approvedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (WDC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={approvedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={approvedByWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        onClick={handleSubmitWards}
                        disabled={
                          recommendationByTheWardDevelopmentCommittee === '' ||
                          recommendationByTheWardDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        Submit
                      </button>
                    </section>
                    <section
                      hidden={
                        userPosition === 'Councilor' ||
                        userPosition === 'WDC Chairperson' ||
                        userPosition === 'WDC Member'
                      }
                    >
                      <div
                        class='card-header mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION E: DECISION BY THE CONSTITUENCY DEVELOPMENT
                            FUND COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Constituency Development
                            Committee <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            value={
                              recommendationByTheConstituencyDevelopmentCommittee
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Approved</option>
                            <option>Not Approved</option>
                          </select>
                        </div>
                        {/*  <!--\Grid column-->		*/}
                        {/*      <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*      <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons For Recommendation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            value={
                              recommendationByTheConstituencyDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                          ></textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row-->*/}
                      {/* <!--Grid row-->*/}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/*<!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (CDFC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonSign'
                              id='constituencyDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                        {/* <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                      </div>

                      <section
                        class='section mt-5'
                        hidden={
                          processedByConstituencyDB === '' ||
                          processedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Processed By :
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={processedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={processedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByConstituencyDB === '' ||
                          approvedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonconstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (CDFC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={approvedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={approvedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        disabled={
                          recommendationByTheConstituencyDevelopmentCommittee ===
                            '' ||
                          recommendationByTheConstituencyDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                        onClick={handleSubmitConstituencies}
                      >
                        Submit
                      </button>
                      {/* <!-- Grid row -->*/}
                    </section>
                  </div>
                  {/* <!-- Grid row -->*/}
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default ApplicantSkillsDevelopmentBursariesView
