import { useState, useEffect } from "react";

const Form1 = () => {
	

  const [names, setNames] = useState([]);

  const handleClick = () => {
    // 👇️ push to end of state array
	setNames(current => [...current, {"title" : "mr", "name" : "Rodeen"}  ]);
	
	//localStorage.setItem('arrayStorages', JSON.stringify([{"title" : "mr", "name" : "Rodeen"} ]));
	localStorage.setItem('arrayStorages', JSON.stringify(current => [{id: 3, name: 'Carl'}]));
    //setNames(current => [...current, {"title" : "mr", "name" : "Rodeen"}  ]);

    // 👇️ spread an array into the state array
    // setNames(current => [...current, ...['Carl', 'Delilah']]);

    // 👇️ push to beginning of state array
    // setNames(current => ['Zoey', ...current]);
  };

  return (
    <div>
      <div>
        <button onClick={handleClick}>Push to state array</button>
      </div>

      {names.map((element, index) => {
        return (
          <div key={index}>
            <h2>{element.title}  {element.name}</h2>
          </div>
        );
      })}
    </div>
  );



  /*const [name, setName] = useState("");
  
useEffect(() => {
  // storing input name
  localStorage.setItem("ruth", JSON.stringify(name));
}, [name]);

  return (
    <form>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Full name"
        aria-label="fullname"
      />
      <input type="submit" value="Submit"></input>
    </form>
  );*/
};

export default Form1;

/*import { useEffect, useState } from "react";

const Form1 = () => {
   
  const [name, setName] = useState(() => {
    // getting stored value
     const saved = localStorage.getItem("name");
     const initialValue = JSON.parse(saved);
    return initialValue || "";
    
  });
  localStorage.setItem("surname", JSON.stringify("Kanchele"));
  const first = localStorage.getItem("surname");
     const second = JSON.parse(first);
    // alert(second);

  return (
    <form>
        <div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Full name"
        aria-label="fullname"
      />
      <input type="submit" value="Submit"></input>
    </form>
  );
};

export default Form1;*/