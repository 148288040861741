
import {useRef, useState, useEffect} from 'react';
import '../App.css';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../api/axios';

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/;
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ; 
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/;
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/; 
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const RegisterStock = () => {

const businessNameRef = useRef();
const errRef = useRef();

const [businessName, setBusinessName] = useState('');
const [businessBranchName, setBusinessBranchName] = useState('');
const [productName, setProductName] = useState('');
const [supplier, setSupplier] = useState('');
const [arrivalDate, setArrivalDate] = useState('');
const [ip,setIP] = useState('');

/*const firstUserID = localStorage.getItem("userID");
const userIDInitial = JSON.parse(firstUserID);*/

const firstSystemSetupID = localStorage.getItem("systemSetupID");
const systemSetupIDInitial = JSON.parse(firstSystemSetupID);

//const [userID,setUserID] = useState(userIDInitial);
const [businessNameDropDown, setBusinessNameDropDown] = useState([]);
const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState([]);
const [productNameDropDown, setProductNameDropDown] = useState([]);
const [supplierDropDown, setSupplierDropDown] = useState([]);

/*const [phone, setPhone] = useState('');
const [validPhone, setValidPhone] = useState(false);
const [phoneFocus, setPhoneFocus ] = useState(false);*/

const [quantity, setQuantity] = useState('');
const [validQuantity, setValidQuantity] = useState(false);
const [quantityFocus, setQuantityFocus ] = useState(false);

const [cost, setCost] = useState('');
const [validCost, setValidCost] = useState(false);
const [costFocus, setCostFocus ] = useState(false);


/*const [email, setEmail] = useState('');
const [validEmail, setValidEmail ] = useState(false);
const [emailFocus, setEmailFocus ] = useState(false);*/

const [price, setPrice] = useState('');
const [validPrice, setValidPrice ] = useState(false);
const [priceFocus, setPriceFocus ] = useState(false);

const [errMsg, setErrMsg ] = useState('');
//const [success, setSuccess ] = useState(false);

useEffect(() => {
    // storing input name
    //localStorage.setItem("ruth", JSON.stringify(businessName));
    
  }, []);

useEffect( () => {
	businessNameRef.current.focus();
},
[])

useEffect( () => {
    const result = QUANTITY_REGEX.test(quantity);
	setValidQuantity(result);
},
[quantity])

useEffect( () => {
    const result = PRICE_REGEX.test(cost);
	setValidCost(result);
},
[cost])

useEffect( () => {
    const result = PRICE_REGEX.test(price);
	setValidPrice(result);
},
[price])

useEffect( () => {
	setErrMsg('');
},
[quantity, cost, price])

/*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/


   //creating function to load ip address from the API
    const getData = async()=>{
        const res = await Axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
    }
  useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])

    /*useEffect( () => {
        Axios.get("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            });
        },
        [])*/

        useEffect( () => {
            Axios.post("http://localhost:3001/api/getBusinessNameDropDown", {
                      businessNameID : systemSetupIDInitial,		
      }).then((response) => { 
        setBusinessNameDropDown(response.data);
       })//.catch((err) => alert("ERROR : Check Your Network Connection"));
              },
              [])

        useEffect( () => {
          Axios.post("http://localhost:3001/api/getBusinessBranchNameDropDown", {
                    businessNameID : businessName,		
    }).then((response) => { 
        setBusinessBranchNameDropDown(response.data);
     }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
            },
            [businessName])


            useEffect( () => {
                //alert(businessName);
                Axios.post("http://localhost:3001/api/getProductNameDropDown", {
                          businessNameID : businessName,		
          }).then((response) => { 
              setProductNameDropDown(response.data);
           }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
                  },
                  [businessName])


                  useEffect( () => {
                    Axios.post("http://localhost:3001/api/getSupplierDropDown", {
                              businessNameID : businessName,		
              }).then((response) => { 
                  setSupplierDropDown(response.data);
               }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
                      },
                      [businessName])
           
         
const handleSubmit = async (e) => 
{
    //alert("businessName = "+businessName);
    const currentDate = new Date();
    const dateToday = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`;
    const timeNow = currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds();
    
    const dateTodayBatch = `${currentDate.getDate()}${currentDate.getMonth()+1}${currentDate.getFullYear()}`;
    const timeNowBatch = currentDate.getHours() + '' + currentDate.getMinutes() + '' + currentDate.getSeconds();
    
    const firstUserID = localStorage.getItem("userID");
    const userIDInitial = JSON.parse(firstUserID);
    var userID = userIDInitial;
    Axios.post("http://localhost:3001/api/insertStock", {
        
        businessName : businessName,
        businessBranchName : businessBranchName,
		productName : productName,
        quantity : quantity,
		cost : cost,
		price : price,
        supplier : supplier,
        arrivalDate : arrivalDate,
        userID : userID,
		system_setup_IP : ip,
        dateToday : dateToday,
        timeNow : timeNow,
        dateTodayBatch : dateTodayBatch,
        timeNowBatch : timeNowBatch,
		
    }).then((res) => {	alert(res.data);
  
	  })  
	  .catch((err) => alert("ERROR : Kindly Contact Your Adminitrator"));
	}

    
    
    return(
	
	<main>
	{/*<form method="POST" encType="multipart/form-data">*/}
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 mb-2">
          <div class="card">
            <div class="card-body" id="faults">
	<div id="divShowMessageFromAjax"></div>
	
		<div class="form-header white-text"> ADD STOCK HERE </div>
         
				
{/*Start Of Row 1*/}
<div class="row">							
	<div class="col-lg-4 col-md-12 mb-1">
	<div class="md-form">
	<label >Business Name <i class="red-text">*</i> </label><br /><br />
<select class="custom-select d-block w-100"  id="businessName" onChange={(e) => setBusinessName(e.target.value)}
						 autoComplete = "off"  required
                         ref= {businessNameRef} >
<option value="" >Choose Business Name ..</option>
{
    businessNameDropDown.map((val)=>{
return (
    <option value={val.system_setupID}>{val.organisation} </option>
);

    })
}
                         </select>
					</div>
					</div>

                    <div class="col-lg-4 col-md-12 mb-1">
	<div class="md-form">
	<label>Business Branch Name <i class="red-text">*</i> </label><br /><br />
<select disabled={businessName ===''} class="custom-select d-block w-100"  id="businessBranchName" onChange={(e) => setBusinessBranchName(e.target.value)}
	 
						 autoComplete = "off" required
						>
<option value="" >Choose Business Branch Name</option>
{
    businessBranchNameDropDown.map((val)=>{
return (
    <option value={val.system_setup_branchID}>{val.branch_name} </option>
);

    })
}

                            </select>
					</div>
					</div>

                    <div class="col-lg-4 col-md-12 mb-1">
	<div class="md-form">
	<label>Product Name<i class="red-text"></i> *</label><br /><br />
<select disabled={businessName ===''} class="custom-select d-block w-100"  id="productName" onChange={(e) => setProductName(e.target.value)}
	 
						 autoComplete = "off" required
						>
<option value="" >Choose Product Name</option>
{
    productNameDropDown.map((val)=>{
return (
    <option value={val.productsID}>{val.product} </option>
);

    })
}
                            </select>
					</div>
					</div>
					
					</div> {/*End Of Row 1*/}


<div class="row">	
    <div class="col-lg-4 col-md-12 mb-1"  >
<div class="md-form"> <label>Product / Stock Quantity <i class="red-text">*</i>
<span className={validQuantity ? "valid" : "hide"} >  <FontAwesomeIcon icon={faCheck} />
 </span> <span className={validQuantity || !quantity ? "hide" : "invalid"} > <FontAwesomeIcon icon={faTimes} />
</span>	</label><br />
	<input  type="text" class="form-control"  id="quantity" onChange={(e) => setQuantity(e.target.value)}
						
autoComplete = "off"   value={quantity}  required  aria-invalid={validQuantity ? "false" : "true"}
aria-describedby = "quantitynote"	onFocus={() => setQuantityFocus(true)}     onBlur={() => setQuantityFocus(false)}
		/>
<p
 id="quantitynote" className={quantityFocus && quantity && !validQuantity ? "instructions" : "offscreen"} >
          <FontAwesomeIcon icon={faInfoCircle}/>
          Numbers or Figures Only <br />
          Not Less Than 0, But Not More Than 9 Figures
                    </p>
					</div>
					</div>
					
<div class="col-lg-4 col-md-12 mb-1"  >
<div class="md-form"> <label>Product / Stock Cost <i class="red-text">*</i>
<span className={validCost ? "valid" : "hide"} >  <FontAwesomeIcon icon={faCheck} />
 </span> <span className={validCost || !cost ? "hide" : "invalid"} > <FontAwesomeIcon icon={faTimes} />
</span>	</label><br />
	<input  type="text" class="form-control"  id="cost" onChange={(e) => setCost(e.target.value)}
						
autoComplete = "off"   value={cost}  required  aria-invalid={validCost ? "false" : "true"}
aria-describedby = "costnote"	onFocus={() => setCostFocus(true)}     onBlur={() => setCostFocus(false)}
		/>
<p
 id="costnote" className={costFocus && cost && !validCost ? "instructions" : "offscreen"} >
          <FontAwesomeIcon icon={faInfoCircle}/>
          Only Numbers or Figures Only <br />
          Currency amount with optional thousands separators, <br />
          and two-digit fraction.
                    </p>
					</div>
					</div>

					<div class="col-lg-4 col-md-12 mb-1">
				  <div class="md-form">
				  
						<label>Product Unit Price <i class="red-text">*</i>
						 <span className={validPrice ? "valid" : "hide"} > 
                    <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validPrice || !price ? "hide" : "invalid"} > 
                    <FontAwesomeIcon icon={faTimes} />
                    </span>
						</label><br />
						
						<input  type="text" class="form-control"  id="price" onChange={(e) => setPrice(e.target.value)}
							autoComplete = "off"
        value={price}
        required
        aria-invalid={validPrice ? "false" : "true"}
        aria-describedby = "pricenote"
        onFocus={() => setPriceFocus(true)}
        onBlur={() => setPriceFocus(false)}					
						
						/>
						<p
                        id="pricenote" className={priceFocus && price && !validPrice ? "instructions" : "offscreen"} >
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            Only Numbers or Figures Only <br />
          Currency amount with optional thousands separators, <br />
          and two-digit fraction.
                    </p>
						
					</div>
					</div>
					
										
				</div> {/*End Of Row 3*/}

                
{/*Start Of Row 1*/}
<div class="row">							
	<div class="col-lg-6 col-md-12 mb-1">
	<div class="md-form">
	<label >Product Supplier <i class="red-text">*</i> </label><br /><br />
<select disabled={businessName ===''} class="custom-select d-block w-100"  id="supplier" onChange={(e) => setSupplier(e.target.value)}
						 autoComplete = "off"  required
                          >
<option value="" >Choose Supplier ..</option>
{
    supplierDropDown.map((val)=>{
return (
    <option value={val.suppliersID}>{val.supplier_name} </option>
);

    })
}
                         </select>
					</div>
					</div>

                    <div class="col-lg-6 col-md-12 mb-1">
	<div class="md-form">
	<label>Product / Stoct Arrival Date <i class="red-text">*</i> </label><br /><br />
<input type="date" class="form-control"  id="arrivalDate" onChange={(e) => setArrivalDate(e.target.value)}
	 value={arrivalDate}
						 autoComplete = "off" required />
					</div>
					</div>
					
					</div> {/*End Of Row 1*/}
					
					 <p><span class="red-text">* Required</span> <span>- Do not leave Blank</span></p>
              <hr class="mb-1" />
              <button onClick= {handleSubmit} class="btn btn-primary"
			  disabled={businessName === '' || businessBranchName === '' || productName ==='' || quantity ==='' || cost ==='' || price ==='' || supplier ==='' || arrivalDate ===''  || !validQuantity || !validCost || !validPrice}
			  >Submit</button>
						<br /> <br /> <br /> 
					
				
          </div>
        </div>
     
      </div>
    </div>
    </div>
	{/*</form>*/}
  </main>

    )

}

export default RegisterStock;