import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../App.css'
import Header from './header'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const CDFCRecordMinutes = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  let navigate = useNavigate()

  const [wardsID, setWardsID] = useState('')
  const [constituenciesID, setConstituenciesID] = useState('')
  const [provincesID, setProvinceID] = useState('')
  const [councilsID, setCouncilsID] = useState('')
  const [provinces, setProvinces] = useState([])
  const [councils, setCouncils] = useState([])
  const [constituencies, setConstituencies] = useState([])
  const [wards, setWards] = useState([])

  const [minuteNumber, setMinuteNumber] = useState('')
  const [meetingDate, setMeetingDate] = useState('')
  var [resolutionsStatus, setResolutionsStatus] = useState('')
  const [resolutions, setResolutions] = useState('')
  const [minuteFile, setMinuteFile] = useState()
  const [minuteFileName, setMinuteFileName] = useState('')

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)

  var userInstitution = localStorage.getItem('userInstitution')
  userInstitution = JSON.parse(userInstitution)

  var userOrganisation = localStorage.getItem('userOrganisation')
  userOrganisation = JSON.parse(userOrganisation)

  var loggedIn = localStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)

  var login = localStorage.getItem('login')
  login = JSON.parse(login)

  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var userDepartment = localStorage.getItem('userDepartment')
  userDepartment = JSON.parse(userDepartment)

  var userSystemRole = localStorage.getItem('userSystemRole')
  userSystemRole = JSON.parse(userSystemRole)

  var userProvincesID = localStorage.getItem('userProvincesID')
  userProvincesID = JSON.parse(userProvincesID)

  var userCouncilsID = localStorage.getItem('userCouncilsID')
  userCouncilsID = JSON.parse(userCouncilsID)

  var userConstituenciesID = localStorage.getItem('userConstituenciesID')
  userConstituenciesID = JSON.parse(userConstituenciesID)

  var userWardsID = localStorage.getItem('userWardsID')
  userWardsID = JSON.parse(userWardsID)

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let userPhone = localStorage.getItem('userPhone')
  userPhone = JSON.parse(userPhone)

  let userNRC = localStorage.getItem('userNRC')
  userNRC = JSON.parse(userNRC)

  let userEmail = localStorage.getItem('userEmail')
  userEmail = JSON.parse(userEmail)

  let userGender = localStorage.getItem('userGender')
  userGender = JSON.parse(userGender)

  //alert(provincesID)
  /* const businessNameRef = useRef()
  const errRef = useRef()

  //const [employeeImage, setEmployeeImage] = useState('')
  //const [employeeThumbPrint, setEmployeeThumbPrint] = useState('')
  //const [businessName, setBusinessName] = useState('')
  //const [businessBranchName, setBusinessBranchName] = useState('')
  //const [lastName, setLastName] = useState('')
  //const [firstName, setFirstName] = useState('')
  //const [position, setPosition] = useState('')
  //const [role, setRole] = useState('')
  const [ip, setIP] = useState('')
  const firstUserID = localStorage.getItem('userID')
  const userIDInitial = JSON.parse(firstUserID)
  const [userID, setUserID] = useState(userIDInitial)*/

  /*useEffect(() => {
    businessNameRef.current.focus()
  }, [])*/

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  //alert("id = "+systemSetupIDInitial);
*/

  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setProvinces(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', provincesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setCouncils(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [provincesID])

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', councilsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setConstituencies(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [provincesID, councilsID])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituenciesID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setWards(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [provincesID, councilsID, constituenciesID])

  const saveMinuteFile = e => {
    setMinuteFile(e.target.files[0])
    setMinuteFileName(e.target.files[0].name)
  }

  const handleSubmit = async e => {
    var formDataUpload = new FormData(this)
    formDataUpload.append('apiType', 'postUploadWDCMinutes')
    formDataUpload.append('minuteNumber', minuteNumber)
    formDataUpload.append('meetingDate', meetingDate)
    formDataUpload.append('resolutionsStatus', resolutionsStatus)
    formDataUpload.append('resolutions', resolutions)
    formDataUpload.append('provincesID', provincesID)
    formDataUpload.append('councilsID', councilsID)
    formDataUpload.append('constituenciesID', constituenciesID)
    formDataUpload.append('wardsID', wardsID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataUpload,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setResolutions('')
      setResolutionsStatus('')
      setMinuteFile('')
      setMinuteFileName('')
      alert(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div class='form-header white-text'>
                    {' '}
                    RECORD MINUTES AND RESOLUTIONS HERE{' '}
                  </div>
                  {/*Start Of Row 2*/}
                  <div class='row'>
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label
                          for='registerFullName'
                          style={{ float: 'left' }}
                          class='black-text'
                        >
                          <b>Minute Number</b> <span class='red-text'></span>
                        </label>{' '}
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={minuteNumber}
                          onChange={e => setMinuteNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class='col-md-3 mb-0.001'>
                      <div class=''>
                        <label for='registerFullName' style={{ float: 'left' }}>
                          Meeting Date
                          <span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='date'
                          class='form-control'
                          Placeholder=''
                          value={meetingDate}
                          onChange={e => setMeetingDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class=''>
                        <label for='registerFullName' style={{ float: 'left' }}>
                          Minute File <i class='red-text'></i>
                        </label>{' '}
                        <br />
                        <input
                          type='file'
                          class='form-control'
                          Placeholder=''
                          onChange={saveMinuteFile}
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-2 mb-0.001'>
                      <label for='registerFullName' style={{ float: 'left' }}>
                        Resolution/Process Status
                        <span class='red-text'></span>
                      </label>

                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setResolutionsStatus(e.target.value)}
                      >
                        <option value=''>Choose...</option>
                        <option>To be implemented</option>
                        <option>On going</option>
                        <option>Done</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  {/*<!--Grid row 2-->*/}
                  <div class='row'>
                    <div class='col-md-12 mb-0.001'>
                      <div class=''>
                        <label style={{ float: 'left' }}>
                          Resolutions <i class='red-text'></i>
                        </label>
                        <br />
                        <textArea
                          class='form-control'
                          Placeholder=''
                          value={resolutions}
                          onChange={e => setResolutions(e.target.value)}
                        ></textArea>
                      </div>
                    </div>
                  </div>
                  {/*<!--\Grid row-->*/}
                  <hr class='mb-1' />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-primary'
                    disabled={
                      resolutions === '' ||
                      minuteNumber === '' ||
                      meetingDate === '' ||
                      resolutionsStatus === ''
                    }
                  >
                    Submit
                  </button>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default CDFCRecordMinutes
