import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './header'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'

const ApplicantGrantsForYouthWomenAndCommunityEmpowermentView = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  let ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID =
    localStorage.getItem(
      //'WardsSummaryGrantsForYouthWomenAndCommunityEmpowerment'
      'ApplicantGrantsForYouthWomenAndCommunityEmpowermentView'
    )
  ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID = JSON.parse(
    ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID
  )

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)
  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var [validCertificateOfRegistration, setValidCertificateOfRegistration] =
    useState('')
  var [
    greenNationalRegistrationCardForIndividualApplicants,
    setGreenNationalRegistrationCardForIndividualApplicants
  ] = useState('')
  var [activeBankAccount, setActiveBankAccount] = useState('')
  var [constitutionOfTheClub, setConstitutionOfTheClub] = useState('')
  var [
    copiesOfGreenNationalRegistrationCardsOfMembers,
    setCopiesOfGreenNationalRegistrationCardsOfMembers
  ] = useState('')
  var [copyOfSignedMinutes, setCopyOfSignedMinutes] = useState('')
  var [proofOfPropertyOwnership, setProofOfPropertyOwnership] = useState('')
  var [
    applicantHasLivedInTheConstituencyMinimumOf6Months,
    setApplicantHasLivedInTheConstituencyMinimumOf6Months
  ] = useState('')
  var [
    isTheProjectLocatedWithinTheConstituency,
    setIsTheProjectLocatedWithinTheConstituency
  ] = useState('')
  var [isTheBusinessViable, setIsTheBusinessViable] = useState('')
  var [
    howManyDirectJobsWillBeCreatedByTheProject,
    setHowManyDirectJobsWillBeCreatedByTheProject
  ] = useState('')

  var [
    recommendationByTheWardDevelopmentCommittee,
    setRecommendationByTheWardDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheWardDevelopmentCommitteeReason,
    setRecommendationByTheWardDevelopmentCommitteeReason
  ] = useState('')

  var [
    recommendationByTheConstituencyDevelopmentCommittee,
    setRecommendationByTheConstituencyDevelopmentCommittee
  ] = useState('')
  var [
    recommendationByTheConstituencyDevelopmentCommitteeReason,
    setRecommendationByTheConstituencyDevelopmentCommitteeReason
  ] = useState('')

  const handleSubmitWards = async e => {
    //useEffect(() => {
    var formDataRecommendationByWards = new FormData(this)
    formDataRecommendationByWards.append('apiType', 'recommendationByWards')
    formDataRecommendationByWards.append(
      'applicantID',
      ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID
    )
    formDataRecommendationByWards.append('userID', userID)
    formDataRecommendationByWards.append(
      'recommendation',
      recommendationByTheWardDevelopmentCommittee
    )
    formDataRecommendationByWards.append(
      'reason',
      recommendationByTheWardDevelopmentCommitteeReason
    )
    formDataRecommendationByWards.append(
      'table',
      'grantapplicationformforyouthwomenandcommunityempowerment'
    )
    formDataRecommendationByWards.append(
      'validCertificateOfRegistration',
      validCertificateOfRegistration
    )
    formDataRecommendationByWards.append(
      'greenNationalRegistrationCardForIndividualApplicants',
      greenNationalRegistrationCardForIndividualApplicants
    )
    formDataRecommendationByWards.append('activeBankAccount', activeBankAccount)
    formDataRecommendationByWards.append(
      'constitutionOfTheClub',
      constitutionOfTheClub
    )
    formDataRecommendationByWards.append(
      'copiesOfGreenNationalRegistrationCardsOfMembers',
      copiesOfGreenNationalRegistrationCardsOfMembers
    )
    formDataRecommendationByWards.append(
      'copyOfSignedMinutes',
      copyOfSignedMinutes
    )
    formDataRecommendationByWards.append(
      'proofOfPropertyOwnership',
      proofOfPropertyOwnership
    )
    formDataRecommendationByWards.append(
      'applicantHasLivedInTheConstituencyMinimumOf6Months',
      applicantHasLivedInTheConstituencyMinimumOf6Months
    )
    formDataRecommendationByWards.append(
      'isTheProjectLocatedWithinTheConstituency',
      isTheProjectLocatedWithinTheConstituency
    )
    formDataRecommendationByWards.append(
      'isTheBusinessViable',
      isTheBusinessViable
    )
    formDataRecommendationByWards.append(
      'howManyDirectJobsWillBeCreatedByTheProject',
      howManyDirectJobsWillBeCreatedByTheProject
    )

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)

      setDBvalidCertificateOfRegistration(
        response.data.validCertificateOfRegistration
      )
      setDBgreenNationalRegistrationCardForIndividualApplicants(
        response.data.greenNationalRegistrationCardForIndividualApplicants
      )
      setDBactiveBankAccount(response.data.activeBankAccount)
      setDBconstitutionOfTheClub(response.data.constitutionOfTheClub)
      setDBcopiesOfGreenNationalRegistrationCardsOfMembers(
        response.data.copiesOfGreenNationalRegistrationCardsOfMembers
      )
      setDBcopyOfSignedMinutes(response.data.copyOfSignedMinutes)
      setDBproofOfPropertyOwnership(response.data.proofOfPropertyOwnership)
      setDBapplicantHasLivedInTheConstituencyMinimumOf6Months(
        response.data.applicantHasLivedInTheConstituencyMinimumOf6Months
      )
      setDBisTheProjectLocatedWithinTheConstituency(
        response.data.isTheProjectLocatedWithinTheConstituency
      )
      setDBisTheBusinessViable(response.data.isTheBusinessViable)
      setDBhowManyDirectJobsWillBeCreatedByTheProject(
        response.data.howManyDirectJobsWillBeCreatedByTheProject
      )
      //alert(response.data)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const handleSubmitConstituencies = async e => {
    //useEffect(() => {
    var formDataRecommendationByConstituencies = new FormData(this)
    formDataRecommendationByConstituencies.append(
      'apiType',
      'recommendationByConstituencies'
    )
    formDataRecommendationByConstituencies.append(
      'applicantID',
      ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID
    )
    formDataRecommendationByConstituencies.append('userID', userID)
    formDataRecommendationByConstituencies.append(
      'recommendation',
      recommendationByTheConstituencyDevelopmentCommittee
    )
    formDataRecommendationByConstituencies.append(
      'reason',
      recommendationByTheConstituencyDevelopmentCommitteeReason
    )
    formDataRecommendationByConstituencies.append(
      'table',
      'grantapplicationformforyouthwomenandcommunityempowerment'
    )
    formDataRecommendationByConstituencies.append(
      'validCertificateOfRegistration',
      validCertificateOfRegistration
    )
    formDataRecommendationByConstituencies.append(
      'greenNationalRegistrationCardForIndividualApplicants',
      greenNationalRegistrationCardForIndividualApplicants
    )
    formDataRecommendationByConstituencies.append(
      'activeBankAccount',
      activeBankAccount
    )
    formDataRecommendationByConstituencies.append(
      'constitutionOfTheClub',
      constitutionOfTheClub
    )
    formDataRecommendationByConstituencies.append(
      'copiesOfGreenNationalRegistrationCardsOfMembers',
      copiesOfGreenNationalRegistrationCardsOfMembers
    )
    formDataRecommendationByConstituencies.append(
      'copyOfSignedMinutes',
      copyOfSignedMinutes
    )
    formDataRecommendationByConstituencies.append(
      'proofOfPropertyOwnership',
      proofOfPropertyOwnership
    )
    formDataRecommendationByConstituencies.append(
      'applicantHasLivedInTheConstituencyMinimumOf6Months',
      applicantHasLivedInTheConstituencyMinimumOf6Months
    )
    formDataRecommendationByConstituencies.append(
      'isTheProjectLocatedWithinTheConstituency',
      isTheProjectLocatedWithinTheConstituency
    )
    formDataRecommendationByConstituencies.append(
      'isTheBusinessViable',
      isTheBusinessViable
    )
    formDataRecommendationByConstituencies.append(
      'howManyDirectJobsWillBeCreatedByTheProject',
      howManyDirectJobsWillBeCreatedByTheProject
    )

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRecommendationByConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )

      setDBvalidCertificateOfRegistration(
        response.data.validCertificateOfRegistration
      )
      setDBgreenNationalRegistrationCardForIndividualApplicants(
        response.data.greenNationalRegistrationCardForIndividualApplicants
      )
      setDBactiveBankAccount(response.data.activeBankAccount)
      setDBconstitutionOfTheClub(response.data.constitutionOfTheClub)
      setDBcopiesOfGreenNationalRegistrationCardsOfMembers(
        response.data.copiesOfGreenNationalRegistrationCardsOfMembers
      )
      setDBcopyOfSignedMinutes(response.data.copyOfSignedMinutes)
      setDBproofOfPropertyOwnership(response.data.proofOfPropertyOwnership)
      setDBapplicantHasLivedInTheConstituencyMinimumOf6Months(
        response.data.applicantHasLivedInTheConstituencyMinimumOf6Months
      )
      setDBisTheProjectLocatedWithinTheConstituency(
        response.data.isTheProjectLocatedWithinTheConstituency
      )
      setDBisTheBusinessViable(response.data.isTheBusinessViable)
      setDBhowManyDirectJobsWillBeCreatedByTheProject(
        response.data.howManyDirectJobsWillBeCreatedByTheProject
      )
      //alert(response.data)
      if (response.data === 'Error') {
        alert('Error : Kindly Contact System Administrator')
      } else {
        alert('Application Procesed Successfully')
        //alert(response.data)
      }
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
    //}, [])
  }

  const [ip, setIP] = useState('')
  //const [DBtableViewWards, setDBtableViewWards] = useState([])
  //const [DBtableViewWardName, setDBtableViewWardName] = useState([])
  const [grantType, setgrantType] = useState('')
  const [grantAmount, setgrantAmount] = useState([])
  const [nameOfGroupMakingApplication, setnameOfGroupMakingApplication] =
    useState([])
  const [province, setprovince] = useState([])
  const [council, setcouncil] = useState([])
  const [constituency, setconstituency] = useState([])
  const [ward, setward] = useState([])
  const [zone, setzone] = useState([])
  const [physicalAddress, setphysicalAddress] = useState([])
  const [
    dateWhenGroupWasRegisteredWithRelevantAuthorities,
    setdateWhenGroupWasRegisteredWithRelevantAuthorities
  ] = useState([])
  const [
    experienceInAProjectOfSimilarNature,
    setexperienceInAProjectOfSimilarNature
  ] = useState([])
  const [
    experienceInAProjectOfSimilarNatureYesReason,
    setexperienceInAProjectOfSimilarNatureYesReason
  ] = useState([])
  const [
    whatAreTheMainProblemsInYourCommunity,
    setwhatAreTheMainProblemsInYourCommunity
  ] = useState([])
  const [
    whatProblemIsTheProjectGoingToAddress,
    setwhatProblemIsTheProjectGoingToAddress
  ] = useState([])
  const [
    howDidTheGroupIdentifyTheProject,
    sethowDidTheGroupIdentifyTheProject
  ] = useState([])
  const [
    doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile,
    setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
  ] = useState([])
  const [
    projectIdentificationMinutesFile,
    setprojectIdentificationMinutesFile
  ] = useState([])
  const [projectIdentificationMinutes, setprojectIdentificationMinutes] =
    useState([])
  const [
    howWillTheProjectBenefitTheCommunity,
    sethowWillTheProjectBenefitTheCommunity
  ] = useState([])
  const [howManyDirectJobsWillBeCreated, sethowManyDirectJobsWillBeCreated] =
    useState([])
  const [
    haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years,
    sethaveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
  ] = useState([])
  const [organisationA, setorganisationA] = useState([])
  const [organisationAAmount, setorganisationAAmount] = useState([])
  const [organisationB, setorganisationB] = useState([])
  const [organisationBAmount, setorganisationBAmount] = useState([])
  const [whatIsTheStatusOfTheLoanTaken, setwhatIsTheStatusOfTheLoanTaken] =
    useState([])
  const [bankName, setbankName] = useState([])
  const [branch, setbranch] = useState([])
  const [sortOrBranchCode, setsortOrBranchCode] = useState([])
  const [swiftCode, setswiftCode] = useState([])
  const [accountNumber, setaccountNumber] = useState([])
  const [TPIN, setTPIN] = useState([])
  const [mobileMoneyWalletNameAndNumber, setmobileMoneyWalletNameAndNumber] =
    useState([])
  const [hasyourGroupReceivedAnyTraining, sethasyourGroupReceivedAnyTraining] =
    useState([])
  const [ifTrainedHowManyMembers, setifTrainedHowManyMembers] = useState([])
  const [
    ifYesFromWhichOrganizationAndHowLongWasTheTraining,
    setifYesFromWhichOrganizationAndHowLongWasTheTraining
  ] = useState([])
  const [
    typeOrUploadProjectCommitteemembers,
    settypeOrUploadProjectCommitteemembers
  ] = useState([])
  const [name, setname] = useState([])
  const [position, setposition] = useState([])
  const [sex, setsex] = useState([])
  const [nrc, setnrc] = useState([])
  const [declarationDate, setdeclarationDate] = useState([])
  const [signatoriesToTheApplicationName, setsignatoriesToTheApplicationName] =
    useState([])
  const [
    signatoriesToTheApplicationPosition,
    setsignatoriesToTheApplicationPosition
  ] = useState([])
  const [signatoriesToTheApplicationNrc, setsignatoriesToTheApplicationNrc] =
    useState([])
  const [projectCommitteeMembersFile, setprojectCommitteeMembersFile] =
    useState([])
  const [
    projectCommitteeMembersMinuteFile,
    setprojectCommitteeMembersMinuteFile
  ] = useState([])
  const [ProjectProposerName, setProjectProposerName] = useState([])
  const [ProjectProposerNRC, setProjectProposerNRC] = useState([])
  const [ProjectProposerAddress, setProjectProposerAddress] = useState([])
  const [ProjectProposerPhone, setProjectProposerPhone] = useState([])
  const [ProjectProposerDate, setProjectProposerDate] = useState([])
  const [SeconderName, setSeconderName] = useState([])
  const [SeconderNRC, setSeconderNRC] = useState([])
  const [SeconderAddress, setSeconderAddress] = useState([])
  const [SeconderPhone, setSeconderPhone] = useState([])
  const [SeconderDate, setSeconderDate] = useState([])
  const [applicationDate, setapplicationDate] = useState([])
  const [signatoriesList, setGrantsSignatories] = useState([])
  const [inputList, setGrantsCommitteeMembers] = useState([])

  var [statusWardDB, setDBstatusWard] = useState([])
  var [statusProcessedWardDB, setDBstatusProcessedWard] = useState([])
  var [processedWardDateDB, setDBprocessedWardDate] = useState([])
  var [processedByWardDB, setDBprocessedByWard] = useState([])
  var [approvedByWardDB, setDBapprovedByWard] = useState([])
  var [approvedByWardDateDB, setDBapprovedByWardDate] = useState([])
  var [reasonWardDB, setDBreasonWard] = useState([])
  var [statusConstituencyDB, setDBstatusConstituency] = useState([])
  var [statusProcessedConstituencyDB, setDBstatusProcessedConstituency] =
    useState([])
  var [processedByConstituencyDB, setDBprocessedByConstituency] = useState([])
  var [processedConstituencyDateDB, setDBprocessedConstituencyDate] = useState(
    []
  )
  var [approvedByConstituencyDB, setDBapprovedByConstituency] = useState([])
  var [approvedConstituencyDateDB, setDBapprovedConstituencyDate] = useState([])
  var [reasonconstituencyDB, setDBreasonconstituency] = useState([])
  var [statusCouncilPrincipalDB, setDBstatusCouncilPrincipal] = useState([])
  var [processedByCouncilPrincipalDB, setDBprocessedByCouncilPrincipal] =
    useState([])
  var [processedCouncilPrincipalDateDB, setDBprocessedCouncilPrincipalDate] =
    useState([])
  var [approvedByCouncilPrincipalDB, setDBapprovedByCouncilPrincipal] =
    useState([])
  var [approvedCouncilPrincipalDateDB, setDBapprovedCouncilPrincipalDate] =
    useState([])
  var [reasonCouncilPrincipalDB, setDBreasonCouncilPrincipal] = useState([])
  var [tenureCouncilPrincipalDB, setDBtenureCouncilPrincipal] = useState([])
  var [statusPlanningDB, setDBstatusPlanning] = useState([])
  var [processedByPlanningDB, setDBprocessedByPlanning] = useState([])
  var [processedPlanningDateDB, setDBprocessedPlanningDate] = useState([])
  var [approvedByPlanningDB, setDBapprovedByPlanning] = useState([])
  var [approvedPlanningDateDB, setDBapprovedPlanningDate] = useState([])
  var [reasonPlanningDB, setDBreasonPlanning] = useState([])
  var [tenurePlanningDB, setDBtenurePlanning] = useState([])
  var [statusFinanceDB, setDBstatusFinance] = useState([])
  var [processedByFinanceDB, setDBprocessedByFinance] = useState([])
  var [processedFinanceDateDB, setDBprocessedFinanceDate] = useState([])
  var [approvedByFinanceDB, setDBapprovedByFinance] = useState([])
  var [approvedFinanceDateDB, setDBapprovedFinanceDate] = useState([])
  var [reasonFinanceDB, setDBreasonFinance] = useState([])
  var [tenureFinanceDB, setDBtenureFinance] = useState([])
  var [statusEngineeringDB, setDBstatusEngineering] = useState([])
  var [processedByEngineeringDB, setDBprocessedByEngineering] = useState([])
  var [processedEngineeringDateDB, setDBprocessedEngineeringDate] = useState([])
  var [approvedByEngineeringDB, setDBapprovedByEngineering] = useState([])
  var [approvedEngineeringDateDB, setDBapprovedEngineeringDate] = useState([])
  var [reasonEngineeringDB, setDBreasonEngineering] = useState([])
  var [tenureEngineeringDB, setDBtenureEngineering] = useState([])
  var [statusProcurementDB, setDBstatusProcurement] = useState([])
  var [processedByProcurementDB, setDBprocessedByProcurement] = useState([])
  var [processedProcurementDateDB, setDBprocessedProcurementDate] = useState([])
  var [approvedByProcurementDB, setDBapprovedByProcurement] = useState([])
  var [approvedProcurementDateDB, setDBapprovedProcurementDate] = useState([])
  var [reasonProcurementDB, setDBreasonProcurement] = useState([])
  var [tenureProcurementDB, setDBtenureProcurement] = useState([])
  var [statusMinistryDB, setDBstatusMinistry] = useState([])
  var [processedByMinistryDB, setDBprocessedByMinistry] = useState([])
  var [processedMinistryDateDB, setDBprocessedMinistryDate] = useState([])
  var [approvedByMinistryDB, setDBapprovedByMinistry] = useState([])
  var [approvedMinistryDateDB, setDBapprovedMinistryDate] = useState([])
  var [reasonMinistryDB, setDBreasonMinistry] = useState([])
  var [reasonProcessedWardDB, setDBreasonProcessedWard] = useState([])
  var [reasonProcessedConstituencyDB, setDBreasonProcessedConstituency] =
    useState([])
  var [reasonProcessedPlanningDB, setDBreasonProcessedPlanning] = useState([])
  var [reasonProcessedFinanceDB, setDBreasonProcessedFinance] = useState([])
  var [reasonProcessedEngineeringDB, setDBreasonProcessedEngineering] =
    useState([])
  var [reasonProcessedProcurementDB, setDBreasonProcessedProcurement] =
    useState([])
  var [
    reasonProcessedCouncilPrincipalDB,
    setDBreasonProcessedCouncilPrincipal
  ] = useState([])
  var [reasonProcessedMinistryDB, setDBreasonProcessedMinistry] = useState([])
  var [processedAmountWardDB, setDBprocessedAmountWard] = useState([])
  var [approvedAmountWardDB, setDBapprovedAmountWard] = useState([])
  var [processedAmountConstituencyDB, setDBprocessedAmountConstituency] =
    useState([])
  var [approvedAmountConstituencyDB, setDBapprovedAmountConstituency] =
    useState([])
  var [processedAmountMinistryDB, setDBprocessedAmountMinistry] = useState([])
  var [approvedAmountMinistryDB, setDBapprovedAmountMinistry] = useState([])

  var [validCertificateOfRegistrationDB, setDBvalidCertificateOfRegistration] =
    useState([])
  var [
    greenNationalRegistrationCardForIndividualApplicantsDB,
    setDBgreenNationalRegistrationCardForIndividualApplicants
  ] = useState([])
  var [activeBankAccountDB, setDBactiveBankAccount] = useState([])
  var [constitutionOfTheClubDB, setDBconstitutionOfTheClub] = useState([])
  var [
    copiesOfGreenNationalRegistrationCardsOfMembersDB,
    setDBcopiesOfGreenNationalRegistrationCardsOfMembers
  ] = useState([])
  var [copyOfSignedMinutesDB, setDBcopyOfSignedMinutes] = useState([])
  var [proofOfPropertyOwnershipDB, setDBproofOfPropertyOwnership] = useState([])
  var [
    applicantHasLivedInTheConstituencyMinimumOf6MonthsDB,
    setDBapplicantHasLivedInTheConstituencyMinimumOf6Months
  ] = useState([])

  var [
    isTheProjectLocatedWithinTheConstituencyDB,
    setDBisTheProjectLocatedWithinTheConstituency
  ] = useState([])
  var [isTheBusinessViableDB, setDBisTheBusinessViable] = useState([])
  var [
    howManyDirectJobsWillBeCreatedByTheProjectDB,
    setDBhowManyDirectJobsWillBeCreatedByTheProject
  ] = useState([])
  var [statusProvinceDB, setDBstatusProvince] = useState([])
  var [processedByProvinceDB, setDBprocessedByProvince] = useState([])
  var [processedProvinceDateDB, setDBprocessedProvinceDate] = useState([])
  var [approvedByProvinceDB, setDBapprovedByProvince] = useState([])
  var [approvedProvinceDateDB, setDBapprovedProvinceDate] = useState([])
  var [reasonProvinceDB, setDBreasonProvince] = useState([])
  var [reasonProcessedProvinceDB, setDBreasonProcessedProvince] = useState([])
  var [processedAmountProvinceDB, setDBprocessedAmountProvince] = useState([])
  var [approvedAmountProvinceDB, setDBapprovedAmountProvince] = useState([])

  /*if (activeBankAccountDB === 'true') {
    activeBankAccount = activeBankAccountDB
    //alert(activeBankAccount)
  }
  if (constitutionOfTheClubDB != '') {
    constitutionOfTheClub = constitutionOfTheClubDB
  }
  //let greenNationalRegistrationCardForIndividualApplicantsChecked = ''
  if (copiesOfGreenNationalRegistrationCardsOfMembersDB != '') {
    copiesOfGreenNationalRegistrationCardsOfMembers =
      copiesOfGreenNationalRegistrationCardsOfMembersDB
    //greenNationalRegistrationCardForIndividualApplicantsChecked = 'checked'
  }
  if (copyOfSignedMinutesDB != '') {
    copyOfSignedMinutes = copyOfSignedMinutesDB
  }
  if (proofOfPropertyOwnershipDB != '') {
    proofOfPropertyOwnership = proofOfPropertyOwnershipDB
  }
  if (applicantHasLivedInTheConstituencyMinimumOf6MonthsDB != '') {
    applicantHasLivedInTheConstituencyMinimumOf6Months =
      applicantHasLivedInTheConstituencyMinimumOf6MonthsDB
  }
  if (isTheProjectLocatedWithinTheConstituencyDB != '') {
    isTheProjectLocatedWithinTheConstituency =
      isTheProjectLocatedWithinTheConstituencyDB
  }
  if (isTheBusinessViableDB != '') {
    isTheBusinessViable = isTheBusinessViableDB
  }
  if (howManyDirectJobsWillBeCreatedByTheProjectDB != '') {
    howManyDirectJobsWillBeCreatedByTheProject =
      howManyDirectJobsWillBeCreatedByTheProjectDB
  }
  */
  //alert(activeBankAccount)
  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantGrantsForYouthWomenAndCommunityEmpowermentViewCommitteeMembers'
    )
    formData.append(
      'id',
      ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setGrantsCommitteeMembers(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantGrantsForYouthWomenAndCommunityEmpowermentViewSignatories'
    )
    formData.append(
      'id',
      ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setGrantsSignatories(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])
  //alert(ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID)
  useEffect(() => {
    var formData = new FormData(this)
    formData.append(
      'apiType',
      'getApplicantGrantsForYouthWomenAndCommunityEmpowermentView'
    )
    formData.append(
      'id',
      ApplicantGrantsForYouthWomenAndCommunityEmpowermentViewID
    )
    //formData.append('table', 'applyforcommunityproject')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setDBtableViewWardName(response.data)
      setgrantType(response.data.grantType)
      setgrantAmount(response.data.grantAmount)
      setnameOfGroupMakingApplication(
        response.data.nameOfGroupMakingApplication
      )
      setprovince(response.data.province)
      setcouncil(response.data.council)
      setconstituency(response.data.constituency)
      setward(response.data.ward)
      setzone(response.data.zone)
      setphysicalAddress(response.data.physicalAddress)
      setdateWhenGroupWasRegisteredWithRelevantAuthorities(
        response.data.dateWhenGroupWasRegisteredWithRelevantAuthorities
      )
      setexperienceInAProjectOfSimilarNature(
        response.data.experienceInAProjectOfSimilarNature
      )
      setexperienceInAProjectOfSimilarNatureYesReason(
        response.data.experienceInAProjectOfSimilarNatureYesReason
      )
      setwhatAreTheMainProblemsInYourCommunity(
        response.data.whatAreTheMainProblemsInYourCommunity
      )
      setwhatProblemIsTheProjectGoingToAddress(
        response.data.whatProblemIsTheProjectGoingToAddress
      )
      sethowDidTheGroupIdentifyTheProject(
        response.data.howDidTheGroupIdentifyTheProject
      )
      setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile(
        response.data.doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
      )
      setprojectIdentificationMinutesFile(
        response.data.projectIdentificationMinutesFile
      )
      setprojectIdentificationMinutes(
        response.data.projectIdentificationMinutes
      )
      sethowWillTheProjectBenefitTheCommunity(
        response.data.howWillTheProjectBenefitTheCommunity
      )
      sethowManyDirectJobsWillBeCreated(
        response.data.howManyDirectJobsWillBeCreated
      )
      sethaveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years(
        response.data.haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
      )
      setorganisationA(response.data.organisationA)
      setorganisationAAmount(response.data.organisationAAmount)
      setorganisationB(response.data.organisationB)
      setorganisationBAmount(response.data.organisationBAmount)
      setwhatIsTheStatusOfTheLoanTaken(
        response.data.whatIsTheStatusOfTheLoanTaken
      )
      setbankName(response.data.bankName)
      setbranch(response.data.branch)
      setsortOrBranchCode(response.data.sortOrBranchCode)
      setswiftCode(response.data.swiftCode)
      setaccountNumber(response.data.accountNumber)
      setTPIN(response.data.TPIN)
      setmobileMoneyWalletNameAndNumber(
        response.data.mobileMoneyWalletNameAndNumber
      )
      sethasyourGroupReceivedAnyTraining(
        response.data.hasyourGroupReceivedAnyTraining
      )
      setifTrainedHowManyMembers(response.data.ifTrainedHowManyMembers)
      setifYesFromWhichOrganizationAndHowLongWasTheTraining(
        response.data.ifYesFromWhichOrganizationAndHowLongWasTheTraining
      )
      settypeOrUploadProjectCommitteemembers(
        response.data.typeOrUploadProjectCommitteemembers
      )
      setname(response.data.name)
      setposition(response.data.position)
      setsex(response.data.sex)
      setnrc(response.data.nrc)
      setdeclarationDate(response.data.declarationDate)
      setsignatoriesToTheApplicationName(
        response.data.signatoriesToTheApplicationName
      )
      setsignatoriesToTheApplicationPosition(
        response.data.signatoriesToTheApplicationPosition
      )
      setsignatoriesToTheApplicationNrc(
        response.data.signatoriesToTheApplicationNrc
      )
      setprojectCommitteeMembersFile(response.data.projectCommitteeMembersFile)
      setprojectCommitteeMembersMinuteFile(
        response.data.projectCommitteeMembersMinuteFile
      )
      setProjectProposerName(response.data.ProjectProposerName)
      setProjectProposerNRC(response.data.ProjectProposerNRC)
      setProjectProposerAddress(response.data.ProjectProposerAddress)
      setProjectProposerPhone(response.data.ProjectProposerPhone)
      setProjectProposerDate(response.data.ProjectProposerDate)
      setSeconderName(response.data.SeconderName)
      setSeconderNRC(response.data.SeconderNRC)
      setSeconderAddress(response.data.SeconderAddress)
      setSeconderPhone(response.data.SeconderPhone)
      setSeconderDate(response.data.SeconderDate)
      setapplicationDate(response.data.applicationDate)

      setDBstatusWard(response.data.statusWard)
      setDBstatusProcessedWard(response.data.statusProcessedWard)
      setDBprocessedWardDate(response.data.processedWardDate)
      setDBprocessedByWard(response.data.processedByWard)
      setDBapprovedByWard(response.data.approvedByWard)
      setDBapprovedByWardDate(response.data.approvedByWardDate)
      setDBreasonWard(response.data.reasonWard)
      setDBstatusConstituency(response.data.statusConstituency)
      setDBstatusProcessedConstituency(
        response.data.statusProcessedConstituency
      )
      setDBprocessedByConstituency(response.data.processedByConstituency)
      setDBprocessedConstituencyDate(response.data.processedConstituencyDate)
      setDBapprovedByConstituency(response.data.approvedByConstituency)
      setDBapprovedConstituencyDate(response.data.approvedConstituencyDate)
      setDBreasonconstituency(response.data.reasonconstituency)
      setDBstatusCouncilPrincipal(response.data.statusCouncilPrincipal)
      setDBprocessedByCouncilPrincipal(
        response.data.processedByCouncilPrincipal
      )
      setDBprocessedCouncilPrincipalDate(
        response.data.processedCouncilPrincipalDate
      )
      setDBapprovedByCouncilPrincipal(response.data.approvedByCouncilPrincipal)
      setDBapprovedCouncilPrincipalDate(
        response.data.approvedCouncilPrincipalDate
      )
      setDBreasonCouncilPrincipal(response.data.reasonCouncilPrincipal)
      setDBtenureCouncilPrincipal(response.data.tenureCouncilPrincipal)
      setDBstatusPlanning(response.data.statusPlanning)
      setDBprocessedByPlanning(response.data.processedByPlanning)
      setDBprocessedPlanningDate(response.data.processedPlanningDate)
      setDBapprovedByPlanning(response.data.approvedByPlanning)
      setDBapprovedPlanningDate(response.data.approvedPlanningDate)
      setDBreasonPlanning(response.data.reasonPlanning)
      setDBtenurePlanning(response.data.tenurePlanning)
      setDBstatusFinance(response.data.statusFinance)
      setDBprocessedByFinance(response.data.processedByFinance)
      setDBprocessedFinanceDate(response.data.processedFinanceDate)
      setDBapprovedByFinance(response.data.approvedByFinance)
      setDBapprovedFinanceDate(response.data.approvedFinanceDate)
      setDBreasonFinance(response.data.reasonFinance)
      setDBtenureFinance(response.data.tenureFinance)
      setDBstatusEngineering(response.data.statusEngineering)
      setDBprocessedByEngineering(response.data.processedByEngineering)
      setDBprocessedEngineeringDate(response.data.processedEngineeringDate)
      setDBapprovedByEngineering(response.data.approvedByEngineering)
      setDBapprovedEngineeringDate(response.data.approvedEngineeringDate)
      setDBreasonEngineering(response.data.reasonEngineering)
      setDBtenureEngineering(response.data.tenureEngineering)
      setDBstatusProcurement(response.data.statusProcurement)
      setDBprocessedByProcurement(response.data.processedByProcurement)
      setDBprocessedProcurementDate(response.data.processedProcurementDate)
      setDBapprovedByProcurement(response.data.approvedByProcurement)
      setDBapprovedProcurementDate(response.data.approvedProcurementDate)
      setDBreasonProcurement(response.data.reasonProcurement)
      setDBtenureProcurement(response.data.tenureProcurement)
      setDBstatusMinistry(response.data.statusMinistry)
      setDBprocessedByMinistry(response.data.processedByMinistry)
      setDBprocessedMinistryDate(response.data.processedMinistryDate)
      setDBapprovedByMinistry(response.data.approvedByMinistry)
      setDBapprovedMinistryDate(response.data.approvedMinistryDate)
      setDBreasonMinistry(response.data.reasonMinistry)
      setDBreasonProcessedWard(response.data.reasonProcessedWard)
      setDBreasonProcessedConstituency(
        response.data.reasonProcessedConstituency
      )
      setDBreasonProcessedPlanning(response.data.reasonProcessedPlanning)
      setDBreasonProcessedFinance(response.data.reasonProcessedFinance)
      setDBreasonProcessedEngineering(response.data.reasonProcessedEngineering)
      setDBreasonProcessedProcurement(response.data.reasonProcessedProcurement)
      setDBreasonProcessedCouncilPrincipal(
        response.data.reasonProcessedCouncilPrincipal
      )
      setDBreasonProcessedMinistry(response.data.reasonProcessedMinistry)
      setDBprocessedAmountWard(response.data.processedAmountWard)
      setDBapprovedAmountWard(response.data.approvedAmountWard)
      setDBprocessedAmountConstituency(
        response.data.processedAmountConstituency
      )
      setDBapprovedAmountConstituency(response.data.approvedAmountConstituency)
      setDBprocessedAmountMinistry(response.data.processedAmountMinistry)
      setDBapprovedAmountMinistry(response.data.approvedAmountMinistry)

      setDBvalidCertificateOfRegistration(
        response.data.validCertificateOfRegistration
      )
      setDBgreenNationalRegistrationCardForIndividualApplicants(
        response.data.greenNationalRegistrationCardForIndividualApplicants
      )
      setDBactiveBankAccount(response.data.activeBankAccount)
      setDBconstitutionOfTheClub(response.data.constitutionOfTheClub)
      setDBcopiesOfGreenNationalRegistrationCardsOfMembers(
        response.data.copiesOfGreenNationalRegistrationCardsOfMembers
      )
      setDBcopyOfSignedMinutes(response.data.copyOfSignedMinutes)
      setDBproofOfPropertyOwnership(response.data.proofOfPropertyOwnership)
      setDBapplicantHasLivedInTheConstituencyMinimumOf6Months(
        response.data.applicantHasLivedInTheConstituencyMinimumOf6Months
      )
      setDBisTheProjectLocatedWithinTheConstituency(
        response.data.isTheProjectLocatedWithinTheConstituency
      )
      setDBisTheBusinessViable(response.data.isTheBusinessViable)
      setDBhowManyDirectJobsWillBeCreatedByTheProject(
        response.data.howManyDirectJobsWillBeCreatedByTheProject
      )
      setValidCertificateOfRegistration(
        response.data.validCertificateOfRegistration
      )
      setGreenNationalRegistrationCardForIndividualApplicants(
        response.data.greenNationalRegistrationCardForIndividualApplicants
      )
      setActiveBankAccount(response.data.activeBankAccount)
      setConstitutionOfTheClub(response.data.constitutionOfTheClub)
      setCopiesOfGreenNationalRegistrationCardsOfMembers(
        response.data.copiesOfGreenNationalRegistrationCardsOfMembers
      )
      setCopyOfSignedMinutes(response.data.copyOfSignedMinutes)
      setProofOfPropertyOwnership(response.data.proofOfPropertyOwnership)
      setApplicantHasLivedInTheConstituencyMinimumOf6Months(
        response.data.applicantHasLivedInTheConstituencyMinimumOf6Months
      )
      setIsTheProjectLocatedWithinTheConstituency(
        response.data.isTheProjectLocatedWithinTheConstituency
      )
      setIsTheBusinessViable(response.data.isTheBusinessViable)
      setHowManyDirectJobsWillBeCreatedByTheProject(
        response.data.howManyDirectJobsWillBeCreatedByTheProject
      )
      setDBstatusProvince(response.data.statusProvince)
      setDBprocessedByProvince(response.data.processedByProvince)
      setDBprocessedProvinceDate(response.data.processedProvinceDate)
      setDBapprovedByProvince(response.data.approvedByProvince)
      setDBapprovedProvinceDate(response.data.approvedProvinceDate)
      setDBreasonProvince(response.data.reasonProvince)
      setDBreasonProcessedProvince(response.data.reasonProcessedProvince)
      setDBprocessedAmountProvince(response.data.processedAmountProvince)
      setDBapprovedAmountProvince(response.data.approvedAmountProvince)

      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  /*
  if (
    statusWardDB != 'Pending Approval By WDC' &&
    recommendationByTheWardDevelopmentCommittee === ''
  ) {
    recommendationByTheWardDevelopmentCommittee = statusWardDB
  }

  if (
    reasonWardDB != '' &&
    recommendationByTheWardDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheWardDevelopmentCommitteeReason = reasonWardDB
  }

  if (
    statusConstituencyDB != 'Pending Approval By CDFC' &&
    recommendationByTheConstituencyDevelopmentCommittee === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommittee = statusConstituencyDB
  }

  if (
    reasonconstituencyDB != '' &&
    recommendationByTheConstituencyDevelopmentCommitteeReason === ''
  ) {
    recommendationByTheConstituencyDevelopmentCommitteeReason =
      reasonconstituencyDB
  }

  let wardDevelopmentCommitteeChairpersonName = ''
  let wardDevelopmentCommitteeChairpersonDate = ''
  let constituencyDevelopmentCommitteeChairpersonName = ''
  let constituencyDevelopmentCommitteeChairpersonDate = ''
  let wardDevelopmentCommitteePosition = 'Name (WDC Chairperson):'
  let constituencyDevelopmentCommitteePosition = 'Name (CDFC Chairperson):'
  //alert(approvedByWardDB)
  if (
    processedByWardDB != '' &&
    processedByWardDB != null &&
    (approvedByWardDB === '' || approvedByWardDB === null)
  ) {
    wardDevelopmentCommitteeChairpersonName = processedByWardDB
    wardDevelopmentCommitteeChairpersonDate = processedWardDateDB
    wardDevelopmentCommitteePosition = 'Name (WDC Member):'
  }
  if (approvedByWardDB != '' && approvedByWardDB != null) {
    wardDevelopmentCommitteeChairpersonName = approvedByWardDB
    wardDevelopmentCommitteeChairpersonDate = approvedByWardDateDB
  }

  if (
    processedByConstituencyDB != '' &&
    processedByConstituencyDB != null &&
    (approvedByConstituencyDB === '' || approvedByConstituencyDB === null)
  ) {
    constituencyDevelopmentCommitteeChairpersonName = processedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate =
      processedConstituencyDateDB
    constituencyDevelopmentCommitteePosition = 'Name (CDFC Member):'
  }

  if (approvedByConstituencyDB != '' && approvedByConstituencyDB != null) {
    constituencyDevelopmentCommitteeChairpersonName = approvedByConstituencyDB
    constituencyDevelopmentCommitteeChairpersonDate = approvedConstituencyDateDB
  }
*/
  var [
    validCertificateOfRegistrationCheck,
    setCheckvalidCertificateOfRegistration
  ] = useState([])
  var [
    greenNationalRegistrationCardForIndividualApplicantsCheck,
    setCheckgreenNationalRegistrationCardForIndividualApplicants
  ] = useState([])
  var [activeBankAccountCheck, setCheckactiveBankAccount] = useState([])
  var [constitutionOfTheClubCheck, setCheckconstitutionOfTheClub] = useState([])
  var [
    copiesOfGreenNationalRegistrationCardsOfMembersCheck,
    setCheckcopiesOfGreenNationalRegistrationCardsOfMembers
  ] = useState([])
  var [copyOfSignedMinutesCheck, setCheckcopyOfSignedMinutes] = useState([])
  var [proofOfPropertyOwnershipCheck, setCheckproofOfPropertyOwnership] =
    useState([])
  var [
    applicantHasLivedInTheConstituencyMinimumOf6MonthsCheck,
    setCheckapplicantHasLivedInTheConstituencyMinimumOf6Months
  ] = useState([])
  var [
    isTheProjectLocatedWithinTheConstituencyCheck,
    setCheckisTheProjectLocatedWithinTheConstituency
  ] = useState([])
  var [isTheBusinessViableCheck, setCheckisTheBusinessViable] = useState([])
  var [
    howManyDirectJobsWillBeCreatedByTheProjectCheck,
    setCheckhowManyDirectJobsWillBeCreatedByTheProject
  ] = useState([])

  const validCertificateOfRegistrationBtn = async e => {
    setValidCertificateOfRegistration('false')
  }
  const greenNationalRegistrationCardForIndividualApplicantsBtn = async e => {
    setGreenNationalRegistrationCardForIndividualApplicants('false')
  }
  const activeBankAccountBtn = async e => {
    setActiveBankAccount('false')
  }
  const constitutionOfTheClubBtn = async e => {
    setConstitutionOfTheClub('false')
  }
  const copiesOfGreenNationalRegistrationCardsOfMembersBtn = async e => {
    setCopiesOfGreenNationalRegistrationCardsOfMembers('false')
  }
  const copyOfSignedMinutesBtn = async e => {
    setCopyOfSignedMinutes('false')
  }
  const proofOfPropertyOwnershipBtn = async e => {
    setProofOfPropertyOwnership('false')
  }
  const applicantHasLivedInTheConstituencyMinimumOf6MonthsBtn = async e => {
    setApplicantHasLivedInTheConstituencyMinimumOf6Months('false')
  }
  const isTheProjectLocatedWithinTheConstituencyBtn = async e => {
    setIsTheProjectLocatedWithinTheConstituency('false')
  }
  const isTheBusinessViableBtn = async e => {
    setIsTheBusinessViable('false')
  }
  const howManyDirectJobsWillBeCreatedByTheProjectBtn = async e => {
    setHowManyDirectJobsWillBeCreatedByTheProject('false')
  }

  if (validCertificateOfRegistration === 'false') {
    validCertificateOfRegistrationDB = 'false'
  }
  if (greenNationalRegistrationCardForIndividualApplicants === 'false') {
    greenNationalRegistrationCardForIndividualApplicantsDB = 'false'
  }
  if (activeBankAccount === 'false') {
    activeBankAccountDB = 'false'
  }
  if (constitutionOfTheClub === 'false') {
    constitutionOfTheClubDB = 'false'
  }
  if (copiesOfGreenNationalRegistrationCardsOfMembers === 'false') {
    copiesOfGreenNationalRegistrationCardsOfMembersDB = 'false'
  }
  if (copyOfSignedMinutes === 'false') {
    copyOfSignedMinutesDB = 'false'
  }
  if (proofOfPropertyOwnership === 'false') {
    proofOfPropertyOwnershipDB = 'false'
  }
  if (applicantHasLivedInTheConstituencyMinimumOf6Months === 'false') {
    applicantHasLivedInTheConstituencyMinimumOf6MonthsDB = 'false'
  }
  if (isTheProjectLocatedWithinTheConstituency === 'false') {
    isTheProjectLocatedWithinTheConstituencyDB = 'false'
  }
  if (isTheBusinessViable === 'false') {
    isTheBusinessViableDB = 'false'
  }
  if (howManyDirectJobsWillBeCreatedByTheProject === 'false') {
    howManyDirectJobsWillBeCreatedByTheProjectDB = 'false'
  }

  return (
    <>
      <>
        <Header />
      </>
      <br /> <br /> <br />
      <br /> <br />
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text'>
                    {' '}
                    <span class='text-uppercase'>
                      {nameOfGroupMakingApplication} APPLICATION FORM - GRANTS
                      FOR YOUTH WOMEN AND COMMUNITY EMPOWERMENT
                    </span>
                  </div>
                  {/*  <!-- Grid row -->*/}
                  <div class='col-12'>
                    <br /> <br />
                    <section>
                      <div class='text-left'>
                        <center>
                          {' '}
                          <img src={require('./img/coat of arms.jpg')} />
                          <strong>
                            <br />
                            CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> GRANT
                            APPLICATION FORM FOR YOUTH, WOMEN AND COMMUNITY
                            EMPOWERMENT
                          </strong>
                        </center>
                        <br />
                        <strong>Instructions:</strong> This application form
                        should be completed by the applicant and sent together
                        with supporting documents to the Chairperson, Ward
                        Development Committee. <br />
                        <u class='text-left'>
                          <b>NOTE:</b>
                        </u>{' '}
                        This form is not for sale.
                        <br />
                        <i class='text-left'>
                          Disclaimer: Completion of the form does not guarantee
                          the award of the Grant.
                        </i>
                      </div>

                      <br />
                      <div
                        class='text-left'
                        style={{ border: '1px solid black' }}
                      >
                        Seed money to Cooperatives, Clubs and Organised Groups
                        to support Community Savings Groups (e.g. Village
                        Banking and Chilimba)
                        <br />
                        <div class='col-lg-3 col-md-12 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Grant Type <span class='red-text'>*</span>{' '}
                            </label>
                            <br />
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              value={grantType}
                            />
                          </div>
                        </div>
                      </div>

                      <br />
                      <div style={{ border: '1px solid black' }}>
                        <div class='col-lg-3 col-md-12 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Grant Amount (ZMW) <span class='red-text'>*</span>{' '}
                            </label>{' '}
                            <br />
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={grantAmount}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                    </section>
                    <br />
                    <div class='col-lg-3 col-md-12 mb-1 text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Application Date<span class='red-text'>*</span>{' '}
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        value={applicationDate}
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    <br />
                    {/*<!--\form heading -->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION A: GENERAL DETAILS{' '}
                          <span class='yellow-text'>
                            (To be filled by the applicant)
                          </span>{' '}
                        </h6>
                      </center>
                    </div>
                    <br />
                    {/*<!--Grid row-->			*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*<!--<div class="md-form">--> */}
                        <label for='registerFullName'>
                          Name of Club/Organised Group/Enterprise/Cooperative
                          making application <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={nameOfGroupMakingApplication}
                        />

                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->				*/}
                    <center>
                      <h4>
                        <u>PROJECT LOCATION </u>
                      </h4>
                    </center>
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/* <!--Grid column -->*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        <label for='registerFullName'>
                          Provinces<span class='red-text'></span>
                        </label>
                        <input
                          class='form-control'
                          type='text'
                          value={province}
                        />
                      </div>
                      {/* <!--\Grid column-->*/}

                      {/* <!--Grid column-->*/}
                      <div class='col-lg-4 col-md-12 mb-0.01 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Council<span class='red-text'></span>{' '}
                        </label>
                        <input
                          class='form-control'
                          type='text'
                          value={council}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Constituency<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          class='form-control'
                          type='text'
                          value={constituency}
                        />
                        {/*<!--</div>-->*/}
                      </div>

                      {/* <!--\Grid column-->*/}
                      <div class='col-lg-4 col-md-12 mb-1 text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Ward<span class='red-text'>*</span>{' '}
                        </label>
                        <input class='form-control' type='text' value={ward} />
                        {/*<!--</div>-->*/}
                      </div>

                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-3 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Zone<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={zone}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->	*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-5 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Physical Address<span class='red-text'>*</span>
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={physicalAddress}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/*<!--Grid row .-->*/}
                    {/*<!--Grid row-->				*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-lg-12 col-md-12 mb-1 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Date when Club/Organised Group/Enterprise/Cooperative
                          was registered with relevant authorities
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          type='date'
                          class='form-control'
                          Placeholder=''
                          value={
                            dateWhenGroupWasRegisteredWithRelevantAuthorities
                          }
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->		*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*<!--Grid row-->			*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-0.001 text-left'>
                        <label for='registerFullName'>
                          Does the Club/Organised Group/Enterprise/Cooperative
                          have any experience in a project of similar nature?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <input
                          class='form-control'
                          type='text'
                          value={experienceInAProjectOfSimilarNature}
                        />
                      </div>
                      {/*<!--\Grid column-->*/}

                      {experienceInAProjectOfSimilarNature === 'Yes' ? (
                        <>
                          <div
                            class='col-lg-12 col-md-12 mb-3 text-left'
                            id='divShowExperienceInAProjectOfSimilarNatureYesReason'
                          >
                            <label for='registerFullName'>
                              (If yes please give details)
                              <span class='red-text'>*</span>{' '}
                            </label>
                            <textarea
                              class='form-control'
                              value={
                                experienceInAProjectOfSimilarNatureYesReason
                              }
                            ></textarea>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--Grid row-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION B: PROJECT IDENTIFICATION{' '}
                        </h6>
                      </center>
                    </div>
                    {/*      <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*  <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          What Are The Main Problems In Your Community?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          value={whatAreTheMainProblemsInYourCommunity}
                        ></textarea>
                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*   <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          What Problem Is The Project Going To Address?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          value={whatProblemIsTheProjectGoingToAddress}
                        ></textarea>
                        {/*<!--</div>-->*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-12 mb-1 text-left'>
                        {/*  <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          How Did The Group Identify The Project? (Attach
                          minutes where applicable){' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          value={howDidTheGroupIdentifyTheProject}
                        ></textarea>
                        {/*<!--</div>-->*/}
                      </div>
                      {/*   <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/*  <!--Grid column-->	*/}
                      {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                      'Upload File' ? (
                        <>
                          <div
                            class='col-md-12 mb-1 text-left'
                            id='projectIdentificationUploadFile'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Miniutes File <span class='red-text'>*</span>
                              </label>
                              <br />
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                disabled
                              />
                            </div>
                          </div>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*  <!--\Grid column-->	*/}

                      {/* <!--Grid column-->	*/}
                      {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                      'Paste' ? (
                        <>
                          {' '}
                          <div
                            class='col-md-12 mb-1'
                            id='projectIdentificationPaste'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Miniutes <span class='red-text'>*</span>
                              </label>{' '}
                              <br />
                              <textarea
                                class='form-control'
                                Placeholder=''
                                value={projectIdentificationMinutes}
                              ></textarea>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* <!--\Grid column-->	*/}
                    </div>
                    {/* <!--\Grid row-->   */}
                    {/*<!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-8 mb-1 text-left'>
                        {/*    <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          How Will The Project Benefit The Community?{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <textarea
                          class='form-control'
                          Placeholder=''
                          value={howWillTheProjectBenefitTheCommunity}
                        ></textarea>
                        {/*<!--</div>-->*/}
                      </div>
                      {/*    <!--\Grid column-->		*/}
                      {/* <!--Grid column-->	*/}
                      <div class='col-md-4 mb-1 text-left'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            How Many Direct Jobs Will Be Created?{' '}
                            <span class='red-text'>*</span>
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='number'
                            class='form-control'
                            Placeholder=''
                            value={howManyDirectJobsWillBeCreated}
                            disabled
                          />
                        </div>
                      </div>
                      {/*    <!--\Grid column-->		*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    <div
                      class='card-header'
                      style={{ backgroundColor: 'green' }}
                    >
                      <center>
                        <h6 class='white-text'>
                          SECTION C: FINANCIAL ASSESSMENT{' '}
                        </h6>
                      </center>
                    </div>
                    {/* <!--Grid row-->				*/}
                    <div class='row'>
                      {/*<!--Grid column-->*/}
                      <div class='col-lg-12 col-md-2 mb-0.001 text-left'>
                        <label for='registerFullName'>
                          Have You Taken Any Loan From Any Organisation In The
                          Last 3 Years? <span class='red-text'>*</span>
                        </label>
                        <input
                          class='form-control'
                          type='text'
                          value={
                            haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years
                          }
                        />
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*  <!--Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    {haveYouTakenAnyLoanFromAnyOrganisationInTheLast3Years ===
                    'Yes' ? (
                      <>
                        {' '}
                        <div
                          class='row'
                          id='divShowHaveYouTakenAnyLoanFromAnyOrganisationInTheLast3YearsYesReason'
                        >
                          {/* <!--Grid column-->		*/}
                          <div class='col-lg-12 col-md-12 mb-3 text-left'>
                            <center>
                              <h6 class='mt-3'>
                                If yes, from which organisation and how much was
                                the loan?
                              </h6>
                            </center>
                          </div>
                          <div class='col-lg-6 col-md-12 mb-3 text-left'>
                            <label for='registerFullName'>
                              Organisation A<span class='red-text'>*</span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              value={organisationA}
                            />
                          </div>
                          {/*  <!--\Grid column-->*/}
                          {/* <!--Grid column-->			*/}
                          <div class='col-lg-6 col-md-12 mb-3 text-left'>
                            <label for='registerFullName'>
                              Organisation A Amount
                              <span class='red-text'>*</span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              value={organisationAAmount}
                            />
                          </div>
                          {/*<!--\Grid column-->*/}
                          {/*<!--Grid column-->			*/}
                          <div class='col-lg-3 col-md-12 mb-3 text-left'>
                            <label for='registerFullName'>
                              Organisation B<span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={organisationB}
                            />
                          </div>
                          {/* <!--\Grid column-->*/}
                          {/*<!--Grid column-->		*/}
                          <div class='col-lg-3 col-md-12 mb-3 text-left'>
                            <label for='registerFullName'>
                              Organisation B Amount
                              <span class='red-text'></span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={organisationBAmount}
                            />
                          </div>
                          {/* <!--\Grid column-->*/}
                          {/*<!--Grid column-->			*/}
                          <div class='col-lg-6 col-md-12 mb-3 text-left'>
                            <label for='registerFullName'>
                              What Is The Status Of The Loan Taken?
                              <span class='red-text'>*</span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              value={whatIsTheStatusOfTheLoanTaken}
                            />
                          </div>
                          {/*  <!--\Grid column-->*/}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      <div class='col-lg-12 col-md-12 mb-0.001'>
                        <center>
                          <h6>
                            Provide Bank Account Or Mobile Money Wallet
                            Registered For Your
                            Club/Group/Enterprise/Cooperative:
                          </h6>
                        </center>
                      </div>

                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Bank Name<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br /> <br />
                          <input
                            type='text'
                            class='form-control'
                            value={bankName}
                          />
                        </div>
                      </div>
                      {/*  <!--\Grid column-->*/}
                      {/*<!--Grid column-->			*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            branch<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            value={branch}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Sort / Branch Code<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={sortOrBranchCode}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-2 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Swift Code<span class='red-text'></span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={swiftCode}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Account Number<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={accountNumber}
                          />
                        </div>
                      </div>
                      {/*<!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            TPIN<span class='red-text'>*</span>{' '}
                          </label>{' '}
                          <br />
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={TPIN}
                          />
                        </div>
                      </div>
                      {/* <!--\Grid column-->*/}
                      {/*<!--Grid column-->		*/}
                      <div class='col-lg-4 col-md-12 mb-0.001 text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Mobile Money Wallet Name And Number
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={mobileMoneyWalletNameAndNumber}
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->*/}
                    </div>
                    {/*<!--\Grid row-->*/}
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*<!--Grid column-->			*/}
                      <div class='col-lg-12 col-md-12 mb-0.001 text-left'>
                        {/* <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Has your Club/Group/Enterprise/Cooperative received
                          any training in any of the following;
                          <br />
                          (a) Entrepreneurship/Business Skills
                          <br />
                          (b) Technical and Vocational Skills
                          <br />
                          (c) Savings
                          <br />
                          (d) Functional Literacy
                          <br />
                          (e) Financial literacy
                          <span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          class='form-control'
                          type='text'
                          value={hasyourGroupReceivedAnyTraining}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column-->		*/}
                    </div>
                    {/* <!--Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    {hasyourGroupReceivedAnyTraining === 'Yes' ? (
                      <>
                        {' '}
                        <div
                          class='row'
                          id='divShowHasyourGroupReceivedAnyTrainingYesReason'
                        >
                          {/* <!--Grid column-->		*/}
                          <div class='col-lg-12 col-md-12 mb-0.001'>
                            <br />
                            <center>
                              <h6 class='mt-3'>If Trained</h6>
                            </center>
                          </div>
                          <div class='col-lg-6 col-md-12 mb-3'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                How Many Members?<span class='red-text'>*</span>{' '}
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={ifTrainedHowManyMembers}
                              />
                            </div>
                          </div>
                          {/*  <!--\Grid column-->*/}
                          {/*<!--Grid column-->	*/}
                          <div class='col-lg-6 col-md-12 mb-3'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                From Which Organization And How Long Was The
                                Training?<span class='red-text'>*</span>{' '}
                              </label>{' '}
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={
                                  ifYesFromWhichOrganizationAndHowLongWasTheTraining
                                }
                              />
                            </div>
                          </div>
                          {/*<!--\Grid column-->		*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--Grid row-->*/}
                    {/*<!--Grid row-->			*/}
                    {typeOrUploadProjectCommitteemembers === 'Type' ? (
                      <>
                        {' '}
                        <div class='' id='projectCommitteeMembersType'>
                          {/*<!--Grid column-->*/}
                          <div class='col-md-12 mt-3'>
                            <center>
                              <i>
                                List Of Membership In The
                                Club/Group/Enterprise/Cooperative:
                              </i>
                            </center>
                          </div>
                          {/*<!--\Grid column--> */}

                          {inputList.map((x, i) => {
                            return (
                              <>
                                <div class='row'>
                                  <div class='col-md-1 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        {i + 1} .<span class='red-text'></span>
                                      </label>
                                      <br />
                                      <br />
                                    </div>
                                  </div>

                                  <div class='col-md-4 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        Name
                                        <span class='red-text'>*</span>
                                      </label>
                                      <br />
                                      <br />
                                      <input
                                        type='Text'
                                        class='form-control'
                                        value={
                                          x.grantApplicationCommiteeMembersName
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div class='col-md-3 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        Position
                                        <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br />
                                      <br />
                                      <input
                                        type='Text'
                                        class='form-control'
                                        value={
                                          x.grantApplicationCommiteeMembersPosition
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div class='col-lg-2 col-md-2 mb-2 text-left'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        {' '}
                                        Sex <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br /> <br />
                                      <input
                                        type='text'
                                        class='form-control'
                                        value={
                                          x.grantApplicationCommiteeMembersSex
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div class='col-md-2 mb-1'>
                                    <div class='md-form'>
                                      <label for='registerFullName'>
                                        NRC
                                        <span class='red-text'>*</span>
                                      </label>{' '}
                                      <br />
                                      <br />
                                      <input
                                        type='Text'
                                        class='form-control'
                                        value={
                                          x.grantApplicationCommiteeMembersNrc
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}

                          {/*<!--\Grid column--> 		*/}

                          <div class='col-md-12 mb-1'>
                            <center>
                              <h4>DECLARATION</h4>
                            </center>
                            <i>
                              We the undersigned, on{' '}
                              <input type='date' value={declarationDate} />
                              declare that the information given herein is the
                              correct state of affairs to the best of my
                              knowledge. We will take full responsibility in the
                              event of abuse, mismanagement, defrauding of the
                              funds provided under this empowerment fund:
                            </i>
                          </div>
                          <div class='col-md-12 mb-0.001'>
                            <br />{' '}
                            <center>
                              <h4>SIGNATORIES TO THE APPLICATION</h4>
                            </center>{' '}
                          </div>

                          {signatoriesList.map(
                            (SinglesignatoriesList, index) => {
                              return (
                                <>
                                  <div class='row'>
                                    <div class='col-md-1 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          {index + 1} .
                                          <span class='red-text'></span>
                                        </label>
                                        <br />
                                        <br />
                                      </div>
                                    </div>

                                    <div class='col-md-4 mb-0.001'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Name
                                          <span class='red-text'>*</span>
                                        </label>{' '}
                                        <br />
                                        <br />
                                        <input
                                          type='Text'
                                          class='form-control'
                                          placeholder=''
                                          value={
                                            SinglesignatoriesList.grantsSignatoriesName
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div class='col-md-4 mb-0.001'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Position
                                          <span class='red-text'>*</span>
                                        </label>{' '}
                                        <br />
                                        <br />
                                        <input
                                          type='Text'
                                          class='form-control'
                                          placeholder=''
                                          value={
                                            SinglesignatoriesList.grantsSignatoriesPosition
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div class='col-md-3 mb-0.001'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          NRC
                                          <span class='red-text'>*</span>
                                        </label>{' '}
                                        <br />
                                        <br />
                                        <input
                                          type='Text'
                                          class='form-control'
                                          placeholder=''
                                          value={
                                            SinglesignatoriesList.grantsSignatoriesNrc
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          )}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid row-->*/}
                    {/*<!--Grid row-->*/}
                    {typeOrUploadProjectCommitteemembers === 'Upload' ? (
                      <>
                        {' '}
                        <div class='row'>
                          {/*    <!--Grid column-->	*/}
                          <div
                            class='col-md-12 mb-1'
                            id='projectCommitteeMembersUpload'
                          >
                            <div class='md-form'>
                              <label for='registerFullName'>
                                Project Committee Members File{' '}
                                <span class='red-text'>*</span>
                              </label>
                              <br />
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                disabled
                              />
                            </div>
                          </div>
                          {/*     <!--\Grid column-->	*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid row-->*/}
                    <br />
                    <div class='col-md-12 mb-3 mb-5'>
                      <center>
                        <h4 class='red-text'>
                          Note: In the case where you have multiple members, the
                          signatories to the application must be limited up to 5
                          members.
                        </h4>
                      </center>
                    </div>
                    <h3>
                      <center>CONTACT PERSON(s): </center>
                    </h3>
                    <br />
                    <center>
                      <h4 class='red-text'>(Project Proposer)</h4>
                    </center>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/* <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*     <!--<div class="md-form">--->*/}
                        <label for='registerFullName'>
                          Name <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          placeholder=''
                          value={ProjectProposerName}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 				*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          NRC <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={ProjectProposerNRC}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Address <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={ProjectProposerAddress}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 		*/}
                      {/*   <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Phone <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={ProjectProposerPhone}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Date <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control datepicker'
                          value={ProjectProposerDate}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->			*/}
                    </div>
                    {/* <!--\Grid row-->*/}
                    <br />
                    <center>
                      <h4 class='red-text'>(Project Seconder)</h4>
                    </center>
                    {/* <!--Grid row-->*/}
                    <div class='row'>
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*      <!--<div class="md-form">--->*/}
                        <label for='registerFullName'>
                          Name <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={SeconderName}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          NRC <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={SeconderNRC}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> 		*/}
                      {/*  <!--Grid column-->*/}
                      <div class='col-md-3 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Address <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          value={SeconderAddress}
                        />
                        {/*  <!--</div>-->*/}
                      </div>
                      {/*<!--\Grid column--> 			*/}
                      {/*   <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*      <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Phone <span class='red-text'>*</span>
                        </label>
                        <input
                          type='Text'
                          class='form-control'
                          value={SeconderPhone}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column--> */}
                      {/* <!--Grid column-->*/}
                      <div class='col-md-2 mb-1 text-left'>
                        {/*       <!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Date <span class='red-text'>*</span>
                        </label>
                        <input
                          type='text'
                          class='form-control datepicker'
                          value={SeconderDate}
                        />
                        {/* <!--</div>-->*/}
                      </div>
                      {/* <!--\Grid column-->			*/}
                    </div>
                    {/* <!--\Grid row--> */}
                    <section class='section mt-5'>
                      {/*Grid row */}
                      <div class='row'>
                        {/* Grid column */}

                        <div class='col-lg-12 col-md-12'>
                          <div class='card mb-4'>
                            <div class='card-body'>
                              <div
                                class='form-header white-text'
                                style={{ backgroundColor: 'green' }}
                              >
                                <center>
                                  <span class='text-uppercase'>
                                    Application Checklist
                                  </span>
                                </center>
                              </div>
                              <table class='table large-header'>
                                <thead class='text-left'>
                                  <tr>
                                    <th class='font-weight-bold'>
                                      <strong>No</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Checklist</strong>
                                    </th>
                                    <th class='font-weight-bold'>
                                      <strong>Yes / No</strong>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody class='text-left'>
                                  <tr>
                                    <td>1</td>
                                    <td>Valid Certificate of Registration</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {validCertificateOfRegistrationDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox1'
                                            value={
                                              validCertificateOfRegistration
                                            }
                                            onChange={e =>
                                              setValidCertificateOfRegistration(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox1'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            validCertificateOfRegistrationDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            validCertificateOfRegistrationBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>
                                      Green National Registration Card for
                                      Individual Applicants
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {greenNationalRegistrationCardForIndividualApplicantsDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox2'
                                            value={
                                              greenNationalRegistrationCardForIndividualApplicants
                                            }
                                            onChange={e =>
                                              setGreenNationalRegistrationCardForIndividualApplicants(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}
                                        <label
                                          class='form-check-label'
                                          for='checkbox2'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            greenNationalRegistrationCardForIndividualApplicantsDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            greenNationalRegistrationCardForIndividualApplicantsBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Active Bank Account</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {activeBankAccountDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox3'
                                            value={activeBankAccount}
                                            onChange={e =>
                                              setActiveBankAccount(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}
                                        <label
                                          class='form-check-label'
                                          for='checkbox3'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={activeBankAccountDB != 'true'}
                                          title='Cancel Check'
                                          type='button'
                                          onClick={activeBankAccountBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>
                                      Constitution of the
                                      Club/Group/Enterprise/Cooperative:
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {constitutionOfTheClubDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox4'
                                            value={constitutionOfTheClub}
                                            onChange={e =>
                                              setConstitutionOfTheClub(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}
                                        <label
                                          class='form-check-label'
                                          for='checkbox4'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            constitutionOfTheClubDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={constitutionOfTheClubBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>
                                      Copies of Green National Registration
                                      Cards of members
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {copiesOfGreenNationalRegistrationCardsOfMembersDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox5'
                                            value={
                                              copiesOfGreenNationalRegistrationCardsOfMembers
                                            }
                                            onChange={e =>
                                              setCopiesOfGreenNationalRegistrationCardsOfMembers(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}
                                        <label
                                          class='form-check-label'
                                          for='checkbox5'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            copiesOfGreenNationalRegistrationCardsOfMembersDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            copiesOfGreenNationalRegistrationCardsOfMembersBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>Copy of Signed Minutes</td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {copyOfSignedMinutesDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox6'
                                            value={copyOfSignedMinutes}
                                            onChange={e =>
                                              setCopyOfSignedMinutes(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox6'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            copyOfSignedMinutesDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={copyOfSignedMinutesBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>
                                      Proof of property ownership/lease/consent
                                      agreement (Where Applicable)
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {proofOfPropertyOwnershipDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox7'
                                            value={proofOfPropertyOwnership}
                                            onChange={e =>
                                              setProofOfPropertyOwnership(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox7'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            proofOfPropertyOwnershipDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={proofOfPropertyOwnershipBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>
                                      Applicant has lived in the Constituency
                                      minimum of 6 months
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {applicantHasLivedInTheConstituencyMinimumOf6MonthsDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox8'
                                            value={
                                              applicantHasLivedInTheConstituencyMinimumOf6Months
                                            }
                                            onChange={e =>
                                              setApplicantHasLivedInTheConstituencyMinimumOf6Months(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox8'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            applicantHasLivedInTheConstituencyMinimumOf6MonthsDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            applicantHasLivedInTheConstituencyMinimumOf6MonthsBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>9</td>
                                    <td>
                                      Is the project located within the
                                      Constituency
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {isTheProjectLocatedWithinTheConstituencyDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox9'
                                            value={
                                              isTheProjectLocatedWithinTheConstituency
                                            }
                                            onChange={e =>
                                              setIsTheProjectLocatedWithinTheConstituency(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox9'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            isTheProjectLocatedWithinTheConstituencyDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            isTheProjectLocatedWithinTheConstituencyBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>10</td>
                                    <td>
                                      Is the business viable (increase in
                                      working capital, profits )
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {isTheBusinessViableDB === 'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox10'
                                            value={isTheBusinessViable}
                                            onChange={e =>
                                              setIsTheBusinessViable(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox10'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            isTheBusinessViableDB != 'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={isTheBusinessViableBtn}
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>11</td>
                                    <td>
                                      How many direct jobs will be created by
                                      the project?
                                    </td>
                                    <td>
                                      <fieldset class='form-check'>
                                        {howManyDirectJobsWillBeCreatedByTheProjectDB ===
                                        'true' ? (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox11'
                                            checked='checked'
                                          />
                                        ) : (
                                          <input
                                            class='form-check-input'
                                            type='checkbox'
                                            id='checkbox11'
                                            value={
                                              howManyDirectJobsWillBeCreatedByTheProject
                                            }
                                            onChange={e =>
                                              setHowManyDirectJobsWillBeCreatedByTheProject(
                                                e.target.checked
                                              )
                                            }
                                          />
                                        )}

                                        <label
                                          class='form-check-label'
                                          for='checkbox11'
                                        ></label>
                                        <label
                                          class='fa fa-times red-text'
                                          hidden={
                                            howManyDirectJobsWillBeCreatedByTheProjectDB !=
                                            'true'
                                          }
                                          title='Cancel Check'
                                          type='button'
                                          onClick={
                                            howManyDirectJobsWillBeCreatedByTheProjectBtn
                                          }
                                        ></label>
                                      </fieldset>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Grid column */}
                      </div>
                    </section>
                    <section>
                      <div
                        class='card-header mt-5 mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION D: RECOMMENDATION BY THE WARD DEVELOPMENT
                            COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/*  <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Ward Development Committee{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            //name='recommendationByTheWardDevelopmentCommittee'
                            value={recommendationByTheWardDevelopmentCommittee}
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Recommended</option>
                            <option>Not Recommended</option>
                            <option>Defer</option>
                          </select>
                        </div>
                        {/*   <!--\Grid column-->				 */}
                        {/*     <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*       <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            name='recommendationByTheWardDevelopmentCommitteeReason'
                            value={
                              recommendationByTheWardDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheWardDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                            required
                          >
                            {' '}
                          </textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->				*/}
                      </div>
                      {/* <!--\Grid row--> */}
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (WDC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/*  <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonSign'
                              id='wardDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/*  <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='wardDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row--> */}

                      <section
                        class='section mt-5'
                        hidden={
                          processedByWardDB === '' || processedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonProcessedWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Proccessed By
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={processedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={processedWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByWardDB === '' || approvedByWardDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/*  <!--Grid row--> */}
                                  <div class='row'>
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={statusWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                    {/*     <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*       <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        name='recommendationByTheWardDevelopmentCommitteeReason'
                                        value={reasonWardDB}
                                      >
                                        {' '}
                                      </textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->				*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (WDC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonName'
                                          value={approvedByWardDB}
                                        />
                                      </div>
                                    </div>
                                    {/*  <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonSign'
                                          id='wardDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/*  <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='wardDevelopmentCommitteeChairpersonDate'
                                          value={approvedByWardDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row--> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        onClick={handleSubmitWards}
                        disabled={
                          recommendationByTheWardDevelopmentCommittee === '' ||
                          recommendationByTheWardDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Chairperson' &&
                          userPosition != 'WDC Member'
                        }
                      >
                        Submit
                      </button>
                    </section>
                    <section
                      hidden={
                        userPosition === 'Councilor' ||
                        userPosition === 'WDC Chairperson' ||
                        userPosition === 'WDC Member'
                      }
                    >
                      <div
                        class='card-header mb-4'
                        style={{ backgroundColor: 'green' }}
                      >
                        <center>
                          <h6 class='white-text'>
                            {' '}
                            SECTION E: DECISION BY THE CONSTITUENCY DEVELOPMENT
                            FUND COMMITTEE{' '}
                          </h6>
                        </center>
                      </div>
                      {/* <!--Grid row--> */}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/* <!--Grid column--> */}
                        <div class='col-lg-5 col-md-2 mb-2 text-left'>
                          <label for='registerFullName'>
                            {' '}
                            Recommendation By The Constituency Development
                            Committee <span class='red-text'>*</span>
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            value={
                              recommendationByTheConstituencyDevelopmentCommittee
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommittee(
                                e.target.value
                              )
                            }
                          >
                            <option value=''>Choose...</option>
                            <option>Approved</option>
                            <option>Not Approved</option>
                          </select>
                        </div>
                        {/*  <!--\Grid column-->		*/}
                        {/*      <!--Grid column-->	*/}
                        <div class='col-md-7 mb-2 text-left'>
                          {/*      <!--<div class="md-form">--> */}
                          <label for='registerFullName'>
                            Reasons For Recommendation{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            value={
                              recommendationByTheConstituencyDevelopmentCommitteeReason
                            }
                            onChange={e =>
                              setRecommendationByTheConstituencyDevelopmentCommitteeReason(
                                e.target.value
                              )
                            }
                          ></textarea>
                          {/* <!--</div>--> */}
                        </div>
                        {/*     <!--\Grid column-->		*/}
                      </div>
                      {/* <!--\Grid row-->*/}
                      {/* <!--Grid row-->*/}
                      <div
                        class='row'
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                      >
                        {/*<!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name (CDFC Chairperson)
                              <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonName'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column-->*/}
                        {/* <!--Grid column--> */}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Sign: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='text'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonSign'
                              id='constituencyDevelopmentCommitteeChairpersonSign'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                        {/* <!--Grid column-->*/}
                        <div class='col-md-4 mb-1 text-left'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Date: <span class='red-text'></span>
                            </label>{' '}
                            <br /> <br />
                            <input
                              type='date'
                              class='form-control'
                              placeholder=''
                              name='constituencyDevelopmentCommitteeChairpersonDate'
                            />
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                      </div>

                      <section
                        class='section mt-5'
                        hidden={
                          processedByConstituencyDB === '' ||
                          processedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>PROCESSED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonProcessedConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Processed By :
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={processedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={processedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        class='section mt-5'
                        hidden={
                          approvedByConstituencyDB === '' ||
                          approvedByConstituencyDB === null
                        }
                      >
                        <div class='row'>
                          <div class='col-lg-12 col-md-12'>
                            <div class='card mb-4'>
                              <div class='card-body'>
                                <div
                                  class='form-header'
                                  style={{ backgroundColor: 'white' }}
                                >
                                  <center>
                                    <span class='text-uppercase'>
                                      <h4 class='blue-text'>APPROVED</h4>
                                    </span>
                                  </center>
                                </div>

                                <div class='mb-5 pl-1 pr-1'>
                                  {/* <!--Grid row--> */}
                                  <div class='row'>
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Recommendation
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={statusConstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                    {/*      <!--Grid column-->	*/}
                                    <div class='col-md-6 mb-2 text-left'>
                                      {/*      <!--<div class="md-form">--> */}
                                      <label for='registerFullName'>
                                        Reasons For Recommendation{' '}
                                        <span class='red-text'>*</span>
                                      </label>
                                      <textarea
                                        class='form-control'
                                        value={reasonconstituencyDB}
                                      ></textarea>
                                      {/* <!--</div>--> */}
                                    </div>
                                    {/*     <!--\Grid column-->		*/}
                                  </div>
                                  {/* <!--\Grid row-->*/}
                                  {/* <!--Grid row-->*/}
                                  <div class='row'>
                                    {/*<!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Approved By (CDFC Chairperson)
                                          <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonName'
                                          value={approvedByConstituencyDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column-->*/}
                                    {/* <!--Grid column--> */}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Sign: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonSign'
                                          id='constituencyDevelopmentCommitteeChairpersonSign'
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                    {/* <!--Grid column-->*/}
                                    <div class='col-md-4 mb-1 text-left'>
                                      <div class='md-form'>
                                        <label for='registerFullName'>
                                          Date: <span class='red-text'></span>
                                        </label>{' '}
                                        <br /> <br />
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder=''
                                          name='constituencyDevelopmentCommitteeChairpersonDate'
                                          value={approvedConstituencyDateDB}
                                        />
                                      </div>
                                    </div>
                                    {/* <!--\Grid column--> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <button
                        class='btn btn-primary  btn-md btn-block mb-5 mt-3'
                        type='submit'
                        disabled={
                          recommendationByTheConstituencyDevelopmentCommittee ===
                            '' ||
                          recommendationByTheConstituencyDevelopmentCommitteeReason ===
                            ''
                        }
                        hidden={
                          userPosition != 'CDF Committee Chairperson' &&
                          userPosition != 'CDF Committee Member'
                        }
                        onClick={handleSubmitConstituencies}
                      >
                        Submit
                      </button>
                      {/* <!-- Grid row -->*/}
                    </section>
                  </div>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default ApplicantGrantsForYouthWomenAndCommunityEmpowermentView
