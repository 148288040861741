
import {useRef, useState, useEffect} from 'react';
import '../App.css';
import Axios from '../api/axios';

const RegisterBusinessBranch = () => {

const branchRef = useRef();
const errRef = useRef();

const [branch, setBranch] = useState('');
const [address, setAddress] = useState('');
const [ip,setIP] = useState('');

const [errMsg, setErrMsg ] = useState('');
//const [success, setSuccess ] = useState(false);

useEffect( () => {
	branchRef.current.focus();
},
[])


   //creating function to load ip address from the API
    const getData = async()=>{
        const res = await Axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
    }
  useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])

	  
const handleSubmit = async (e) => 
{
    Axios.post("http://localhost:3001/api/insertRegisterBusinessBranch", {

		branch : branch,
		address : address,
		system_setup_branch_IP : ip,
		
    }).then((res) => {	alert(res.data);
  
	  })  
	  .catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
	
    //alert ("Successfully Uploaded");
	/*return(
	<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">&times;</button>
Successfully Uploaded</div>
	)*/
	
    //console.log(user);
    //console.log(pwd);
	//alert ("Successfully Uploaded : " +pacraCertificateFileName);
	//alert(JSON.stringify(formData));
}

    return(
	
	<main>
	{/*<form method="POST" encType="multipart/form-data">*/}
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 mb-2">
          <div class="card">
            <div class="card-body" id="faults">
	<div id="divShowMessageFromAjax"></div>
	 <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
	aria-live = "assertive"> {errMsg} </p>
		<div class="form-header white-text"> REGISTER BUSINESS BRANCH HERE   </div>
             
							<input  type="hidden" class="form-control"  id="ip" value={ip} />
<div class="row">							
				  <div class="col-lg-12 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Branch Name <i class="red-text">*</i> </label><br />
						<input  type="text" class="form-control"  id="branch" onChange={(e) => setBranch(e.target.value)}
						 ref= {branchRef}
						 autoComplete = "off" value={branch} required
						/>
					</div>
					</div>
					

					</div> {/*End Of Row 2*/}
				
				

 <div class="row">	
					<div class="col-lg-12 col-md-12 mb-1">
				  <div class="md-form">
						<label for="registerFullName">Address <i class="red-text">*</i> </label><br />
						<textarea  type="text" class="form-control"  id="address" onChange={(e) => setAddress(e.target.value)}
						autoComplete = "off" value={address} required

						/>
					</div>
					</div>
					
			</div> {/*End Of Row 4*/}
					
			<br />
              <button onClick= {handleSubmit} class="btn btn-block btn-primary"
			  disabled={branch ===''  || address==='' }
			  >Submit</button>
						
					
				
          </div>
        </div>
     
      </div>
    </div>
    </div>
	{/*</form>*/}
  </main>

    )

}

export default RegisterBusinessBranch;